<div class="card" style="padding: 24px 36px 32px;gap: 20px">
  <div class="d-flex align-self-center">
    <mat-icon *ngIf="icon" [svgIcon]="icon" style="height: 36px; width: 36px"></mat-icon>
    <img *ngIf="image" [ngSrc]="image" alt="" height="200" style="background-color: transparent;" width="201">
  </div>
  <div class="d-flex flex-column" style="gap:32px">
    <div class="d-flex flex-column align-items-center" style="gap: 16px">
      <h5>{{ title }}</h5>
      <div [innerHTML]="textContent" style="text-align:center; white-space: pre-line"></div>
    </div>
    <div class="d-flex justify-content-center">
      <button (click)="dialogRef.close()" class="sp-btn sp-btn-secondary">
        {{ buttonText }}
      </button>
    </div>
  </div>
</div>
