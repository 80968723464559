import { NgModule } from '@angular/core';
import { SpAccountUserEditComponent } from './sp-account-user-edit/sp-account-user-edit.component';
import { SpAccountRoutingModule } from './sp-account-routing.module';
import {CommonModule} from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import {MessagesModule} from 'primeng/messages';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SpModalFacebookAccountAddComponent} from './sp-account-user-edit/sp-modal-facebook-account-add/sp-modal-facebook-account-add.component';
import {FilterPipe} from './sp-account-user-edit/sp-modal-facebook-account-add/filter.pipe';
import {SpAccountUserEditImageComponent} from './sp-account-user-edit/sp-account-user-edit-image/sp-account-user-edit-image.component';

@NgModule({
    declarations: [
        SpAccountUserEditComponent,
        SpModalFacebookAccountAddComponent,
        SpAccountUserEditImageComponent,
        FilterPipe
    ],
    imports: [
        SpAccountRoutingModule,
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatDatepickerModule,
        MessagesModule,
        MatTableModule,
        MatPaginatorModule,
        MatNativeDateModule,
        MatButtonModule,
        MatInputModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        FormsModule,
        MatTooltipModule,
        MatSortModule
    ],
    exports: [
        FilterPipe
    ]
})
export class SpAccountModule {}
