import {Model} from "./model";
import {User} from "./user";
import {Subscription} from "./subscription";
import {HasPermission} from './has-permission';
import {Permission} from './permission';
import {NamedItem} from '../pipes/array-name-join.pipe';

export class Company implements HasPermission, NamedItem {
  id: number;
  name: string;
  street: string;
  city: string;
  postcode: string;
  state: string;
  country: string;
  image: string;
  tin: string;
  credits_used: number;
  users: Array<User>;
  subscription: Subscription;
  default_segmentation_universe?: number;
  created_at: Date;
  updated_at: Date;
  permissions: Permission[];
  audiense_id?: number;

  // API custom fields not always applicable
  payment_frequency?: string;
  planName?: string;

  constructor(name: string) {
    this.name = name;
  }
}
