export const FACEBOOK_GE0_SUPPORT: string[] = [
  'country_group', 'country', 'region', 'city', 'subcity', 'neighborhood', 'zip', 'medium_geo_area'
];

export class FbGeolocation {
  country_code?: string;
  country_name?: string;
  key: string;
  name: string;
  region?: string;
  region_id?: number;
  primary_city?: string;
  primary_city_id?: number;
  supports_city?: boolean;
  supports_region?: boolean;
  type: string;

  static fromCountryKey(key: string): FbGeolocation {
    const geo = new FbGeolocation();
    geo.country_code = key;
    geo.country_name = key;
    geo.key = key;
    geo.name = key;
    geo.type = 'country';
    return geo;
  }

  static fromCountryGroupKey(key: string): FbGeolocation {
    const geo = new FbGeolocation();
    geo.key = key;
    geo.name = key[0].toUpperCase() + key.slice(1);
    geo.type = 'country_group';
    return geo;
  }
}

export interface FbGeolocationGroup {
  geolocations: FbGeolocation[];
  name: string;
}

export class FbCriteria {
  id: string;
  name: string;
  audience_size?: number;
  audience_size_lower_bound: number;
  audience_size_upper_bound: number;
  path: string[];
  type: string;
}

export class CriteriaExtra {
  id: string;
  accountId: string;
  accountName: string;
  accountToken: string;
  subtype: string;
}

// export type FbLocale = KeyName;
export class FbLocale implements KeyName {
  key: string;
  name: string;

  toString() {
    return this.name;
  }
}

// export type FbRelationshipStatus = KeyName;
export class FbRelationshipStatus implements KeyName {
  key: string;
  name: string;

  constructor(key: string, name: string) {
    this.key = key;
    this.name = name;
  }

  toString() {
    return this.name;
  }
}

export class FlexSpec extends Map<string, Array<Map<string, string>|number>> {
  mergeWith(spec: FlexSpec): FlexSpec {
    const flex = new FlexSpec(this);
    const other = new FlexSpec(spec);

    Array.from(other.keys()).forEach(k => {
      if (flex.has(k)) {
        const ar = flex.get(k);
        other.get(k).forEach(c => ar.push(c));
      } else {
        flex.set(k, other.get(k));
      }
    });

    return flex;
  }
}

export interface LookalikeSpec {
  country?: string;
  target_countries?: string[];
  origin: Array<IdNameType>;
  ratio: number;
  type: string;
}

export interface IdName {
  id: string;
  name: string;
}

interface IdNameType extends IdName {
  type: string;
}

export interface KeyName {
  key: string;
  name: string;
  [propName: string]: any; // Extra params
}

export interface MediumGeoAreaKeyName extends KeyName {
  country_code?: string;
  country_name?: string;
  region?: string;
  region_id?: number;
  supports_region?: boolean;
  supports_city?: boolean;
  geo_hierarchy_level?: string;
  geo_hierarchy_name?: string;
}

export interface CityKeyName extends KeyName {
  country_code?: string;
  country_name?: string;
  region?: string;
  region_id?: number;
  supports_region?: boolean;
  supports_city?: boolean;
  radius?: number;
  distance_unit?: string;
  country?: string;
}

export interface SubCityKeyName extends CityKeyName {
  geo_hierarchy_level?: string;
  geo_hierarchy_name?: string;
}

export type GeoCountryGroup = string;
export type GeoCountry = string;
export type GeoRegion = KeyName;
export type GeoMediumGeoArea = MediumGeoAreaKeyName;
export type GeoCity = CityKeyName;
export type GeoSubCity = SubCityKeyName;
export type GeoNeighborhood = SubCityKeyName;
export interface GeoZip extends KeyName {
  primary_city_id?: number;
  region_id?: number;
  country?: string;
}

export class Geolocation {
  country_groups?: Array<GeoCountryGroup>;
  countries?: Array<GeoCountry>;
  regions?: Array<GeoRegion>;
  medium_geo_areas?: Array<GeoMediumGeoArea>;
  cities?: Array<GeoCity>;
  zips?: Array<GeoZip>;
  subcities?: Array<GeoSubCity>;
  neighborhoods?: Array<GeoNeighborhood>;
  location_types?: Array<string>;

  // Using static method because geolocation ends up being more like an interface than a real class
  static isEmpty(geolocation: Geolocation) {
    const countryGroups = !geolocation.country_groups || geolocation.country_groups.length == 0;
    const countries = !geolocation.countries || geolocation.countries.length == 0;
    const regions = !geolocation.regions || geolocation.regions.length == 0;
    const cities = !geolocation.cities || geolocation.cities.length == 0;
    const zips = !geolocation.zips || geolocation.zips.length == 0;
    const subcities = !geolocation.subcities || geolocation.subcities.length == 0;
    const neighborhoods = !geolocation.neighborhoods || geolocation.neighborhoods.length == 0;

    return countryGroups && countries && regions && cities && zips && subcities && neighborhoods;
  }
}
