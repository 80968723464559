
<mat-menu #childMenu="matMenu"  (mousemove)="testMouse()" [hasBackdrop]="true"  [overlapTrigger]="false" class="sp-menu-preset">
  <span *ngFor="let child of items">
    <span *ngIf="child.children && child.children.length > 0">
      <button #trigger="matMenuTrigger" [matMenuTriggerFor]="menu" mat-menu-item>
        <span>{{child.value.name}}</span>
      </button>

      <mat-menu #menu  (mousemove)="testMouse()" [hasBackdrop]="false" class="sp-menu-preset">
        <span *ngFor="let chi of child.children">
          <!--<button (click)="onSelected(chi.value)" [matTooltip]="chi.value.description" mat-menu-item matTooltipPosition="right">-->
          <button (click)="onSelected(chi.value)" mat-menu-item>
            <span>{{chi.value.name}}</span>
          </button>
        </span>
      </mat-menu>
<!--
      <app-sp-project-create-audience-preset-menu (onSelect)="onSelected($event.selected)" #menu [items]="child.children"></app-sp-project-create-audience-preset-menu>
    -->
    </span>

    <span *ngIf="!child.children || child.children.length === 0">
      <!--<button (click)="onSelected(child.value)" [matTooltip]="child.value.description" mat-menu-item matTooltipPosition="right">-->
      <button (click)="onSelected(child.value)" mat-menu-item>
        <span>{{child.value.name}}</span>
      </button>
    </span>
  </span>


</mat-menu>
