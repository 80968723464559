import { Injectable } from '@angular/core';
import {ApiModelService} from '../sp-api-model/api-model.service';
import {Dashboard} from '../../../models/dashboard';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from '../../sp-logger/logger.service';
import {DashboardData, GraphSpec, Link} from '../../sp-reporting/reporting-manager.service';
import {DashboardShared} from "../../../models/dashboard-shared";
import {firstValueFrom} from "rxjs";
import {Project} from "../../../models/project";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiDashboardService extends ApiModelService<Dashboard>{
  protected readonly basePath = "dashboards";
  constructor(httpClient: HttpClient, logger: LoggerService) {
    super(httpClient, logger);
  }

  public share(dash: Dashboard, data: DashboardData) {
    const share: DashboardShared = { content: { scheme: dash.scheme, data } };
    return firstValueFrom(this.post<DashboardShared>(this.basePath + '/share', share));
  }

  public getShared(shareId: string) {
    return firstValueFrom(this.get<DashboardShared>(this.basePath + '/shared/' + shareId));
  }

  public presets() {
    return firstValueFrom(this.get<Dashboard[]>(this.basePath + '/presets'));
  }

  public generatePptx(specs: GraphSpec[], links: Link[], project: Project, target: string) {
    return this.postRaw(environment.config.pptx.api + "/pptxgen", {specs, links, project, type: target});
  }
}
