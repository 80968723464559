<div *ngIf="!freemium">
  <div *ngIf="options.allowAudienceImport" class="row no-gutters mb-5 sp-audience-setup">
    <div [ngClass]="{ 'col-12': true }" >
      <div class="sp-card">
        <div class="card">
          <div class="card-body">
            <mat-form-field appearance="outline" class="w-100 p-4 sp-form-field">
              <mat-label class="sp-input-label">{{ options.importFieldName ? options.importFieldName : 'Facebook Audience' }}</mat-label>
              <mat-select no-space (selectionChange)="onAudienceImportSelectChange($event.value)" [placeholder]="audiencesLoaded ? (importedAudiences.length > 0 ? 'Select your audience to import from facebook' : 'No audience found') : 'Loading your audiences, please wait...'" class="sp-input-content" panelClass="sp-select-option-icon">
                <input [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" aria-label="Number" class="sp-input-search-content" matInput placeholder="Pick one" type="text">
                <mat-option *ngFor="let audience of importedAudiences |  FilterPipe : searchText" [value]="audience">{{ audience.name + ' | ' + (audience.fb_size | numberFormat) }}</mat-option>
              </mat-select>
              <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="This menu allows you to access to your facebook’s audiences and select it to analyze it in SOPRISM." matTooltipHideDelay="10">
              <span class="">i</span>
            </span>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="options.allowGeolocationEditing" class="row no-gutters pb-5">
    <div class="w-100 card">
      <div class="card-header">
        <h5 class="pl-3">Audience location</h5>
      </div>
      <div class="card-body p-3 sp-project-setup-localization">

        <div class="sp-project-setup-localization-div ">

          <div class="sp-project-setup-localization-div-left" style="">
            <mat-form-field (click)="resetSearchInput()" appearance="outline" class="w-100 sp-form-field sp-project-universe" id="sp-select-universe" style="transform: translateY(24px)">
              <mat-label class="sp-input-label">Project universe</mat-label>
              <mat-select (selectionChange)="onUniverseSelected($event.value)" [(ngModel)]="selectedUniverse" [formControl]="universeControl" [placeholder]="selectedUniverses.length == 0 ? 'Select at least one universe' : 'Not found'" class="sp-input-content" disableOptionCentering panelClass="sp-select-option-icon">
                <input [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" aria-label="Number" class=" sp-input-search-content" matInput placeholder="Pick one" type="text">
                <mat-option *ngFor="let universe of universeOrder() |  FilterPipe : searchText" [value]="universe">{{ universe.name }}</mat-option>
              </mat-select>
              <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="By selecting a geographical universe, you will get a detailed map and additional relevant criteria for that universe (ex: local brands, media,… )." matTooltipHideDelay="10">
                <span class="">i</span>
              </span>
            </mat-form-field>
          </div>
          <div class="sp-project-setup-localization-div-right">
            <div class="pb-3 pl-3 pr-3 pt-4 mb-2 sp-project-localization">
              <mat-form-field appearance="outline" class="w-100 mb-3 sp-form-field">
                <mat-label class="sp-input-label">
                  <span>Localization</span>
                </mat-label>
                <input #geolocInput (input)="onGeolocationInputChange(geolocInput.value)" [(ngModel)]="includedGeolocationField.geolocation" [matAutocomplete]="geolocAuto" class="sp-input-content" matInput placeholder="Type one or several locations" />
                <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="Here you can define the geographical scope of your project. Worldwide, continent, country, region, city can be selected.  You have also the possibility to select multiple area or exclude it." matTooltipHideDelay="10">
                  <span class="">i</span>
                </span>
                <mat-autocomplete #geolocAuto="matAutocomplete" (optionSelected)="onGeolocationSelected($event.option.value, TargetingType.INCLUSION); geolocInput.value = ''" [displayWith]="creationManager.geolocToString">
                  <mat-option *ngFor="let geolocation of geolocations" [value]="geolocation">{{geolocation ? geolocToString(geolocation) : "Loading..."}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-chip-listbox aria-label="Geolocation selection" class="sp-chips sp-chips-input">
                <mat-chip-option [selectable]="false" (removed)="onGeolocationGroupDeleted($event.chip.value)" *ngFor="let group of includedGeolocationGroups" [removable]="true"
                          [value]="group" class="ml-2">
                  {{group.name}}
                  <mat-icon class="sp-icon-chips-delete" matChipRemove svgIcon="icon_sp_x"></mat-icon>
                </mat-chip-option>
                <mat-chip-option [selectable]="false" (removed)="onGeolocationDeleted($event.chip.value, TargetingType.INCLUSION)" *ngFor="let geolocation of includedGeolocations" [removable]="true"
                          [value]="geolocation" class="ml-2">
                  {{geolocToString(geolocation)}}
                  <mat-icon class="sp-icon-chips-delete" matChipRemove svgIcon="icon_sp_x"></mat-icon>
                </mat-chip-option>
              </mat-chip-listbox>
              <div class="sp-project-criteria-button">
                <button (click)="toggleGeolocationExclusion()" *ngIf="!excludedGeolocationField" class="btn btn-primary rounded-circle sp-btn sp-btn-primary sp-btn-circle sp-btn-red" title="Exclude interest" type="button">
                <span class="sp-icon">
                  <i class="icon-sp-minus"></i>
                </span>
                </button>
              </div>
            </div>
            <div *ngIf="excludedGeolocationField" class="row mb-3 no-gutters">
              <div class="col-2">

              </div>
              <div class="col-1 sp-project-interest-minus">
                <div class="row line vl line-less">
                  <i class="icon-sp-minus"></i>
                </div>
              </div>
              <div class="col-9">
                <div class="p-3 sp-project-localization" style="border: 1px solid #F51C1C">
                  <button (click)="toggleGeolocationExclusion()" class="btn btn-primary rounded-circle sp-btn sp-btn-secondary sp-btn-circle sp-btn-remove" title="Delete action" type="button">
                  <span class="sp-icon" style="transform: translateX(0.05rem)">
                    <i class="icon-sp-escape"> </i>
                  </span>
                  </button>
                  <mat-form-field appearance="outline" class="sp-form-field" style="width: 95%">
                    <mat-label class="sp-input-label">
                      <span>Localization</span>
                    </mat-label>
                    <input #geoInput (input)="onGeolocationInputChange(geoInput.value)" [(ngModel)]="excludedGeolocationField.geolocation" [matAutocomplete]="geoAuto" class="sp-input-content" matInput placeholder="Type one or several locations" />
                    <mat-autocomplete #geoAuto="matAutocomplete" (optionSelected)="addGeolocation($event.option.value, TargetingType.EXCLUSION); geoInput.value = ''" [displayWith]="creationManager.geolocToString">
                      <mat-option *ngFor="let geolocation of geolocations" [value]="geolocation">{{ geolocation ? geolocToString(geolocation) : "Loading..." }}</mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-chip-listbox aria-label="Geolocation selection" class="sp-chips sp-chips-input">
                    <mat-chip-option [selectable]="false" (removed)="onGeolocationDeleted(geolocation, TargetingType.EXCLUSION)" *ngFor="let geolocation of excludedGeolocations" [removable]="true"
                              [value]="geolocation" class="ml-2">
                      {{ geolocToString(geolocation) }}
                      <mat-icon class="sp-icon-chips-delete" matChipRemove svgIcon="icon_sp_x"></mat-icon>
                    </mat-chip-option>
                  </mat-chip-listbox>
                  <div class="sp-project-interest-2-plus sp-project-criteria-button sp-project-criteria-button-plus">
                    <!--<button type="button" class="btn btn-primary rounded-circle sp-btn sp-btn-primary sp-btn-circle sp-btn-red" title="Lorem ipsum">
                      <span class="sp-icon">
                        <i class="icon-sp-minus"></i>
                      </span>
                    </button>-->
                  </div>
                </div>
              </div>
            </div>

          </div>

          <!--
          <div *ngIf="options.allowSociodemoOptions" class="sp-project-localization-option d-flex justify-content-center">
            <button (click)="showOptions()" [hidden]="showMoreOptions" class="btn btn-primary sp-btn sp-btn-sm sp-btn-primary " matTooltip="More option" matTooltipHideDelay="10" style="transform: translateY(3.2rem)" type="button">
              <span class="sp-icon">
                <i class="icon-sp-plus"></i>
              </span>
              <span class="pl-3">More option</span>
            </button>
          </div>
          -->
          <button *ngIf="options.allowSociodemoOptions" (click)="showOptions()" [hidden]="showMoreOptions" class="btn btn-primary sp-btn sp-btn-sm sp-btn-primary sp-btn-more-option" matTooltip="More option" matTooltipHideDelay="10" style="transform: translateY(3.2rem)" type="button">
              <span class="sp-icon">
                <i class="icon-sp-plus"></i>
              </span>
            <span class="pl-3">More option</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Sociodemo options -->
  <div *ngIf="options.allowSociodemoOptions" [hidden]="!showMoreOptions" class="row mb-5">
    <div class="card w-100">
      <div class="sp-project-localization-option-less d-flex justify-content-center">
        <button (click)="hideOptions()" class="btn btn-primary sp-btn sp-btn-sm sp-btn-primary sp-btn-red" matTooltip="Less option" matTooltipHideDelay="10" type="button">
           <span class="sp-icon">
              <i class="icon-sp-minus"></i>
            </span>
          <span class="pl-3">Less option</span>
        </button>
      </div>

      <div class="card-body sp-more-option-div py-4">
        <div class="d-flex flex-column px-4 flex-grow-1" style="flex-basis: 0; gap:32px">
          <div class="tmp-data-source">
            <h4 class="pb-3">Select a data source</h4>
            <mat-form-field appearance="outline" class="w-100 sp-form-field">
              <mat-label class="sp-input-label">Data source</mat-label>
              <mat-select placeholder="None selected" (selectionChange)="updateAudienceTargetingAndSize(true)" [(ngModel)]="platformsSelected" class="sp-input-content" disableOptionCentering multiple>
                <mat-option [value]="AudienceInfo.facebookPlatforms">Facebook</mat-option>
                <mat-option [value]="AudienceInfo.instagramPlatforms">Instagram</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="tmp-gender">
            <h4 class="pb-3">Select a gender</h4>
            <div class="sp-project-gender">
              <div class="sp-project-gender-both">
                <div [ngClass]=" genders.length == 2 || genders.length == 0 ? 'sp-gender-color': ''" class="card sp-project-gender-card" >
                  <div (click)="updateGenders([1, 2])" class="card-body text-center">
                    <img alt="" class="sp-image-gender-male" src="assets/images/socio-demo/genderMale.png" />
                    <img alt="" class="sp-image-gender-female" src="assets/images/socio-demo/genderFemale.png" />
                  </div>
                </div>
              </div>
              <div class="sp-project-gender-male">
                <div [ngClass]=" genders.length == 1 && genders[0] == 1  ? 'sp-gender-color': ''" class="card sp-project-gender-card" >
                  <div (click)="updateGenders([1])" class="card-body text-center">
                    <img alt="" class="sp-image-gender-male" src="assets/images/socio-demo/genderMale.png" />
                  </div>
                </div>
              </div>
              <div class="sp-project-gender-female">
                <div [ngClass]=" genders.length == 1 && genders[0] == 2 ? 'sp-gender-color': ''" class="card sp-project-gender-card" >
                  <div (click)="updateGenders([2])" class="card-body text-center">
                    <img alt="" class="sp-image-gender-female" src="assets/images/socio-demo/genderFemale.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Separator line grey -->
        <div class="divider" style="height: 100%; border-right: 1px dashed grey"></div>
        <div class="d-flex flex-column tmp-right px-4 flex-grow-1" style="flex-basis: 0; gap:32px">
          <div class="tmp-age">
            <h4 class="pb-3">Select age</h4>
            <h6 *ngIf="ages[1] <65" class="mt-3">Range: {{ages[0] + ' - ' + ages[1]}}</h6>
            <h6 *ngIf="ages[1] >=65" class="mt-3">Range: {{ages[0] + ' - ' + ages[1]+'+'}}</h6>

            <p-slider (ngModelChange)="ageSliderChange.next(ages)" [(ngModel)]="ages" [max]="65" [min]="13" name="ranger" range="true"></p-slider>
          </div>
          <div class="tmp-locales">
            <h4 class="pb-3">Languages of your audience</h4>
            <mat-form-field appearance="outline" class="w-100  sp-form-field">
              <mat-label class="sp-input-label">
                <span>Languages</span>
              </mat-label>
              <input #localeInput (input)="onLocaleInputChange(localeInput.value)" [matAutocomplete]="localeAuto" class="sp-input-content" matInput placeholder="Type languages" />
              <mat-autocomplete #localeAuto="matAutocomplete" (optionSelected)="onLocaleSelected($event.option.value); localeInput.value = ''" [displayWith]="localeToString">
                <mat-option *ngFor="let locale of locales" [value]="locale">{{locale ? locale.name : "Loading..."}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-chip-listbox aria-label="Geolocation selection" class="sp-chips sp-chips-input pt-1" style="height: auto">
              <mat-chip-option [selectable]="false" (removed)="onLocaleDeleted(locale)" *ngFor="let locale of includedLocales" [removable]="true"
                               [value]="locale">
                {{ locale.name }}
                <mat-icon class="sp-icon-chips-delete" matChipRemove svgIcon="icon_sp_x"></mat-icon>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
          <div class="tmp-relationships">
            <h4 class="pb-3">Relationship statuses of your audience</h4>
            <mat-form-field appearance="outline" class="w-100 sp-form-field">
              <mat-label class="sp-input-label">Relationship statuses</mat-label>
              <mat-select id="relationship-select" [compareWith]="compareRelationshipStatuses" placeholder="None selected" (selectionChange)="updateAudienceTargetingAndSize(true)" [(ngModel)]="includedRelationshipStatus" class="sp-input-content" disableOptionCentering multiple>
                <mat-option class="relationship-option" *ngFor="let relStatus of relationshipStatuses" [value]="relStatus">{{ relStatus.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Interests fields -->
  <div class="row sp-project-interest">
    <div class="w-100 card">
      <div class="card-header">
        <h5 class="pl-3">Audience's interest</h5>
      </div>
      <div class="card-body p-3">
        <div class="row pb-3 no-gutters">
          <div class="col-12">
            <div *ngIf="includedCriteriaFields.length > 0" class="pb-3 pl-3 pr-3 pt-4 sp-project-localization">
              <mat-form-field appearance="outline" class="w-100 mb-3 sp-form-field pa-4">
                <mat-label class="sp-input-label">
                  <span>Interest</span>
                </mat-label>
                <input #criteriaInput #trigger="matAutocompleteTrigger" (click)="closePanelIfShowingSuggestions(trigger, criteriaInput)" (input)="onCriteriaInputChange(criteriaInput.value)" [(ngModel)]="includedCriteriaFields[0].criteria" [matAutocomplete]="criteriaAuto" class="sp-input-content" matInput placeholder="Type one or several criterion" />
                <mat-autocomplete #criteriaAuto="matAutocomplete" (closed)="resetCriterionIfShowingSuggestions(trigger, criteriaInput)" (optionSelected)="onCriteriaSelected(includedCriteriaFields[0], TargetingType.INCLUSION); criteriaInput.value = ''" [displayWith]="criteriaToString">
                  <mat-option *ngFor="let criteria of criterion" [value]="criteria">{{criteria ? criteriaToString(criteria) : (criteria === null ? "Loading..." : 'No interests found')}}</mat-option>
                </mat-autocomplete>
                <span matSuffix class="d-flex align-center" style="gap: 4px">
                  <button [disabled]="suggestionButtonDisabled()" type="button" (click)="trigger.panelOpen ? trigger.closePanel() : fetchSuggestions(trigger, criteriaInput)" class="sp-suggestion">
                     Suggestions
                  </button>
                  <span class="sp-information-tooltip sp-transform-icon" matTooltip="Here you have access to all audiences existing in the Facebook & Instagram database. You will find audiences based on commun interest, behavior, sociodemographics, work employer, job title,…  You can build your precise audience based on the combination of overlapping, addition or exclusion of audience(s)." matTooltipHideDelay="10">
                    <span class="">i</span>
                  </span>
                </span>

              </mat-form-field>
              <mat-chip-listbox aria-label="Criteria selection" class="sp-chips sp-chips-input">
                <mat-chip-option [selectable]="false" (removed)="onCriteriaDeleted(includedCriteriaFields[0], criteria, TargetingType.INCLUSION)" *ngFor="let criteria of includedCriterion.get(includedCriteriaFields[0])" [removable]="true"
                          [value]="criteria" class="ml-2">
                  {{criteriaToString(criteria)}}
                  <mat-icon class="sp-icon-chips-delete" matChipRemove svgIcon="icon_sp_x"></mat-icon>
                </mat-chip-option>
              </mat-chip-listbox>
              <div class="sp-project-criteria-button">
                <button (click)="toggleCriteriaExclusion()" *ngIf="!excludedCriteriaField" class="btn btn-primary rounded-circle sp-btn sp-btn-primary sp-btn-circle sp-btn-red" title="Exclude interest" type="button">
                  <span class="sp-icon">
                    <i class="icon-sp-minus"></i>
                  </span>
                </button>
                <button (click)="addIncludeCriteriaField()" *ngIf="includedCriteriaFields.length <= 1" class="btn btn-primary rounded-circle sp-btn sp-btn-primary sp-btn-circle sp-btn-green ml-2" title="Add interest" type="button">
                  <span class="sp-icon">
                    <i class="icon-sp-plus"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div *ngFor="let field of includedCriteriaFields | slice:1:includedCriteriaFields.length; let i = index" class="row pb-3 no-gutters">
          <div class="col-2 sp-project-interest-plus">
            <div class="row line vl line-more">
              <i class="icon-sp-plus"></i>
            </div>
          </div>
          <div class="col-10">
            <div class="row pb-3 no-gutters">
              <div class="col-12">
                <div class="pb-3 pl-3 pr-3 pt-4 sp-project-localization" >
                  <button (click)="removeIncludeCriteriaField(field)" class="btn btn-primary rounded-circle sp-btn sp-btn-secondary sp-btn-circle sp-btn-remove" title="Delete action" type="button">
                  <span class="sp-icon" style="transform: translateX(0.05rem)">
                   <i class="icon-sp-escape"> </i>
                  </span>
                  </button>
                  <mat-form-field appearance="outline" class="sp-form-field mb-3" style="width: 95%">
                    <mat-label class="sp-input-label">
                      <span>Interest</span>
                    </mat-label>
                    <input #criteriaInput #trigger="matAutocompleteTrigger" (input)="onCriteriaInputChange(criteriaInput.value)" [(ngModel)]="field.criteria" [matAutocomplete]="criteriaAuto" class="sp-input-content" matInput placeholder="Type one or several criterion" />
                    <mat-autocomplete #criteriaAuto="matAutocomplete" (optionSelected)="onCriteriaSelected(field, TargetingType.INCLUSION); criteriaInput.value = ''" [displayWith]="criteriaToString">
                      <mat-option *ngFor="let criteria of criterion" [value]="criteria">{{criteria ? criteriaToString(criteria) : "Loading..."}}</mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <mat-chip-listbox aria-label="Criteria selection" class="sp-chips sp-chips-input">
                    <mat-chip-option [selectable]="false" (removed)="onCriteriaDeleted(field, criteria, TargetingType.INCLUSION)" *ngFor="let criteria of includedCriterion.get(field)" [removable]="true"
                              [value]="criteria" class="ml-2">
                      {{criteriaToString(criteria)}}
                      <mat-icon class="sp-icon-chips-delete" matChipRemove svgIcon="icon_sp_x"></mat-icon>
                    </mat-chip-option>
                  </mat-chip-listbox>
                  <div *ngIf="i + 2 == includedCriteriaFields.length" class="sp-project-interest-2-plus sp-project-criteria-button">
                    <button (click)="addIncludeCriteriaField()" class="btn btn-primary rounded-circle sp-btn sp-btn-primary sp-btn-circle sp-btn-green" title="Add interest" type="button">
                  <span class="sp-icon">
                    <i class="icon-sp-plus"></i>
                  </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="excludedCriteriaField" class="row no-gutters">
          <div class="col-2">

          </div>
          <div class="col-1 sp-project-interest-minus">
            <div class="row line vl line-less">
              <i class="icon-sp-minus"></i>
            </div>
          </div>
          <div class="col-9">
            <button (click)="toggleCriteriaExclusion()" class="btn btn-primary rounded-circle sp-btn sp-btn-secondary sp-btn-circle sp-btn-remove" title="Delete action" type="button">
                  <span class="sp-icon" style="transform: translateX(0.05rem)">
                 <i class="icon-sp-escape"> </i>
                  </span>
            </button>
            <div class="p-3 sp-project-localization" style="border: 1px solid #F51C1C">
              <mat-form-field appearance="outline" class="sp-form-field mb-3" style="width: 95%">
                <mat-label class="sp-input-label">
                  <span>Interest</span>
                </mat-label>
                <input #criteriaInput #trigger="matAutocompleteTrigger" (input)="onCriteriaInputChange(criteriaInput.value)" [(ngModel)]="excludedCriteriaField.criteria" [matAutocomplete]="criteriaAuto" class="sp-input-content" matInput placeholder="Type one or several criterion" />
                <mat-autocomplete #criteriaAuto="matAutocomplete" (optionSelected)="onCriteriaSelected(excludedCriteriaField, TargetingType.EXCLUSION); criteriaInput.value = ''" [displayWith]="criteriaToString">
                  <mat-option *ngFor="let criteria of criterion" [value]="criteria">{{criteria ? criteriaToString(criteria) : "Searching..."}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-chip-listbox aria-label="Criteria selection" class="sp-chips sp-chips-input">
                <mat-chip-option [selectable]="false" (removed)="onCriteriaDeleted(includedCriteriaFields[0], criteria, TargetingType.EXCLUSION); criteriaInput.value = ''" *ngFor="let criteria of excludedCriterion" [removable]="true"
                          [value]="criteria" class="ml-2">
                  {{ criteriaToString(criteria) }}
                  <mat-icon class="sp-icon-chips-delete" matChipRemove svgIcon="icon_sp_x"></mat-icon>
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div *ngIf="freemium">
  <div  class="row no-gutters pt-3 pb-5">
    <div class="w-100 sp-card">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6 pt-4 pb-4 pl-4 pr-3">
              <mat-form-field appearance="outline" class="w-100 mb-3 sp-form-field">
                <mat-label class="sp-input-label">
                  <span>Interest</span>
                </mat-label>
                <input #criteriaInput #trigger="matAutocompleteTrigger" (input)="onCriteriaInputChange(criteriaInput.value)" [(ngModel)]="includedCriteriaFields[0].criteria" [matAutocomplete]="criteriaAuto" class="sp-input-content" matInput placeholder="Type one or several criterion" />
                <mat-autocomplete #criteriaAuto="matAutocomplete" (optionSelected)="onCriteriaSelected(includedCriteriaFields[0], TargetingType.INCLUSION); criteriaInput.value = ''; fetchSuggestions(trigger)" [displayWith]="criteriaToString">
                  <mat-option *ngFor="let criteria of criterion" [value]="criteria">{{criteria ? criteriaToString(criteria) : "Loading..."}}</mat-option>
                </mat-autocomplete>
                <span matSuffix class="d-flex align-center" style="gap: 4px">
                  <button [disabled]="suggestionButtonDisabled()" type="button" (click)="trigger.panelOpen ? trigger.closePanel() : fetchSuggestions(trigger, criteriaInput)" class="sp-suggestion">
                     Suggestions
                  </button>
                  <span class="sp-information-tooltip sp-transform-icon" matTooltip="Here you have access to all audiences existing in the Facebook & Instagram database. You will find audiences based on commun interest, behavior, sociodemographics, work employer, job title,…  You can build your precise audience based on the combination of overlapping, addition or exclusion of audience(s)." matTooltipHideDelay="10">
                    <span class="">i</span>
                  </span>
                </span>
              </mat-form-field>
              <mat-chip-listbox aria-label="Criteria selection" class="sp-chips sp-chips-input">
                <mat-chip-option [selectable]="false" (removed)="onCriteriaDeleted(includedCriteriaFields[0], criteria, TargetingType.INCLUSION); criteriaInput.value = ''" *ngFor="let criteria of includedCriterion.get(includedCriteriaFields[0])" [removable]="true"
                          [value]="criteria" class="ml-2">
                  {{ criteriaToString(criteria) }}
                  <mat-icon class="sp-icon-chips-delete" matChipRemove svgIcon="icon_sp_x"></mat-icon>
                </mat-chip-option>
              </mat-chip-listbox>

              <div *ngIf="audienceTarget.fb_size <= 100000 && audienceTarget.fb_size > 0" style="color: #F51C1C" class="mt-3 ml-2">Your audience is too small. Build your audience to be larger than 100,000 people.</div>
            </div>
            <div class="col-6 pt-4 pb-4 pl-3 pr-4 sp-project-setup-analysis">
              <mat-form-field (click)="searchText = ''" appearance="outline" class="w-100 sp-form-field">
                <mat-label class="sp-input-label">Localization</mat-label>
                <mat-select (selectionChange)="onUniverseSelected($event.value)" [(ngModel)]="selectedUniverse" [placeholder]="selectedUniverses.length == 0 ? 'Select at least one universe' : 'Not found'" class="sp-input-content" disableOptionCentering panelClass="sp-select-option-icon">
                  <input [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" aria-label="Number" class="sp-input-search-content" matInput placeholder="Pick one" type="text">
                  <mat-option *ngFor="let universe of universes |  FilterPipe : searchText" [value]="universe">{{ universe.name }}</mat-option>
                </mat-select>
                <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="By selecting a geographical universe, you will get a detailed map and additional relevant criteria for that universe (ex: local brands, media,… )." matTooltipHideDelay="10">
                  <span class="">i</span>
                </span>
              </mat-form-field>
            </div>
          </div>
        </div>

      </div>
      <div class="sp-project-localization-option d-flex justify-content-center mt-3" matTooltip="Advanced parameters are not available for free users"  matTooltipHideDelay="10" style="transform: translateY(-2rem)">
        <button class="btn btn-primary sp-btn sp-btn-sm " style="background: linear-gradient(111deg, #B2B2B2FF 0, #a9a9a9  100%) 0 0 !important;border:none;" type="button">
              <span class="sp-icon">
                <i class="icon-sp-plus"></i>
              </span>
          <span class="pl-3">More option</span>
        </button>
      </div>
    </div>
  </div>
</div>
