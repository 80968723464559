import {Component, Input} from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-sp-modal-audience-score-information',
  templateUrl: './sp-modal-audience-score-information.component.html',
  styleUrls: ['./sp-modal-audience-score-information.component.scss']
})
export class SpModalAudienceScoreInformationComponent {
  @Input() showCloseButton: boolean = false;
  constructor(public dialogRef: MatDialogRef<SpModalAudienceScoreInformationComponent>) { }

}
