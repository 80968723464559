import { NgModule } from '@angular/core';
import { SpToolsComponent } from './sp-tools.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    SpToolsComponent
  ],
  imports: [
    MatProgressSpinnerModule,
    MatProgressBarModule,
    CommonModule

  ],
  exports: [
    SpToolsComponent
  ]
})
export class SpToolsModule {}
