import { NgModule } from '@angular/core';
import { SpLayoutDashboardDefaultModule } from './sp-layout-dashboard-default/sp-layout-dashboard-default.module';
import { SpLayoutDashboardDeskModule } from './sp-layout-dashboard-desk/sp-layout-dashboard-desk.module';
import { SpLayoutDashboardSimpleModule } from './sp-layout-dashboard-simple/sp-layout-dashboard-simple.module';
import { SpLayoutDashboardWelcomeModule } from './sp-layout-dashboard-welcome/sp-layout-dashboard-welcome.module';

@NgModule({
  exports: [
    SpLayoutDashboardDefaultModule,
    SpLayoutDashboardDeskModule,
    SpLayoutDashboardSimpleModule,
    SpLayoutDashboardWelcomeModule,
  ],
  imports: [
    SpLayoutDashboardDefaultModule,
    SpLayoutDashboardDeskModule,
    SpLayoutDashboardSimpleModule,
    SpLayoutDashboardWelcomeModule,
  ]
})
export class SpLayoutModule {}
