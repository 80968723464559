import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { SpThemingService } from '../../../services/sp-theming/sp-theming.service';
import {ValidationService} from "../../../services/sp-validation/validation.service";
import {MatStepper} from "@angular/material/stepper";
import {AdAccount} from "../../../models/ad-account";
import {FacebookLinkService} from "../../../services/sp-facebook/facebook-link.service";
import {User} from "../../../models/user";
import {AuthenticationService} from "../../../services/sp-authentication/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ErrorCode} from "../../../utils/error/error-code";
import {ApiService} from "../../../services/sp-api/api.service";
import {ApiUserService} from "../../../services/sp-api/sp-api-user/api-user.service";
import {Company} from "../../../models/company";
import {NavigationService} from '../../../services/sp-navigation/navigation.service';
import {AlertService} from '../../../services/sp-alert/alert.service';
import {LoaderService} from '../../../services/sp-loading/loader.service';
import {environment} from '../../../../environments/environment';
import {LoggerService} from '../../../services/sp-logger/logger.service';
import {SocketService} from "../../../services/sp-ws/socket.service";
import {firstValueFrom} from "rxjs";
import { Analytics } from '@segment/analytics-node'

@Component({
  selector: 'app-sp-user-register',
  templateUrl: './sp-user-register.component.html',
  styleUrls: ['./sp-user-register.component.scss']
})

export class SpUserRegisterComponent implements OnInit {

  @ViewChild('stepper') private stepper: MatStepper;

  registerCompleted:boolean = false;
  hide = true;
  searchText: string;
  byInvite = false;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  // Register form related
  registerFormGroup: UntypedFormGroup;
  passwordControl: FormControl;

  adAccounts: Array<AdAccount> = [];
  registerToken: string = null;
  localeSelected = 'en_BE';

  private registerUser: User;

  private analytics: Analytics = new Analytics({writeKey: 'CZMrKZHIV4YEY6Kd7bQr1QhDRPmocR3L'});

  languages = [
    { code: 'fr_BE', name: 'French - Belgium' },
    { code: 'fr_FR', name: 'French - France' },
    { code: 'it_IT', name: 'Italian - Italy' },
    { code: 'en_BE', name: 'English - Belgium' },
    { code: 'en_OT', name: 'English - Other' },
  ];
  changeRegisterToCompleted() {
    this.registerCompleted = true;
  }
  // --

  // Constructor.
  constructor(private _spTheming: SpThemingService,
              private _formBuilder: UntypedFormBuilder,
              private validation: ValidationService,
              private facebook: FacebookLinkService,
              private auth: AuthenticationService,
              private api: ApiService,
              private loader: LoaderService,
              private apiUser: ApiUserService,
              private router: Router,
              private route: ActivatedRoute,
              private navigation: NavigationService,
              private formBuilder: UntypedFormBuilder,
              private alert: AlertService,
              private logger: LoggerService,
              private socket: SocketService) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-welcome');
  }

  // On init.
  ngOnInit() {
    this.navigation.adBlockDetectionLaunch().then();

    this.route.queryParamMap.subscribe(params => {
      let secret = params.get('s');
      let confirm = params.get('confirm') == '1';

      if (secret !== null) {
        if (confirm) {
          firstValueFrom(this.auth.registerConfirm(secret)).then(() => {
            this.router.navigate(['user/login'], {queryParams: {'state': 'confirmed'}}).then();
          }).catch(err => {
            this.alert.notify("Error", "An error has occurred while validating your account... This incident has been reported.", "error");
            this.logger.logError("Error while validating account for token '" + secret + "' : " + err.error.message, 3);
          });
        } else {
          this.apiUser.getOneByToken(secret).then(user => {
            this.registerUser = user;
            this.loader.load();
            this.registerToken = secret;
            this.fillInfoWithUser(user);
            this.byInvite = true;
            this.loader.loaded();
          });
        }
      }
    });

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: [
        '',
        Validators.required
      ]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: [
        '',
        Validators.required
      ]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: [
        '',
        Validators.required
      ]
    });

    this.registerFormGroup = this.formBuilder.group({
      first_name: ['',
        Validators.required,
      ],
      last_name: ['',
        Validators.required,
      ],
      email: ['', {
        validators: [
          Validators.required,
          Validators.email
        ],
        asyncValidators: this.validation.laravelSideValidator('email', 'validation/user/email'),
        updateOn: 'blur'
      }],
      company: ['',
        Validators.required,
      ],
      password: ['',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)
        ]
      ],
      passwordConfirm: ['',
        Validators.required
      ],
    }, {
      validators: this.validation.passwordMatchValidator('password', 'passwordConfirm')
    });
  }

  fillInfoWithUser(user: User) {
    this.registerFormGroup.get('first_name').setValue(user.first_name);
    this.registerFormGroup.get('first_name').disable();

    this.registerFormGroup.get('last_name').setValue(user.last_name);
    this.registerFormGroup.get('last_name').disable();

    this.registerFormGroup.get('email').setAsyncValidators([]); // Removing laravel validation for email has it is already created
    this.registerFormGroup.get('email').setValue(user.email);
    this.registerFormGroup.get('email').disable();

    this.registerFormGroup.get('company').setValue(user.company.name);
    this.registerFormGroup.get('company').disable();
    this.registerFormGroup.get('password').disable();
    this.registerFormGroup.get('passwordConfirm').disable();

    //test pour password
    setTimeout(() => {
      this.registerFormGroup.get('password').enable();
      this.registerFormGroup.get('passwordConfirm').enable();
    }, 1000);

  }

  getSelectedAccounts() {
    return this.adAccounts.filter(p => { return p.selected });
  }

  saveUserInfo() {
    this.registerFormGroup.markAllAsTouched();
    if (this.registerFormGroup.valid) {
        this.handleRegistration();
        this.changeRegisterToCompleted() ;
    }
  }
  changeLanguage(event) {
    this.localeSelected = event;
  }

  handleRegistration() {
    const selectedAdAccounts = this.getSelectedAccounts();

    const firstName = this.registerFormGroup.get('first_name').value;
    const lastName = this.registerFormGroup.get('last_name').value;
    const email = this.registerFormGroup.get('email').value;
    const company = new Company(this.registerFormGroup.get('company').value);
    const password = this.registerFormGroup.get('password').value;

    const user = new User(email, firstName, lastName);
    user.company = company;
    user.ad_accounts = selectedAdAccounts;
    user.password = password;
    user.locale = this.localeSelected;

    if(this.byInvite) {
      if(this.registerToken) {
        this.auth.registerFromInvite(this.registerToken, user)
          .subscribe(() => {
            if (this.registerUser) {
              this.socket.sendMessageType('user-signup', {
                user_email: this.registerUser.email,
                user_id: this.registerUser.id,
                user_first_name: this.registerUser.first_name,
                user_last_name: this.registerUser.last_name,
                user_created_at: this.registerUser.created_at,
                user_role: this.registerUser.role.name
              });
              if (environment.config.production) {
                const subPlan = this.registerUser.company.subscription.plan;
                const subPlanName = (subPlan.custom ? "Custom plan" : subPlan.name).toLowerCase().replace(" ", "_");
                this.analytics.identify({
                  userId: this.registerUser.id.toString(),
                  traits: {
                    firstName: this.registerUser.first_name,
                    lastName: this.registerUser.last_name,
                    email: this.registerUser.email,
                    soprism_plans: subPlanName
                  }
                });
              }
            }
            this.router.navigate(['user/login'], {queryParams: {from: "invite"}}).then();
          });
      } else {
        this.logger.logWarning("No register token has been found");
      }
    } else {
      this.auth.register(user).then(user => {
        this.socket.sendMessageType('user-signup', {
          user_email: user.email,
          user_id: user.id,
          user_name: user.first_name + ' ' + user.last_name,
          user_role: user.role.name
        });
        if (environment.config.production) {
          this.analytics.identify({
            userId: user.id.toString(),
            traits: {
              email: user.email,
              firstName: user.first_name,
              lastName: user.last_name
            }
          });
        }
        this.router.navigate(['user/login'], {queryParams: {state: "registered"}}).then();
      }, error => {
        if (error.status == 403) {
          this.alert.notify("Error", error.error.message, "error");
        } else {
          this.alert.notify("Error", "An unknown error has occurred, if this persists, contact an administrator. Code " + ErrorCode.httpToErrorCode(error.status), "error");
        }
      });
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.saveUserInfo();
    }
  }

  get environment() { return environment }
}
