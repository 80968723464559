import {Model} from "./model";
import {Audience} from "./audience";
import {User} from './user';
import {NamedItem} from '../pipes/array-name-join.pipe';

export class Project implements Model, NamedItem {
  id: number;
  user?: User;
  name: string;
  _owner?: boolean; // If logged user is owner or not
  _shared_info?: boolean; // ?
  _ready?: boolean = false; // If project is loaded and ready
  flag?: string; // Crawl flag system
  audience_target: Audience;
  audience_bench: Audience;
  source_type?: string;
  persona_merge_mode?: string;
  shared_users?: User[];
  created_at?: Date;
  updated_at?: Date;

  constructor(name: string) {
    this.name = name;
  }
}
