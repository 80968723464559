import {Component, OnDestroy, OnInit} from '@angular/core';
import { SpThemingService } from '../../../services/sp-theming/sp-theming.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormControl, Validators} from '@angular/forms';
import {ErrorCode} from '../../../utils/error/error-code';
import {AuthenticationService} from '../../../services/sp-authentication/authentication.service';
import {Message} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {firstValueFrom, Subscription} from 'rxjs';
import {LoaderService} from '../../../services/sp-loading/loader.service';
import {RoleUtils} from '../../../utils/role/role-utils';
import {SocketService} from '../../../services/sp-ws/socket.service';


@Component({
  selector: 'app-sp-user-login',
  templateUrl: './sp-user-login.component.html',
  styleUrls: ['./sp-user-login.component.scss'],
  providers: [MessageService]
})
export class SpUserLoginComponent implements OnInit, OnDestroy {
  hide = true;

  private _emailControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required
  ]);

  private _passwordControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required
  ]);

  private _waiting: boolean = false;
  private _authError: string = null;
  private oneTimeAuth: boolean = false;

  msgs: Message[] = [];

  // Subscriptions
  private authSub: Subscription;

  // Constructor.
  constructor(private _spTheming: SpThemingService,
              private auth: AuthenticationService,
              public router: Router,
              private loader: LoaderService,
              private route: ActivatedRoute,
              private socket: SocketService
              ) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-welcome');
  }

  showError(authErr) {
    this.msgs = [];
    this.msgs.push({severity: 'error', summary: 'Error Message', detail: authErr});
  }

  // On init.
  ngOnInit() {
    if (this.auth.session.initialized && this.auth.session.authenticated) { this.router.navigateByUrl('/').then(); }

    this.route.queryParamMap.subscribe(params => {
      const secret = params.get('s');
      const from = params.get('from');
      const state = params.get('state');

      if (from === 'invite' || state === 'confirmed') {
        this.msgs = [];
        this.msgs.push({severity: 'success', summary: 'Registration successful', detail: 'You can now login with your credentials !'});
      }
      if (secret !== null) {
        this._waiting = true;

        this.oneTimeAuth = true;
        this.auth.loginOtp(secret).then(() => {
          const tmpSub = this.auth.sessionListener.subscribe(session => {
            if (session) {
              this.router.navigateByUrl('account/edit').then();
            } else {
              this._waiting = false;
            }

            tmpSub.unsubscribe();
          });
        }).catch(err => {
          if (err.status && err.status === 401) {
            this.msgs = [];
            this.msgs.push({severity: 'error', summary: 'Error Message', detail: 'Your login link has expired'});
          }
        });
      }
    });

    // Listener for session authentication
    this.authSub = this.auth.sessionListener.subscribe(authInfo => {
      if (authInfo && !this.oneTimeAuth) {
        if (!authInfo.failed) {
          if (authInfo.user.first_connection && authInfo.user.role.key !== RoleUtils.trialKey
            && authInfo.user.role.level > RoleUtils.freemiumLevel) {
            this.router.navigate(['account/edit']).then();
          }
          else { this.router.navigateByUrl('/projects/list').then(); }

          if (!this.socket.connected) {
            firstValueFrom(this.socket.onConnect()).then(() => {
              this.socket.sendMessageType('user-login', {
                role: this.auth.session.user.role.name
              });
            });
          }
        }
        else {
          if (authInfo.errorCode === ErrorCode.ERROR_CODES.ERR_AUTH_FAILURE || authInfo.errorCode === ErrorCode.ERROR_CODES.ERR_FORM) {
            this._authError = authInfo.error && authInfo.error.error && authInfo.error.error.message ? authInfo.error.error.message : 'Invalid credentials, please try again.';
          } else if (authInfo.errorCode === ErrorCode.ERROR_CODES.ERR_FORBIDDEN) {
            this._authError = authInfo.error && authInfo.error.error && authInfo.error.error.message ? authInfo.error.error.message : 'Could not login: forbidden';
          } else {
            this._authError = 'An unexpected error has occurred. If this persists, contact an administrator (code ' + authInfo.errorCode + ')';
          }

          this.showError(this.authError);
          this._waiting = false;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.authSub) { this.authSub.unsubscribe(); }
  }

  /**
   * Sign in button event
   */
  login() {
    this._waiting = true;
    this.emailControl.markAsTouched();
    this.passwordControl.markAsTouched();

    // Call of authentication service built-in method if input is valid
    if (this.emailControl.valid && this.passwordControl.valid) {
      this.auth.login(this._emailControl.value, this._passwordControl.value);
    } else {
      this._waiting = false;
    }
  }

  isSubmitEnabled(){
    return !this.waiting && this._emailControl.valid && this._passwordControl.valid;
  }

  get emailControl(): UntypedFormControl {
    return this._emailControl;
  }

  get passwordControl(): UntypedFormControl {
    return this._passwordControl;
  }

  get waiting(): boolean {
    return this._waiting;
  }

  get authError(): string {
    return this._authError;
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }
}

