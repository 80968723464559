import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SpProjectCreateFacebookInterestComponent } from './sp-project-create-facebook-interest/sp-project-create-facebook-interest.component';
import { SpProjectCreateFacebookImportComponent } from './sp-project-create-facebook-import/sp-project-create-facebook-import.component';
import { SpProjectCreateFacebookPageComponent } from './sp-project-create-facebook-page/sp-project-create-facebook-page.component';
import { SpProjectCreateTypeSelectComponent } from './sp-project-create-type-select/sp-project-create-type-select.component';
import {AccountGuard} from '../../../guards/account/account.guard';

const spProjectCreateRoutes: Routes = [
  {
    path: '',
    redirectTo: 'select',
    pathMatch: 'full'
  },
  {
    path: 'select',
    component: SpProjectCreateTypeSelectComponent,
    canActivate: [AccountGuard],
    data: {freemiumAccess: true, trialAccess: true}
  },
  {
    path: 'facebook-interest',
    component: SpProjectCreateFacebookInterestComponent,
    canActivate: [AccountGuard],
    data: {freemiumAccess: true}
  },
  {
    path: 'facebook-import',
    component: SpProjectCreateFacebookImportComponent,
    canActivate: [AccountGuard]
  },
  {
    path: 'facebook-page',
    component: SpProjectCreateFacebookPageComponent,
    canActivate: [AccountGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(spProjectCreateRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SpProjectCreateRoutingModule {}
