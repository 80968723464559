import {Plan} from './plan';
import {HasPermission} from './has-permission';
import {Permission} from './permission';

export class Subscription implements HasPermission {
  id: number;
  //plan_id: number;
  plan: Plan;
  credits: number;
  users: number;
  credits_extra: number;
  credits_extra_total: number;
  payment_frequency: string;
  contract_start_at: Date;
  contract_expire_at: Date;
  created_at: Date;
  updated_at: Date;
  permissions: Permission[];

  constructor(credits: number, users: number, payment_frequency: string, contract_start_at: Date, contract_expire_at: Date) {
    this.credits = credits;
    this.users = users;
    this.payment_frequency = payment_frequency;
    this.contract_start_at = contract_start_at;
    this.contract_expire_at = contract_expire_at;
  }
}
