<div class="sp-dialog">
  <div class="sp-dialog-header">
    <h5>Create a new folder</h5>
  </div>
  <div class="sp-dialog-body">
    <mat-form-field class="w-100 sp-form-field" appearance="outline">
      <mat-label class="sp-input-label">Folder Name</mat-label>
      <input [formControl]="folderNameControl" class="sp-input-content" matInput placeholder="Type folder’s name..."/>
    </mat-form-field>
    <div class="pb-3 sp-dialog-footer">
      <button mat-button class="btn btn-secondary m-1 text-uppercase sp-btn sp-btn-secondary" (click)="onNoClick()" matTooltip="Cancel" matTooltipHideDelay="10">Cancel</button>
      <button mat-button class="btn btn-primary m-1 text-uppercase sp-btn sp-btn-primary" (click)="createFolder()" matTooltip="Create" matTooltipHideDelay="10">Create</button>
    </div>
  </div>
</div>
