import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {DashboardArea} from '../../utils/dashboard/dashboard';
import {NavigationService} from '../sp-navigation/navigation.service';
import {AuthenticationService} from "../sp-authentication/authentication.service";
import {RoleUtils} from "../../utils/role/role-utils";

@Injectable({
  providedIn: 'root'
})
export class IntroService {
  private readonly PROJECT_AREAS: DashboardArea[] = [
    DashboardArea.INSIGHT_SOCIODEMO,
    DashboardArea.PERSONA_OVERVIEW,
  ];

  openIntroPanel = true;
  autoStart = false;
  private projectOnly = false;
  introOver$:Observable<boolean>;
  introOver= new Subject<boolean>();
  //private currentArea: DashboardArea;
  private introStartedArea: DashboardArea[] = [];

  introStarted$: Observable<boolean>;
  private introStarted = new Subject<boolean>();

  autoStartIntro$: Observable<void>;
  private autoStartIntro = new Subject<void>();

  openIntroDialogSignal$: Observable<any>;
  private openDialogSignal = new Subject<any>();

  constructor(router: Router,
              private navigation: NavigationService,
              private auth: AuthenticationService) {
    this.introStarted$ = this.introStarted.asObservable();
    this.openIntroDialogSignal$ = this.openDialogSignal.asObservable();
    this.introOver$ = this.introOver.asObservable();
    this.autoStartIntro$ = this.autoStartIntro.asObservable();

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.autoStart) {
        this.startIntroService(false, this.projectOnly, true);
      }
    })
  }

  isIntroStarted(introStarted: boolean, force: boolean, byAutoStart: boolean = false) {
    if (this.auth.session.user?.role.level > RoleUtils.freemiumLevel) return;
    if (introStarted && this.openIntroPanel) this.openIntroPanel = false;

    if (!this.introStartedArea.includes(this.currentArea) || force) {
      if (byAutoStart) this.autoStartIntro.next(); else this.introStarted.next(introStarted);
      this.addDoneArea(this.currentArea);
    }
  }

  sendOpenIntroSignal() {
    if (this.auth.session.user?.role.level > RoleUtils.freemiumLevel) return;
    this.projectOnly = false;
    this.openDialogSignal.next(undefined);
  }

  startIntroService(force: boolean, projectOnly: boolean = false, byAutoStart: boolean = false) {
    if (this.auth.session.user?.role.level > RoleUtils.freemiumLevel) return;
    this.autoStart = true;
    this.projectOnly = projectOnly;
    if (this.openIntroPanel) {
      this.sendOpenIntroSignal();
    } else {
      if ((this.projectOnly && this.PROJECT_AREAS.includes(this.currentArea)) || !this.projectOnly) {
        this.isIntroStarted(true, force, byAutoStart);
      }
    }
  }

  private addDoneArea(area: DashboardArea) {
    if (!this.introStartedArea.includes(area)) this.introStartedArea.push(area);
  }

  enableAutoStart() {
    this.autoStart = true;
  }

  get currentArea() { return this.navigation.currentDashboardArea; }
}
