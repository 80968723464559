import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { SpProjectListComponent } from './sp-project-list/sp-project-list.component';
import { SpProjectRoutingModule } from './sp-project-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import {SpLayoutModule} from '../sp-layout/sp-layout.module';
import { MatInputModule } from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FilterPipe } from './sp-project-list/filter.pipe';
import { OrderByPipe } from './sp-project-list/order-by.pipe';
import { SpModalStartDemoTourComponent } from 'src/app/modules/sp-project/sp-project-list/sp-modal-start-demo-tour/sp-modal-start-demo-tour.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CarouselModule} from 'primeng/carousel';
import {ResizableModule} from 'angular-resizable-element';
import {GridsterModule} from 'angular-gridster2';

@NgModule({
  declarations: [
    SpProjectListComponent,
    FilterPipe,
    OrderByPipe,
    SpModalStartDemoTourComponent,

  ],
  exports: [
    OrderByPipe,
    FilterPipe
  ],
  imports: [
    CommonModule,
    SpProjectRoutingModule,
    MatIconModule,
    SpLayoutModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatSelectModule,
    CarouselModule,
    MatStepperModule,
    ReactiveFormsModule,
    ResizableModule,
    MatCheckboxModule,
    GridsterModule,
    MatExpansionModule
  ]
})
export class SpProjectModule {}
