import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-sp-dashboard-tab-creation-modal',
  templateUrl: './sp-dashboard-tab-creation-modal.component.html',
  styleUrls: ['./sp-dashboard-tab-creation-modal.component.scss']
})
export class SpDashboardTabCreationModalComponent implements OnInit {

  doNotShowAgain: boolean = false;

  constructor( private dialogRef: MatDialogRef<SpDashboardTabCreationModalComponent>) { }

  ngOnInit() {
  }

  sendData(typeDashboard: string){
    const data = {
      doNotShowAgain: this.doNotShowAgain,
      type: typeDashboard
    };

    this.dialogRef.close(data);
  }
  onNoClick(): void {
    this.dialogRef.close(undefined);
  }

}
