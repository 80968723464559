<div class="sp-dialog">
  <div class="sp-dialog-header">
    <h5> Share your project with your team </h5>
  </div>
  <div class="sp-dialog-body">
    <mat-form-field  appearance="outline" class="w-100 sp-form-field">
      <mat-label class="sp-input-label">Team Members</mat-label>
      <mat-select #userSelection (click)="resetFilter()" (focus)="userSelection._onBlur()" [(ngModel)]="selectedUsers" [placeholder]="loading ? 'Loading...' : 'Select a team member.'" class="sp-input-content" disableOptionCentering multiple no-space panelClass="sp-select-option-icon">
        <input [(ngModel)]="searchUsers" [ngModelOptions]="{standalone: true}" aria-label="Number" class=" sp-input-search-user" matInput placeholder="Search user" type="text">
        <mat-option (click)="handleSelected(user)" *ngFor="let user of users | FilterPipeUser: searchUsers" [value]="user" class="sp-select-option-user">
          <div class="row d-flex no-gutters">
            <div class=" text-center align-self-center pr-2">
              <span *ngIf="user.id == auth.session.user.id && project._owner">
                <img class="sp-avatar" *ngIf="user && user.image !== null" [src]="env.config.api.storageUrl + user.image" alt="" matTooltip="{{user.first_name}} {{user.last_name}} (Owner)" matTooltipHideDelay="10">
              </span>
              <span *ngIf="user.id !== auth.session.user.id && project._owner">
                <img class="sp-avatar" *ngIf="user && user.image !== null" [src]="env.config.api.storageUrl + user.image" alt="" matTooltip="{{user.first_name}} {{user.last_name}}" matTooltipHideDelay="10">
              </span>
              <span *ngIf="user && user.image == null" class="avatar-rounded">
                  <span class="avatar-company-name" *ngIf="user.id == auth.session.user.id && project._owner" matTooltip="{{user.first_name}} {{user.last_name}} (Owner)" matTooltipHideDelay="10">{{user.first_name.substr(0,1)}}{{user.last_name.substr(0,1)}}</span>
                  <span class="avatar-company-name" *ngIf="user.id !== auth.session.user.id && project._owner" matTooltip="{{user.first_name}} {{user.last_name}}" matTooltipHideDelay="10">{{user.first_name.substr(0,1)}}{{user.last_name.substr(0,1)}}</span>
                </span>
            </div>
            <div class=" align-self-center">
              <span [ngClass]="{'sp-project-owner': user.id == auth.session.user.id && project._owner}" > {{user.first_name}} </span> <br/>
              <span [ngClass]="{'sp-project-owner': user.id == auth.session.user.id && project._owner}"> {{user.last_name}}</span>
            </div>
          </div>
        </mat-option>
        <div class="sp-validate-selection">
          <button (click)="userSelection.close()" class="btn btn-primary m-1 text-uppercase sp-btn sp-btn-primary " mat-button matTooltip="Validate your selection"  matTooltipHideDelay="10" style="height: 2rem">Validate</button>
        </div>
      </mat-select>
    </mat-form-field>

    <div class="pb-3 sp-dialog-footer">
      <button mat-button class="btn btn-secondary m-1 text-uppercase sp-btn sp-btn-secondary" (click)="onNoClick()" matTooltip="Cancel" matTooltipHideDelay="10">Cancel</button>
      <button (click)="shareProject()" class="btn btn-primary m-1 text-uppercase sp-btn sp-btn-primary" mat-button matTooltip="Update" matTooltipHideDelay="10">Update</button>
    </div>
  </div>
</div>
