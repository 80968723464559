import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {firstValueFrom, Observable} from "rxjs";
import {LoggerService} from '../sp-logger/logger.service';
import {take} from 'rxjs/operators';

export interface CountryJson {
  name: string;
  code: string;
  type: string;
}


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  protected readonly BASE_URL = environment.config.api.baseUrl;
  protected readonly BASE_STAT_URL = environment.config.api.statsUrl;

  constructor(protected httpClient: HttpClient, private logger: LoggerService) {}

  /**
   * Check if API is alive
   */
  live(): void {
    this.get('live').pipe(take(1)).subscribe(() => {
      this.logger.logInfo('API Live check successful');
    });
  }

  /**
   * Retrieves a parameter from DB
   */
  getParam(key: string): Promise<{ key: string, value: string }> {
    return firstValueFrom(this.get<{key: string, value: string}>('parameters/' + key));
  }

  getAllParams(): Promise<{key: string, value: string}[]> {
    return firstValueFrom(this.get<{key: string, value: string}[]>('parameters'));
  }

  private createOrUpdateParam(key: string, value: string): Promise<{key: string, value: string}> {
    return firstValueFrom(this.post<{key: string, value: string}>('parameters', {key, value}));
  }

  async scoreParameters(percentAffinityWeight: number, penetrationWeight: number, penetrationAffinityWeight: number) {
    //await this.createOrUpdateParam('per_aff_weight', percentAffinityWeight.toString());
    //await this.createOrUpdateParam('pen_weight', penetrationWeight.toString());
    //await this.createOrUpdateParam('pen_aff_weight', penetrationAffinityWeight.toString());

    return firstValueFrom(this.post<string>('parameters/score', {
      percentAffinityWeight, penetrationWeight, penetrationAffinityWeight
    }));
  }

  async getCountryList(): Promise<CountryJson[]> {
    return firstValueFrom(this.get<CountryJson[]>('data/countries'));
  }

  async updateCountryList(countries: CountryJson[]) {
    return firstValueFrom(this.put<void>('data/countries', {countries: countries}));
  }

  /**
   * REST API get method
   * @param endpoint Termination of base URL
   * @param params
   */
  get<T>(endpoint: string, params?: HttpParams|{[param: string]:string|number|boolean|ReadonlyArray<string|number|boolean>;}): Observable<T> {
    return this.httpClient.get<T>(this.BASE_URL + endpoint, {params: params});
  }

  /**
   * REST API post method
   * @param endpoint Termination of base URL
   * @param args JSON body
   */
  post<T>(endpoint: string, args: any = {}): Observable<T> {
    return this.httpClient.post<T>(this.BASE_URL + endpoint, args)
  }

  /**
   * REST API put method
   * @param endpoint Termination of base URL
   * @param body JSON body
   */
  put<T>(endpoint: string, body: any = {}) {
    return this.httpClient.put<T>(this.BASE_URL + endpoint, body)
  }

  /**
   * REST API delete method
   * @param endpoint Termination of base URL
   * @param params Eventual query params
   */
  delete<T>(endpoint: string, params: any = {}) {
    return this.httpClient.delete<T>(this.BASE_URL + endpoint, {params: params})
  }
}
