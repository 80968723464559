
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {Logger} from './app/classes/util/logger';

if (environment.online) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then()
  .catch(err => Logger.logError(err));
