import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiModelService} from "../sp-api-model/api-model.service";
import {Plan} from "../../../models/plan";
import {LoggerService} from '../../sp-logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class ApiPlanService extends ApiModelService<Plan> {
  protected readonly basePath = "plans";

  constructor(httpClient: HttpClient, logger: LoggerService) {
    super(httpClient, logger);
  }

  /* Other methods with relations go here */


  /* ------------------------------------ */
}
