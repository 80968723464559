import {Component, Inject, OnInit} from '@angular/core';
import {FacebookLinkService} from "../../../../services/sp-facebook/facebook-link.service";
import {AdAccount} from "../../../../models/ad-account";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LoggerService} from "../../../../services/sp-logger/logger.service";

@Component({
  selector: 'app-sp-modal-facebook-account-add',
  templateUrl: './sp-modal-facebook-account-add.component.html',
  styleUrls: ['./sp-modal-facebook-account-add.component.scss']
})
export class SpModalFacebookAccountAddComponent implements OnInit {

  initSelectionIds: number[] = [];
  adAccounts: Array<AdAccount> = [];
  searchText: string;
  dataChanged = false;
  constructor(
    public dialogRef: MatDialogRef<SpModalFacebookAccountAddComponent>,
    private fb: FacebookLinkService,
    private logger: LoggerService,
    @Inject(MAT_DIALOG_DATA) public data: Array<AdAccount>,
  ) {
  }

  ngOnInit() {
    this.getAdAccounts();
  }

  getAdAccounts(){
    this.fb.getAdAccounts().then(ads => {
      this.adAccounts = ads.map(ad => {
        const selected = !!this.data.find(d => d.facebook_account_id == ad.facebook_account_id);
        ad.selected = selected;
        if (selected) this.initSelectionIds.push(ad.facebook_account_id);
        return ad;
      });
    }).catch(err => {
      this.logger.logError("Couldn't load ad accounts in FB Add popup", err);
    });
  }

  onAdSelectionChange() {
    let changed = false;

    const selectedAccountIds = this.adAccounts.filter(ad => ad.selected).map(ad => ad.facebook_account_id);

    if (selectedAccountIds.length != this.initSelectionIds.length) changed = true;
    else {
      selectedAccountIds.forEach(id => {
        if (!this.initSelectionIds.includes(id)) changed = true;
      });
    }

    this.dataChanged = changed;
  }

  save() {
    this.dialogRef.close({
      accounts: this.adAccounts.filter(ad => ad.selected),
      updated: this.dataChanged
    });
  }
}
