import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {SpAssetManagerComponent} from './sp-asset-manager.component';
import {PermissionGuard} from "../../../guards/permission/permission.guard";

const spAssetManagerRoutes: Routes = [
  {
    path: 'admin/assets',
    component: SpAssetManagerComponent,
    canActivate: [PermissionGuard],
    data: {permission: 'admin_manage_assets'}
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(spAssetManagerRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SpAssetManagerRoutingModule {}
