<main class="">
  <div class="card-header p-3">
    Choose the type of tab you want to add
  </div>
  <div class="p-5 d-flex justify-content-around">
    <div>
      <div class="card" style="width: 18rem">
        <div class=" text-center d-flex justify-content-center align-items-center" style="height: 9rem">
          <img alt="" class="" src="assets/images/dashboard/box.png" style="background-color: white" width="84" />
        </div>
        <div class="card-body p-3">
          <h5>Empty tab</h5>
          <p><span class="sp-text-secondary"> Select this option if you want to create your own link from scratch.</span></p>
        </div>
        <div class="card-footer d-flex justify-content-center">
          <button (click)="sendData('tab')" class="btn btn-primary sp-btn sp-btn-primary" matTooltip="Start" matTooltipHideDelay="10"  routerLinkActive="active" style="width: 6rem" type="button">
            <span>Select</span>
          </button>
        </div>
      </div>
    </div>

    <div class="sp-or-between">
      <div style="width: 1rem;border: 2px solid #9D9A9A;border-radius: 1rem"></div>
      <span class="pl-2 pr-2">OR</span>
      <div style="width: 1rem;border: 2px solid #9D9A9A;border-radius: 1rem"></div>
    </div>
    <div>
      <div class="card" style="width: 18rem">
        <div class=" text-center d-flex justify-content-center align-items-center" style="height: 9rem">
          <img alt="" class="" src="assets/images/dashboard/template.png" width="231" />
        </div>
        <div class="card-body p-3">
          <h5>Select a template</h5>
          <p><span class="sp-text-secondary"> Select this option if you want to create a link from pre-built templates.</span></p>
        </div>
        <div class="card-footer d-flex justify-content-center">
          <button (click)="sendData('template')" class="btn btn-primary sp-btn sp-btn-primary" matTooltip="Start" matTooltipHideDelay="10" routerLinkActive="active" style="width: 6rem" type="button">
            <span>Select</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-3 text-center">
    <mat-checkbox [(ngModel)]="doNotShowAgain" class="sp-form-field">
      <span class="sp-checkbox">
        Do not show again
      </span>
    </mat-checkbox>

  </div>


</main>
