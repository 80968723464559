import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DebugService {
  private _debugOutput: string;
  private _debugBoxOpen: boolean;
  public featureFlags: {flag: string, active: boolean}[] = [];
  public featureFlagChanged: EventEmitter<{flag: string, active: boolean}> = new EventEmitter();

  constructor() {
    this._debugBoxOpen = false;
  }

  public toggleDebugBox() {
    this._debugBoxOpen = !this._debugBoxOpen;
  }

  public setDebugOutput(output: string) {
    this._debugOutput = (output != '' ? output : undefined);
  }

  public updateFeatureFlag(flag: string, active: boolean) {
    if (!this.featureFlags.find(f => f.flag === flag)) {
      this.featureFlags.push({flag, active});
      return;
    }

    this.featureFlags.find(f => f.flag === flag).active = active;
    this.featureFlagChanged.emit({flag, active});
  }

  get debugBoxOpen(): boolean { return this._debugBoxOpen; }
  get debugOutput(): string { return this._debugOutput; }
}
