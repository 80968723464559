import {Audience} from "../../models/audience";
import {MapUtils} from '../../utils/map/map-utils';
import {TargetingUtils} from '../../utils/targeting/targeting-utils';
import {environment} from '../../../environments/environment';
import {CriteriaExtra, FlexSpec, IdName, Geolocation, LookalikeSpec} from '../../types/facebook-types';

export class Targeting {
  // General
  type: string;

  // Base targeting
  ageMin: number = 18;
  ageMax: number = 65;
  customAudiences?: Array<IdName>;
  connections?: Array<IdName>;
  genders: Array<number> = [1, 2];
  geolocations?: Geolocation;
  excludedGeolocations?: Geolocation;
  rigid: FlexSpec = new FlexSpec();
  flex?: FlexSpec[];
  exclusions?: FlexSpec;
  platforms: Array<string> = ['audience_network', 'facebook', 'messenger', 'instagram'];
  locales?: Array<number>;
  relationshipStatuses?: Array<number>;

  // Extra
  extra?: CriteriaExtra;

  constructor() {
    this.type = 'audience';
  }

  static fromJson(target_spec: string, type: string, extra?: string, lookalikeSpec?: LookalikeSpec): Targeting {
    const targeting = new Targeting();
    targeting.type = type;

    const targetingJson = JSON.parse(target_spec);

    if (targetingJson.age_min) targeting.ageMin = targetingJson.age_min;
    if (targetingJson.age_max) targeting.ageMax = targetingJson.age_max;
    if (targetingJson.exclusions) targeting.exclusions = this.jsonToFlex(targetingJson.exclusions);
    if (targetingJson.genders) targeting.genders = targetingJson.genders.filter(g => g !== 0);
    if (targetingJson.custom_audiences) targeting.customAudiences = targetingJson.custom_audiences;
    if (targetingJson.connections) targeting.connections = targetingJson.connections;
    if (targetingJson.geo_locations) targeting.geolocations = targetingJson.geo_locations;
    if (lookalikeSpec) targeting.geolocations = Targeting.getGeolocationFromLookalikeSpec(lookalikeSpec);
    if (targetingJson.excluded_geo_locations) targeting.excludedGeolocations = targetingJson.excluded_geo_locations;
    if (targetingJson.locales) targeting.locales = targetingJson.locales;
    if (targetingJson.relationship_statuses) targeting.relationshipStatuses = targetingJson.relationship_statuses;


    // Fetching rigid specs
    for (const key in targetingJson) {
      if (targetingJson.hasOwnProperty(key)) {
        if (TargetingUtils.NUMBER_FLEX_TYPES.includes(key)) {
          const rigidValues = targetingJson[key];
          if (key !== 'locales' && key !== 'relationship_statuses') {
            targeting.addRigid(key, rigidValues);
          }
        }
      }
    }

    // Parsing different types of targeting (map and number)
    if (targetingJson.flexible_spec) {
      const mapFlex: FlexSpec[] = [];

      targetingJson.flexible_spec.forEach(andClause => {
        const maps: FlexSpec = new FlexSpec();

        for (const andClauseType in andClause) {
          if (andClause.hasOwnProperty(andClauseType)){
            if (TargetingUtils.NUMBER_FLEX_TYPES.includes(andClauseType)) {
              const innerMap: number[] = andClause[andClauseType].map(jss => jss as number);
              maps.set(andClauseType, innerMap);
            } else {
              const innerMap: Map<string, string>[] = andClause[andClauseType].map(jss => MapUtils.jsonToMap<string>(jss));
              maps.set(andClauseType, innerMap);
            }
          }
        }

        if (maps.size > 0) mapFlex.push(maps);
      });

      targeting.flex = mapFlex;
    }

    if (extra) {
      targeting.extra = JSON.parse(extra);
    }

    return targeting;
  }

  private static getGeolocationFromLookalikeSpec(lookalikeSpec: LookalikeSpec): Geolocation {
    const countries = lookalikeSpec.country ? [lookalikeSpec.country] : lookalikeSpec.target_countries;
    return { countries };
  }

  static fromAudience(audience: Audience): Targeting {
    if (!audience || !audience.target_spec) return new Targeting();

    return this.fromJson(audience.target_spec, audience.type, audience.data_extra, audience.lookalike_spec);
  }

  private static jsonToFlex(json: any): FlexSpec {
    const map: FlexSpec = new FlexSpec();

    for (const value in json) {
      if (json.hasOwnProperty(value)) {
        if (TargetingUtils.NUMBER_FLEX_TYPES.includes(value)) {
          const inner: number[] = json[value].map(js => js as number);
          map.set(value, inner);
        } else {
          const innerMap: Map<string, string>[] = json[value].map(js => MapUtils.jsonToMap<string>(js));
          map.set(value, innerMap);
        }
      }
    }

    return map;
  }
  private addRigid(key: string, values: number[]) {
    if (!this.rigid.has(key)) {
      this.rigid.set(key, []);
    }

    const rigidVal = this.rigid.get(key);

    values.forEach(value => {
      if (!rigidVal.includes(value)) {
        rigidVal.push(value);
      }
    });
  }
  mergeWith(targeting: Targeting): Targeting {
    // Base
    const ageMin = [targeting.ageMin, this.ageMin].reduce((a, b) => a > b ? a : b);
    const ageMax = [targeting.ageMax, this.ageMax].reduce((a, b) => a < b ? a : b);
    const customAudiences = this.arrayConcat(this.customAudiences, targeting.customAudiences);
    const connections = this.arrayConcat(this.connections, targeting.connections);
    const genders = this.genders.filter(g => targeting.genders.includes(g));
    // Geolocations
    const countryGroups = this.arrayConcat(this.geolocations?.country_groups, targeting.geolocations?.country_groups);
    const countries = this.arrayConcat(this.geolocations?.countries, targeting.geolocations?.countries);
    const regions = this.arrayConcat(this.geolocations?.regions, targeting.geolocations?.regions);
    const mediumGeoAreas = this.arrayConcat(this.geolocations?.medium_geo_areas, targeting.geolocations?.medium_geo_areas);
    const cities = this.arrayConcat(this.geolocations?.cities, targeting.geolocations?.cities);
    const zips = this.arrayConcat(this.geolocations?.zips, targeting.geolocations?.zips);
    const subCities = this.arrayConcat(this.geolocations?.subcities, targeting.geolocations?.subcities);
    const neighborhood = this.arrayConcat(this.geolocations?.neighborhoods, targeting.geolocations?.neighborhoods);
    const geolocations: Geolocation = {country_groups: countryGroups, countries: countries, regions: regions, medium_geo_areas: mediumGeoAreas, cities: cities, zips: zips, subcities: subCities, neighborhoods: neighborhood}
    // Excluded geolocations
    const exCountryGroups = this.arrayConcat(this.excludedGeolocations?.country_groups, targeting.excludedGeolocations?.country_groups);
    const exCountries = this.arrayConcat(this.excludedGeolocations?.countries, targeting.excludedGeolocations?.countries);
    const exRegions = this.arrayConcat(this.excludedGeolocations?.regions, targeting.excludedGeolocations?.regions);
    const exMediumGeoAreas = this.arrayConcat(this.excludedGeolocations?.medium_geo_areas, targeting.excludedGeolocations?.medium_geo_areas);
    const exCities = this.arrayConcat(this.excludedGeolocations?.cities, targeting.excludedGeolocations?.cities);
    const exZips = this.arrayConcat(this.excludedGeolocations?.zips, targeting.excludedGeolocations?.zips);
    const exSubCities = this.arrayConcat(this.excludedGeolocations?.subcities, targeting.excludedGeolocations?.subcities);
    const exNeighborhood = this.arrayConcat(this.excludedGeolocations?.neighborhoods, targeting.excludedGeolocations?.neighborhoods);
    const excludedGeolocations: Geolocation = {country_groups: exCountryGroups, countries: exCountries, regions: exRegions, medium_geo_areas: exMediumGeoAreas, cities: exCities, zips: exZips, subcities: exSubCities, neighborhoods: exNeighborhood}
    // Rigid specs
    const rigid = new FlexSpec(this.rigid);
    targeting.rigid.forEach((v,k) => {
      if (rigid.has(k)) {
        v.forEach(value => {
          if (!rigid.get(k).includes(value)) {
            rigid.get(k).push(value);
          }
        })
      } else {
        rigid.set(k, v);
      }
    });
    // Filter is for avoiding undefined objects
    const flex = this.flex ? this.flex.concat(targeting.flex).filter(f => !!f) : (targeting.flex ? targeting.flex : []);
    const exclusions = this.exclusions ? this.exclusions.mergeWith(targeting.exclusions) : new FlexSpec();
    const platforms = this.arrayConcat(this.platforms, targeting.platforms);
    const locales = this.arrayConcat(this.locales, targeting.locales);

    const tar = new Targeting();
    tar.ageMin = ageMin;
    tar.ageMax = ageMax;
    tar.customAudiences = customAudiences;
    tar.connections = connections;
    tar.genders = genders ? genders : [];
    tar.geolocations = geolocations;
    if (!Geolocation.isEmpty(excludedGeolocations) )tar.excludedGeolocations = excludedGeolocations;
    if (rigid && rigid.size) tar.rigid = rigid;
    if (flex && flex.length > 0) tar.flex = flex;
    if (exclusions && exclusions.size > 0) tar.exclusions = exclusions;
    if (platforms && platforms.length > 0) tar.platforms = platforms;
    if (locales && locales.length > 0) tar.locales = locales;

    return tar;
  }
  flexOrString(spec: FlexSpec): string {
    const items: string[] = [];
    if(!spec) return "None";

    spec.forEach((maps, key) => {
      items.push(
        maps.map(val => {
          if (typeof val === "number") {
            const label = TargetingUtils.getLabelForType(key, val);
            return label ? (environment.config.enableDebugFeatures ? val + ';' + label + ';' + key : label) : "N/A";
          } else {
            return val.has("name") ? (environment.config.enableDebugFeatures ? val.get("id") + ";" + val.get("name") : val.get("name")) : "N/A";
          }
        }).join(" OR ")
      );
    });

    return items.length == 0 ? "None" : items.join(" OR ");
  }
  geolocString(excluded: boolean = false): string {
    const makeOrSeparated: (any) => string = spec => {
      if(!spec) return "";

      const jsonValues = Object.values<any>(spec);

      return jsonValues.map(data => data.name).join("  OR  ");
    }

    let geoString = "";
    if(!this.geolocations) return "";

    const geoMap: Map<string, any> = new Map();

    if (excluded) {
      if (this.excludedGeolocations.country_groups) geoMap.set("country_groups", this.excludedGeolocations.country_groups)
      if (this.excludedGeolocations.countries) geoMap.set("countries", this.excludedGeolocations.countries);
      if (this.excludedGeolocations.regions) geoMap.set("regions", this.excludedGeolocations.regions);
      if (this.excludedGeolocations.medium_geo_areas) geoMap.set("medium_geo_areas", this.excludedGeolocations.medium_geo_areas);
      if (this.excludedGeolocations.cities) geoMap.set("cities", this.excludedGeolocations.cities);
      if (this.excludedGeolocations.zips) geoMap.set("zips", this.excludedGeolocations.zips);
      if (this.excludedGeolocations.subcities) geoMap.set("sub_cities", this.excludedGeolocations.subcities);
      if (this.excludedGeolocations.neighborhoods) geoMap.set("neighborhoods", this.excludedGeolocations.neighborhoods);
    } else {
      if (this.geolocations.country_groups) geoMap.set("country_groups", this.geolocations.country_groups)
      if (this.geolocations.countries) geoMap.set("countries", this.geolocations.countries);
      if (this.geolocations.regions) geoMap.set("regions", this.geolocations.regions);
      if (this.geolocations.medium_geo_areas) geoMap.set("medium_geo_areas", this.geolocations.medium_geo_areas);
      if (this.geolocations.cities) geoMap.set("cities", this.geolocations.cities);
      if (this.geolocations.zips) geoMap.set("zips", this.geolocations.zips);
      if (this.geolocations.subcities) geoMap.set("sub_cities", this.geolocations.subcities);
      if (this.geolocations.neighborhoods) geoMap.set("neighborhoods", this.geolocations.neighborhoods);
    }

    let geoCount = 0;
    geoMap.forEach(((value, key) => {
      if(key == "countries" || key == "country_groups") {
        geoString += value.join(", ");
      } else {
        geoString += makeOrSeparated(value);
      }
      geoCount++;

      if(geoCount < geoMap.size) geoString += "  OR  ";
    }));

    return geoString == "" ? "None" : geoString
  }
  gendersString() {
    if (this.genders.length == 0 || this.genders.length == 2) {
      return "Both";
    } else {
      switch (this.genders[0]) {
        case 0: return "Gender 0";
        case 1: return "Man";
        case 2: return "Woman";
      }
    }
  }
  sociodemoCopy(): Targeting {
    let targeting = new Targeting();

    targeting.ageMin = this.ageMin;
    targeting.ageMax = this.ageMax;
    targeting.genders = this.genders;
    targeting.geolocations = this.geolocations;

    return targeting;
  }
  /**
   * Make sure that the targeting is complete and no info is missing
   */
  validate(): {geoloc: boolean, flex: boolean, all: boolean} {
    const geolocOk = !!(this.geolocations) &&
      (!!(this.geolocations.country_groups) ||
        !!(this.geolocations.countries) ||
        !!(this.geolocations.regions) ||
        !!(this.geolocations.medium_geo_areas) ||
        !!(this.geolocations.cities) ||
        !!(this.geolocations.zips) ||
        !!(this.geolocations.subcities) ||
        !!(this.geolocations.neighborhoods));
        //!!(this.geolocations.)
    const flexOk = !!this.flex && this.flex.length > 0;

    return {geoloc: geolocOk, flex: flexOk, all: geolocOk && flexOk};
  }
  toFbJsonString(): string {
    let json: any;

    json = {
      age_min: this.ageMin,
      age_max: this.ageMax,
      genders: this.genders,
    }

    if (this.customAudiences) json.custom_audiences = this.customAudiences;
    if (this.connections) json.connections = this.connections;
    if (this.flex) json.flexible_spec = this.flex.filter(f => !!f).map(this.flexToJson);
    if (this.geolocations) json.geo_locations = this.geolocations;
    if (this.excludedGeolocations) json.excluded_geo_locations = this.excludedGeolocations;
    if (this.exclusions) json.exclusions = this.flexToJson(this.exclusions);
    if (this.platforms) json.publisher_platforms = this.platforms;
    if (this.locales) json.locales = this.locales;
    if (this.relationshipStatuses) json.relationship_statuses = this.relationshipStatuses;

    this.rigid.forEach((val, key) => {
      json[key] = val;
    });

    let jsonString = JSON.stringify(json);
    jsonString = jsonString.replace(/&/g, " and "); // Avoid API requests to fail

    return jsonString;
  }
  toAudience(name: string, partition: string, locale: string, type: string): Audience {
    let audience = new Audience(name, partition, locale, type);
    audience.target_spec = this.toFbJsonString();
    if (this.extra) audience.data_extra = JSON.stringify(this.extra);

    return audience;
  }
  private flexToJson(flex: FlexSpec): any {
    const json: any = {};
    Array.from(flex.keys()).forEach(k => {
      json[k] = flex.get(k).map(m => MapUtils.mapToJson(m));
    });

    return json;
  }
  private arrayConcat<T>(a: T[], b: T[]) {
    if ((!a || a.length == 0) && (!b || b.length == 0)) return undefined;
    const arr: T[] = a ? [...a] : [];
    if (b) {
      b.forEach(e => {
        if (!arr.includes(e)) {
          arr.push(e);
        }
      });
    }
    return arr;
  }
}
