export class RoleUtils {
  public static readonly freemiumLevel = 5;
  public static readonly trialLevel = 8;
  public static readonly userLevel = 10;
  public static readonly teamAdminLevel = 15;
  public static readonly superAdminLevel = 50;

  public static readonly freemiumKey = 'user_free';
  public static readonly trialKey = 'user_trial';
  public static readonly userKey = 'user';
  public static readonly teamAdminKey = 'team_admin';
  public static readonly superAdminKey = 'admin';
}
