import { Pipe, PipeTransform } from '@angular/core';

export interface NamedItem {
  name: string
}

@Pipe({name: 'arrayNameJoin'})
export class ArrayNameJoinPipe implements PipeTransform {
  transform(content: Array<NamedItem>): string {
    if (!content) return "";
    return content.map(c => c.name).join(", ");
  }
}
