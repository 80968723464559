export enum MetricType {
  TARGET_PEN = "Target Penetration",
  BENCHMARK_PEN = "Benchmark Penetration",
  TARGET_SIZE = "Target Size",
  BENCHMARK_SIZE = "Benchmark Size",
  SELECTIVITY = "Selectivity",
  SCORE = "Score",
  NONE = "No metrics",
  AUDIENCE_SIZE = "Audience Size",
  AUDIENCE_BENCHMARK_SIZE = "Audience Benchmark Size"
}
