import {CrawlTrackerService} from "../services/sp-crawl-tracker/crawl-tracker.service";

export class CrawlStatus {
  name: string;
  crawlId: string;
  lastUpdate: number;
  size: number;
  state: string;
  progress: number;
  startedAt: number;
  actualStep: number;
  steps: number;
  progression: boolean;
  loadingStatus: string;
  universeIds: number[];

  static done(): CrawlStatus {
    const done = new CrawlStatus();
    done.universeIds = [];
    done.state = "done";
    done.loadingStatus = "Done !";
    done.progression = false;
    done.actualStep = CrawlTrackerService.STEP_PERSONAE + 1;
    return done;
  }

  static unavailable(): CrawlStatus {
    const unavailable = new CrawlStatus();
    unavailable.state = "unavailable";
    unavailable.loadingStatus = "Unavailable...";
    unavailable.progression = false;
    return unavailable;
  }
}
