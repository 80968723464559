import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ApiCompanyService} from "../../../../../services/sp-api/sp-api-company/api-company.service";
import {Company} from '../../../../../models/company';
import {User} from '../../../../../models/user';
import {AuthenticationService} from "../../../../../services/sp-authentication/authentication.service";
import {AlertService} from '../../../../../services/sp-alert/alert.service';

@Component({
  selector: 'app-sp-modal-company-remove',
  templateUrl: './sp-modal-company-remove.component.html',
  styleUrls: ['./sp-modal-company-remove.component.scss']
})
export class SpModalCompanyRemoveComponent implements OnInit {


  constructor( public dialogRef: MatDialogRef<SpModalCompanyRemoveComponent>,
               private apiCompany: ApiCompanyService,
               private auth: AuthenticationService,
               private alert: AlertService,
               @Inject(MAT_DIALOG_DATA) public data: {company: Company, user: User},
               ) {
  }

  checkDeletePermission() {
    return !this.data.company.users.find(user => this.auth.session.user.id == user.id);
  }

  deleteCompany(){
    this.apiCompany.deleteOne(this.data.company)
      .then(() => this.dialogRef.close(this.data.company))
      .catch(error => {
        this.alert.notify("Error", error.error.message, "error");
        this.dialogRef.close(null);
      })
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  ngOnInit() {}
}
