import {Component, Inject, OnInit} from '@angular/core';
import {User} from '../../../../models/user';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AuthenticationService} from '../../../../services/sp-authentication/authentication.service';
import {environment} from '../../../../../environments/environment';
import {ApiProjectService} from '../../../../services/sp-api/sp-api-project/api-project.service';
import {Project} from '../../../../models/project';
import {ApiProjectSharingService} from '../../../../services/sp-api/sp-api-project-sharing/api-project-sharing.service';

@Component({
  selector: 'app-sp-modal-project-list-share',
  templateUrl: './sp-modal-project-list-share.component.html',
  styleUrls: ['./sp-modal-project-list-share.component.scss']
})
export class SpModalProjectListShareComponent implements OnInit {
  loading: boolean = true;
  searchUsers = '';
  env = environment;
  users: User[] = [];
  selectedUsers: User[] = [];

  constructor(public dialogRef: MatDialogRef<SpModalProjectListShareComponent>,
              @Inject(MAT_DIALOG_DATA) public project: Project,
              private apiProjectSharing: ApiProjectSharingService,
              private apiProject: ApiProjectService,
              public auth: AuthenticationService) { }

  async ngOnInit() {
    this.users = await this.apiProjectSharing.getAllShareableUsers();
    this.loading = false;

    this.project.shared_users.forEach(p => {
      const usr = this.users.find(u => u.id === p.id);
      if (usr) {
        usr.isTemporarySelected = true;
        this.selectedUsers.push(usr);
      }
    });
  }

  ngAfterViewInit(){

  }
  resetFilter() {
    this.searchUsers = '';
  }

  async shareProject() {
    const selectedUids = this.selectedUsers.map(u => u.id);
    const existingUids = this.project.shared_users.map(u => u.id);
    const newlySelectedUsers = this.selectedUsers.filter(u => !existingUids.includes(u.id));
    const unselectedUsers = this.project.shared_users.filter(u => !selectedUids.includes(u.id));
    const allUsersChange = {selected: newlySelectedUsers, unselected: unselectedUsers};
    await this.apiProject.projectShareRevoke(this.project, unselectedUsers);
    await this.apiProject.projectShare(this.project, newlySelectedUsers);
    this.dialogRef.close(allUsersChange);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  handleSelected(us: any) {
    this.users.find(u => u.id === us.id).isTemporarySelected = !us.isTemporarySelected;
  }
}
