import {PathEntry} from './path-entry';
import {Criteria} from '../../models/criteria';
import {AudiencePreset} from '../../models/audience-preset';

export class TreeNode<T> {
  value: T;
  children: TreeNode<T>[];

  getChildrenValues(): T[] {
    return this.children.filter(c => !c.children || c.children.length == 0).map(v => v.value);
  }
  static getAllValues<T>(items: TreeNode<T>[]): T[] {
    let allValues = [];
    const currentValues = items.filter(i => !i.children || i.children.length == 0).map(i => i.value);
    allValues = allValues.concat(currentValues);
    items.filter(i => i.children && i.children.length > 0).forEach(i => {
      allValues = allValues.concat(this.getAllValues(i.children));
    });
    return allValues;
  }
  static flatten<T>(items: TreeNode<T>[]): T[] {
    let allValues = [];
    const currentValues = items.map(i => i.value);
    allValues = allValues.concat(currentValues);
    items.forEach(i => {
      if (i.children && i.children.length > 0) allValues = allValues.concat(this.flatten(i.children));
    });
    return allValues;
  }

  static getPathByIds(items: TreeNode<PathEntry>[], pathIds: number[]): { elem: TreeNode<PathEntry>, path: PathEntry[] } {
    const pathTrack: PathEntry[] = [];
    let currElem = new TreeNode<PathEntry>();
    currElem.children = items;
    currElem.value = new PathEntry(undefined, [], 'Root');
    pathIds.forEach((id, idx) => {
      currElem = currElem.children.find(t => t.value.id == id);
      pathTrack.push(currElem.value);
    });

    return {elem: currElem, path: pathTrack};
  }

  static fromCriterion(criterion: Criteria[]) {
    const safeCriterion = criterion.filter(c => c.path);
    const entries = safeCriterion.map(c => new PathEntry(c.id, c.path.split(' -- '), c.name));
    return this.fromEntries(entries);
  }

  static fromPresets(presets: AudiencePreset[]) {
    const pathEntries = presets.map(p => {
      return new PathEntry(p.id, p.category_path.split(' -- '), p.name, p.description);
    });
    return this.fromEntries(pathEntries);
  }

  /**
   * Create TreeNode from entries
   * @param pathEntries source path entries
   * @param name name filter which must be include in path, defaults to no filter if not defined
   */
  static fromEntries(pathEntries: PathEntry[], name?: string) {
    // Filtered by name or no filter if not defined
    const items = name ? pathEntries.filter(pn => pn.path.includes(name)) : pathEntries;
    let elems: {id?: number, name: string, description?: string}[] = [];
    items.forEach(i => {
      // Getting path index
      const indexOf = name ? i.path.indexOf(name) : -1;
      // Checking if path has no more path
      if (i.path.length - 1 >= indexOf + 1) {
        const isFinal = i.path.length - 1 == indexOf + 1;
        const child = i.path[indexOf+1];
        if (!elems.find(e => e.name == child)) elems.push(isFinal ? {id: i.id, name: child, description: i.description} : {name: child});
      }
    });

    return elems.map(c => {
      const selfChildren = this.fromEntries(pathEntries, c.name);
      const tn = new TreeNode<{id?: number, name: string, description?: string}>();
      tn.value = c;
      tn.children = selfChildren;
      return tn;
    });
  }
}
