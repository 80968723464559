import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SpLayoutDashboardDefaultComponent } from './sp-layout-dashboard-default.component';
import {NotifierModule} from 'angular-notifier';

@NgModule({
  declarations: [SpLayoutDashboardDefaultComponent],
  exports: [
    SpLayoutDashboardDefaultComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        NotifierModule.withConfig({
          theme: "material",
          position: {
            horizontal: {
              position: "right"
            },
            vertical: {
              position: "top"
            }
          }
        })
    ]
})
export class SpLayoutDashboardDefaultModule { }
