import { Injectable } from '@angular/core';
import {User} from '../../../models/user';
import {ApiService} from '../api.service';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from '../../sp-logger/logger.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiProjectSharingService extends ApiService {
  protected readonly basePath = "project-sharing";

  constructor(httpClient: HttpClient, logger: LoggerService) {
    super(httpClient, logger);
  }

  getAllShareableUsers(): Promise<User[]> {
    return firstValueFrom(this.get<User[]>(`${this.basePath}/users`));
  }
}
