import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SpAdminCompanyCreateComponent } from './sp-admin-company-create/sp-admin-company-create.component';
import { SpAdminCompanyListComponent } from './sp-admin-company-list/sp-admin-company-list.component';
import {PermissionGuard} from "../../../guards/permission/permission.guard";

const spAdminCompanyRoutes: Routes = [
  {
    path: 'companies',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      },
      {
        path: 'create',
        component: SpAdminCompanyCreateComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'company.admin' }
      },
      {
        path: 'update/:company_id',
        component: SpAdminCompanyCreateComponent
      },
      {
        path: 'details',
        redirectTo: 'list',
        pathMatch: 'full'
      },

      {
        path: 'list',
        component: SpAdminCompanyListComponent,
        canActivate: [PermissionGuard],
        data: { permission: 'company.admin' }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(spAdminCompanyRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SpAdminCompanyRoutingModule {}
