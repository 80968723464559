import { Injectable } from '@angular/core';
import {Project} from '../../models/project';
import {Subject} from 'rxjs';
import {LoggerService} from "../sp-logger/logger.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _loading: boolean = false;
  private _loadingGlobal: boolean = false;
  private _personaLoadingData: Project;
  private _loadedSub: Subject<boolean> = new Subject<boolean>();
  private _loadText: string;
  private _loadStartTime: number;
  private _loadProgress: number;
  constructor(private logger: LoggerService) {}

  loadGlobal() { this._loadingGlobal = true; }
  loadedGlobal() { this._loadingGlobal = false; }
  load(loadTxt?: string) {
    if (!this._loading) {
      this._loadStartTime = Date.now();
      if (loadTxt) this._loadText = loadTxt + "...";
      else this._loadText = "Loading...";
      this._loading = true;
    }
  }
  progress(progress: number) {
    if (environment.config.enableExperimentalFeatures) this._loadProgress = progress == 0 ? 1 : progress;
  }
  step(stepName: string) {
    this._loadText = stepName + "...";
  }
  loaded() {
    if (this._loading) {
      const deltaMs = Date.now() - this._loadStartTime;
      this.logger.debug("Loading took " + deltaMs + " ms (" + Math.round(deltaMs / 100) / 10 + "s)");
      this._loading = false;
      this._loadText = undefined;
      this._loadedSub.next(true);
    }
    if (this._loadProgress) this._loadProgress = undefined;
  }
  get loading(): boolean { return this._loading; }
  get loadedSub(): Subject<boolean> { return this._loadedSub; }
  get loadingGlobal(): boolean { return this._loadingGlobal; }

  get personaLoadingData(): Project { return this._personaLoadingData; }
  set personaLoadingData(project: Project) { this._personaLoadingData = project; }

  get loadText() { return this._loadText; }
  get loadProgress() { return this._loadProgress; }
}
