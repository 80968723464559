import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SpAccountUserEditComponent } from './sp-account-user-edit/sp-account-user-edit.component';
import {AuthGuard} from "../../guards/auth/auth.guard";

const spAccountRoutes: Routes = [
  {
    path: 'account',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'edit',
        pathMatch: 'full'
      },
      {
        path: 'edit',
        component: SpAccountUserEditComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(spAccountRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SpAccountRoutingModule {}
