import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ClientUtils} from '../../../utils/client/client-utils';
import {ErrorUtils} from '../../../utils/error/error-utils';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorService {
  private readonly apiUrl: string = environment.config.api.baseUrl;

  constructor(private http: HttpClient) { }

  sendErrorReport(errorMessage: any, error?: Error) {
    const jsonError = error ? ErrorUtils.stacktraceToStringList(error) : null;
    const sub = this.http.post(this.apiUrl + "error/report", {error_stack: jsonError, message: errorMessage, info: ClientUtils.getClientInfo()}).subscribe(() => sub.unsubscribe());
  }
}
