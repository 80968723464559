import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SpLayoutDashboardWelcomeComponent } from './sp-layout-dashboard-welcome.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MessagesModule} from 'primeng/messages';
import {NotifierModule} from 'angular-notifier';
import {MenubarModule} from 'primeng/menubar';
@NgModule({
  declarations: [SpLayoutDashboardWelcomeComponent],
  exports: [
    SpLayoutDashboardWelcomeComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        MatTooltipModule,
        MessagesModule,
        NotifierModule,
        MenubarModule,
        MatIconModule,
    ]
})
export class SpLayoutDashboardWelcomeModule {
}
