import { DynamicEnvironment } from './dynamic-environment';

class Environment extends DynamicEnvironment {
  public online: boolean;
  constructor() {
    super();
    this.online = true;
    this.recaptchaKey = '6Lcv81MpAAAAADMGsHa8_JAUfxqJqo2EkBjGj0Yn';
  }
}

export const environment = new Environment();
