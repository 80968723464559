import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {firstValueFrom, Subject} from 'rxjs';
import {Logger} from "../../classes/util/logger";
import {CookieService} from "ngx-cookie-service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthenticationService} from "../sp-authentication/authentication.service";
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

export interface SocketMessage {
  id?: string;
  type: string;
  data: any;
  date?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private readonly API_URL: string = environment.config.socket.apiHost + ':' + environment.config.socket.apiPort;

  private _socketMessageQueue: SocketMessage[] = [];
  private socket: Socket;
  private _connected: boolean = false;
  private _notifications: boolean;
  private _msgHistory: SocketMessage[] = [];
  //private _listeners: Subject<SocketMessage>[] = [];
  private _connectListener: Subject<void> = new Subject<void>();
  private _socketListener: Subject<SocketMessage> = new Subject<SocketMessage>();
  private _closeListener: Subject<void> = new Subject<void>();
  activeEvent: Subject<SocketMessage> = new Subject<SocketMessage>();

  constructor(private cookie: CookieService, private http: HttpClient) {
    if (!cookie.check('notifications')) {
      cookie.set('notifications', 'true', 0, '/', undefined, environment.online, 'Strict');
    }
    const notificationCookie = cookie.get('notifications');
    this._notifications = notificationCookie === 'true';
  }

  private createMessageType(type: string, msg: any): SocketMessage {
    return {type, data: msg};
  }

  /**
   * Get query to the websocket API
   * @param query
   * @param params
   */
  get(query: string, params: HttpParams) {
    return firstValueFrom(this.http.get(this.API_URL + '/' + query, {params}));
  }

  /**
   * Post query to the websocket API
   * @param query
   * @param body
   */
  post(query: string, body: any) {
    return firstValueFrom(this.http.post(this.API_URL + '/' + query, body))
  }

  /**
   * Put query to the websocket API
   * @param query
   * @param body
   */
  put(query: string, body: any) {
    return firstValueFrom(this.http.put(this.API_URL + '/' + query, body));
  }

  async init(): Promise<boolean> {
    if (environment.config.socket.enabled) {
      if (!this.cookie.check("track_id")) {
        const domain = environment.online ? '.soprism.com' : undefined;
        this.cookie.set("track_id", uuidv4(), 0, '/', domain, environment.online, 'Strict');
      }
      return new Promise(resolve => {
        if (this.socket) this.socket.close();
        const auth = this.cookie.get(AuthenticationService.authInfoCookie);
        const token = auth ? JSON.parse(auth).socketToken : undefined;
        this.socket = io((environment.config.socket.ssl ? 'wss' : 'ws') + '://' + environment.config.socket.host + ':' + environment.config.socket.port, {
          auth: {token: token}
        });
        this.socket.io.on('error', () => {
          Logger.logDebug('WebSocket connection failed!');
          resolve(false);
        });
        this.socket.io.on('open', () => {
          Logger.logDebug('WebSocket connection established!');
          this._connected = true;
          this._connectListener.next();
          if (this._socketMessageQueue.length > 0) {
            console.log("Sent " + this._socketMessageQueue.length + " queued events");
            this._socketMessageQueue.forEach(msg => {
              this.sendMessageType(msg.type, msg.data);
            });
            this._socketMessageQueue = [];
          }
          resolve(true);
        });
        this.socket.io.on('close', () => {
          this._connected = false;
          this._closeListener.next();
        });
        this.socket.onAny((evt, data) => {
          //const msg: SocketMessage = JSON.parse(data);
          const msg: SocketMessage = {type: evt, data};
          this._msgHistory.push(msg);
          this._socketListener.next(msg);
        });
      });
    } else {
      return false;
    }
  }

  close() {
    if (this.socket) {
      this.socket.close();
      this.socket = undefined;
    }
  }

  /**
   * Session retrieving
   * Retrieve the session from the storage
   */
  private sessionRetrieve(): {token: string, impersonate: boolean}|null {
    if (this.cookie.check(AuthenticationService.authInfoCookie)) {
      const raw = this.cookie.get(AuthenticationService.authInfoCookie);
      if (raw) {
        return JSON.parse(raw);
      } else return null;
    }
    else {
      const raw = localStorage.getItem(AuthenticationService.authInfoCookie)
      if (raw) {
        return JSON.parse(raw);
      } else return null;
    }
  }

  sendMessageType(type: string, msg: any) {
    const socketMessage = this.createMessageType(type, msg);
    if (this.socket && !this.sessionRetrieve()?.impersonate && this.socket.connected) {
      this.socket.emit(type, msg);
    } else {
      console.log('Socket message queued');
      this.addToQueue(socketMessage);
    }
  }

  addToQueue(message: SocketMessage) {
    if (this._socketMessageQueue.length == 10) {
      this._socketMessageQueue.pop();
    }

    this._socketMessageQueue.push(message);
  }

  createListener() {
    //const subject = new Subject<SocketMessage>();
    //this._listeners.push(subject);
    return this._socketListener.asObservable();
  }

  onConnect() {
    return this._connectListener.asObservable();
  }
  onDisconnect(callback) {
    return this._closeListener.subscribe(callback);
  }

  notificationToggle(value: boolean) {
    this._notifications = value;
    this.cookie.set('notifications', value ? 'true' : 'false', 0, '/', undefined, environment.online, 'Strict');
  }

  get notifications() { return this._notifications; }
  get connected() { return this._connected };
  get messageHistory() { return this._msgHistory; }
}
