import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Universe} from '../../../../../models/universe';
import {ApiUniverseService} from '../../../../../services/sp-api/sp-api-universe/api-universe.service';
import {AudienceType, ProjectCreationManagerService} from '../../../../../services/sp-project/project-creation-manager.service';
import {Audience} from '../../../../../models/audience';
import {AuthenticationService} from '../../../../../services/sp-authentication/authentication.service';
import {Subscription} from 'rxjs';
import {RoleUtils} from '../../../../../utils/role/role-utils';
import {ApiAudiencePresetService} from '../../../../../services/sp-api/sp-api-audience-preset/api-audience-preset.service';
import {AudiencePreset} from '../../../../../models/audience-preset';
import {TreeNode} from '../../../../../classes/data/tree-node';
import {PresetSelectEvent} from '../sp-project-create-audience-preset-menu/sp-project-create-audience-preset-menu.component';
import {Targeting} from '../../../../../classes/targeting/targeting';
import {MatMenuTrigger} from '@angular/material/menu';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {SocketService} from '../../../../../services/sp-ws/socket.service';
import {CountryJson} from '../../../../../services/sp-api/api.service';
import {environment} from '../../../../../../environments/environment';
import {LoaderService} from '../../../../../services/sp-loading/loader.service';

export interface ProjectSetupOptions {
  universeNameFallback?: string;
  showPresetSelection?: boolean;
}

interface ProjectChangeEvent {
  name: string;
  valid: boolean;
}

@Component({
  selector: 'app-sp-project-create-project-setup',
  templateUrl: './sp-project-create-project-setup.component.html',
  styleUrls: ['./sp-project-create-project-setup.component.scss']
})
export class SpProjectCreateProjectSetupComponent implements OnInit, AfterViewInit ,OnDestroy {
  @Input() readonly options: ProjectSetupOptions
  @Output() private readonly projectNameChange: EventEmitter<ProjectChangeEvent> = new EventEmitter();
  @ViewChild('presetTrigger') private presetTrigger: MatMenuTrigger;

  presetInputTest = '';
  projectNameControl: UntypedFormControl = new UntypedFormControl('', Validators.required);
  universeControl: UntypedFormControl = new UntypedFormControl('');
  selectedUniverse: Universe;
  selectedUniverses: Universe[] = [];
  universes: Universe[] = [];
  searchText: string = '';
  freemium = false;

  audiencePresets: AudiencePreset[] = [];
  presetsTreeNode: TreeNode<{id?: number, name: string, description?: string}>[] = [];
  selectedPreset?: Map<AudienceType, AudiencePreset> = new Map();

  countryList: CountryJson[] = [];
  topicsList: Universe[] = [];
  politicsList: Universe[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private creationManager: ProjectCreationManagerService,
    private apiUniverse: ApiUniverseService,
    private auth: AuthenticationService,
    private apiAudiencePreset: ApiAudiencePresetService,
    private socket: SocketService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private loader: LoaderService,
  ) {
  }
  @ViewChild('sp-input-preset') el: any;

  /*
   * Note on "Default" universe: it is automatically set up in API if not selected
   * Here the default universe refers to the default selection, and not the default universe included in every project,
   * we need to clarify that
   */

  async ngOnInit() {
    this.loader.load();
    const canShowPresets = await this.auth.permissionCheck('audience.library.access');
    if (canShowPresets) {
      this.apiAudiencePreset.getAll().then((presets: AudiencePreset[]) => {
        presets.forEach(p => {
          if (p.category_path) p.category_path = p.category_path + ' -- ' + p.name
        });

        this.audiencePresets = presets;
        this.presetsTreeNode = TreeNode.fromPresets(this.audiencePresets);
        this.presetsTreeNode.sort((a, b) => a.value.name.localeCompare(b.value.name)).forEach(child => {
          child.children.sort((a, b) => a.value.name.localeCompare(b.value.name));
        });
      });
    }

    this.apiUniverse.getAll().then((u: Universe[]) => {
      this.universes = u.filter(u => !u.default);
      let universeDefault = this.universes.find(u => u.default_select);
      if (!universeDefault && this.options.universeNameFallback) {
        universeDefault = this.universes.find(u => u.name === this.options.universeNameFallback);
      }
      if (universeDefault) {
        this.selectedUniverse = universeDefault;
        this.onUniverseSelected(universeDefault);
      }
    });

    const permCrawlCustom = await this.auth.permissionCheck('crawl.custom');
    if (permCrawlCustom) {
      this.apiUniverse.getCountryList()
          .then(countryList => this.countryList = countryList);

      this.apiUniverse.getTopicUniverses()
          .then(topics => this.topicsList = topics);

      this.apiUniverse.getPoliticalUniverses()
        .then(politics => this.politicsList = politics);
    }

    this.subscriptions.push(this.creationManager.projectNameUpdateSubject.subscribe(name => {
      this.projectNameControl.setValue(name);
      this.onProjectNameChange(name);
    }));

    this.subscriptions.push(this.creationManager.wrongProjectNameSubject.subscribe(() => {
      this.projectNameControl.markAllAsTouched();
    }));

    if (this.auth.session.authenticated) {
      this.freemium = this.auth.session.user.role.level <= RoleUtils.freemiumLevel;
    }
  }

  ngAfterViewInit(){
    this.loader.loaded();
  }

  @HostListener("window:scroll", []) onWindowScroll() {

      //sp-menu-preset
      //Un set timeout de 1000ms
    setTimeout(() => {
      if (this.presetTrigger && this.presetTrigger.menuOpen) {
        //this.presetTrigger.closeMenu();
      }
    }, 1000);
  }


  separatorKeysCodes: number[] = [ENTER, COMMA];

  onPresetSearch(search: string) {
    // If the menu is closed, we simulate an input click event to open the menu
    if (!this.presetTrigger.menuOpen) {
      this.presetTrigger.openMenu();
      this.applyMatMenuStyle();
      setTimeout(() => {
        //this.presetTrigger.focus();
      }, 100);
    }
    const filtered = this.audiencePresets.filter(a => {
      return a.name.toLowerCase().includes(search.toLowerCase());
    });
    this.presetsTreeNode = TreeNode.fromPresets(filtered);
    this.presetsTreeNode.sort((a, b) => a.value.name.localeCompare(b.value.name)).forEach(child => {
      child.children.sort((a, b) => a.value.name.localeCompare(b.value.name));
    });
  }
  deletePresetSelection() {
    if (this.selectedPreset.has(this.creationManager.audienceType)) this.selectedPreset.delete(this.creationManager.audienceType);
    this.resetAudiencePreset();
  }

  @ViewChild("presetTrigger") trigger: MatMenuTrigger;
  @ViewChild("presetInput") presetInput;

  onPresetSelected(event: PresetSelectEvent) {
    setTimeout(() => {
      this.presetTrigger.closeMenu();

    }, 100);

    this.presetInputTest = '';
    this.onPresetSearch(this.presetInputTest);
    const selectedId = event.selected.id;
    const preset = this.audiencePresets.find(a => a.id == selectedId);

    this.selectedPreset.set(this.creationManager.audienceType, preset);
    let rootCategory = preset.category;
    if (rootCategory) {
      while (!!rootCategory.parent) {
        rootCategory = rootCategory.parent;
      }
    }
    this.socket.sendMessageType('audience-preset-select', {
      category: rootCategory.name,
      name: preset.name
    });

    if (this.creationManager.audienceType == AudienceType.target) {
      this.creationManager.changeProjectName(preset.name);
    }

    const audiencePreset = Audience.fromPreset(preset);
    const audiencePresetSpec = Targeting.fromJson(preset.target_spec, 'sociodemo');

    // Taking only flexible specs for the targeting
    let filteredAudiencePresetSpec = new Targeting();
    filteredAudiencePresetSpec.flex = audiencePresetSpec.flex;
    filteredAudiencePresetSpec.exclusions = audiencePresetSpec.exclusions;
    filteredAudiencePresetSpec = filteredAudiencePresetSpec.mergeWith(this.creationManager.currentAudience.targeting.sociodemoCopy());
    audiencePreset.target_spec = filteredAudiencePresetSpec.toFbJsonString();
    const selectedUniverses = [...this.creationManager.currentAudience.universes]; // Array copy of universes

    //this.resetAudiencePreset(false);

    // Setting target
    this.creationManager.pushAudienceType(audiencePreset, this.creationManager.audienceType);
    //const audienceTarget = this.creationManager.audienceTarget;
    this.creationManager.loadAudienceInfoFromTargeting(filteredAudiencePresetSpec, this.creationManager.audienceType);

    // Setting benchmark if linked
    /*if (this.creationManager.targetBenchmarkLink) {
      const audienceBenchmark = new Audience();
      const benchmarkSpec = this.creationManager.audienceTarget.targeting.sociodemoCopy();
      //if (this.audienceTarget.type == 'sociodemo') benchmarkSpec.exclusions =  this.audienceTarget.targeting.flex.reduce((prev, curr) => prev.mergeWith(curr));

      audienceBenchmark.name = audienceTarget.name + " - Benchmark";
      audienceBenchmark.type = "sociodemo";
      audienceBenchmark.locale = "en_US";
      audienceBenchmark.target_spec = benchmarkSpec.toFbJsonString();

      this.creationManager.pushAudienceType(audienceBenchmark, AudienceType.benchmark);
    }*/
    this.creationManager.setProjectUniverses(selectedUniverses);
    this.creationManager.updateAudience(this.creationManager.audienceType).then();
  }
  resetAudiencePreset(update: boolean = true){
    this.projectNameControl.setValue('');

    this.creationManager.resetAudienceFields(this.creationManager.audienceType, false, true, true);
    //this.creationManager.resetAudienceFields(AudienceType.benchmark, false, true, true);

    if (update) {
      this.creationManager.updateAudience(this.creationManager.audienceType).then();
      //this.creationManager.updateAudience(AudienceType.benchmark).then();
    }
  }

  applyMatMenuStyle(){

   document.getElementById("sp-top").classList.add("sp-top-fixed");

    let widthButton = document.getElementById('sp-test').offsetWidth;
    //let widthPreset = document.getElementById('tag-preset').clientWidth;


    const elems = document.getElementsByClassName('mat-menu-panel') as HTMLCollectionOf<HTMLElement>;

    for(let i = 0; i < elems.length; i++) {
      elems[i].style.minWidth = '0px !important';
      elems[i].style.maxWidth = '1000px !important';

      elems[i].style.width = widthButton+"px";
    }

    const matPanel = document.getElementById('mat-menu-panel-4');
    if (matPanel) {
      /*
      matPanel.style.width = widthButton +'px !important';

      //widthPreset = widthPreset + 60;
      //matPanel.style.marginLeft = '-14px';
      //matPanel.style.marginLeft = '-'+ widthPreset + 'px';
      matPanel.style.marginTop = '16px';

       */
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onProjectNameChange(name: string) {
    if (name != "") {
      // Trigger Event is false to avoid looping through event subscription
      this.creationManager.changeProjectName(name, false);
      if (this.projectTitleLink) {
        this.audienceTarget.name = name;
        this.audienceBenchmark.name = name + " - Benchmark";
      }
    }

    const event: ProjectChangeEvent = {
      name: name,
      valid: this.projectNameControl.valid,
    };

    this.projectNameChange.emit(event);
  }

  resetSearchInput(){
    this.searchText = '';
  }

  /**
   * Event for when an universe is selected
   * @param universe
   */
  onUniverseSelected(universe : Universe) {
    this.selectedUniverses = [];
    this.selectedUniverses.push(universe);
    this.creationManager.setProjectUniverses(this.selectedUniverses);
  }

  onTopicUniverseSelected(universe: Universe) {
    this.creationManager.topicUniverseSelected = universe;
    //if (this.creationManager.cicCountriesSelected) this.creationManager.cicCountriesSelected = undefined;
  }

  onPoliticalUniverseSelected(universe: Universe) {
    this.creationManager.politicalUniverseSelected = universe;
    //if (this.creationManager.cicCountriesSelected) this.creationManager.cicCountriesSelected = undefined;
  }

  onCicCountriesSelected(countries: CountryJson[]) {
    this.creationManager.cicCountriesSelected = countries;
    //if (this.creationManager.topicUniverseSelected) this.creationManager.topicUniverseSelected = undefined;
  }


  get currentAudience(): Audience { return this.creationManager.currentAudience; }
  get audienceTarget(): Audience { return this.creationManager.audienceTarget; }
  get audienceBenchmark(): Audience { return this.creationManager.audienceBenchmark; }
  get projectTitleLink(): boolean { return this.creationManager.projectTitleLink; }
  get flag(): string|undefined { return this.creationManager.flag; }
  get currentSelectedPreset(): AudiencePreset|undefined { return this.selectedPreset.get(this.creationManager.audienceType); }

  get topicUniverseSelected(): Universe|undefined { return this.creationManager.topicUniverseSelected; }
  get politicalUniverseSelected(): Universe|undefined { return this.creationManager.politicalUniverseSelected; }
  get cicCountriesSelected(): CountryJson[]|undefined { return this.creationManager.cicCountriesSelected; }

  protected readonly top = top;
  protected readonly environment = environment;
}
