<div *ngIf="!freemium">
  <div class="row no-gutters pt-3 pb-5">
      <div class="w-100 card">
        <div class="card-header">
          <h5 class="pl-3">Project's setup</h5>
        </div>
        <div class="card-body">
          <div class="sp-project-setup">
            <div [ngClass]="{'sp-project-setup-analysis': options.showPresetSelection}" class=" sp-project-setup-analysis">
              <mat-form-field appearance="outline" class="w-100  sp-form-field">
                <mat-label class="sp-input-label">
                  <span>Project Name</span>
                </mat-label>
                <input #projectNameInput (change)="onProjectNameChange(projectNameInput.value)" [formControl]="projectNameControl" class="sp-input-content"  matInput maxlength="25" placeholder="Type project’s name..."/>
                <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="To create you project, please define the name of it." matTooltipHideDelay="10" style="transform: translateY(-3px)">
                    <span class="">i</span>
                 </span>
              </mat-form-field>
              <span class="float-right">{{projectNameInput.value.length}} / 25</span>
            </div>
            <div *ngIf="options.showPresetSelection" [ngClass]="{'sp-preset-input-selected' : currentSelectedPreset}"   class="sp-project-setup-preset" >
              <mat-form-field (click)="applyMatMenuStyle()" appearance="outline" class="w-100 sp-form-field sp-form-field-preset" id="sp-test">
                <mat-label class="sp-input-label">
                  <span>Audiences Library</span>
                </mat-label>
                <mat-chip-grid #chipListLocalization aria-label="Add a preset" class="sp-chips" style="width: 94% !important;">
                  <mat-chip-row *ngIf="currentSelectedPreset" id="tag-preset">
                    {{currentSelectedPreset?.name}}
                    <button matChipRemove >
                      <mat-icon  (click)="deletePresetSelection()"  class="sp-icon-chips-delete"
                                svgIcon="icon_sp_x">cancel</mat-icon>
                    </button>

                  </mat-chip-row>
                  <!--(keyup)="onPresetSearch(presetInput.value)"-->
                  <input
                    #presetInput
                    #presetTrigger="matMenuTrigger"
                    [matMenuTriggerFor]="menu.childMenu"
                    (click)="applyMatMenuStyle()"
                    (input)="onPresetSearch(presetInput.value)"
                    [(ngModel)]="presetInputTest"

                    [matChipInputFor]="chipListLocalization"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"

                    [ngClass]="{'sp-input-content' : !currentSelectedPreset}"

                    placeholder="{{currentSelectedPreset ? '' : 'Search an Audience Preset...' }}">

                  <div (click)="presetInput.click()" class="mat-select-arrow-wrapper ng-tns-c88-32" >
                    <div class="mat-select-arrow ng-tns-c88-32"></div>
                  </div>
                </mat-chip-grid>
                <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="Select an Audience preset" matTooltipHideDelay="10" style="transform: translateY(-3px)">
                    <span class="">i</span>
                 </span>
              </mat-form-field>

<!--
              <mat-form-field  appearance="outline" class="w-100  sp-form-field" id="sp-test">
                <mat-label class="sp-input-label">
                  <span>Audience Library</span>
                </mat-label>
                <mat-icon class="sp-input-icon "  matPrefix style="transform: translateY(-10px)" svgIcon="icon_sp_preset"></mat-icon>
                <div>
                  <mat-chip-list aria-label="Add a preset" class="sp-chips">
                    <div style="display: inline-flex;width: 100%;padding-right: 0.2rem">
                      <mat-chip *ngIf="selectedPreset">
                        {{selectedPreset?.name}}
                        <mat-icon  class="sp-icon-chips-delete"
                                   matChipRemove svgIcon="icon_sp_x">cancel</mat-icon>
                      </mat-chip>
                      <input #presetInput #presetTrigger="matMenuTrigger" (click)="applyMatMenuStyle()" (input)="onPresetSearch(presetInput.value)" [matMenuTriggerFor]="menu.childMenu" class="sp-input-content" id="sp-input-preset" matInput maxlength="25" placeholder="Search an audience..."/>


                      <div (click)="presetInput.click()" class="mat-select-arrow-wrapper ng-tns-c88-32" >
                        <div class="mat-select-arrow ng-tns-c88-32"></div>
                      </div>
                    </div>

                  </mat-chip-list>
                </div>


                <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="Select an audience" matTooltipHideDelay="10" style="transform: translateY(-3px)">
                    <span class="">i</span>
                 </span>
              </mat-form-field>
-->

              <app-sp-project-create-audience-preset-menu (onSelect)="onPresetSelected($event)" [items]="presetsTreeNode" #menu></app-sp-project-create-audience-preset-menu>


            </div>


<!--
            <div class="col-4 pt-4 pb-4 pl-3 pr-4 sp-project-setup-analysis">
              <mat-form-field (click)="resetSearchInput()" appearance="outline" class="w-100 sp-form-field">
                <mat-label class="sp-input-label">Project universe</mat-label>
                <mat-icon class="sp-input-icon " matPrefix svgIcon="icon_sp_world"></mat-icon>
                <mat-select (selectionChange)="onUniverseSelected($event.value)" [(ngModel)]="selectedUniverse" [formControl]="universeControl" [placeholder]="selectedUniverses.length == 0 ? 'Select at least one universe' : 'Not found'" class="sp-input-content" panelClass="sp-select-option-icon">
                  <input [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" aria-label="Number" class=" sp-input-search-content" matInput placeholder="Pick one" type="text">
                  <mat-option *ngFor="let universe of universeOrder() |  FilterPipe : searchText" [value]="universe">{{ universe.name }}</mat-option>
                </mat-select>
                <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="By selecting a geographical universe, you will get a detailed map and additional relevant criteria for that universe (ex: local brands, media,… )." matTooltipHideDelay="10">
                <span class="">i</span>
              </span>
              </mat-form-field>
            </div>
-->
          </div>
        </div>
        <div *ngIf="flag" class="card-body">
          <hr/>
          <h5 class="pl-3 pb-3">Custom CIC/TIC params</h5>
          <div class="row pl-3 pr-3" style="column-gap: 1rem; display: flex">
            <mat-form-field  appearance="outline" class="sp-form-field" style="width: 32%">
              <mat-label class="sp-input-label">Countries to launch</mat-label>
              <mat-icon class="sp-select-icon sp-dialog-icon" matPrefix svgIcon="icon_sp_world"></mat-icon>
              <mat-select (selectionChange)="onCicCountriesSelected($event.value)" [value]="cicCountriesSelected" placeholder="Select the countries" class="sp-input-content" multiple no-space>
                <mat-option *ngFor="let country of countryList" [value]="country">{{country.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field  appearance="outline" class="sp-form-field" style="width: 32%">
              <mat-label class="sp-input-label">Topics universe to launch</mat-label>
              <mat-icon class="sp-select-icon sp-dialog-icon" matPrefix svgIcon="icon_sp_world"></mat-icon>
              <mat-select (selectionChange)="onTopicUniverseSelected($event.value)" [value]="topicUniverseSelected" placeholder="Select the topic universe" class="sp-input-content" no-space>
                <mat-option>No selection</mat-option>
                <mat-option *ngFor="let topics of topicsList" [value]="topics">{{ topics.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field  appearance="outline" class="sp-form-field" style="width: 32%">
              <mat-label class="sp-input-label">Political universe to launch</mat-label>
              <mat-icon class="sp-select-icon sp-dialog-icon" matPrefix svgIcon="icon_sp_world"></mat-icon>
              <mat-select (selectionChange)="onPoliticalUniverseSelected($event.value)" [value]="politicalUniverseSelected" placeholder="Select the political universe" class="sp-input-content" no-space>
                <mat-option>No selection</mat-option>
                <mat-option *ngFor="let politics of politicsList" [value]="politics">{{ politics.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

  </div>
</div>
