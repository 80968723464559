import { Injectable } from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {BaseStatFilter} from '../../classes/filter/base-stat-filter';
import {PersonaFilter} from '../../classes/filter/persona-filter';
import {LoggerService} from '../sp-logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private _baseStatFilterListener: Subject<BaseStatFilter> = new Subject<BaseStatFilter>();
  private _personaFilterListener: Subject<PersonaFilter> = new Subject<PersonaFilter>();

  private _lastAppliedBaseStatFilter: BaseStatFilter;
  private _lastAppliedPersonaFilter: PersonaFilter;

  private criteriaFilterScorableThreshold: number = 200;
  private tagFilterScorableThreshold: number = 200;

  private targetSizeMin: number = 0;
  private targetSizeMax: number = 60000000;

  private _segments: string[] = [];

  readonly onThresholdChange: Subject<{flag: string, value: number}> = new Subject();

  constructor(private logger: LoggerService) { }

  get lastAppliedBaseStatFilter() { return this._lastAppliedBaseStatFilter; }

  baseStatFilterSubscription(obs: (value: BaseStatFilter) => void): Subscription {
    return this._baseStatFilterListener.subscribe(obs);
  }

  personaFilterRequest(filter: PersonaFilter) {
    this._lastAppliedPersonaFilter = filter;
    this._personaFilterListener.next(filter);
  }

  personaFilterSubscription(obs: (value: PersonaFilter) => void): Subscription {
    return this._personaFilterListener.subscribe(obs);
  }

  setThreshold(flag: string, value: number) {
    switch (flag) {
      case "criterion":
        this.criteriaFilterScorableThreshold = value;
        this.onThresholdChange.next({flag, value});
        break;
      case "tags":
        this.tagFilterScorableThreshold = value;
        this.onThresholdChange.next({flag, value});
        break;
      case "segment-min":
        this.targetSizeMin = value;
        this.onThresholdChange.next({flag, value});
        break;
      case "segment-max":
        this.targetSizeMax = value;
        this.onThresholdChange.next({flag, value});
        break;
      default:
        this.logger.logWarning("Threshold for flag " + flag + " is to be defined");
    }
  }

  get lastAppliedPersonaFilter() { return this._lastAppliedPersonaFilter; }

  baseStatFilterRequest(filter?: BaseStatFilter) {
    this._lastAppliedBaseStatFilter = filter;
    this._baseStatFilterListener.next(filter);
  }

  set segments(segments: string[]) { this._segments = segments; }
  get segments() { return this._segments; }
}
