import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {AuthenticationService} from "../../services/sp-authentication/authentication.service";
import {LoggerService} from '../../services/sp-logger/logger.service';
import {RoleUtils} from "../../utils/role/role-utils";

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(private auth: AuthenticationService, private router: Router, private logger: LoggerService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>(resolve => {
      if(!next.data.permission) {
        this.logger.logWarning("Data permission not defined for permission guard");
        resolve(false);
      } else {
        let bypass = false;
        if (next.data.freemiumBypass) {
          bypass = this.auth.session.user.role.level <= RoleUtils.freemiumLevel;
        }
        this.auth.permissionCheck(next.data.permission)
          .then(granted => (granted || bypass) ? resolve(true) : resolve(this.router.parseUrl('/page-access-denied')))
      }
    });
  }

}
