import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from "./guards/auth/auth.guard";
import {SpUserPasswordComponent} from './modules/sp-user/sp-user-password/sp-user-password.component';
import {SpMetaTargetingComponent} from './modules/sp-meta-targeting/sp-meta-targeting.component';

const routes: Routes = [
  {
    path: '',
    //canActivate: [AuthGuard],
    redirectTo: '/projects/list',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/sp-account/sp-account.module').then(m => m.SpAccountModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/sp-admin/sp-admin.module').then(m => m.SpAdminModule)
  },
  {
    path: 'targeting',
    canActivate: [AuthGuard],
    component: SpMetaTargetingComponent
  },
  {
    path: '',
    loadChildren: () => import('./modules/sp-page/sp-page.module').then(m => m.SpPageModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/sp-project/sp-project.module').then(m => m.SpProjectModule)
  },
  {
    path: '',
    loadChildren: () => import('./modules/sp-user/sp-user.module').then(m => m.SpUserModule)
  },
  {
    path: '',
    loadChildren: () => import('./modules/sp-dashboard/sp-dashboard.module').then(m => m.SpDashboardModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/sp-logo/sp-asset-manager/sp-asset-manager.module').then(m => m.SpAssetManagerModule)
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

