<div class="w-100 pl-5 pr-5 pt-3 pb-3">
  <div class="row d-flex no-gutters justify-content-center text-center">
    <div class="col-12 pb-4 align-self-center">
      <h4 class="sp-roboto-condensed-regular sp-text-secondary">Here you can manage your Facebook Ad accounts. You need to add at least one Facebook Ad account to start to analyze your audience</h4>
    </div>
  </div>
  <div class="row d-flex no-gutters justify-content-center text-center">
    <div class="col-12 align-self-center">
      <mat-form-field appearance="outline" class="w-100 sp-form-field">
        <mat-label class="sp-input-label">Search</mat-label>
        <mat-icon class="sp-input-search-icon"  matPrefix svgIcon="icon_sp_search"></mat-icon>
        <input [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" class=" sp-input-search-content"matInput placeholder="Type here to search an account">
        <button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="searchText=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
  <div class="sp-card-body-ad-account">
    <div class="row d-flex no-gutters" >
      <div class="col-6 pt-2 pb-2" *ngFor="let adAccount of adAccounts |  FilterPipe : searchText" >
        <mat-checkbox (change)="onAdSelectionChange()" [(ngModel)]="adAccount.selected" [ngModelOptions]="{standalone: true}" class="sp-form-field">
                    <span class="sp-checkbox">
                      {{ adAccount.name }}
                    </span>
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="row pt-3 d-flex no-gutters justify-content-center text-center">
    <div class="col-12 align-self-center">
      <button (click)="save()" [disabled]="!dataChanged" class="btn btn-primary w-100 text-uppercase sp-btn sp-btn-xl sp-btn-primary" matTooltip="Click here to add an ad account." matTooltipHideDelay="10" type="button">
        <span>Update Ad Account(s)</span>
      </button>
    </div>
  </div>
</div>
