import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { SpThemingService } from '../../../../services/sp-theming/sp-theming.service';
import {Company} from "../../../../models/company";
import {ApiCompanyService} from "../../../../services/sp-api/sp-api-company/api-company.service";
import {AuthenticationService} from "../../../../services/sp-authentication/authentication.service";
import {MatSort, SortDirection} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {SpModalCompanyRemoveComponent} from './sp-modal-company-remove/sp-modal-company-remove.component';
import {environment} from '../../../../../environments/environment';
import {Router} from "@angular/router";
import {LoaderService} from '../../../../services/sp-loading/loader.service';
import {DataPagination} from '../../../../services/sp-api/sp-api-model/api-model.service';
import {debounceTime, Subject} from 'rxjs';

@Component({
  selector: 'app-sp-admin-company-list',
  templateUrl: './sp-admin-company-list.component.html',
  styleUrls: ['./sp-admin-company-list.component.scss']
})
export class SpAdminCompanyListComponent implements OnInit {
  protected readonly companyPerPage = 10;

  displayedColumns: string[] = ['image', 'name', 'userCount', 'payment_frequency', 'planName', 'credits', 'subscription_contract_start_at','subscription_contract_end_at','credits_left','actions'];
  dataSource = new MatTableDataSource<Company>([]);
  env = environment;

  pageSize: number = 0;
  dataLength: number = 0;

  searchFilter: string = '';

  sortBy?: string = 'name';
  sortDir: SortDirection = 'asc';

  companySearchFilterSubject: Subject<string> = new Subject<string>();

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  // Constructor.

  constructor(
    private _spTheming: SpThemingService,
    private apiCompany: ApiCompanyService,
    private dialog: MatDialog,
    private router: Router,
    private loader: LoaderService
  ) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-desk');
  }
  // On init.

  async ngOnInit() {
    this.loader.load();

    // Filter and paginator for company
    this.dataSource.sort = this.sort;
    this.sort.sortChange.subscribe(async sort => {
      if (sort.direction == '') {
        this.sortBy = undefined;
        this.sortDir = '';
      } else {
        this.sortBy = sort.active;
        this.sortDir = sort.direction;
      }
      await this.loadCompanies(this.paginator.pageIndex + 1);
    });

    this.sort.active = this.sortBy;
    this.sort.direction = this.sortDir;

    this.companySearchFilterSubject.pipe(debounceTime(200)).subscribe(async () => {
      await this.loadFirstPageCompany();
    });

    await this.loadCompanies();
    this.loader.loaded();
  }
  async loadCompanies(page?: number) {
    const params: any = {
      paginate: true,
      page: page ?? 1,
      per_page: this.companyPerPage,
      search: this.searchFilter
    };

    if (this.sortBy) {
      params.sortBy = this.sortBy;
      params.sortDir = this.sortDir;
    }

    const paginatedData = <DataPagination<Company>>await this.apiCompany.getAll(params);

    this.pageSize = paginatedData.meta.per_page;
    this.dataLength = paginatedData.meta.total;
    this.dataSource.data = paginatedData.data;
  }

  async applySearchFilter() {
    this.searchFilter = this.searchFilter.trim();
    this.companySearchFilterSubject.next(this.searchFilter);
  }

  async clearCompanyFilters() {
    this.searchFilter = '';
    await this.loadFirstPageCompany();
  }

  private async loadFirstPageCompany() {
    this.paginator.pageIndex = 0;
    await this.loadCompanies(1);
  }

 openDeleteDialog(company: Company): void {
     const dialogRef = this.dialog.open(SpModalCompanyRemoveComponent,{autoFocus: false,
       maxHeight:'30rem',maxWidth:'40rem', data: {
         company
       }
     });

     dialogRef.afterClosed().subscribe(company => {
       if(company !== null) {
        let companies: Company[] = this.dataSource.data;
        companies = companies.filter(c => c.id !== company.id);
         this.dataSource.data = companies;
       }
     });
   }

  companyHasSubscription(company: Company): boolean {
    return company.subscription !== null;
  }

  companyHasPlan(company: Company): boolean {
    return company.subscription !== null && company.subscription.plan !== null;
  }
  // TODO: This should be a routerLink attr in html

  navigateToCompany(company: Company) {
    this.router.navigateByUrl('admin/companies/update/' + company.id);
  }

  async onPageChange($event: PageEvent) {
    await this.loadCompanies($event.pageIndex + 1);
  }
}
