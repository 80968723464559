export class PathEntry {
  id: number;
  path: string[];
  name: string;
  description?: string;
  highlight: boolean;

  constructor(id: number, path: string[], name: string, description?: string) {
    this.id = id;
    this.path = path;
    this.name = name;
    this.description = description;
    this.highlight = false;
  }
}
