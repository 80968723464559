import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SpProjectListComponent } from './sp-project-list/sp-project-list.component';

const spProjectRoutes: Routes = [
  {
    path: 'projects',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      },
      {
        path: 'create',
        loadChildren: () => import('./sp-project-create/sp-project-create.module').then(m => m.SpProjectCreateModule)
      },
      {
        path: 'details',
        redirectTo: 'list',
        pathMatch: 'full'
      },
      {
        path: 'details/:project_id',
        loadChildren: () => import('./sp-project-details/sp-project-details.module').then(m => m.SpProjectDetailsModule)
      },
      {
        path: 'list',
        component: SpProjectListComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(spProjectRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SpProjectRoutingModule {}
