import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

interface ImageChangeEvent {
  image: string,
  b64: boolean
}

@Injectable({
  providedIn: 'root'
})
export class SpCustomThemeService {
  selectedImage;

  private imageChangeSubject: Subject<ImageChangeEvent> = new Subject<ImageChangeEvent>();

  constructor() { }

  onLogoChange(sub: (image?: ImageChangeEvent) => void) {
    return this.imageChangeSubject.subscribe(sub);
  }

  logoChange(imageInput: HTMLInputElement) {
    let file: File = imageInput.files[0];
    let fileDimensionTooBig = false;
    const reader = new FileReader();
    //Read the contents of Image File.
    reader.readAsDataURL(imageInput.files[0]);
    reader.addEventListener('load', (event: any) => {
      //Initiate the JavaScript Image object.
      const image = new Image();
      //Set the Base64 string return from FileReader as source.
      if (typeof event.target.result === 'string') {
        image.src = event.target.result;
      }
      //Validate the File Height and Width.
      image.onload = () => {
        const height = image.height;
        const width = image.width;
        /*
        if (height > 512 || width > 512) {
          alert("Height and Width must not exceed 512px.");
          return false;
        } else if(file.size > 5242880) {
          alert("File size is too big");
          file = null;
        } else  {
          this.selectedImage = event.target.result;
          return true;
        }

         */
        this.selectedImage = event.target.result;

        this.imageChangeSubject.next({image: this.selectedImage, b64: true});
      };
    });
  }

  reset() {
    this.imageChangeSubject.next(undefined);
  }
}
