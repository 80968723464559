import { Injectable } from '@angular/core';
import {ApiModelService} from '../sp-api-model/api-model.service';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from '../../sp-logger/logger.service';
import {AudiencePreset} from '../../../models/audience-preset';

@Injectable({
  providedIn: 'root'
})
export class ApiAudiencePresetService extends ApiModelService<AudiencePreset> {
  protected readonly basePath = 'audience_presets';

  constructor(httpClient: HttpClient, logger: LoggerService) {
    super(httpClient, logger);
  }
}
