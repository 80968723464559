<div class="sp-dialog">
  <div class="sp-dialog-header">
      <h5> Add an existing user </h5>
  </div>
    <div class="sp-dialog-body">
      <mat-form-field class="w-100 sp-form-field" appearance="outline">
        <mat-label class="sp-input-label">Choose the user</mat-label>
        <mat-select (click)="resetFilter()" [(ngModel)]="selectedUser" class="sp-input-content" disableOptionCentering panelClass="sp-select-option-icon" placeholder="Select a user">
          <input [(ngModel)]="searchUniverse" [ngModelOptions]="{standalone: true}" aria-label="Number" class="pl-3 sp-input-search-user" matInput placeholder="Search user" type="text">
          <mat-option *ngFor="let user of users |  FilterPipeUser : searchUniverse" [value]="user" class="sp-select-option-user">
            <div class="row  d-flex no-gutters">
              <div class="col-3 text-center align-self-center ">
                <img class="sp-avatar" *ngIf="user && user.image !== null" [src]="env.config.api.storageUrl + user.image">
                <span *ngIf="user && user.image == null" class="avatar-rounded">
                  <span class="avatar-company-name">{{user.first_name.substr(0,1)}}{{user.last_name.substr(0,1)}} </span>
                </span>
              </div>
              <div class="col-9 align-self-center">
                <span>{{user.first_name}} </span> <br/>
                <span> {{user.last_name}}</span>
              </div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-100 sp-form-field" appearance="outline">
        <mat-label class="sp-input-label">User Role</mat-label>
        <mat-select disableOptionCentering panelClass="sp-select-option-icon" class="sp-input-content" placeholder="Select a role" [(ngModel)]="selectedRole">
          <mat-option [disabled]="role.level > auth.session.user.role.level" *ngFor="let role of roles" [value]="role">
            {{role.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="pb-3 sp-dialog-footer">
        <button mat-button class="btn btn-secondary m-1 text-uppercase sp-btn sp-btn-secondary" (click)="onNoClick()">Cancel</button>
        <button mat-button class="btn btn-primary m-1 text-uppercase sp-btn sp-btn-primary" (click)="addUser()">Create</button>
      </div>
    </div>
</div>
