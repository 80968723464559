import { OnDestroy, OnInit, Directive } from "@angular/core";
import {SpThemingService} from "../../services/sp-theming/sp-theming.service";
import {Subscription} from "rxjs";
import {AuthenticationService} from "../../services/sp-authentication/authentication.service";
import {RoleUtils} from "../../utils/role/role-utils";

@Directive()
export abstract class DashboardComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];
  private _freemium: boolean = false;
  private _trial: boolean = false;

  protected constructor(private _spTheming: SpThemingService, protected auth: AuthenticationService) {
    this._spTheming.setSpThemeName('dashboard-desk');
  }

  ngOnInit(): void {
    this.beforeInit();

    if (this.auth.session.authenticated) {
      this._freemium = this.auth.session.user.role.level <= RoleUtils.freemiumLevel;
      this._trial = this.auth.session.user.role.level === RoleUtils.trialLevel;
    }
    const sessionSub = this.auth.sessionListener.subscribe(session => {
      if (session) {
        this._freemium = session.user.role.level <= RoleUtils.freemiumLevel;
      }
    });
    this.addSubscription(sessionSub);

    this.afterInit();
  }

  ngOnDestroy() {
    this.beforeDestroy();

    this._subscriptions.forEach(s => s.unsubscribe());

    this.afterDestroy();
  }

  // Methods meant to be overwritten as event
  protected beforeInit() {}
  protected afterInit() {}
  protected beforeDestroy() {}
  protected afterDestroy() {}

  protected addSubscription(...subs: Subscription[]) {
    this._subscriptions = this._subscriptions.concat(subs)
  }

  //set freemium(value: boolean) { this._freemium = value; }
  get freemium(): boolean { return this._freemium; }

  //set trial(value: boolean) { this._trial = value; }
  get trial(): boolean { return this._trial; }
}
