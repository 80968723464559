import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {TreeNode} from '../../../../../classes/data/tree-node';
import {FormControl} from '@angular/forms';
import {AudiencePreset} from '../../../../../models/audience-preset';
import {MAT_SELECT_SCROLL_STRATEGY} from '@angular/material/select';
import {MAT_MENU_SCROLL_STRATEGY} from '@angular/material/menu';
import { Overlay, BlockScrollStrategy } from '@angular/cdk/overlay';
export interface PresetSelectEvent {
  selected: {id?: number, name: string};
}
interface Pokemon {
  value: string;
  viewValue: string;
}

interface PokemonGroup {
  disabled?: boolean;
  name: string;
  pokemon: Pokemon[];
}
export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

@Component({
  selector: 'app-sp-project-create-audience-preset-menu',
  templateUrl: './sp-project-create-audience-preset-menu.component.html',
  styleUrls: ['./sp-project-create-audience-preset-menu.component.scss'],

})
export class SpProjectCreateAudiencePresetMenuComponent implements OnInit, AfterViewInit {
  @Output() onSelect: EventEmitter<PresetSelectEvent> = new EventEmitter<PresetSelectEvent>();
  @Output() onPresetSearch: EventEmitter<string> = new EventEmitter();
  @Input() items: TreeNode<{id?: number, name: string, description?: string}>[];

  @ViewChild('childMenu') public childMenu;
  @ViewChild('animals') public animals;

  constructor(public router: Router) {
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.items.sort((a,b) =>a.value.name.localeCompare(b.value.name)).forEach(child => {
      child.children.sort((a,b) =>a.value.name.localeCompare(b.value.name));

    })
  }


  @HostListener("window:scroll", []) onWindowScroll() {

    //sp-menu-preset
    //Un set timeout de 1000ms
    setTimeout(() => {
      if (this.childMenu && this.childMenu.menuOpen) {
        this.childMenu.closeMenu();
      }
    }, 1000);
  }


  onSelected(item: {id?: number, name: string}) {
    this.onSelect.next({selected: item});
    //this.childMenu.close();
  }
  onSelectedNone() {
    this.onSelect.next({selected: null});
  }

  onPresetSearched(search: string) {
    this.onPresetSearch.next(search);
  }

  preventCloseOnScroll(event: WheelEvent): void {
    event.preventDefault();
  }

  testMouse(){
  }
}
