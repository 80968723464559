import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiModelService, DataPagination} from "../sp-api-model/api-model.service";
import {Role} from "../../../models/role";
import {LoggerService} from '../../sp-logger/logger.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiRoleService extends ApiModelService<Role> {
  protected readonly basePath = "roles";

  constructor(httpClient: HttpClient, logger: LoggerService) {
    super(httpClient, logger);
  }

  /* Other methods with relations go here */
  // Overriding get all method to filter freemium features
  getAll(): Promise<Role[]|DataPagination<Role>> {
    if (environment.config.enableExperimentalFeatures) {
      return super.getAll();
    } else {
      return new Promise((resolve, reject) => {
        super.getAll()
          .then((res: Role[]) => resolve(res.filter(role => role.key != 'user_free')))
          .catch(err => reject(err));
      });
    }
  }

  /* ------------------------------------ */
}
