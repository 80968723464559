<div class="container-fluid sp-h-100">
  <div class="row no-gutters sp-h-100">
    <div class="sp-div-ad-register">
      <div #alert *ngIf="navigation.adBlockDetected" class="alert alert-danger alert-dismissible sp-ad-alert-danger" role="alert">
        Our solution may not work properly when an ad blocker is enabled in your web browser.
        Please turn off the ad blocker or add soprism.com as an exception so that you do not have issues using our dashboard.
        You will need to refresh the page after making these changes.
        <button (click)="alert.remove()" aria-label="Close" class="close" data-dismiss="alert" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="col-md-12 col-lg-5 d-none d-lg-block">
      <div class="sp-wrapper-left">
        <div class="sp-brand">
          <a matTooltip="Back to home" matTooltipHideDelay="10" routerLink="/user/login">
            <h1 class="text-hide">
              <span class="sp-brand-name">SoPrism</span>
              <span class="sp-brand-slogan">Social audience for marketing intelligence</span>
              <img class="sp-brand-logo" src="assets/images/logos/sodiense/logo-sodiense.svg" />
            </h1>
          </a>
        </div>
        <div class="sp-menu">
          <a class="h5 text-decoration-none " matTooltip="Click here to sign in" matTooltipHideDelay="10" routerLink="/user/login" routerLinkActive="active-link">
            <span>Sign In</span>
          </a><br />
          <a class="h5 text-decoration-none" matTooltip="Click here to sign up" matTooltipHideDelay="10" routerLink="/user/register" routerLinkActive="active-link">
            <span>Sign Up</span>
          </a>
        </div>
        <div class="sp-message-welcome">
          <p *ngIf="router?.url.includes('/user/login') || router?.url.includes('/user/password')" class="h1 text-black-50 sp-text">Know your audience</p>
          <p *ngIf="router?.url.includes('/user/register')" class="h1 text-black-50 sp-text">The leading digital consumer insights tool</p>
          <p *ngIf="router?.url.includes('/user/login') || router?.url.includes('/user/password')" class="sp-sub-text text-black-50">Get instant access to the richest global consumer Insights shaped every second by billions of social users.</p>
          <p *ngIf="router?.url.includes('/user/register')" class="sp-sub-text text-black-50">
            <span> > Access audience insights to inform your marketing strategy</span> <br>
            <span> > Get a 360-degree understanding of your target group</span> <br>
            <span> > Learn about your competitor's audiences</span>
          </p>
        </div>

        <div *ngIf="!router?.url.includes('user/login?state=registered')" class="sp-img-gif text-center">
          <img class="sp-img-gif-signUp" src="assets/images/gif/sign-up.gif" />
        </div>

        <div *ngIf="router?.url.includes('user/login?state=registered')" class="sp-img-register text-center">
          <img class="sp-img" src="assets/images/layout/register-success.png"  />
        </div>

        <!--
        <div *ngIf="router?.url.includes('/user/register') && showFreemiumVideo" class="sp-freemium-video">
          <div class="card">
            <mat-icon (click)="closeFreemium()" class="sp-freemium-video-close" style="transform: translateY(10px)">close</mat-icon>

            <div class="">
              <h5 class="mb-2 mt-1 text-center">Watch a preview of the platform here:</h5>
              <div class="text-center">
                <iframe allow="autoplay; fullscreen" allowfullscreen class="sp-freemium-video-start" data-ready="true" frameborder="0" height="230" mozallowfullscreen="" src=" https://player.vimeo.com/video/579742280?byline=0&portrait=0&title=0&autoplay=1&loop=1&control=0&byline=0&portrait=0&background=1" width="230"></iframe>
              </div>
              <div class="text-center">
                <button class="btn btn-sm btn-primary sp-btn sp-btn-sm sp-btn-primary sp-freemium-video-btn" onclick="window.open('https://vimeo.com/579742280','_blank')" type="submit" type="button">
                  <span style="font-size: 1.2rem">Watch it!</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        -->
        <div class="sp-img-bg">
          <img class="sp-img-pattern" src="assets/images/layout/pattern.png" width="400" />
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-7 d-flex">
      <div class="sp-wrapper-right">
        <div  class="sp-brand pt-2 pb-1 mb-5 sp-logo-phone text-center">
          <a matTooltip="Back to home" matTooltipHideDelay="10" routerLink="/user/login">
            <h1 class="text-hide">
              <span class="sp-brand-name">SoPrism</span>
              <span class="sp-brand-slogan">Social audience for marketing intelligence</span>
              <img class="" src="assets/images/logos/soprism/soprism-logo-black.svg" width="200" />
            </h1>
          </a>
        </div>
        <img class="sp-img-pattern-mobile" src="assets/images/layout/pattern.png" width="400" />

        <div class="row d-flex no-gutters justify-content-center sp-right">
          <div class="col-9 align-self-center">
            <div class="sp-content-wrapper">
              <notifier-container></notifier-container>
              <router-outlet *ngIf="true"></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
