import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormControl,
  UntypedFormGroup, ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {Subject} from "rxjs";
import {ActivationStart, Router, RouterEvent} from "@angular/router";
import {catchError, filter, map} from "rxjs/operators";
import {ApiService} from "../sp-api/api.service";
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

/**
 * Validation service
 * Used for portable custom validations
 * This is rather a util tool than a real service
 */
export class ValidationService {
  constructor(private api: ApiService) {}

  private static processFormError(error: any): Map<string, string[]> {
    if(error.status == 422) {
      let map = new Map<string, string[]>();

      let errors = error.error.errors;

      for(let key in errors) {
        map.set(key, errors[key]);
      }
      return map;
    } else {
      return new Map<string, string[]>();
    }
  }

  /**
   * Password matcher validator
   * This is a custom validator responsible for checking password match between two inputs
   * Custom validator: see https://angular.io/guide/form-validation#custom-validators
   *
   * @return ValidatorFn
   * @param controlName
   * @param matchingControlName
   */
  passwordMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: UntypedFormGroup): {[key: string]: any} | null => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.disabled) return null;

      const match = control.value == matchingControl.value;

      if (!match) matchingControl.setErrors({ passwordMatch: true });
      else matchingControl.setErrors(matchingControl.errors);

      // set error on matchingControl if validation fails
      return null;
    }
  }

  /**
   * Laravel side validator
   * This is a custom async validator responsible of laravel validation service call for an input element
   * Custom async validator: see https://angular.io/guide/form-validation#async-validation
   *
   * @param name
   * @param path
   * @param params
   */
  laravelSideValidator(name: string, path: string, params?: Map<string, string>): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return new Promise<ValidationErrors | null>(resolve => {
        let dataJson: any = {};
        dataJson[name] = control.value;

        if(params) {
          params.forEach(((value, key) => {
            dataJson[key] = value;
          }))
        }

        if(environment.config.apiCheck) {
          this.api.post(path, dataJson).subscribe(() => resolve(null), error => {
            let errors = ValidationService.processFormError(error);
            resolve(errors.has(name) ? { 'laravel' : errors.get(name)[0] } : null)
          });
        } else {
          resolve(null)
        }
      });
    }
  }
}
