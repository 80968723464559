import {AdAccount} from '../../models/ad-account';
import {Audience} from "../../models/audience";
import {Injectable} from "@angular/core";
import {Criteria} from "../../models/criteria";
import {environment} from '../../../environments/environment';
import {Targeting} from '../../classes/targeting/targeting';
import {AlertService} from '../sp-alert/alert.service';
import {LoggerService} from '../sp-logger/logger.service';
import {AuthenticationService} from '../sp-authentication/authentication.service';
import {FbCriteria, FbGeolocation, FbLocale} from '../../types/facebook-types';
import {ApiService} from '../sp-api/api.service';
import {RoleUtils} from '../../utils/role/role-utils';
import {firstValueFrom, Subject} from "rxjs";
import {SocketService} from "../sp-ws/socket.service";

export enum AudienceImportType {
  SavedAudience,
  CustomAudience,
  FanpageAudience
}

interface ApiFacebookResponse<T> {
  success: boolean;
  response: T[];
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FacebookLinkService {

  constructor(
    private alert: AlertService,
    private logger: LoggerService,
    private auth: AuthenticationService,
    private spApi: ApiService,
    private socket: SocketService) {}

  public static readonly LOCAL_KEY = 'fb_auth';
  public static readonly LOCAL_CHECK_KEY = 'sp_fb_status';
  public static allScopes = ['email', 'ads_management', 'business_management', 'pages_show_list'];
  public static restrictedScopes = ['email', 'pages_show_list'];

  private accessToken: string;
  private useAccessToken = environment.config.allowCustomTokenUse;

  static requiredScopesIncluded(currentScopes: string[], toInclude: string[]){
    const scopesThatMightBeIncludedButNotReturned = ['pages_show_list'];
    const allCurrentScopes = currentScopes.concat(scopesThatMightBeIncludedButNotReturned);

    for (const include of toInclude) {
      if (!allCurrentScopes.includes(include)) return false;
    }

    return true;
  }

  // Facebook SDK integration
  /**
   * Facebook login using SDK
   * @param businessScopes including permissions impacted by (now old) business manager 2fa
   * @param refresh
   */
  login(businessScopes: boolean = false, refresh: boolean = false): Promise<fb.StatusResponse> {
    const scopes = businessScopes ? FacebookLinkService.allScopes : FacebookLinkService.restrictedScopes;
    return new Promise<fb.StatusResponse>(resolve => {
      FB.login(async resp => {
        if (resp.authResponse && resp.status === 'connected') {
          const grantedScopes = resp.authResponse.grantedScopes || '';
          // If account granted all needed permission implicitly or explicitly, doing a token extend
          if ((!localStorage.getItem(FacebookLinkService.LOCAL_KEY) || refresh) && FacebookLinkService.requiredScopesIncluded(grantedScopes.split(','), scopes)) {
            await this.extendLocalToken(resp);
          } else if (refresh) localStorage.removeItem(FacebookLinkService.LOCAL_KEY);

          if (localStorage.getItem(FacebookLinkService.LOCAL_CHECK_KEY) === 'disconnected') {
            this.socket.sendMessageType('facebook-connect-success', {connected: true});
            localStorage.setItem(FacebookLinkService.LOCAL_CHECK_KEY, 'connected');
          }
        }
        resolve(resp);
      }, {
        scope: scopes.join(','),
        return_scopes: true
      });
    });
  }

  /**
   * Facebook Logout
   */
  logout(): Promise<fb.StatusResponse> {
    return new Promise<fb.StatusResponse>(resolve => {
      // Clear of all Facebook related local data and logout notification
      this.clearFacebookSession();
      // Get any pending session before trying to disconnect (because we use token overriding)
      this.getLoginStatus().then(log => {
        if (log.status === 'connected') {
          FB.logout(resp => {
            resolve(resp);
          });
        }
      });
    });
  }

  unlink(): Promise<any> {
    return this.api('/me/permissions', 'delete', this.generateFbParams());
  }

  /**
   * Get Facebook connection status with SDK
   */
  getLoginStatus(): Promise<fb.StatusResponse> {
    return new Promise<facebook.StatusResponse>(resolve => {
      FB.getLoginStatus(resp => {
        //this.connected = resp.status === 'connected';
        resolve(resp);
      })
    })
  }

  // Custom Facebook integration
  /**
   * Get Facebook login status by calling the session
   */
  isLogged(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.getSession().then(() => resolve(true)).catch(err => {
        resolve(err.code && err.code === 190 ? false : null)
      });
    });
  }

  checkAllScopesAreValid(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.getPermissions().then(result => {
        const grantedScopes: string[] = result.data.filter(d => d.status === 'granted').map(d => d.permission);
        resolve(FacebookLinkService.requiredScopesIncluded(grantedScopes, FacebookLinkService.allScopes));
      }).catch(() => {
        resolve(false);
      });
    });
  }

  /**
   * Clear any Facebook related data in storage
   * @private
   */
  private clearFacebookSession() {
    this.accessToken = undefined;
    //this.connected = false;
    localStorage.removeItem(FacebookLinkService.LOCAL_KEY);
  }

  /**
   * Facebook API call with SDK
   * @param path
   * @param method
   * @param params
   * @private
   */
  private api(path: string, method: "get"|"post"|"delete", params?: any) {
    return new Promise<any>((resolve, reject) => FB.api(path, method, params, (resp: any) => {
      if (resp.error) {
        this.logger.logError("Facebook API error encountered", 1, resp.error);
        reject(resp.error);
      }
      else resolve(resp);
    }));
  }

  /**
   * Get Facebook session and check if the token is still valid
   * @private
   */
  private getSession(): Promise<fb.AuthResponse> {
    const setFacebookLocalCheckKeyToDisconnected = () => {
      const fbStatus = localStorage.getItem(FacebookLinkService.LOCAL_CHECK_KEY);
      if (fbStatus !== 'disconnected') {
        if (fbStatus) this.socket.sendMessageType('facebook-connect-error', {connected: false});
        localStorage.setItem(FacebookLinkService.LOCAL_CHECK_KEY, 'disconnected');
      }
    };

    return new Promise<fb.AuthResponse>((resolve, reject) => {
      const localInfo = localStorage.getItem(FacebookLinkService.LOCAL_KEY);
      const authResp: fb.AuthResponse = localInfo ? JSON.parse(localInfo) : null;
      if (this.useAccessToken && authResp) {
        this.checkTokenExpiration(authResp.accessToken).then(() => {
          this.accessToken = authResp.accessToken;
          resolve(authResp);
        }).catch(err => { // Token is invalid, reconnection needed
          setFacebookLocalCheckKeyToDisconnected();
          this.logger.logError('Could not get session : ' + JSON.stringify(err), 1, err);
          reject(err);
        });
      } else {
        this.getLoginStatus().then(resp => {
          if (resp.status === 'connected') resolve(resp.authResponse);
          else {
            setFacebookLocalCheckKeyToDisconnected();
            reject('Login status : ' + resp.status);
          }
        }).catch(reject);
      }
    });
  }

  /**
   * Check if provided Facebook access token is still valid
   * @param token
   * @private
   */
  private checkTokenExpiration(token: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.api('/me', "get", {redirect: false, access_token: token}).then(() => {
        resolve(true);
      }, error => {
        this.logger.logWarning("Facebook token check has failed with following message : " + error.message);
        reject(error)
      });
    });
  }

  private tokenExchange(token: string) {
    return firstValueFrom(this.spApi.post<{access_token: string, expire_in: number, token_type: string}>('facebook/exchange', {token}));
  }

  /**
   * Replaces the actual facebook short-live token by a long-live one
   * @param log
   * @private
   */
  private async extendLocalToken(log: fb.StatusResponse) {
    if (this.useAccessToken) {
      const exchangeInfo = await this.tokenExchange(log.authResponse.accessToken);
      log.authResponse.accessToken = exchangeInfo.access_token;
      localStorage.setItem(FacebookLinkService.LOCAL_KEY, JSON.stringify(log.authResponse));
      return log.authResponse;
    } else {
      return log.authResponse;
    }
  }

  private getPermissions(): Promise<any> {
    return this.api('/me/permissions', 'get', this.generateFbParams());
  }
  getUserInfo(): Promise<any> {
    return this.api('/me', 'get', this.generateFbParams());
  }
  getUserPicture(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.getSession().then(() => {
        const params: any = {
          redirect: false,
          width: 100,
          height: 100
        }
        this.api('/me/picture', "get", this.generateFbParams(params)).then(resp => {
          resolve(resp.data.url);
        }, error => reject(error))
      });
    })
  }
  async getAdAccounts(): Promise<AdAccount[]> {
      if (this.auth.session.authenticated && this.auth.session.user.role.level <= RoleUtils.freemiumLevel) {
        return [];
      } else {
        const info = await this.getSession();
        const params: any = {
          fields: 'id,account_id,name',
          limit: '50'
        };

        let resp = await this.api('/me/adaccounts', 'get', this.generateFbParams(params));
        let adAccounts: AdAccount[] = resp.data.map(adAccount => {
          return new AdAccount(adAccount.name, info.accessToken, adAccount.id, adAccount.account_id)
        });

        while (resp.paging && resp.paging.next) {
          resp = await this.api(resp.paging.next, 'get');
          adAccounts = adAccounts.concat(resp.data.map(adAccount => {
            return new AdAccount(adAccount.name, info.accessToken, adAccount.id, adAccount.account_id)
          }));
        }

        return adAccounts;
      }
  }

  async getAdAccountInfo(accountId: string): Promise<{id: string, account_id: string, name: string, default_dsa_payor?: string, default_dsa_beneficiary?: string}> {
    await this.getSession();

    const params = {
      fields: 'id,account_id,name,default_dsa_payor,default_dsa_beneficiary'
    };
    return await this.api(accountId, 'get', this.generateFbParams(params));
  }

  /**
   * Create an Ad Campaign with provided Ad Account
   * The "success" value in return is mentioned by FB doc but never returned in real case when calling Graph API
   * @param adAccount
   * @param name
   * @param objective
   */
  async createAdCampaign(adAccount: AdAccount, name: string, objective: string = 'OUTCOME_AWARENESS'): Promise<{id: number, success?: boolean}> {
    await this.getSession();
    return this.api(adAccount.facebook_id + '/campaigns', 'post', this.generateFbParams({
      name, objective, status: 'PAUSED', special_ad_categories: 'NONE'
    }));
  }

  async createAdSet(adAccount: AdAccount, campaignId: number, name: string, targeting: string, dsaPayer?: string, dsaBeneficiary?: string, bidAmount: number = 2, dailyBudget: number = 10000,
                    billingEvent: string = 'IMPRESSIONS', optimizationGoal: string = 'IMPRESSIONS') {
    await this.getSession();

    const params: any = {
      name,
      campaign_id: campaignId,
      bid_amount: bidAmount,
      daily_budget: dailyBudget,
      billing_event: billingEvent,
      optimization_goal: optimizationGoal,
      targeting: targeting,
      status: 'PAUSED'
    }

    if (dsaPayer) params.dsa_payor = dsaPayer;
    if (dsaBeneficiary) params.dsa_beneficiary = dsaBeneficiary;

    return this.api(adAccount.facebook_id + '/adsets', 'post', this.generateFbParams(params));
  }

  importAudiencesForAdAccount(adAccount: AdAccount, audienceType: AudienceImportType) {
    return new Promise<Array<Audience>>((resolve, reject) => {
      const audiences: Audience[] = [];
      const actId = adAccount.facebook_id;

      this.getSession().then(async () => {
        const fieldMap: Map<AudienceImportType, string> = new Map();
        fieldMap.set(AudienceImportType.SavedAudience, 'name,targeting,approximate_count_lower_bound,approximate_count_upper_bound');
        fieldMap.set(AudienceImportType.CustomAudience, 'id,name,approximate_count_lower_bound,approximate_count_upper_bound,subtype,account_id,lookalike_spec');
        fieldMap.set(AudienceImportType.FanpageAudience, 'id,name,category,fan_count,tasks,is_published');

        let path: string;
        if (audienceType == AudienceImportType.SavedAudience) {
          path = '/' + actId + '/saved_audiences';
        } else if (audienceType == AudienceImportType.CustomAudience) {
          path = '/' + actId + '/customaudiences';
        } else if (audienceType == AudienceImportType.FanpageAudience) {
          path = '/me/accounts';
        }

        if (path) {
          const params: any = {
            fields: fieldMap.get(audienceType),
            limit: 30,
            access_token: adAccount.token
          };

          const handlePage = (next?: string) => {
            if (next) this.logger.debug('Handling next URL : ' + next);
            this.api(next ? next : path, "get", next ? {} : this.generateFbParams(params)).then(async result => {
              for (let fbAudience of result.data) {
                let audience: Audience;

                if (audienceType == AudienceImportType.CustomAudience) {
                  audience = new Audience(fbAudience.name, "base", 'en_US', 'custom');
                  audience.target_spec = JSON.stringify({
                    "custom_audiences": [{
                      id: fbAudience.id,
                      name: fbAudience.name
                    }]
                  });
                  audience.targeting = Targeting.fromAudience(audience);
                  audience.lookalike_spec = fbAudience.lookalike_spec;
                  audience.data_extra = JSON.stringify({
                    id: fbAudience.id,
                    accountId: adAccount.facebook_id,
                    accountName: adAccount.name,
                    accountToken: adAccount.token,
                    subtype: fbAudience.subtype.toLowerCase()
                  });
                  audience.fb_size = fbAudience.approximate_count_lower_bound;
                  audience.fb_size_lower = fbAudience.approximate_count_lower_bound;
                  audience.fb_size_upper = fbAudience.approximate_count_upper_bound;
                } else if (audienceType == AudienceImportType.FanpageAudience) {
                  audience = new Audience(fbAudience.name, "base", "en_US", "fanpage");
                  audience.target_spec = JSON.stringify({
                    "connections": [{
                      id: fbAudience.id,
                      name: fbAudience.name
                    }]
                  });
                  audience.targeting = Targeting.fromAudience(audience);
                  audience.data_extra = JSON.stringify({
                    id: fbAudience.id,
                    accountId: adAccount.facebook_id,
                    accountName: adAccount.name,
                    accountToken: adAccount.token,
                    subtype: "fanpage"
                  });
                  audience.fb_size = fbAudience.fan_count;
                  audience.fb_size_lower = fbAudience.fan_count;
                  audience.fb_size_upper = fbAudience.fan_count;
                } else if (audienceType == AudienceImportType.SavedAudience) {
                  const target = JSON.stringify(fbAudience.targeting);

                  const convertedTargeting = Targeting.fromJson(target, "unknown");

                  const realAudienceType = convertedTargeting.customAudiences ? "custom" : "sociodemo";
                  convertedTargeting.type = realAudienceType;

                  audience = new Audience(fbAudience.name, "base", 'en_US', realAudienceType);
                  audience.target_spec = convertedTargeting.toFbJsonString();
                  audience.targeting = convertedTargeting;
                  audience.fb_size = fbAudience.approximate_count_lower_bound;
                  audience.fb_size_lower = fbAudience.approximate_count_lower_bound;
                  audience.fb_size_upper = fbAudience.approximate_count_upper_bound;

                  if (convertedTargeting.customAudiences) {
                    audience.data_extra = JSON.stringify({
                      id: convertedTargeting.customAudiences[0].id,
                      accountId: adAccount.facebook_id,
                      accountName: adAccount.name,
                      accountToken: adAccount.token,
                      subtype: "custom"
                    });
                  }
                }

                if (audience) {
                  audience.imported = true;

                  if (audienceType == AudienceImportType.CustomAudience) {
                    if (audience.fb_size > 0) audiences.push(audience);
                    if (fbAudience.account_id != adAccount.facebook_account_id.toString()) {
                      this.logger.logWarning("Warning : Owner of audience " + audience.name + " is not the user");
                    }
                    //else this.logger.logWarning("Audience " + audience.name + " not loaded because the owner is another account")
                  } else if (audienceType == AudienceImportType.FanpageAudience) {
                    const canBypassFanpageSize = await this.auth.permissionCheck('bypass.fanpage.size');
                    if (canBypassFanpageSize || audience.fb_size > 0) {
                      if (fbAudience.tasks) {
                        if (fbAudience.tasks.includes("ADVERTISE") && fbAudience.is_published) {
                          audiences.push(audience);
                        } else {
                          this.logger.logWarning("Audience " + audience.name + " not loaded because the user is not admin of the page");
                        }
                      } else {
                        audiences.push(audience);
                      }
                    }
                  } else {
                    audiences.push(audience);
                  }
                } else {
                  this.logger.logError('Audience type not recognized');
                  if (environment.config.showErrorModal) this.alert.notify('Facebook Audience Import Error', 'Audience type not recognized', 'error');
                }
              }
              if (result.paging && result.paging.next) {
                handlePage(result.paging.next);
              } else {
                resolve(audiences);
              }
            }).catch(error => {
              this.logger.logError('An error occurred when importing audiences : ' + error.message);
              resolve(audiences);
            });
          }

          handlePage();
        } else {
          reject({message: "Could not build path for specified audience type"})
        }
      }).catch(reject);
    })
  }

  /**
   * Import audiences for all ad accounts based on its type
   * @param adAccounts
   * @param audienceType
   */
  importAudiences(adAccounts: Array<AdAccount>, audienceType: AudienceImportType): Promise<Array<Audience>> {
    return new Promise<Array<Audience>>((resolve, reject) => {
      let audiences: Audience[] = [];
      let accountsDone = 0;

      adAccounts.forEach(ad => {
        this.importAudiencesForAdAccount(ad, audienceType).then(auds => {
          // => NOTE: If some client audiences are missing, remove this name filter
          audiences = audiences.concat(auds.filter(a => !audiences.map(aa => aa.name).includes(a.name)));

          accountsDone++;

          if(accountsDone == adAccounts.length) {
            resolve(audiences);
          }
        }).catch(reject);
      })
    });
  }

  private searchAdTypeApi<T>(params: any): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      firstValueFrom(this.spApi.get<ApiFacebookResponse<T>>('facebook/search', params)).then(result => {
        const success = result.success;
        if (success) {
          resolve(result.response);
        } else {
          reject(result.error);
        }
      })
    })
  }

  private searchTargetingApi<T>(params: any): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      firstValueFrom(this.spApi.get<ApiFacebookResponse<T>>('facebook/search/targeting', params)).then(result => {
        const success = result.success;
        if (success) {
          resolve(result.response);
        } else {
          reject(result.error);
        }
      })
    })
  }

  interestSuggestion(name: string): Promise<FbCriteria[]> {
    if (name.includes('(') && name.includes(')')) name = name.replace(/ *\([^)]*\) */g, "");

    return new Promise<FbCriteria[]>((resolve, reject) => {
      this.searchAdTypeApi<FbCriteria>({
        type: "adinterestsuggestion",
        'interest_list[]': name
      }).then(criterion => {
        criterion.forEach(c => {
          c.audience_size = c.audience_size_lower_bound
        });
        resolve(criterion);
      }).catch(error => reject(error));
    });
  }

  searchGeolocation(query: string, locale: string = "en_US", options?: any): Promise<FbGeolocation[]> {
    const baseParams = {q: query, type: "adgeolocation", locale};
    const params = {...baseParams, ...options};
    return this.searchAdTypeApi<FbGeolocation>(params);
  }
  searchLocale(query: string, locale: string = "en_US"): Promise<FbLocale[]> {
    return this.searchAdTypeApi<FbLocale>({q: query, type: "adlocale", locale});
  }
  searchFbInterest(query: string, locale: string = "en_US"): Promise<FbCriteria[]> {
    const params = {
      q: query,
      locale: locale
    };

    return new Promise<FbCriteria[]>((resolve, reject) => {
      this.searchTargetingApi<FbCriteria>(params).then(crits => {
        crits.forEach(crit => {
          crit.audience_size = crit.audience_size_lower_bound;
        });
        resolve(crits);
      }).catch(err => reject(err));
    });
  }
  searchCriteriaInterest(query: string, locale: string = "en_US"): Promise<Criteria[]> {
    return new Promise<Criteria[]>((resolve, reject) => {
        const params = {
          q: query,
          locale: locale
        };
        this.searchTargetingApi<FbCriteria>(params).then(result => {
          resolve(result.map(json => {
            const name = json.name;
            const id = json.id;
            const type = json.type;
            const size = json.audience_size_lower_bound;

            const jsonEntry = {};
            jsonEntry[type] = [{"id": id, "name": name}];

            let targeting: string = JSON.stringify({
              "flexible_spec": [
                  jsonEntry
              ]
            });

            let criteria = new Criteria(name, type, targeting)
            criteria.fb_size = size;
            return criteria;
          }));
        }).catch(err => reject(err));
    });
  }

  private generateFbParams(params: any = {}) {
    if (this.useAccessToken && this.accessToken && !params.access_token) params.access_token = this.accessToken;
    return params;
  }
}
