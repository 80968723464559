<div [ngClass]="{'sp-project-top-fixed': pageScrolled == true}" class="sp-top" id="sp-top">
  <div style="width: 100%;display: flex;justify-content: end">
    <div style="display: flex;column-gap:10px;margin-top: 1rem;margin-bottom: 1rem">
      <mat-checkbox (change)="onFlagBoxChange($event)" *ngIf="canCustomCrawl">CIC Flag</mat-checkbox>
      <button class="btn btn-secondary sp-btn sp-btn-secondary sp-change-type" matTooltip="Change type" matTooltipHideDelay="10" routerLink="/projects/create/select" routerLinkActive="active" type="button">
        <span> Change type </span>
      </button>
      <button (click)="confirmProject()" [class.sp-btn-disable]="freemium && audienceTarget.fb_size < 100000" [disabled]="freemium && audienceTarget.fb_size < 100000" class="btn btn-primary sp-btn sp-btn-primary sp-btn-launch" id="step5" style="display: flex;justify-content: space-around" type="button">
        <span>Launch your project</span>
        <i class=" icon-sp-arrow-circle-right" style="font-size: 1.5rem;"></i>
      </button>
    </div>
  </div>

  <div style="display: flex;justify-content: space-between;column-gap: 2rem">
    <div  class="sp-target-card" id="step2">
      <!-- Target info card -->
      <div class="sp-card sp-card-audience" id="card-target">
        <div [ngClass]="isSelected == false ? 'sp-card-target-color-selected':''" class="card sp-card-target">
          <div class="pb-3 p-0 card-header">
            <input #title (blur)="onAudienceTitleUpdate(title, audienceTarget); isInsideName = false" (keyup.enter)="title.blur()" [hidden]="!isTitleEditable(title)" [value]="audienceTarget.name" class="sp-target-audience" matTooltip="{{audienceTarget.name}}" matTooltipHideDelay="10" >
            <div (click)="editTitle(title)" [hidden]="isTitleEditable(title)" class="sp-target-audience" matTooltip="{{audienceTarget.name}}" matTooltipHideDelay="10">{{audienceTarget.name.substr(0,25)}}</div>
            <div class="pl-1" style="position: absolute; top: 22px; right: 10px">
              <i (click)="editTitle(title)" class="icon-sp-edit-white" style="font-size: 0.8rem;"> </i>
            </div>
          </div>
          <div class="card-body" id="card-body-target">
            <div *ngIf="audienceTarget.type == 'custom'">
              <span>Universes: <span class="sp-element">{{ audienceTarget.universes | arrayNameJoin }}</span> </span><br/>
              <span>Age:
                <span *ngIf="audienceTarget.targeting.ageMax < 65" class="sp-element">{{ audienceTarget.targeting.ageMin }}-{{ audienceTarget.targeting.ageMax }}</span>
                <span *ngIf="audienceTarget.targeting.ageMax >= 65" class="sp-element">{{ audienceTarget.targeting.ageMin }}-{{ audienceTarget.targeting.ageMax }}+</span>
              </span>
              <br />
              <span>Gender: <span class="sp-element">{{ audienceTarget.targeting.gendersString() }} </span></span>
              <br/>
              <span *ngIf="audienceTarget.targeting.geolocations">Geolocation:
                <span class="sp-element">{{ audienceTarget.targeting.geolocString() }}<br/></span>
              </span>
              <span>Custom Audience: <span class="sp-element">{{ audienceTarget.targeting.extra ? audienceTarget.targeting.extra.subtype : "Unknown" }} </span></span><br />
              <span>Ad Account: <span class="sp-element">{{ audienceTarget.targeting.extra ? audienceTarget.targeting.extra.accountName : "Unknown" }}</span></span><br />
              <div *ngFor="let flex of audienceTarget.targeting.flex; let i = index">
                <span>{{ i == 0 ? 'Interests' : 'And' }}:
                  <span [innerHTML]="audienceTarget.targeting.flexOrString(flex) | boldWord:'OR'" class="sp-element"></span>
                </span>
              </div>
              <span *ngIf="audienceTarget.targeting.exclusions">Exclude:
                <br/>
                <span [innerHTML]="audienceTarget.targeting.flexOrString(audienceTarget.targeting.exclusions) | boldWord:'OR'" class="sp-element"><br/></span>
              </span>
            </div>
            <div *ngIf="audienceTarget.type != 'custom'">
              <span>Universes: <span class="sp-element">{{ audienceTarget.universes | arrayNameJoin }}</span></span><br/>
              <span>Age:
              <span *ngIf="audienceTarget.targeting.ageMax < 65" class="sp-element">{{ audienceTarget.targeting.ageMin }}-{{ audienceTarget.targeting.ageMax }}</span>
              <span *ngIf="audienceTarget.targeting.ageMax >= 65" class="sp-element">{{ audienceTarget.targeting.ageMin }}-{{ audienceTarget.targeting.ageMax }}+</span>
            </span><br />
              <span>Gender: <span class="sp-element">{{ audienceTarget.targeting.gendersString() }} </span></span><br/>
              <span *ngIf="audienceTarget.targeting.geolocations">Geolocation:
              <span class="sp-element">{{ audienceTarget.targeting.geolocString() }}<br/></span>
            </span>
              <span *ngIf="environment.config.enableDebugFeatures && audienceTarget.targeting.locales && audienceTarget.targeting.locales.length > 0">
                Locales : {{ audienceTargetInfo.localesToString().join(', ') }}
              </span>
              <div *ngIf="audienceTarget.targeting.relationshipStatuses && audienceTarget.targeting.relationshipStatuses.length > 0">
                Relationship Statuses: {{ audienceTargetInfo.relationshipStatusToString().join(', ') }}
              </div>
              <div *ngFor="let flex of audienceTarget.targeting.flex; let i = index">
                <span>{{ i == 0 ? 'Interests' : 'And' }}:
                  <span [innerHTML]="audienceTarget.targeting.flexOrString(flex) | boldWord:'OR'" class="sp-element"></span>
                </span>
              </div>
              <span *ngIf="audienceTarget.targeting.exclusions">Exclude:
                <br/>
                <span [innerHTML]="audienceTarget.targeting.flexOrString(audienceTarget.targeting.exclusions) | boldWord:'OR'" class="sp-element"><br/></span>
              </span>
            </div>
          </div>
          <div class="card-footer">
            <div class="row no-gutters">
              <div [class]="audienceType == AudienceType.benchmark ? 'col-10 d-flex justify-content-start' : 'col-12'">
                <span class="sp-target-people"><span class="sp-target-number">{{ (audienceTarget ? audienceTarget.fb_size : 0) | numberFormat }}</span> people</span>
              </div>
              <div *ngIf="audienceType == AudienceType.benchmark" class="col-2 d-flex justify-content-end">
                <button (click)="switchAudienceType(AudienceType.target)" class="btn btn-primary sp-btn sp-btn-primary" matTooltip="Edit" matTooltipHideDelay="10" type="button">
                  <span> Edit </span>
                </button>
              </div>
            </div>
            <app-sp-audience-score *ngIf="audienceType == AudienceType.target" [useNewIndicator]="useNewAudienceScoreIndicator" [audienceTarget]="audienceTarget"></app-sp-audience-score>
          </div>
        </div>
      </div>
    </div>
    <div class=" d-flex align-self-center justify-content-center">
      <span class="sp-project-vs">VS</span>
    </div>
    <div class="sp-benchmark-card " id="step3">
      <!-- Benchmark info card -->
      <div class="sp-card sp-card-audience" id="card-benchmark">
        <div [ngClass]="isSelected == true ? 'sp-card-target-color-selected':''" class="card sp-card-benchmark" id="">
          <div class="pb-3 p-0 card-header">
            <input #benchmarkTitle (blur)="onAudienceTitleUpdate(benchmarkTitle, audienceBenchmark); isInsideName = false" (keyup.enter)="benchmarkTitle.blur()" [hidden]="!isTitleEditable(benchmarkTitle)" [value]="audienceBenchmark.name" class="sp-benchmark-audience" matTooltip="{{audienceBenchmark.name}}" matTooltipHideDelay="10" maxlength="25" >
            <div (click)="editTitle(benchmarkTitle)" [hidden]="isTitleEditable(benchmarkTitle)" class="sp-benchmark-audience" matTooltip="{{audienceBenchmark.name}}" matTooltipHideDelay="10">
              {{ audienceBenchmark.name }}
            </div>
            <div class="pl-1" style="position: absolute; top: 22px;right: 10px">
              <i (click)="editTitle(benchmarkTitle)" class="icon-sp-edit-white" style="font-size: 0.8rem;"> </i>
            </div>
            <!-- <span class="sp-benchmark-audience">{{ audienceBenchmark ? audienceBenchmark.name : 'Benchmark audience' }}</span>-->
          </div>
          <div class="card-body" id="card-body-benchmark">
            <div *ngIf="audienceBenchmark.type == 'custom'">
              <span>Universes: <span class="sp-element">{{ audienceBenchmark.universes | arrayNameJoin }}</span></span><br/>
              <span>Age:
              <span *ngIf="audienceBenchmark.targeting.ageMax <65" class="sp-element"> {{ audienceBenchmark.targeting.ageMin }}-{{ audienceBenchmark.targeting.ageMax }}</span>
              <span *ngIf="audienceBenchmark.targeting.ageMax >=65" class="sp-element"> {{ audienceBenchmark.targeting.ageMin }}-{{ audienceBenchmark.targeting.ageMax }}+</span>
            </span><br/>
              <span>Gender: <span class="sp-element">{{ audienceBenchmark.targeting.gendersString() }}</span></span><br/>
              <span>Custom Audience: <span class="sp-element">{{ audienceBenchmark.targeting.extra.subtype }}</span></span><br />
              <span>Ad Account: <span class="sp-element"> {{ audienceBenchmark.targeting.extra.accountName }}</span></span><br />
              <span *ngIf="audienceBenchmark.targeting.geolocations">Geolocation:  <span class="sp-element">{{ audienceBenchmark.targeting.geolocString() }}<br/></span></span>
              <div *ngFor="let flex of audienceBenchmark.targeting.flex; let i = index"><span>{{ i == 0 ? 'Interests' : 'And' }}:  <span [innerHTML]="audienceBenchmark.targeting.flexOrString(flex) | boldWord:'OR'" class="sp-element"></span></span></div>
              <span *ngIf="audienceBenchmark.targeting.exclusions">Exclude:  <span [innerHTML]="audienceBenchmark.targeting.flexOrString(audienceBenchmark.targeting.exclusions) | boldWord:'OR'" class="sp-element"><br/></span></span>

            </div>
            <div *ngIf="audienceBenchmark.type != 'custom'" >
              <span>Universes: <span class="sp-element">{{ audienceBenchmark.universes | arrayNameJoin }}</span></span><br/>
              <span>Age:
              <span *ngIf="audienceBenchmark.targeting.ageMax <65" class="sp-element"> {{ audienceBenchmark.targeting.ageMin }}-{{ audienceBenchmark.targeting.ageMax }}</span>
              <span *ngIf="audienceBenchmark.targeting.ageMax >=65" class="sp-element"> {{ audienceBenchmark.targeting.ageMin }}-{{ audienceBenchmark.targeting.ageMax }}+</span>
            </span><br/>
              <span>Gender: <span class="sp-element">{{ audienceBenchmark.targeting.gendersString() }}</span></span><br/>
              <span *ngIf="audienceBenchmark.targeting.geolocations">Geolocation:  <span class="sp-element">{{ audienceBenchmark.targeting.geolocString() }}<br/></span></span>
              <div *ngFor="let flex of audienceBenchmark.targeting.flex; let i = index"><span>{{ i == 0 ? 'Interests' : 'And' }}:  <span [innerHTML]="audienceBenchmark.targeting.flexOrString(flex) | boldWord:'OR'" class="sp-element"></span></span></div>
              <span *ngIf="audienceBenchmark.targeting.exclusions">Exclude:  <span [innerHTML]="audienceBenchmark.targeting.flexOrString(audienceBenchmark.targeting.exclusions) | boldWord:'OR'" class="sp-element"><br/></span></span>
            </div>
          </div>
          <div class="card-footer">
            <div class="row d-flex no-gutters">
              <div [class]="audienceType == AudienceType.target ? 'col-10 d-flex justify-content-start' : 'col-12'">
                <span class="sp-benchmark-people"><span class="sp-target-number">{{ (audienceBenchmark ? audienceBenchmark.fb_size : 0) | numberFormat}}</span> people</span>
              </div>
              <div *ngIf="audienceType == AudienceType.target" class="col-2 d-flex justify-content-end">
                <button (click)="switchAudienceType(AudienceType.benchmark)" [disabled]="freemium"  class="btn btn-primary sp-btn sp-btn-primary" matTooltip="Edit" matTooltipHideDelay="10" type="button">
                  <span> Edit </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=" sp-card-switch card" style="width: 20rem;">
      <div class="card-body text-center" id="card-img-1" style="display: flex;align-items: center;justify-content: center;padding: 0">
        <img *ngIf="audienceMode == AudienceCreationMode.interest" alt="" class="sp-image-facebook-import" id="sp-image-facebook-import-interest" src="assets/images/backgrounds/facebook-criteria.png" />
        <img *ngIf="audienceMode == AudienceCreationMode.import" alt="" class="sp-image-facebook-import" id="sp-image-facebook-import-import" src="assets/images/backgrounds/facebook-import.svg" />
        <img *ngIf="audienceMode == AudienceCreationMode.facebookPage" alt="" class="sp-image-facebook-import" id="sp-image-facebook-import-facebookPage" src="assets/images/backgrounds/facebook-page.svg" />
      </div>
      <div class="pt-2 pb-3 pr-2 card-footer row no-gutters d-flex justify-content-end">
        <div class="col-md-auto align-self-center pr-2">
          <span *ngIf="audienceMode == AudienceCreationMode.import">Facebook import</span>
          <span *ngIf="audienceMode == AudienceCreationMode.interest">Facebook criteria</span>
          <span *ngIf="audienceMode == AudienceCreationMode.facebookPage">Facebook page</span>
        </div>
        <div class="col-md-auto ml-xxxl-3 ml-md-1">
          <button class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Change type" matTooltipHideDelay="10" routerLink="/projects/create/select" routerLinkActive="active" type="button">
            <span> Change type </span>
          </button>
        </div>
      </div>
    </div>

  </div>
  <!--
  <div class="" style="width: 10rem">
    <div [class.h-100]="freemium" [matTooltipDisabled]="!freemium || audienceTarget.fb_size >= 100000" matTooltip="Your audience is too small. Build your audience to be larger than 100,000 people." matTooltipHideDelay="10">
      <button (click)="confirmProject()" [class.h-100]="freemium" [class.sp-btn-disable]="freemium && audienceTarget.fb_size < 100000" [disabled]="freemium && audienceTarget.fb_size < 100000" class="w-100 mb-4 btn btn-primary sp-btn sp-btn-primary sp-btn-launch d-flex" id="step5" matTooltip="Launch your project" matTooltipHideDelay="10" type="button">
        <span>Launch your project</span>
        <i class=" icon-sp-arrow-circle-right" style="font-size: 1.5rem;position: absolute;right: 1rem;"></i>
      </button>
    </div>

  <div *ngIf="freemium == false" style="width: 10rem">
    <div class="sp-card sp-card-1" id="step4">
      <div class="card">
        <div class="card-body text-center" id="card-img-1">
          <img alt="" class="sp-image-facebook-import" *ngIf="audienceMode == AudienceCreationMode.interest" src="assets/images/backgrounds/facebook-criteria.png" />
          <img alt="" class="sp-image-facebook-import" *ngIf="audienceMode == AudienceCreationMode.import" src="assets/images/backgrounds/facebook-import.svg" />
          <img alt="" class="sp-image-facebook-import" *ngIf="audienceMode == AudienceCreationMode.facebookPage" src="assets/images/backgrounds/facebook-page.svg" />
        </div>
        <div class="pt-2 pb-3 pr-2 card-footer row no-gutters d-flex justify-content-end">
          <div class="col-md-auto align-self-center pr-2">
            <span *ngIf="audienceMode == AudienceCreationMode.import">Facebook import</span>
            <span *ngIf="audienceMode == AudienceCreationMode.interest">Facebook criteria</span>
            <span *ngIf="audienceMode == AudienceCreationMode.facebookPage">Facebook page</span>
          </div>
          <div class="col-md-auto ml-xxxl-3 ml-md-1">
            <button type="button" class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Change type" matTooltipHideDelay="10" routerLink="/projects/create/select" routerLinkActive="active">
              <span> Change type </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sp-card sp-card-2">
      <div class="card">
        <div class="card-body text-center" id="card-img-2">
          <img class="sp-image-facebook-import" src="assets/images/backgrounds/facebook-import.svg" />
        </div>
        <div class="pt-2 card-footer row no-gutters d-flex justify-content-end">
          <div class="col-md-auto align-self-center pr-2">
            <span></span>
          </div>
          <div class="col-md-auto pb-3 pr-2">
            <button type="button" class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Change type" matTooltipHideDelay="10">
              <span> Change type </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sp-card sp-card-3">
      <div class="card">
        <div class="card-body text-center" id="card-img-3">
          <img class="sp-image-facebook-import" src="assets/images/backgrounds/facebook-import.svg" />
        </div>
        <div class="pt-2 card-footer row no-gutters d-flex justify-content-end">
          <div class="col-md-auto align-self-center pr-2">
            <span></span>
          </div>
          <div class="col-md-auto pb-3 pr-2">
            <button type="button" class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Change type" matTooltipHideDelay="10">
              <span> Change type </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="sp-card sp-card-4">
      <div class="card">
        <div class="card-body text-center" id="card-img-4">
          <img class="sp-image-facebook-import" src="assets/images/backgrounds/facebook-import.svg" />
        </div>
        <div class="pt-2 card-footer row no-gutters d-flex justify-content-end">
          <div class="col-md-auto align-self-center pr-2">
            <span></span>
          </div>
          <div class="col-md-auto pb-3 pr-2">
            <button type="button" class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Change type" matTooltipHideDelay="10">
              <span> Change type </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
-->
</div>
