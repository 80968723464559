import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {User} from "../../../models/user";
import {ApiModelService, DataPagination} from "../sp-api-model/api-model.service";
import {Project} from "../../../models/project";
import {Company} from "../../../models/company";
import {AdAccount} from "../../../models/ad-account";
import {Role} from '../../../models/role';
import {Folder} from '../../../models/folder';
import {Tag} from '../../../models/tag';
import {LoggerService} from '../../sp-logger/logger.service';
import {Dashboard} from '../../../models/dashboard';
import {firstValueFrom} from "rxjs";
import {ProjectSortType} from "../../sp-project/project-manager.service";

@Injectable({
  providedIn: 'root'
})
export class ApiUserService extends ApiModelService<User> {
  protected readonly basePath = "users";

  constructor(httpClient: HttpClient, logger: LoggerService) {
    super(httpClient, logger);
  }

  block(user: User|{id: number}, msg: {message: string}): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const sub = this.post(`${this.basePath}/${user.id}/block`, msg).subscribe(() => resolve(true),
        err => reject(err), () => sub.unsubscribe());
    });
  }

  unblock(user: User): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const sub = this.delete(`${this.basePath}/${user.id}/block`).subscribe(() => resolve(true),
        err => reject(err), () => sub.unsubscribe());
    })
  }

  getOneByToken(token: string): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.post<User>('user/token', { token })
        .subscribe(user => resolve(user), error => reject(error))
    });
  }

  /* Other methods with relations go here */
  updateAdAccounts(user: User, accounts: AdAccount[]): Promise<AdAccount[]> {
    return firstValueFrom(this.put<AdAccount[]>(`${this.basePath}/${user.id}/adaccounts`, {ad_accounts: accounts}));
  }

  getProjects(user: User, perPage: number = 15, page: number = 1, sort?: ProjectSortType, filter?: string, typeFilter?: string[], folderId?: number): Promise<DataPagination<Project>> {
    const params: any = {
      perPage: perPage,
      page: page
    };

    if (sort) params.sort = sort;
    if (filter) params.filter = filter;
    if (typeFilter) params.filter_type = typeFilter.join(',');
    if (folderId) params.folder_id = folderId;

    return firstValueFrom(this.get<DataPagination<Project>>(`${this.basePath}/${user.id}/projects`, params));
  }

  getDashboards(user: User): Promise<Dashboard[]> {
    return firstValueFrom(this.get<Dashboard[]>(`${this.basePath}/${user.id}/dashboards`));
  }

  saveDashboard(user: User, dashboard: Dashboard): Promise<void> {
    return this.post<void>(`${this.basePath}/${user.id}/dashboards`, dashboard).toPromise();
  }

  getSharedProjects(user: User): Promise<Project[]> {
    return firstValueFrom(this.get<Project[]>(`${this.basePath}/${user.id}/projects/shared`));
  }

  getFavoriteTags(user: User): Promise<Tag[]> {
    return new Promise<Tag[]>((resolve, reject) => {
      const sub = this.get<Tag[]>(`${this.basePath}/${user.id}/tags/favorite`)
        .subscribe(tags => resolve(tags), error => reject(error), () => sub.unsubscribe());
    });
  }

  saveFavoriteTag(user: User, tag: {id: number, name: string}): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const sub = this.post(`${this.basePath}/${user.id}/tags/favorite/${tag.id}`)
        .subscribe(() => resolve(true), error => reject(error), () => sub.unsubscribe());
    });
  }

  deleteFavoriteTag(user: User, tag: {id: number, name: string}): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const sub = this.delete(`${this.basePath}/${user.id}/tags/favorite/${tag.id}`)
        .subscribe(() => resolve(true), error => reject(error), () => sub.unsubscribe());
    });
  }

  getFolders(user: User, flagged: boolean = false): Promise<Folder[]> {
    const query = flagged ? '?flagged=1' : '';
    return firstValueFrom(this.get<Folder[]>(`${this.basePath}/${user.id}/folders${query}`));
  }

  switchCompanyRole(user: User, company: Company, role: Role): Promise<void> {
    return firstValueFrom(this.post<void>(`${this.basePath}/${user.id}/companies/${company.id}/role/${role.id}`));
  }

  sendInvitation(user: User): Promise<void> {
    return firstValueFrom(this.post<void>(`${this.basePath}/${user.id}/invite`));
  }

  /* ------------------------------------ */
}
