import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {Project} from "../../../../models/project";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ApiProjectService} from "../../../../services/sp-api/sp-api-project/api-project.service";

@Component({
  selector: 'app-sp-modal-project-list-project-delete',
  templateUrl: './sp-modal-project-list-project-delete.component.html',
  styleUrls: ['./sp-modal-project-list-project-delete.component.scss']
})
export class SpModalProjectListProjectDeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SpModalProjectListProjectDeleteComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Project,
              private apiProject: ApiProjectService) { }

  ngOnInit() {
  }

  deleteProject() {
    this.apiProject.deleteOne(this.data).then(() => {
      this.dialogRef.close(true);
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
