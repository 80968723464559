<div class="sp-dialog">
  <div class="sp-dialog-header">
    Delete project
  </div>
  <div class="sp-dialog-body">
    <h3>Are you sure you want to delete this project ?</h3>
    <p class="text-center">This action cannot be undone.<br />The used credits will not be refunded.</p>
    <div class="pb-3 row justify-content-center">
      <button mat-button class="btn btn-secondary m-1 text-uppercase sp-btn sp-btn-secondary" (click)="onNoClick()" matTooltip="Cancel" matTooltipHideDelay="10">Cancel</button>
      <button mat-button class="btn btn-primary m-1 text-uppercase sp-btn sp-btn-primary" (click)="deleteProject()" matTooltip="Delete" matTooltipHideDelay="10">Delete</button>
    </div>
  </div>
</div>
