import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Asset} from "../../../../models/asset";
import {ApiAssetService, AssetSuggestion} from "../../../../services/sp-api/sp-api-asset/api-asset.service";
import {environment} from "../../../../../environments/environment";
import {EMPTY, Subject, Subscription} from "rxjs";
import {debounceTime, switchMap} from "rxjs/operators";
import {KeyboardUtils} from "../../../../utils/keyboard/keyboard-utils";
import {Logger} from '../../../../classes/util/logger';
import {LoggerService} from '../../../../services/sp-logger/logger.service';

@Component({
  selector: 'app-sp-modal-asset-manager-edit',
  templateUrl: './sp-modal-asset-manager-edit.component.html',
  styleUrls: ['./sp-modal-asset-manager-edit.component.scss']
})
export class SpModalAssetManagerEditComponent implements OnInit, OnDestroy {
  readonly defaultImageUrl = environment.config.api.storageUrl + 'files/default.png';

  suggestions: AssetSuggestion[] = [];
  selectedSuggestion: AssetSuggestion;

  private assetInputChange: Subject<string> = new Subject<string>();
  private assetInputSubscription: Subscription;

  pending = false;

  constructor(public dialogRef: MatDialogRef<SpModalAssetManagerEditComponent>,
              @Inject(MAT_DIALOG_DATA) public asset: Asset,
              private apiAsset: ApiAssetService,
              private logger: LoggerService) { }

  ngOnInit() {
    this.assetInputSubscription = this.assetInputChange.pipe(
      debounceTime(500),
      switchMap(query => {
        if (query !== "") this.apiAsset.getSuggestions(query, this.asset.type).then(s => this.suggestions = s);
        else this.suggestions = [];
        return EMPTY;
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.assetInputSubscription.unsubscribe();
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  confirm() {
    if (this.selectedSuggestion) {
      this.pending = true;

      let suggestion;

      if (this.asset.type == 'brands') {
        suggestion = this.selectedSuggestion.name;
      } else if (this.asset.type == 'icons') {
        suggestion = this.selectedSuggestion.id;
      } else if (this.asset.type == "personalities" || this.asset.type == "events") {
        suggestion = this.selectedSuggestion.logo;
      }

      this.apiAsset.useSuggestion(this.asset, suggestion, this.asset.type).then(asset => {
        this.dialogRef.close(asset);
      }).catch(error => {
        this.logger.logError("Error with asset suggestion : " + error.message, 3);
        this.dialogRef.close(false);
      });
    }
  }

  onAssetSearchInputChange(query: string, event: KeyboardEvent) {
    if (KeyboardUtils.isValidCharacter(event.key) || event.key == 'Backspace') {
      this.suggestions = [null];
      this.assetInputChange.next(query);
    }
  }

  assetToString(asset: AssetSuggestion) {
    if (!asset) return undefined;
    return asset.name;
  }

  onCustomImageChange(input: HTMLInputElement) {
    const file: File = input.files[0];
    const reader = new FileReader();

    this.pending = true;
    reader.addEventListener('load', (event: any) => {
      const customImage = event.target.result;
      this.apiAsset.useCustomImage(this.asset, customImage).then(asset => {
        this.dialogRef.close(asset);
      }).catch(error => {
        this.logger.logError("Dashboard error when using custom image : " + error.message, 3);
        this.dialogRef.close();
      });
    });

    reader.readAsDataURL(file);
  }
}
