import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiModelService} from "../sp-api-model/api-model.service";
import {Company} from "../../../models/company";
import {User} from '../../../models/user';
import {Role} from '../../../models/role';
import {LoggerService} from '../../sp-logger/logger.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiCompanyService extends ApiModelService<Company> {
  protected readonly basePath = "companies";

  constructor(httpClient: HttpClient, logger: LoggerService) {
    super(httpClient, logger);
  }

  /* Other methods with relations go here */

  addUser(company: Company, user: User, role: Role): Promise<Company> {
    return new Promise<Company>((resolve, reject) => {
      this.post<Company>(`companies/${company.id}/users/${user.id}`, {role_id: role.id})
        .subscribe(company => resolve(company), error => reject(error))
    });
  }

  deleteUser(company: Company, user: User): Promise<Company> {
    return new Promise<Company>((resolve, reject) => {
      this.delete<Company>(`${this.basePath}/${company.id}/users/${user.id}`)
        .subscribe(company => resolve(company), error => reject(error))
    });
  }

  inviteUser(company: Company, user: User): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.post<User>(`companies/${company.id}/user/invite`, user)
        .subscribe(user => resolve(user), error => reject(error))
    });
  }

  linkAgency(company: Company, agency: Company): Promise<void> {
    return firstValueFrom(this.post<void>(`companies/${company.id}/agency/${agency.id}`));
  }

  unlinkAgency(company: Company, agency: Company): Promise<void> {
    return firstValueFrom(this.delete<void>(`companies/${company.id}/agency/${agency.id}`));
  }

  getManagedCompanies(company: Company): Promise<Company[]> {
    return firstValueFrom(this.get<Company[]>(`companies/${company.id}/managed`));
  }

  /* ------------------------------------ */
}
