import { NgModule } from '@angular/core';
import { SpProjectCreateRoutingModule } from './sp-project-create-routing.module';
import { SpProjectCreateFacebookInterestComponent } from './sp-project-create-facebook-interest/sp-project-create-facebook-interest.component';
import { SpProjectCreateFacebookImportComponent } from './sp-project-create-facebook-import/sp-project-create-facebook-import.component';
import { SpProjectCreateFacebookPageComponent } from './sp-project-create-facebook-page/sp-project-create-facebook-page.component';
import { SpProjectCreateTypeSelectComponent } from './sp-project-create-type-select/sp-project-create-type-select.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import {MatChipsModule} from '@angular/material/chips';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {FilterPipe} from './sp-project-create-facebook-import/filter.pipe';
import {MessagesModule} from 'primeng/messages';
import {SliderModule} from 'primeng/slider';
import { SpProjectCreateHeaderComponent } from './modules/sp-project-create-header/sp-project-create-header.component';
import {NumberFormatPipe} from '../../../pipes/number-format.pipe';
import {BoldWordPipe} from '../../../pipes/bold-word.pipe';
import {ArrayNameJoinPipe} from '../../../pipes/array-name-join.pipe';
import { SpProjectCreateProjectSetupComponent } from './modules/sp-project-create-project-setup/sp-project-create-project-setup.component';
import { SpProjectCreateAudienceSetupComponent } from './modules/sp-project-create-audience-setup/sp-project-create-audience-setup.component';
import {DirectivesModule} from "../../../directives/directives.module";
import {MatMenuModule} from '@angular/material/menu';
import { SpProjectCreateAudiencePresetMenuComponent } from './modules/sp-project-create-audience-preset-menu/sp-project-create-audience-preset-menu.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SpAdminCompanyModule} from '../../sp-admin/sp-admin-company/sp-admin-company.module';
import { SpModalAudienceScoreInformationComponent } from './modules/sp-modal-audience-score-information/sp-modal-audience-score-information.component';
import { SpAudienceScoreComponent } from './modules/sp-audience-score/sp-audience-score.component';

@NgModule({
    declarations: [
        SpProjectCreateFacebookInterestComponent,
        SpProjectCreateFacebookImportComponent,
        SpProjectCreateFacebookPageComponent,
        SpProjectCreateTypeSelectComponent,
        FilterPipe,
        SpProjectCreateHeaderComponent,
        NumberFormatPipe,
        BoldWordPipe,
        ArrayNameJoinPipe,
        SpProjectCreateProjectSetupComponent,
        SpProjectCreateAudienceSetupComponent,
        SpProjectCreateAudiencePresetMenuComponent,
        SpModalAudienceScoreInformationComponent,
        SpAudienceScoreComponent
    ],
  exports: [
    SpModalAudienceScoreInformationComponent,
    SpAudienceScoreComponent
  ],
    imports: [
        SpProjectCreateRoutingModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        MatInputModule,
        MatChipsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MessagesModule,
        MatTooltipModule,
        SliderModule,
        MatButtonModule,
        DirectivesModule,
        MatMenuModule,
        MatCheckboxModule,
        SpAdminCompanyModule,

    ]
})
export class SpProjectCreateModule {}
