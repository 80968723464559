import {Model} from "./model";
import {Project} from "./project";
import {NamedItem} from '../pipes/array-name-join.pipe';

export class Folder implements Model, NamedItem {
  id: number;
  name: string;
  projects: Array<Project>;
  flag?: string;
  created_at: Date;
  updated_at: Date;

  constructor(name: string) {
    this.name = name;
  }
}
