<ng-container>
   <div class="sp-meta-targeting">
     <div class="sp-meta-targeting-first-line">
         <mat-card appearance="outlined" class="sp-campaign-parent" id="sp-campaign">
           <div style="margin-bottom: 1rem">
             <mat-card-title *ngIf="stepNumber == 1" class="sp-campaign-name-title">Define a campaign name</mat-card-title>
             <mat-card-title *ngIf="stepNumber == 2" class="sp-campaign-name-title">Define an Ad Account</mat-card-title>
             <mat-card-title *ngIf="stepNumber == 3" class="sp-campaign-name-title">Define a campaign’s objective</mat-card-title>
             <mat-card-title *ngIf="stepNumber == 4" class="sp-campaign-name-title">Define a beneficiary</mat-card-title>
             <mat-card-title *ngIf="stepNumber == 5" class="sp-campaign-name-title">Define a payer</mat-card-title>
           </div>
           <mat-card-content class="sp-campaign">
             <div *ngIf="stepNumber == 1">
               <mat-form-field appearance="outline" class="w-100  sp-form-field">
                 <mat-label class="sp-input-label">
                   <span>Campaign name</span>
                 </mat-label>
                 <input [(ngModel)]="campaignName" class="sp-input-content" matInput placeholder="Enter your title" />
               </mat-form-field>
             </div>
             <div *ngIf="stepNumber == 2">
               <mat-form-field appearance="outline" class="w-100  sp-form-field">
                 <mat-label class="sp-input-label">Ad Account</mat-label>
                 <mat-select (selectionChange)="adAccountSelect()" [(value)]="adAccountDefault">
                   <mat-option *ngFor="let account of adAccounts" [value]="account">{{account.name}}</mat-option>
                 </mat-select>
               </mat-form-field>
             </div>
             <div *ngIf="stepNumber == 3">
               <mat-form-field appearance="outline" class="w-100  sp-form-field">
                 <mat-label class="sp-input-label">Campaign's objective</mat-label>
                 <mat-select (selectionChange)="campaignObjectiveSelect()" [(value)]="campaignObjectiveDefault">
                   <mat-option *ngFor="let objective of objectives" [value]="objective">{{objective.label}}</mat-option>
                 </mat-select>
               </mat-form-field>
             </div>
             <!--
             <div *ngIf="stepNumber == 4">
               <mat-form-field appearance="outline" class="w-100  sp-form-field">
                 <mat-label class="sp-input-label">Beneficiary</mat-label>
                 <input matInput [(ngModel)]="dsaBeneficiary"/>
               </mat-form-field>
             </div>
             <div *ngIf="stepNumber == 5">
               <mat-form-field appearance="outline" class="w-100  sp-form-field">
                 <mat-label class="sp-input-label">Payer</mat-label>
                 <input matInput [placeholder]="dsaBeneficiary" [(ngModel)]="dsaPayer"/>
               </mat-form-field>
             </div>
             -->
             <div class="w-100 text-center mt-3 mb-3">
               <div (click)="stepNumber = 1" [ngClass]="stepNumber == 1 ? 'sp-button-widget-color-primary':'sp-button-widget-color-primary-light'" class="sp-button-widget"></div>
               <div (click)="stepNumber = 2 " [ngClass]="stepNumber == 2 ? 'sp-button-widget-color-primary':'sp-button-widget-color-primary-light'" class="sp-button-widget"></div>
               <div (click)="stepNumber = 3 " [ngClass]="stepNumber == 3 ? 'sp-button-widget-color-primary':'sp-button-widget-color-primary-light'" class="sp-button-widget"></div>
               <!--
               <div (click)="stepNumber = 4 " [ngClass]="stepNumber == 4 ? 'sp-button-widget-color-primary':'sp-button-widget-color-primary-light'" class="sp-button-widget"></div>
               <div (click)="stepNumber = 5 " [ngClass]="stepNumber == 5 ? 'sp-button-widget-color-primary':'sp-button-widget-color-primary-light'" class="sp-button-widget"></div>
               -->
             </div>
             <div class="sp-campaign-next-button">
               <button (click)="stepNumber = stepNumber - 1" *ngIf="stepNumber >= 2" class="mb-3 btn btn-primary sp-btn sp-btn-primary" style="display: flex;align-items: center" title="Next step" type="button">
                 <span class="material-symbols-rounded" style="rotate: 180deg">arrow_forward</span>
                 <span  style="margin-right: 1rem">Previous</span>
               </button>
               <button (click)="stepNumber = stepNumber + 1" *ngIf="stepNumber < 3" class="mb-3 btn btn-primary sp-btn sp-btn-primary" style="display: flex;align-items: center" title="Next step" type="button">
                 <span  style="margin-right: 1rem">Next</span>
                 <span class="material-symbols-rounded">arrow_forward</span>
               </button>
               <button (click)="createAdSet()" *ngIf="stepNumber == 3" [disabled]="!exportIsValid()" class="mb-3 btn btn-primary sp-btn sp-btn-primary" style="display: flex;align-items: center" title="Next step" type="button">
                 <span>Validate</span>
               </button>
             </div>

           </mat-card-content>
         </mat-card>
         <mat-card appearance="outlined" class="sp-attributes-parent" id="sp-attributes">
           <div>
             <mat-card-title class="sp-attributes-title">Attributes of your audience</mat-card-title>
           </div>
           <mat-card-content class="sp-attributes">
               <div class=" sp-attributes-left-side">
                 <div (click)="genderSelect(true,null)" class="sp-attributes-female" id="sp-attributes-female">
                   <span [hidden]="!femaleSelected" class="material-icons sp-attributes-female-checked">
                    check_circle
                    </span>
                   <img height="100" src="assets/images/meta-targeting/Woman.svg" width="100">
                 </div>

                 <div (click)="genderSelect(null,true)" class="sp-attributes-male" id="sp-attributes-male">
                   <span [hidden]="!maleSelected" class="material-icons sp-attributes-male-checked">
                    check_circle
                    </span>
                   <img height="100" src="assets/images/meta-targeting/Man.svg" width="100">
                 </div>
               </div>
               <div class=" sp-attributes-right-side">
                 <div class="sp-attributes-age-range">
                   <h6>Range: {{rangeValues[0] + ' - ' + rangeValues[1]}}</h6>
                   <p-slider (ngModelChange)="ageSliderChange.next(rangeValues)" [(ngModel)]="rangeValues" [max]="65" [min]="18" [range]="true"></p-slider>
                 </div>
                 <div class="sp-attributes-localization">
                   <mat-form-field appearance="outline" class="sp-attributes-localization-input w-100  sp-form-field" >
                     <mat-label  class="sp-input-label">Localization</mat-label>
                     <mat-chip-grid #chipListLocalization aria-label="Add a localization" class="sp-test">
                       <mat-chip-row
                         *ngFor="let location of includedGeolocationGroups; let idx=index">
                         <span class="sp-input-label">{{location.name}}</span>
                         <span (click)="onGeolocationGroupDeleted(location)" class="material-icons" matChipRemove>
                          close
                        </span>
                       </mat-chip-row>
                       <mat-chip-row
                         *ngFor="let location of includedGeolocations; let idx=index">
                         {{ geolocToString(location) }}
                         <span (click)="onGeolocationDeleted(location)" class="material-icons" matChipRemove>
                          close
                        </span>
                       </mat-chip-row>

                     </mat-chip-grid>
                     <input
                       #geolocInput
                       (keyup)="onGeolocationInputChange(geolocInput.value, $event)"
                       [matAutocomplete]="geolocAuto"
                       [matChipInputFor]="chipListLocalization"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       placeholder="Type one or several locations...">
                     <mat-autocomplete #geolocAuto="matAutocomplete" (optionSelected)="onGeolocationSelected($event.option.value, TargetingType.INCLUSION); geolocInput.value = ''">
                       <mat-option *ngFor="let geolocation of geolocations" [value]="geolocation">{{geolocation ? geolocToString(geolocation) : "Loading..."}}</mat-option>
                     </mat-autocomplete>
                   </mat-form-field>

                 </div>
               </div>
           </mat-card-content>
         </mat-card>
     </div>
     <div class="sp-meta-targeting-second-line">
        <mat-card appearance="outlined" class="sp-media-parent" id="sp-media">
          <div>
            <mat-card-title class="sp-social-media-title">Select the social media you want to target</mat-card-title>
          </div>
          <mat-card-content class="sp-social-media-to-target">
            <div class="row">
              <div class="sp-top-side">
                <div (click)="socialMediaSelect(true,null)" class=" sp-facebook" id="sp-facebook">
                  <span [hidden]="!facebookSelected" class="material-icons sp-facebook-checked">
                    check_circle
                    </span>
                  <img height="100" src="assets/images/meta-targeting/facebook.svg" width="100">
                </div>
                <div (click)="socialMediaSelect(null,true)" class="sp-instagram" id="sp-instagram">
                  <span [hidden]="!instagramSelected" class="material-icons sp-instagram-checked">check_circle</span>

                  <img height="70" src="assets/images/meta-targeting/instagram.svg" width="70">
                </div>
              </div>
              <div class="sp-down-side">
                <p class="sp-targeting-paragraph">
                  Your audience has an estimated reach on
                  <span *ngIf="facebookSelected" class="sp-targeting-chose">
                     Facebook
                   </span>
                  <span *ngIf="instagramSelected && facebookSelected">
                     and
                   </span>
                  <span *ngIf="instagramSelected" class="sp-targeting-chose">
                     Instagram
                   </span>
                  of  </p>
                <p>
                  <span class="sp-reach-size">{{ currentAudience.fb_size.toLocaleString("en-EN") }}</span>&nbsp;
                  <span class="sp-reach-size-people">people</span>
                </p>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="sp-interest-parent" id="sp-interest" >
          <div>
            <mat-card-title class="sp-interest-title">Interests of your audience</mat-card-title>
          </div>

          <mat-card-content class="sp-interest">
            <div class="sp-interest-list">
              <mat-form-field appearance="outline" class="interest-chip-list" id="sp-test-input">
                <mat-chip-grid #chipList aria-label="Add a criteria">
                  <mat-chip-row
                    (removed)="onLocaleDeleted(locale)"
                    *ngFor="let locale of includedLocales">
                    (Locale) {{locale.name}}
                    <span class="material-icons" matChipRemove>
                      close
                    </span>
                  </mat-chip-row>
                  <mat-chip-row
                    (removed)="onCriteriaDeleted(includedCriteriaFields[0], interest, TargetingType.INCLUSION)"
                    *ngFor="let interest of includedCriterion.get(includedCriteriaFields[0])">
                    {{interest.name}}
                    <span class="material-icons" matChipRemove>
                      close
                    </span>
                  </mat-chip-row>
                  <input
                    #interestDefaultInput
                    (input)="onCriteriaInputChange(interestDefaultInput.value, true)"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    placeholder="Add a criteria...">
                </mat-chip-grid>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCriteriaSelected($event.option.value, includedCriteriaFields[0], TargetingType.INCLUSION, $event.option.value.key !== undefined); interestDefaultInput.value = ''">
                  <mat-option *ngFor="let locale of locales" [disabled]="!locale" [value]="locale">{{ locale ? ('(Locale) ' + locale.name) : (locale === null ? "Searching..." : 'No locales found')}}</mat-option>
                  <mat-option *ngFor="let criteria of fbCriteriaList" [disabled]="!criteria" [value]="criteria">{{criteria ? criteria.name : (criteria === null ? "Searching..." : 'No interests found')}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div class="sp-criteria-button">
                <button (click)="addExcludedCriterionField()" *ngIf="!hideExclusion" class="btn btn-primary rounded-circle sp-btn sp-btn-circle sp-exclude-button" title="Exclude interest" type="button">
                  <span class="sp-icon">
                    <i class="icon-sp-minus"></i>
                  </span>
                </button>
                <button (click)="addIncludedCriterionField()" class="btn btn-primary rounded-circle sp-btn sp-btn-circle sp-include-button ml-2" title="Add interest" type="button">
                  <span class="sp-icon">
                    <i class="icon-sp-plus"></i>
                  </span>
                </button>
              </div>
            </div>
            <div style="height: 16rem;overflow-y: scroll">
              <div *ngIf="includedCriteriaFields.slice(1).length > 0" >
                <div *ngFor="let field of includedCriteriaFields.slice(1);let idx = index" class="sp-interest-list-include-interest">

                  <div class="sp-plus">
                    <div class="sp-plus-plus"></div>
                    <button class="btn btn-primary rounded-circle sp-btn sp-btn-circle sp-include-button-visual ml-2" style="" title="Add interest" type="button">
                      <span class="sp-icon">
                        <i class="icon-sp-plus"></i>
                      </span>
                    </button>
                  </div>

                  <mat-form-field appearance="outline" class="interest-chip-list ">
                    <mat-chip-grid #chipList aria-label="Add a criteria">
                      <mat-chip-row
                        (removed)="onCriteriaDeleted(field, interest, TargetingType.INCLUSION)"
                        *ngFor="let interest of includedCriterion.get(field);let i = index">
                        {{interest.name}}
                        <span class="material-icons" matChipRemove>
                      close
                     </span>
                      </mat-chip-row>
                      <input
                        #interestIncludedInput
                        (input)="onCriteriaInputChange(interestIncludedInput.value)"
                        [(ngModel)]="field.criteria"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        placeholder="Add a criteria...">
                    </mat-chip-grid>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCriteriaSelected($event.option.value, field, TargetingType.INCLUSION); interestIncludedInput.value = ''">
                      <mat-option *ngFor="let criteria of fbCriteriaList" [disabled]="!criteria" [value]="criteria">{{criteria ? criteria.name : (criteria === null ? "Searching..." : 'No interests found')}}</mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <div (click)="removeIncludeCriteriaField(field)" class="sp-interest-remove">
                   <span class="sp-interest-remove-icon material-icons">
                     delete_forever
                   </span>
                  </div>
                </div>
              </div>
              <div *ngIf="excludedCriteriaField">
                <div *ngIf="hideExclusion" class="sp-interest-list-exclude-interest">
                  <div class="sp-plus">
                    <div class="sp-plus-plus"></div>
                    <button class="btn btn-primary rounded-circle sp-btn sp-btn-circle sp-exclude-button-visual" style="" title="Exclude interest" type="button">
                      <span class="sp-icon">
                        <i class="icon-sp-minus"></i>
                      </span>
                    </button>
                  </div>

                  <mat-form-field appearance="outline" class="interest-chip-list ">
                    <mat-chip-grid #chipList aria-label="Add a criteria">
                      <mat-chip-row
                        (removed)="onCriteriaDeleted(excludedCriteriaField, interest, TargetingType.EXCLUSION)"
                        *ngFor="let interest of excludedCriterion">
                        {{interest.name}}
                        <span class="material-icons" matChipRemove>
                        close
                       </span>
                      </mat-chip-row>
                      <input
                        #interestExcludedInput
                        (input)="onCriteriaInputChange(interestExcludedInput.value)"
                        [(ngModel)]="excludedCriteriaField.criteria"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        placeholder="Add a criteria...">
                    </mat-chip-grid>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCriteriaSelected($event.option.value, excludedCriteriaField, TargetingType.EXCLUSION); interestExcludedInput.value = ''">
                      <mat-option *ngFor="let criteria of fbCriteriaList" [disabled]="!criteria" [value]="criteria">{{criteria ? criteria.name : (criteria === null ? "Searching..." : 'No interests found')}}</mat-option>
                    </mat-autocomplete>
                  </mat-form-field>

                  <div (click)="toggleCriteriaExclusion()" class="sp-interest-remove">
                     <span class="sp-interest-remove-icon material-icons">
                       delete_forever
                     </span>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>

        </mat-card>
     </div>
   </div>

</ng-container>
