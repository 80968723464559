import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiModelService} from "../sp-api-model/api-model.service";
import {Universe} from "../../../models/universe";
import {Criteria} from '../../../models/criteria';
import {Tag} from '../../../models/tag';
import {LoggerService} from '../../sp-logger/logger.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiUniverseService extends ApiModelService<Universe> {
  protected readonly basePath = "universes";

  constructor(httpClient: HttpClient, logger: LoggerService) {
    super(httpClient, logger);
  }

  /* Other methods with relations go here */

  getSegmentUniverses() {
    return firstValueFrom(this.get<Universe[]>(this.basePath, {'type': 'segments'}));
  }

  getTopicUniverses() {
    return firstValueFrom(this.get<Universe[]>(this.basePath, {'type': 'topics'}));
  }

  updateTopicUniverse(universe: Universe, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return firstValueFrom(this.post<Universe>(this.basePath + '/' + universe.id + '/topic', formData))
  }

  getPoliticalUniverses() {
    return firstValueFrom(this.get<Universe[]>(this.basePath, {'type': 'political'}));
  }

  updatePoliticalUniverse(universe: Universe, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return firstValueFrom(this.post<Universe>(this.basePath + '/' + universe.id + '/political', formData))
  }

  getCriterionFromUniverse(universe: Universe) {
    return firstValueFrom(this.get<Criteria[]>(this.basePath + "/" + universe.id + "/criterion"));
  }

  getTagsFromUniverse(universe: Universe, getBasicData: boolean = false) {
    return firstValueFrom(this.get<Tag[]>(this.basePath + "/" + universe.id + "/tags", {'min': getBasicData}));
  }

  /* ------------------------------------ */
}
