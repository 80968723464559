import {Model} from "./model";

export class AdAccount implements Model {
  id: number;
  user_id: number;
  name: string;
  token: string;
  facebook_id: string;
  facebook_account_id: number;
  selected: boolean;
  created_at: Date;
  updated_at: Date;

  constructor(name: string, token: string, facebook_id: string, facebook_account_id: number) {
    this.name = name;
    this.token = token;
    this.facebook_id = facebook_id;
    this.facebook_account_id = facebook_account_id;
  }
}
