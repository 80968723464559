import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from '../../services/sp-authentication/authentication.service';
import {RoleUtils} from "../../utils/role/role-utils";
import {by} from "protractor";

@Injectable({
  providedIn: 'root'
})
export class AccountGuard implements CanActivate {
  constructor(private auth: AuthenticationService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean | UrlTree>(async resolve => {
      const data = next.data;
      const freemiumAccess = data.freemiumAccess || false;
      const trialAccess = data.trialAccess || false;
      if(this.auth.session.initialized) {
        const hasAccounts = this.auth.session.user.ad_accounts.length > 0;
        const trial = this.auth.session.user.role.level === RoleUtils.trialLevel;
        const freemium = this.auth.session.user.role.level <= RoleUtils.freemiumLevel;
        const bypass = await this.auth.permissionCheck('adaccount.bypass');
        hasAccounts || (freemiumAccess && freemium) || (trial && trialAccess) || bypass ? resolve(true) : resolve(this.router.navigate(['/account/edit']));//resolve(this.router.parseUrl('/page-access-denied'));
      } else {
        const sub = this.auth.sessionListener.subscribe(async session => {
          if (session) {
            const hasAccounts = session.user.ad_accounts.length > 0;
            const trial = session.user.role.level === RoleUtils.trialLevel;
            const freemium = session.user.role.level <= RoleUtils.freemiumLevel;
            const bypass = await this.auth.permissionCheck('adaccount.bypass');
            hasAccounts || (freemiumAccess && freemium) || (trial && trialAccess) || bypass ? resolve(true) : resolve(this.router.navigate(['/account/edit']));//resolve(this.router.parseUrl('/page-access-denied'));
            sub.unsubscribe();
          }
        });
      }
    });
  }

}
