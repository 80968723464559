export abstract class MapUtils {
  public static mapToJson(map: Map<string, any>|number): any {
    if (typeof map === "number") {
      return map;
    } else {
      const json: any = {};
      map.forEach((val, key) => {
        json[key] = val;
      });

      return json;
    }
  }

  public static jsonToMap<T>(json: any): Map<string, T> {
    const map: Map<string, T> = new Map();

    for (let value in json) {
      if (json.hasOwnProperty(value)) {
        map.set(value, json[value]);
      }
    }

    return map;
  }

  public static whatThis(object) {
    const str = "a".constructor;
    const arr = [].constructor;
    const obj = ({}).constructor;

    if (object === null) return "null";
    if (object === undefined) return "undefined";
    if (object.constructor === str) return "string";
    if (object.constructor === arr) return "array";
    if (object.constructor === obj) return "object";

    return "unknown";
  }
}
