import {environment} from '../../../environments/environment';

export class Logger {
  private static readonly prefixMap = new Map([
    ['info', 'INFO:'],
    ['warn', 'WARNING:'],
    ['error', 'ERROR:'],
    ['debug', 'DEBUG:']
  ]);

  private static log(message: any, type: string, priority: number = 1): string {
    if (environment.config && environment.config.enableLogging && priority >= environment.config.showLogMinPriority) {
      const prefix = this.prefixMap.has(type) ? this.prefixMap.get(type) : type;
      const date = new Date();

      message = JSON.stringify(message);

      const logMessage = "(" + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + ") " + prefix + " " + message

      switch (type) {
        case "error": console.error(logMessage); break;
        case "warn": console.warn(logMessage); break;
        default: console.log(logMessage);
      }

      return logMessage;
    }

    return '';
  }

  public static logInfo(message: any, priority: number = 1) {
    return this.log(message, 'info', priority);
  }

  public static logWarning(message: any, priority: number = 1) {
    return this.log(message, 'warn', priority);
  }

  public static logError(message: any, priority: number = 1) {
    return this.log(message, 'error', priority);
  }

  public static logDebug(...message: any[]) {
    const messages = message.reduce((a, b) => a + " " + b);
    return this.log(messages, 'debug', 1);
  }
}
