import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ApiUserService} from "../../../../../services/sp-api/sp-api-user/api-user.service";
import {ApiRoleService} from "../../../../../services/sp-api/sp-api-role/api-role.service";
import {User} from "../../../../../models/user";
import {Role} from "../../../../../models/role";
import {Company} from "../../../../../models/company";
import {ApiCompanyService} from "../../../../../services/sp-api/sp-api-company/api-company.service";
import {AuthenticationService} from "../../../../../services/sp-authentication/authentication.service";
import {RoleUtils} from '../../../../../utils/role/role-utils';
import {environment} from "../../../../../../environments/environment";
import {AlertService} from '../../../../../services/sp-alert/alert.service';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-sp-modal-company-add-users',
  templateUrl: './sp-modal-company-add-users.component.html',
  styleUrls: ['./sp-modal-company-add-users.component.scss']
})
export class SpModalCompanyAddUsersComponent implements OnInit {
  env = environment;

  selectedUser: User = null;
  selectedRole: Role = null;
  searchUniverse = '';
  users: Array<User> = [];
  roles: Array<Role> = [];
  company: Company = null;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<User[]>;
  constructor(
    public dialogRef: MatDialogRef<SpModalCompanyAddUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { company: Company },
    private apiUser: ApiUserService,
    private apiRole: ApiRoleService,
    private apiCompany: ApiCompanyService,
    public auth: AuthenticationService,
    private alert: AlertService
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  ngOnInit() {
    this.company = this.data.company;
    this.apiUser.getAll().then((users: User[]) => {
      this.users = users;
      this.apiRole.getAll().then((roles: Role[]) => {
        this.roles = roles.filter(r => r.level <= this.auth.session.user.role.level);
        if (this.company?.subscription?.payment_frequency == 'Trial') this.selectedRole = this.roles.find(r => r.key == RoleUtils.trialKey) || null;
      });
    });

  }
  resetFilter() {
    this.searchUniverse = '';
}

  addUser() {
    if(this.selectedUser !== null) {
      this.apiCompany.addUser(this.company, this.selectedUser, this.selectedRole)
        .then(company => this.dialogRef.close(company))
        .catch(error => {
          if (environment.config.showErrorModal) this.alert.notify("Error", error.error.message, "error");
          this.dialogRef.close(null);
        })
    }
  }
}
