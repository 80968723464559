<div class="w-100 container">
  <div class="row d-flex no-gutters">
    <div class="col-8">
      <h3>{{ editMode ? "Edit company" : "New company" }}</h3>
    </div>
    <div *ngIf="canManageCompany" class="col-4 text-right">
      <button type="button" class="btn btn-secondary sp-btn sp-btn-secondary" [routerLink]="backUrl" routerLinkActive="active" matTooltip="Back to company list" matTooltipHideDelay="10">
        <span>Cancel</span>
      </button>
      <button (click)="submitFormButton.click()" class="ml-3 btn btn-primary sp-btn sp-btn-primary" [title]="createdCompany === null ? 'Create company' : 'Update ' + createdCompany.name">
        <span>{{ createdCompany === null ? 'Create' : 'Update' }}</span>
      </button>
      <button (click)="companySubscriptionBlock()" class="ml-3 btn btn-primary sp-btn sp-btn-primary" title="Block company">
        <span>Block</span>
      </button>
    </div>
  </div>

  <div class="mt-3 sp-card">
    <div class="card">
      <div class="card-header">
        <h5> Company details </h5>
      </div>
      <div class="card-body">
        <form class="company-management-area" (ngSubmit)="createdCompany === null ? companyCreate() : companyUpdate()" [formGroup]="companyFormGroup" style="">
          <button type="submit" hidden #submitFormButton></button>
          <div style="max-width: 10rem" class="d-flex align-self-center justify-content-center">
            <input [disabled]="!isCompanyAdmin && !canManageCompany" (change)="onImageChange(imagePicker)" #imagePicker type="file" accept="image/jpeg,image/gif,image/png" [hidden]="true"/>
            <img *ngIf="selectedImage !== null" (click)="imagePicker.click()" class="sp-avatar sp-avatar-xl" [src]="selectedImage">
            <span *ngIf="selectedImage == null" (click)="imagePicker.click()" class="avatar-rounded d-flex align-items-center justify-content-center">
              <span class="avatar-company-name">
                {{ this.companyFormGroup.get('name').valid ? this.companyFormGroup.get('name').value.substr(0, 2) : 'CN' }}
              </span>
            </span>
          </div>
          <div class="align-self-center">
            <mat-form-field class="w-100 sp-form-field" appearance="outline">
              <mat-label class="sp-input-label">Company Name</mat-label>
              <input class="sp-input-content" formControlName="name" matInput placeholder="Enter a company name..."/>
              <mat-error *ngIf="companyFormGroup.get('name').hasError('required')">
                Please specify a <strong>company name</strong>
              </mat-error>
              <mat-error *ngIf="companyFormGroup.get('name').hasError('maxlength')">
                 Your <strong>company name</strong> has more than 50 characters
              </mat-error>
            </mat-form-field>
          </div>
          <div class="align-self-center">
            <mat-form-field class="w-100 sp-form-field" appearance="outline">
              <mat-label class="sp-input-label">Payment Recurrency</mat-label>
              <mat-select (selectionChange)="onPaymentInfoChange($event.value)" formControlName="paymentFrequency" disableOptionCentering panelClass="sp-select-option-icon" class="sp-input-content" placeholder="Select a payment Type...">
                <mat-option *ngFor="let opt of paymentOptions" [value]="opt.value" >
                  <div style="display: flex;justify-content: space-between">
                    <span>{{ opt.value }} </span>
                    <span>{{opt.label}}</span>
                  </div>

                </mat-option>
              </mat-select>
              <mat-error *ngIf="companyFormGroup.get('paymentFrequency').hasError('required')">
               You need to select a <strong>Payment Frequency</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="isCompanyAdmin" class="align-self-center">
            <mat-form-field appearance="outline" class="w-100 sp-form-field">
              <mat-label class="sp-input-label">Audiense id</mat-label>
              <input class="sp-input-content" formControlName="audienseId" matInput placeholder="Enter an Audiense id..." type="text"/>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="pt-4 sp-card">
    <div class="card">
      <div class="card-header">
        <h5> Monthly plan details </h5>
      </div>
      <div class="pl-4 pr-4 card-body">
        <div class="row no-gutters">
          <div class="col-4 pr-5 align-self-center">
            <mat-form-field style="width: 90%; margin-right: 5px" class="sp-form-field" appearance="outline">
              <mat-label class="sp-input-label">Select Plan</mat-label>
              <mat-select (selectionChange)="onPlanSelectChange()" [(ngModel)]="selectedPlan" [disabled]="!isCompanyAdmin" class="sp-input-content" disableOptionCentering panelClass="sp-select-option-icon" placeholder="Custom plan">
                <mat-option [value]="customPlan"> Custom plan </mat-option>
                <mat-option *ngFor="let plan of plans" [value]="plan"> {{plan.name}} </mat-option>
              </mat-select>
            </mat-form-field>
            <span class="sp-information-tooltip" [matTooltip]="permissionsToString()" matTooltipHideDelay="10">
              <span>i</span>
            </span>
          </div>

          <div class="col-4 pr-5 align-self-center">
            <mat-form-field [ngClass]="{'sp-datepicker': !isCompanyAdmin }" appearance="outline" class="w-100 sp-form-field" >
              <mat-label class="sp-input-label">Subscription Start on</mat-label>
              <input class="sp-datepicker-content" [(ngModel)]="subscriptionStartDate" [disabled]="!isCompanyAdmin" readonly matInput (focus)="picker.open()" (click)="picker.open()" [matDatepicker]="picker" placeholder="Pick a date">
              <mat-datepicker #picker [disabled]="!isCompanyAdmin" startView="year"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field [ngClass]="{'sp-datepicker': !isCompanyAdmin }" appearance="outline" class="w-100 sp-form-field" >
              <mat-label class="sp-input-label">Subscription Stop on</mat-label>
              <input (click)="picker2.open()" (focus)="picker2.open()" [disabled]="!isCompanyAdmin" [(ngModel)]="subscriptionEndDate" [matDatepicker]="picker2" [min]="subscriptionStartDate" class="sp-datepicker-content" matInput placeholder="Pick a date" readonly>
              <mat-datepicker #picker2 [disabled]="!isCompanyAdmin" startView="year"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row d-flex no-gutters">
          <div class="col-4">
            <h6>Monthly Credits <span *ngIf="createdCompany && createdCompany.subscription" style="font-size: smaller;color: dimgrey">{{ createdCompany.subscription.credits }} remaining</span></h6>
            <div class="sp-form-field sp-increment-decrement sp-credits">
              <button *ngIf="isUserAdmin" mat-button class="sp-btn sp-button-decrement" (click)="modifyMonthlyCredits(-1)"><i class="icon-sp-minus"></i></button>
              <mat-form-field>
                <input [disabled]="!isUserAdmin" class="w-100 sp-input-increment-value" id="monthlyCredits" type="number" [(ngModel)]="monthlyCredits" matInput>
              </mat-form-field>
              <button *ngIf="isUserAdmin" mat-button class="sp-btn sp-button-increment" (click)="modifyMonthlyCredits(1)"><i class="icon-sp-plus"></i></button>
            </div>
          </div>
          <div class="col-4">
            <h6>Number of users <span *ngIf="createdCompany && createdCompany.subscription" style="font-size: smaller;color: dimgrey">{{ createdCompany.subscription.users - createdCompany.users.length }} remaining</span></h6>
            <div class="sp-form-field sp-increment-decrement sp-credits">
              <button *ngIf="isUserAdmin" mat-button class="sp-btn sp-button-decrement" (click)="modifyUsers(-1)"><i class="icon-sp-minus"></i></button>
              <mat-form-field>
                <input [disabled]="!isUserAdmin" class="w-100 sp-input-increment-value" id="nbrUsers" type="number" [(ngModel)]="nbrUsers" matInput>
              </mat-form-field>
              <button *ngIf="isUserAdmin" mat-button class="sp-btn sp-button-increment" (click)="modifyUsers(1)"><i class="icon-sp-plus"></i></button>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex">
              <h6>Extra Credits</h6> <div class="icon-sp-info" matTooltip="Add or remove extra analysis available undefinitely" matTooltipHideDelay="10"></div>
            </div>
            <div class="sp-form-field sp-increment-decrement sp-credits">
              <button *ngIf="isUserAdmin" mat-button class="sp-btn sp-button-decrement" (click)="modifyExtraCredits(-1)"><i class="icon-sp-minus"></i></button>
              <mat-form-field>
                <input [disabled]="!isUserAdmin" class="w-100 sp-input-increment-value" id="extraCredits" type="number" [(ngModel)]="extraCredits" matInput>
              </mat-form-field>
              <button *ngIf="isUserAdmin" mat-button class="sp-btn sp-button-increment" (click)="modifyExtraCredits(1)"><i class="icon-sp-plus"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 row d-flex justify-content-center sp-notification-div">
    <p-messages [(value)]="msgs" class="w-50 sp-notification"></p-messages>
  </div>
  <div class="mt-5 sp-table-div" *ngIf="createdCompany !== null">
    <div class="row d-flex no-gutters sp-table-header">
      <div class="col-5 align-self-center">
        <mat-form-field appearance="outline" class="w-100 ml-3 mt-3 mb-1 sp-form-field">
          <mat-label class="sp-input-label">Search</mat-label>
          <mat-icon class="sp-input-search-icon"  matPrefix svgIcon="icon_sp_search"></mat-icon>
          <input (keyup)="applyFilter($event)" [ngModel]="userSearchText" class=" sp-input-search-content " matInput placeholder="Search a company members...">
          <button mat-button *ngIf="userSearchText" matSuffix mat-icon-button aria-label="Clear" (click)="userSearchText=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-2 ml-3 mt-4 mb-1 align-self-center">
        <p class="pl-3 sp-table-number">
          {{ createdCompany !== null ? createdCompany.users.length : 0}} company members
        </p>
      </div>

      <div *ngIf="canManageUser" class="col-5 text-right">
        <button type="button" class="btn btn-primary rounded-circle sp-btn sp-btn-xl sp-btn-primary sp-btn-circle sp-out" matTooltip="Add a new user" matTooltipHideDelay="10">
        <span class="sp-icon">
          <i class="icon-sp-users-add-button" routerLink="/admin/users/create" [queryParams]="{'from': 'company', 'company': this.createdCompany.id}"> </i>
        </span>
        </button>
      </div>
    </div>

    <table mat-table [dataSource]="userDataSource" class="mat-elevation-z8 sp-table" matSort>

      <!-- Position Column -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="goToUserUpdate(element)">
          <img class="sp-avatar sp-avatar-md" *ngIf="element.image !== null" [src]="element.image !== null ? env.config.api.storageUrl + element.image : env.config.noPpImage">
          <span *ngIf="element.image == null" class="avatar-rounded">
            <span class="avatar-user-name">{{element.first_name.substr(0,1)}}{{element.last_name.substr(0,1)}}</span>
          </span> </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element" (click)="goToUserUpdate(element)"> {{ element.first_name + " " + element.last_name }} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element" (click)="goToUserUpdate(element)"> {{ element.email }} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> User Type </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field class="w-50 sp-form-field">
            <mat-select [disabled]="!canManageUser || element.id == auth.session.user.id || isRoleDisabled(element, element.role)" [value]="element.role.id" (selectionChange)="onRoleSelectionChange(element, $event.value)">
              <mat-option *ngFor="let role of roles" [disabled]="isRoleDisabled(element, role)" [value]="role.id"> {{ role.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">
          <i class="icon-sp-edit-white" (click)="goToUserUpdate(element)"> </i>
          <i class="icon-sp-delete" (click)="openDeleteDialog(element)"> </i>
          <i *ngIf="!element.activated && !invitationSent.includes(element.id) && !invitationPending.includes(element.id)" class="icon-sp-email" (click)="sendInvitation(element)"> </i>
          <i *ngIf="invitationPending.includes(element.id)" class="icon-sp-update"></i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="userListDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: userListDisplayedColumns;"></tr>
    </table>
    <div class="mb-3 sp-table-footer" style="min-height: 5vh">
      <button *ngIf="isCompanyAdmin" type="button" class="btn btn-primary m-3 sp-btn sp-btn-sm sp-btn-primary" (click)="openAddDialog()" matTooltip="Add an existing user" matTooltipHideDelay="10">
        <span>Add an existing user</span>
      </button>
    </div>
  </div>

  <mat-paginator class="sp-paginator" [ngStyle]="{'display': userDataSource.data.length > 10  ? 'block' :  'none'}" [hidden]="isNotCreated" [pageSize]="10" hidePageSize showFirstLastButtons></mat-paginator>

  <ng-container *ngIf="isCompanyAdmin">
    <hr>
    <h3 class="mb-4">Agency Customers Management</h3>
    <ng-container *ngIf="allCompanies.length > 0">
      <div class="d-flex mb-5" style="gap: 20px">
        <mat-form-field appearance="outline" class="sp-form-field d-flex w-25">
          <mat-label class="sp-input-label">Search company</mat-label>
          <mat-icon class="sp-input-search-icon"  matPrefix svgIcon="icon_sp_search"></mat-icon>
          <input #companySearchInput [(ngModel)]="selectedCompany" (input)="onSearchCompanyChange(companySearchInput.value)" [matAutocomplete]="companyAutoComplete" class="sp-input-search-content " matInput>
          <mat-autocomplete #companyAutoComplete [displayWith]="companyToString">
            <mat-option [value]="undefined">No choice</mat-option>
            <mat-option *ngFor="let company of allCompaniesFiltered" [value]="company">
              {{ '[' + company.id + '] ' + company.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button [disabled]="!selectedCompany" class="btn btn-primary sp-btn sp-btn-primary" (click)="addCompanyToAgency(this.selectedCompany)">Add company to agency</button>
      </div>
      <table mat-table [dataSource]="companyDataSource" class="mat-elevation-z8 sp-table">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> Company ID </th>
          <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Company Name </th>
          <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element">
            <i class="icon-sp-delete" (click)="removeCompanyFromAgency(element)"> </i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="companyManagedDisplayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: companyManagedDisplayColumns;"></tr>
      </table>
      <mat-paginator class="sp-paginator" [ngStyle]="{'display': companyDataSource.data.length > 10  ? 'block' :  'none'}" [pageSize]="10" hidePageSize showFirstLastButtons></mat-paginator>
    </ng-container>
    <ng-container *ngIf="allCompanies.length == 0">
      Loading companies...
    </ng-container>
  </ng-container>
</div>
