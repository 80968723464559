import {Model} from "./model";
import {Crawl} from "./crawl";
import {Universe} from './universe';
import {CrawlStatus} from './crawl-status';
import {Targeting} from "../classes/targeting/targeting";
import {NamedItem} from '../pipes/array-name-join.pipe';
import {AudiencePreset} from './audience-preset';
import {LookalikeSpec} from '../types/facebook-types';

export class Audience implements Model, NamedItem {
  id: number;
  user_id: number;
  name: string;
  partition: string;
  locale: string;
  target_spec: string; // Source json targeting data
  targeting?: Targeting; // Parsed targeting into a typed class: Custom attribute, used for targeting building and retrieving
  type: string;
  universes: Array<Universe>;
  audiences_attached: Array<Audience>;
  benchmark?: Audience;
  latest_crawl?: Crawl;
  data_extra?: string;
  linked_audience_id: number;
  map_version: number;
  created_at: Date;
  updated_at: Date;
  default_segmentation_universe?: number;
  fb_score?: number; // Custom, view only attribute
  fb_score_v2?: string; // Custom, view only attribute
  fb_size?: number; // Custom attribute, use latest_crawl for crawled size
  fb_size_lower?: number; // Custom attribute, use latest_crawl for crawled size
  fb_size_upper?: number; // Custom attribute, use latest_crawl for crawled size
  status?: CrawlStatus; // Custom attribute, used for crawl tracker service
  imported?: boolean = false; // Custom attribute, used to know if this audience was created or imported
  from_preset?: boolean = false; // Custom attribute, used to know if the audience is from a preset or not
  lookalike_spec?: LookalikeSpec; // Custom attribute, Source json lookalike data

  get isCustomAndLookalikeSpecHasGeolocation(): boolean {
    return this.type === 'custom' && (!!this.lookalike_spec?.target_countries || !!this.lookalike_spec?.country);
  }

  constructor(name?: string, partition?: string, locale?: string, type?: string) {
    this.name = name;
    this.partition = partition;
    this.locale = locale;
    this.type = type;
  }

  static fromPreset(preset: AudiencePreset) {
    const audience = new Audience(preset.name, undefined, 'en_US', 'sociodemo');
    audience.target_spec = preset.target_spec;
    audience.from_preset = true;

    return audience;
  }
}
