import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from '../../../services/sp-alert/alert.service';
import {NotifierService} from 'angular-notifier';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-sp-layout-dashboard-default',
  templateUrl: './sp-layout-dashboard-default.component.html',
  styleUrls: ['./sp-layout-dashboard-default.component.scss']
})
export class SpLayoutDashboardDefaultComponent { }
