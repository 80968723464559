import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Project} from '../../models/project';
import {from, Subject} from 'rxjs';
import {ProjectManagerService, ProjectSortType} from '../sp-project/project-manager.service';
import {environment} from '../../../environments/environment';
import {LoaderService} from '../sp-loading/loader.service';
import {DataType} from '../../classes/data/stat-handler';
import {AlertService} from '../sp-alert/alert.service';
import {AuthenticationService} from '../sp-authentication/authentication.service';
import {LoggerService} from '../sp-logger/logger.service';
import {Dashboard, DashboardArea} from '../../utils/dashboard/dashboard';
import {ApiNotificationService} from '../sp-api/sp-api-notification/api-notification.service';
import {Notification} from '../../models/notification';
import {DebugService} from "../sp-debug/sp-debug.service";
import {RoleUtils} from "../../utils/role/role-utils";
import {SocketService} from '../sp-ws/socket.service';

export class HeaderOption {
  readonly key: string;
  readonly url: string;
  readonly viewValue: string;
  readonly disabled: boolean;
  readonly queryParams?: any;

  constructor(key: string, url: string, viewValue: string, disabled: boolean = false, queryParams?: any) {
    this.key = key;
    this.url = url;
    this.viewValue = viewValue;
    this.disabled = disabled;
    this.queryParams = queryParams
  }
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private lastBrowsedUrl = "";
  private _currentDashboardArea: DashboardArea;

  private _dataTypeSwitchEnabled: boolean = false;
  private _criteriaFilterEnabled: boolean = false;
  private _tagFilterEnabled: boolean = false;
  private _personaFilterEnabled: boolean = false;
  private _calendarEnabled: boolean = false;
  private _projectListEnabled: boolean = false;
  private _projectNavEnabled: boolean = false;
  private _adminNavEnabled: boolean = false;
  private _adBlockDetected: boolean = false;

  private _headerLinkOptions: HeaderOption[] = [];

  private readonly autoBehaviorDetect: boolean = true;

  dontRefreshNext: boolean = false;
  headerLinkSelected: string;

  readonly projectChangeListener: Subject<Project> = new Subject<Project>();
  readonly reportingChangeListener: Subject<Dashboard> = new Subject<Dashboard>();
  readonly notificationTrigger: Subject<Notification> = new Subject<Notification>();
  readonly blockedUserMessageTrigger: Subject<string> = new Subject<string>();
  private readonly _dataTypeChangeListener: Subject<DataType> = new Subject<DataType>();

  dashboardListSearchListener: Subject<string> = new Subject<string>();
  dashboardListSortListener: Subject<ProjectSortType> = new Subject<ProjectSortType>();

  reportingMenuTools: Subject<string> = new Subject<string>();
  reportingEditable: Subject<boolean> = new Subject<boolean>();

  private _currentDataType = DataType.NORMALIZED;

  private _lastNotification: Notification;
  private _lastNotificationFail: boolean = false;

  constructor(
    loader: LoaderService,
    router: Router,
    private projectManager: ProjectManagerService,
    private alert: AlertService,
    private auth: AuthenticationService,
    private logger: LoggerService,
    private apiNotification: ApiNotificationService,
    private debug: DebugService,
    private socket: SocketService
  ) {
    // To detect navigation after refresh or accessing dashboard for the first time
    if (this.autoBehaviorDetect) this.autoBehavior(window.location.pathname)
    router.events.subscribe(value => {
      if (value instanceof NavigationStart) {
        loader.load();
      }
      if (value instanceof NavigationEnd) {
        this.debug.setDebugOutput('');
        if (loader.loading) loader.loaded();
        this._adBlockDetected = false;
        if (!this.dontRefreshNext) {
          // Avoid disabling without enabling back required headers and navs
          if (this.lastBrowsedUrl !== value.urlAfterRedirects) {
            this.disableAll();
          }
        }
        else this.dontRefreshNext = false;
        if (this.autoBehaviorDetect) this.autoBehavior(value.urlAfterRedirects);
        this.lastBrowsedUrl = value.urlAfterRedirects;
        this.detectArea(value.urlAfterRedirects);
        const anonymizedUrl = value.urlAfterRedirects.split("?")[0].replace(/\d+/g, "x");
        if (auth.session.authenticated) {
          this.socket.sendMessageType('page-visit', {
            uri: anonymizedUrl
          });
        }
      }
    });
    loader.loadGlobal();

    this._dataTypeChangeListener.subscribe(dataType => {
      this._currentDataType = dataType;
    });
  }

  private detectArea(url: string) {
    const anonymizedUrl = url.split("?")[0].replace(/\d+/g, "x");
    this._currentDashboardArea = Dashboard.area.get(anonymizedUrl);
    if (!this._currentDashboardArea) this._currentDashboardArea = DashboardArea.ALL_AREA;
    if (!this._lastNotificationFail) {
      if (!this._lastNotification && this.auth.session.authenticated) {
        this.apiNotification.getLastNotification().then(n => {
          this._lastNotification = n;
          if (this._currentDashboardArea == this._lastNotification.area || this._lastNotification.area == "All area") this.notificationTrigger.next(this._lastNotification);
          else this.notificationTrigger.next(undefined);
        }).catch(() => this._lastNotificationFail = true);
      } else if (this._lastNotification) {
        if (this._currentDashboardArea == this._lastNotification.area || this._lastNotification.area == "All area") this.notificationTrigger.next(this._lastNotification);
        else this.notificationTrigger.next(undefined);
      }
    }
  }

  notifyProjectChange(project: Project) {
    this.projectChangeListener.next(project);
  }
 notifyReportingChange(dash: Dashboard) {
    this.reportingChangeListener.next(dash);
  }

  enableDataTypeSwitch() { this._dataTypeSwitchEnabled = true }
  disableDataTypeSwitch() { this._dataTypeSwitchEnabled = false }

  enableCriteriaFilter() { this._criteriaFilterEnabled = true; }
  disableCriteriaFilter() { this._criteriaFilterEnabled = false; }

  enableTagFilter() { this._tagFilterEnabled = true; }
  disableTagFilter() { this._tagFilterEnabled = false; }

  enablePersonaFilter() { this._personaFilterEnabled = true }
  disablePersonaFilter() { this._personaFilterEnabled = false }

  //enableCalendar() { this._calendarEnabled = true }
  disableCalendar() { this._calendarEnabled = false }

  enableProjectList() { this._projectListEnabled = true }
  disableProjectList() { this._projectListEnabled = false }

  enableProjectNav() { this._projectNavEnabled = true }
  disableProjectNav() { this._projectNavEnabled = false }

  enableAdminNav() { this._adminNavEnabled = true }
  disableAdminNav() { this._adminNavEnabled = false }

  addLinkHeader(key: string, url: string, viewValue: string, selected: boolean = false, disabled: boolean = false, queryParams: any = undefined) {
    if (this._headerLinkOptions.find(h => h.key == key)) {
      this._headerLinkOptions = this._headerLinkOptions.filter(h => h.key !== key);
    }

    this._headerLinkOptions.push(new HeaderOption(key, url, viewValue, disabled, queryParams));

    if (selected) {
      this.headerLinkSelected = key;
    }
  }

  disableAll() {
    this.disableDataTypeSwitch();
    this.disableCalendar();
    this.disableCriteriaFilter();
    this.disableTagFilter();
    this.disableProjectList();
    this.disableProjectNav();
    this.disableAdminNav();
    this.disablePersonaFilter();
    this._headerLinkOptions = [];
  }

  get dataTypeSwitchEnabled(): boolean { return this._dataTypeSwitchEnabled; }
  get criteriaFilterEnabled(): boolean { return this._criteriaFilterEnabled; }
  get tagFilterEnabled(): boolean { return this._tagFilterEnabled; }
  get personaFilterEnabled(): boolean { return this._personaFilterEnabled; }
  get calendarEnabled(): boolean { return this._calendarEnabled; }
  get projectListEnabled(): boolean { return this._projectListEnabled; }
  get projectNavEnabled(): boolean { return this._projectNavEnabled; }
  get adBlockDetected(): boolean { return this._adBlockDetected; }

  get headerLinkOptions(): HeaderOption[] { return this._headerLinkOptions; }
  get dataTypeChangeListener(): Subject<DataType> { return this._dataTypeChangeListener; }

  get currentDataType(): DataType { return this._currentDataType; }

  adBlockDetectionLaunch(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      // Using from and fetch for its no-cors option
      const result = from( // wrap the fetch in a from if you need a rxjs Observable
        fetch(
          "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
          {
            method: 'HEAD',
            mode: 'no-cors'
          }
        )
      );

      result.subscribe(() => {
        this._adBlockDetected = false;
        resolve(false)
      }, () => {
        this._adBlockDetected = true;
        resolve(true)
      });
    });
  }

  /*
   * BEHAVIORS : Rapid access to common behaviors anywhere in the dashboard
   */

  /*
   * Try to know which behavior to enable according to the uri
   * Actually applied to :
   * - Admin navigation
   */
  autoBehavior(uri: string) {
    const uriSegments = uri.split("/");

    if (uriSegments.length == 0) {
      this.logger.logError("Attempt to auto detect navigation behavior on non-uri element : " + uri);
      if (environment.config.showErrorModal) {
        this.alert.notify("Error", "Could not parse uri '" + uri + "'", "error");
      }
    }

    if (uriSegments[0] == "")
      uriSegments.splice(0, 1); // Remove first empty elem

    const base = uriSegments[0];
    switch (base) {
      case "admin": {
        this.behaviorAdmin(uriSegments[1]);
        break;
      }
      case "projects": {
        // No way to easily trigger behaviors : project info missing
        break;
      }
    }
  }

  // Behavior for tag manager part (tag manager and tag analysis)
  behaviorTagManager(project: Project, manager: boolean) {
    //this.addLinkHeader("tag-analysis", this.linkTagAnalysis(project.id), "Analysis", !manager);
    this.addLinkHeader("tag-manager", this.linkTagManager(project.id), "Manager", manager);

    if (!manager) {
      this.enableTagFilter();
    } else {
      this.disableTagFilter();
    }

    this.enableProjectList();
    this.enableProjectNav();
    this.projectManager.selectedProject = project;
  }

  // Behavior for insight part (socio-demo, overview and maps)
  behaviorInsights(project: Project, actual: string) {
    this.addLinkHeader("project-sociodemo", this.linkInsightsSociodemo(project.id), "Socio-Demo", actual == "sociodemo");
    this.addLinkHeader("project-topics", this.linkInsightsTopics(project.id), "Topics", actual == "topics");
    this.addLinkHeader("project-overview", this.linkInsightsOverview(project.id), "Interest", actual == "overview");
    this.addLinkHeader("project-maps", this.linkInsightsMaps(project.id), "Maps", actual == "maps");
    if (actual == "topics") {
      this.enableTagFilter();
      if (this.criteriaFilterEnabled) this.disableCriteriaFilter();
      this.enableDataTypeSwitch();
    } else if (actual == "overview") {
      this.enableCriteriaFilter();
      if (this.tagFilterEnabled) this.disableTagFilter();
      this.disableDataTypeSwitch();
    } else {
      this.disableTagFilter();
      this.disableCriteriaFilter();
      this.disableDataTypeSwitch();
    }
    this.enableProjectList();
    this.enableProjectNav();
    this.projectManager.selectedProject = project;
  }

  behaviorWidget(project: Project, actual: string, queryParams: any){
    this.addLinkHeader("project-widget", this.linkWidget(project.id),"New Widget",actual == "widget", false, queryParams);
    this.addLinkHeader("project-widget-available", this.linkWidgetAvailable(project.id),"Widget templates",actual == "widget-available", false, queryParams);
    this.enableProjectNav();
    this.projectManager.selectedProject = project;
  }
  behaviorDashboard(project: Project, actual: string){
    //this.addLinkHeader("dashboard-list", this.linkDashboard(project.id),"Dashboard List",actual == "list")
    //this.addLinkHeader("dashboard-template", this.linkTemplate(project.id),"Dashboard Template",actual == "template")
    if (!this.projectListEnabled) this.enableProjectList();
    this.enableProjectNav();
    this.projectManager.selectedProject = project;

  }
  behaviorReporting(project: Project) {
    if (!this.projectListEnabled) this.enableProjectList();
    this.enableProjectNav();
    this.projectManager.selectedProject = project;
  }
  behaviorReportingShared(project: Project) {
    if (!this.projectListEnabled) this.enableProjectList();

    this.projectManager.selectedProject = project;
  }

  // Behavior for persona part (persona overview and persona manager)
  behaviorPersona(project: Project, manager: boolean) {
    this.addLinkHeader('persona-overview', this.linkPersonaOverview(project.id), 'Overview', !manager);

    this.auth.permissionCheck('persona.segmentation').then(authorization => {
      this.addLinkHeader('persona-manager', this.linkPersonaManager(project.id), "Segmentation", manager, !(authorization || this.auth.session.user.role.level <= RoleUtils.freemiumLevel));
    });

    if (!manager) {
      this.enableCriteriaFilter();
      this.enableTagFilter();
      this.enableDataTypeSwitch();
      this.disablePersonaFilter();
    } else {
      this.disableDataTypeSwitch();
      this.enablePersonaFilter();
      if (this.criteriaFilterEnabled) this.disableCriteriaFilter();
      if (this.tagFilterEnabled) this.disableTagFilter();
    }

    if (!this.projectListEnabled) this.enableProjectList();
    if (!this.projectNavEnabled) this.enableProjectNav();
    this.projectManager.selectedProject = project;
  }

  // Behavior for admin part (users and companies management)
  async behaviorAdmin(actual: string) {
    if (actual == "assets") {
      this.addLinkHeader("assets", this.linkAdminAssets(), "Assets", true);
    } else if (actual == "companies" || actual == "users" || actual == "notification" || actual == "events" || actual == 'parameters' || actual == 'projects') {
      // Companies
      const companiesAuth = await this.auth.permissionCheck('company.admin');
      if (companiesAuth) this.addLinkHeader("companies", this.linkAdminCompanies(), "Companies", actual == "companies");
      else this.addLinkHeader("companies", this.linkAdminCompanies(this.auth.session.user.company.id), "Companies", actual == "companies");

      // Users
      const usersAuth = await this.auth.permissionCheck('user.admin');
      if (usersAuth) this.addLinkHeader("users", this.linkAdminUsers(), "Users", actual == "users");
      else if (actual == "users") this.addLinkHeader("users", undefined, "Users", actual == "users");
      else this._headerLinkOptions = this._headerLinkOptions.filter(h => h.key !== 'users');

      // Notifications
      const notificationsAuth = await this.auth.permissionCheck('notification.manage');
      if (notificationsAuth) this.addLinkHeader("notification", this.linkNotification(), "Notification", actual == "notification");

      // Events
      // TODO: Add permissions
      if (this.auth.session.user.role.level >= RoleUtils.superAdminLevel) {
        if (!environment.online || environment.config.dev) this.addLinkHeader('events', this.linkEvent(), "Events", actual == 'events');
        this.addLinkHeader('parameters', this.linkParameters(), 'Parameters', actual == 'parameters');
      }

      const projectsAuth = await this.auth.permissionCheck('project.manage');
      if (projectsAuth) this.addLinkHeader('projects', this.linkProjects(), 'Projects', actual == 'projects');
    } else {
      this.logger.logError("Invalid path segment or path segment not supported : " + actual);
    }
  }

  /*
   * LINKS : Links to views
   */

  linkTagManager(projectId: number): string { return "/projects/details/" + projectId + "/tags/manager" }
  linkTagAnalysis(projectId: number): string { return "/projects/details/" + projectId + "/tags/analysis" }

  linkInsights(projectId: number): string { return "/projects/details/" + projectId + "/insights" }
  linkInsightsOverview(projectId: number): string { return "/projects/details/" + projectId + "/insights/overview" }
  linkInsightsSociodemo(projectId: number): string { return "/projects/details/" + projectId + "/insights/socio-demo" }
  linkInsightsTopics(projectId: number): string { return "/projects/details/" + projectId + "/insights/topics" }
  linkInsightsMaps(projectId: number): string { return "/projects/details/" + projectId + "/insights/maps" }

  linkPersonaManager(projectId: number): string { return "/projects/details/" + projectId + "/persona/manager" }
  linkPersonaOverview(projectId: number): string { return "/projects/details/" + projectId + "/persona/overview" }
  linkWidget(projectId : number): string { return "/dashboard/reporting/widget/" + projectId }
  linkWidgetAvailable(projectId : number): string { return "/dashboard/reporting/widget-available/" + projectId }
  linkTemplate(projectId : number): string { return "/dashboard/reporting/template/" +projectId }
  linkDashboard(projectId : number): string { return "/dashboard/reporting/list/" + projectId }
  linkNotification(): string { return "/admin/notification" }
  linkEvent(): string { return "/admin/events"; }
  linkParameters(): string { return "/admin/parameters"; }
  linkProjects(): string { return '/admin/projects'; }
  linkAdminAssets(): string { return "/admin/assets" }
  linkAdminCompanies(id?: number): string {
    if (id) {
      return "/admin/companies/update/" + id;
    } else {
      return "/admin/companies";
    }
  }
  linkReporting(projectId: number, dashboardId: number): string { return "/dashboard/reporting/list/" +projectId + "/insight/" + dashboardId }
  linkAdminUsers(): string { return "/admin/users" }

  get currentDashboardArea(): DashboardArea { return this._currentDashboardArea; }
}
