import {Component, OnInit} from '@angular/core';
import {NavigationService} from '../../../services/sp-navigation/navigation.service';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sp-layout-dashboard-welcome',
  templateUrl: './sp-layout-dashboard-welcome.component.html',
  styleUrls: ['./sp-layout-dashboard-welcome.component.scss']
})
export class SpLayoutDashboardWelcomeComponent implements OnInit {
  env = environment;
  showFreemiumVideo = true;
  constructor(public navigation: NavigationService,
              public router: Router) {
  }

  ngOnInit(){

  }

  closeFreemium(){
    this.showFreemiumVideo = false;
  }
}
