import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {ActiveToast, IndividualConfig, ToastrService} from 'ngx-toastr';

export interface Notification {
  title: string;
  message: string;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private readonly _notificationHistory: Notification[];
  private readonly _notificationListener$: Subject<Notification>;

  private static nextId = 0;

  constructor(private toastr: ToastrService) {
    this._notificationHistory = [];
    this._notificationListener$ = new Subject<Notification>();
  }

  notify(title: string, message: string, type: string, config?: Partial<IndividualConfig>): ActiveToast<any> {
    const notification: Notification = {title, message, type};
    this._notificationHistory.push(notification);
    this._notificationListener$.next(notification);

    AlertService.nextId++;
    //this.notifier.notify(type, message, "notification-" + AlertService.nextId);
    switch (type) {
      case 'success':
        return this.toastr.success(message, title, config);
      case 'info':
        return this.toastr.info(message, title, config);
      case 'warning':
        return this.toastr.warning(message, title, config);
      case 'error':
        return this.toastr.error(message, title, config);
      default:
        return this.toastr.show(message, title, config, type);
    }
  }

  get notificationHistory() { return this._notificationHistory; }
  get notificationListener$() { return this._notificationListener$; }
}
