import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiBotService {
  private readonly apiUrl: string = environment.config.api.baseUrl;

  constructor(private http: HttpClient) { }


  sendFeedback(messages: any[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const sub = this.http.post(`${this.apiUrl}notification/feedback`, {messages})
        .subscribe(() => resolve(true), error => reject(error), () => sub.unsubscribe());
    });
  }

  getGoogleToken(): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      const sub = this.http.get<any>(`${this.apiUrl}google/token`)
        .subscribe(resp => resolve(resp.access_token), error => reject(error), () => sub.unsubscribe());
    });
  }
}
