import {Component, HostListener, OnInit} from '@angular/core';
import { SpThemingService } from '../../../services/sp-theming/sp-theming.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {AuthenticationService} from "../../../services/sp-authentication/authentication.service";
import {environment} from '../../../../environments/environment';
import {AlertService} from '../../../services/sp-alert/alert.service';

@Component({
  selector: 'app-sp-user-password',
  templateUrl: './sp-user-password.component.html',
  styleUrls: ['./sp-user-password.component.scss']
})
export class SpUserPasswordComponent implements OnInit {
  env = environment;
  private captchaToken: string;

  private _emailFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email
  ]);

  private _waiting: boolean = false;
  private _alreadyRequested: boolean = false;

  // Constructor.
  constructor(private _spTheming: SpThemingService,
              private auth: AuthenticationService,
              private alert: AlertService) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-welcome');

  }
  screenHeight: number;
  screenWidth: number;

  // On init.
  ngOnInit() {
    this.getScreenSize();
  }

  // TODO: Change this
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  showSuccess() {
    this.alert.notify('Success', 'If the email address provided is correct, you will receive instructions by mail', 'success');
  }

  showError(error: string) {
    this.alert.notify('Error', error, 'error');
  }

  onCaptchaResponse(event: any) {
    this.captchaToken = event;
  }

  requestPasswordReset() {
    this._waiting = true;
    if(this.emailFormControl.valid && this.captchaToken) {
      this.auth.requestPasswordReset(this.emailFormControl.value, this.captchaToken)
        .then(() => {
          this.showSuccess();
          this._alreadyRequested = true;
        })
        .catch(error => {
          this.showError(error.error.message);
        })
        .finally(() => this._waiting = false)
    } else {
      this.showError("Wrong email or captcha");
    }
  }

  get emailFormControl(): UntypedFormControl { return this._emailFormControl; }
  get waiting(): boolean { return this._waiting; }
  get alreadyRequested(): boolean { return this._alreadyRequested; }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.requestPasswordReset();
    }
  }

  buttonLocked(): boolean {
      return !this._emailFormControl.valid || !this.captchaToken;
  }
}
