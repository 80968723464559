import {Injectable} from '@angular/core';
import {Audience} from '../../models/audience';
import {Targeting} from '../../classes/targeting/targeting';
import {ApiAudienceService} from '../sp-api/sp-api-audience/api-audience.service';
import {BuildOptions, TargetingBuilder, TargetingWrapper} from '../../classes/targeting/targeting-builder';
import {FacebookLinkService} from '../sp-facebook/facebook-link.service';
import {NavigationEnd, Router} from '@angular/router';
import {TargetingUtils} from '../../utils/targeting/targeting-utils';
import {LoggerService} from '../sp-logger/logger.service';
import {
  FbCriteria,
  FbGeolocation,
  FbGeolocationGroup,
  FbLocale, FbRelationshipStatus,
  FlexSpec,
  Geolocation
} from '../../types/facebook-types';
import {Subject} from 'rxjs';
import {Universe} from '../../models/universe';
import {AlertService} from '../sp-alert/alert.service';
import {SocketService} from '../sp-ws/socket.service';
import {environment} from '../../../environments/environment';
import {CountryJson} from '../sp-api/api.service';

export enum AudienceType {
  target,
  benchmark
}

export enum AudienceCreationMode {
  interest,
  import,
  facebookPage
}

export enum TargetingType {
  INCLUSION, EXCLUSION
}

export class GeoField {
  private static nextId = 0;

  id: number;
  geolocation?: FbGeolocation;

  constructor() {
    this.id = GeoField.nextId;

    GeoField.nextId++;
  }
}

export class CriteriaField {
  private static nextId = 0;

  id: number;
  criteria?: FbCriteria;
  locale?: FbLocale;

  constructor() {
    this.id = CriteriaField.nextId;

    CriteriaField.nextId++;
  }
}

// Audience Targeting Info
export class AudienceInfo {
  public static readonly facebookPlatforms = ['audience_network', 'facebook', 'messenger'];
  public static readonly instagramPlatforms = ['instagram'];

  /* Fields related */
  includedGeolocationField: GeoField = new GeoField();
  excludedGeolocationField: GeoField = null;

  includedCriteriaFields: CriteriaField[] = [new CriteriaField()];
  excludedCriteriaField: CriteriaField = null;
  /*. --- .*/

  /* Targeting related */
  // Criterion
  includedCriterion: Map<CriteriaField, Array<FbCriteria>> = new Map();
  excludedCriterion: Array<FbCriteria> = [];
  // Geolocations
  includedGeolocations: Array<FbGeolocation> = [];
  includedGeolocationGroups: Array<FbGeolocationGroup> = [];
  excludedGeolocations: Array<FbGeolocation> = [];
  // Locales
  includedLocales: Array<FbLocale> = [];
  // Relationship Statuses
  includedRelationshipStatuses: Array<FbRelationshipStatus> = [];
  // Publishers
  platformsSelected: string[][] = [AudienceInfo.facebookPlatforms, AudienceInfo.instagramPlatforms];
  // Ages
  ages: number[] = [18, 65];
  // Genders
  genders: number[] = [1, 2];
  /*. --- .*/

  // Utils
  localesToString() {
    if (environment.config.enableDebugFeatures) {
      return this.includedLocales
        .map(l => l.name + ' (' + l.key + ')');
    } else {
      return this.includedLocales
        .map(l => l.name);
    }
  }

  relationshipStatusToString(){
    return this.includedRelationshipStatuses
      .map(r => r.name);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectCreationManagerService {
  // Project related
  private audiences: Map<AudienceType, Audience> = new Map();

  private _audienceCreationMode: AudienceCreationMode;
  private _audienceType: AudienceType;

  private _audiencesInfo: Map<AudienceType, AudienceInfo> = new Map();

  private _projectName = '';
  // . ---

  // Audience related
  /* Boolean that decides if when target is updated, benchmark specs are updated
   * with the info from target (inclusion of criterion become exclusion, and geolocations are duplicated)
   */
  private _targetBenchmarkLink = true;

  /* Boolean that decides if when project title is updated, the titles
   * for target and benchmark audiences are updated too
   */
  private _projectTitleLink = true;
  // . ---

  // Listeners & Events
  private _audienceTypeSwitchSubject: Subject<AudienceType> = new Subject<AudienceType>();
  private _projectNameUpdateSubject: Subject<string> = new Subject<string>();
  private _wrongProjectNameSubject: Subject<void> = new Subject();
  // . ---

  // Custom stuff
  private _flag?: string;
  private _topicUniverseSelected?: Universe;
  private _politicalUniverseSelected?: Universe;
  private _cicCountriesSelected?: CountryJson[];

  constructor(
    private apiAudience: ApiAudienceService,
    private router: Router,
    private logger: LoggerService,
    private alert: AlertService,
    private facebook: FacebookLinkService,
    private socket: SocketService
  ) {
    this.reset();

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        if (!url.includes('/projects/create')) {
          this.reset();
        } else {
          if (this.audienceTarget.imported && this.audienceTarget.type != 'sociodemo') {
            this.resetAudienceType(AudienceType.target);
            if (this._targetBenchmarkLink) { this.resetAudienceType(AudienceType.benchmark); }
          }

          if (this.audienceBenchmark.imported && this.audienceBenchmark.type != 'sociodemo') {
            this.resetAudienceType(AudienceType.benchmark);
          }
        }
      }
    });
  }

  setProjectUniverses(universes: Universe[], geolocationOverride: boolean = false) {
    this.audienceTarget.universes = universes;
    this.audienceBenchmark.universes = universes;

    if (universes.length === 0) return;

    const isAudienceCustomAndLookalike = this.currentAudience.isCustomAndLookalikeSpecHasGeolocation;
    const isAudienceCustom = this.currentAudience.type === 'custom' || this.currentAudience.type === 'fanpage';
    if (geolocationOverride || (isAudienceCustom && !isAudienceCustomAndLookalike)) {
      this.setUniversesGeolocation(this.audienceType, universes);
    }
  }

  /**
   * Checks if all the required fields for project creation has been filled
   */
  isProjectComplete(freemium: boolean = false): boolean {
    if (this.projectName == '' && !freemium) {
      this.wrongProjectNameSubject.next();
      return false;
    }

    const targetValidate = this.audienceTarget.targeting.validate();
    const benchmarkValidate = this.audienceBenchmark.targeting.validate();
    const targetUniverse = this.audienceTarget.universes.length > 0;
    const benchmarkUniverse = this.audienceBenchmark.universes.length > 0;

    if (this.audienceTarget.fb_size <= 0 || this.audienceBenchmark.fb_size <= 0) {
      this.alert.notify('Project Check Error', 'Your project cannot be launched because an audience is invalid... Please check if the audience is valid on your Facebook business manager account.', 'error');
      return false;
    }

    const targetValidation = freemium ? targetValidate.all : targetValidate.geoloc;

    if (!targetValidation) {
      this.socket.sendMessageType('user-project-difficulty', {
        target: this.audienceTarget.targeting,
        benchmark: this.audienceBenchmark.targeting,
        check: {
          geolocation: targetValidate.geoloc,
          interests: targetValidate.flex
        }
      });
      if (!targetValidate.geoloc) { this.alert.notify('Project Error', 'You must specify a geolocation for target audience to launch the project', 'error'); }
      else if (!targetValidate.flex) { this.alert.notify('Project Error', 'You must specify interests', 'error'); }
      this.logger.logError('Audience target targeting is not valid', 1);
    }
    if (!benchmarkValidate.geoloc) {
      this.alert.notify('Project Error', 'You must specify a geolocation for benchmark audience to launch the project', 'error');
      this.logger.logError('Audience benchmark targeting is not valid', 1);
    }
    if (!targetUniverse) { this.logger.logError('Audience target universes are not valid', 1); }
    if (!benchmarkUniverse) { this.logger.logError('Audience benchmark universes are not valid', 2); }

    return targetValidation
    && benchmarkValidate.geoloc
    && targetUniverse
    && benchmarkUniverse;
  }

  /**
   * Important flag to let API know that the audience has been imported (and refuse if user is freemium)
   */
  markCurrentAsImported() {
    const audience = this.currentAudience;
    let dataExtra = audience.data_extra;
    if (!dataExtra) { dataExtra = '{}'; }
    const jsonData = JSON.parse(dataExtra);
    jsonData.imported = true;
    audience.data_extra = JSON.stringify(jsonData);
  }

  private setDefaultGeolocation(update: boolean = true) {
    const emptyGeolocation = this.includedGeolocations.length == 0;
    if (!emptyGeolocation) {
      this.resetGeolocation(TargetingType.INCLUSION, AudienceType.target);
      this.resetGeolocation(TargetingType.EXCLUSION, AudienceType.target);
      this.resetGeolocation(TargetingType.INCLUSION, AudienceType.benchmark);
      this.resetGeolocation(TargetingType.EXCLUSION, AudienceType.benchmark);
    }

    if (emptyGeolocation) {
      if (this.audienceTarget.universes && this.audienceTarget.universes.length) {
        this.setUniversesGeolocation(AudienceType.target, this.audienceTarget.universes);
      } else {
        this.addGeolocation({name: 'Worldwide', key: 'worldwide', type: 'country_group'}, TargetingType.INCLUSION, update).then();
      }
    }
  }

  /**
   * Reset all information relative to an audience type, and set audience mode
   * @param audienceType
   * @param audienceCreationMode
   */
  reset(audienceType: AudienceType = AudienceType.target, audienceCreationMode: AudienceCreationMode = AudienceCreationMode.interest) {
    this.resetAudienceType(AudienceType.target);
    this.resetAudienceType(AudienceType.benchmark);

    this.switchAudienceType(audienceType);
    this.switchAudienceMode(audienceCreationMode);

    this._flag = undefined;
    this._cicCountriesSelected = undefined;
    this._politicalUniverseSelected = undefined;
    this._topicUniverseSelected = undefined;
    this._projectName = '';

    this._targetBenchmarkLink = true;
    this._projectTitleLink = true;

    this.setDefaultGeolocation(true);
  }

  /**
   * Reset audience matching selected type
   * @param audienceType
   */
  resetAudienceType(audienceType: AudienceType) {
    this._audiencesInfo.set(audienceType, new AudienceInfo());

    const audience = new Audience('My ' + (audienceType == AudienceType.target ? 'target' : 'benchmark') + ' audience', 'base', 'en_US', 'sociodemo');
    audience.targeting = new Targeting();
    audience.fb_size = 0;
    audience.locale = 'en_US';
    audience.universes = [];

    this.pushAudienceType(audience, audienceType);
  }

  /**
   * Reset fields for the selected audience type
   * @param audienceType
   * @param geolocation
   * @param criterion
   */
  resetAudienceFields(audienceType: AudienceType, geolocation: boolean = true, criterion: boolean = true, includeTargeting: boolean = false) {
    if (geolocation) {
      this._audiencesInfo.get(audienceType).includedGeolocationField = new GeoField();
      this._audiencesInfo.get(audienceType).includedGeolocations = [];

      this._audiencesInfo.get(audienceType).excludedGeolocationField = null;
      this._audiencesInfo.get(audienceType).excludedGeolocations = [];

      if (includeTargeting) {
        const audience = this.getAudienceByType(audienceType);
        audience.targeting.geolocations = new Geolocation();
        audience.targeting.excludedGeolocations = new Geolocation();
      }
    }

    if (criterion) {
      this._audiencesInfo.get(audienceType).includedCriteriaFields = [new CriteriaField()];
      this._audiencesInfo.get(audienceType).includedCriterion = new Map();

      this._audiencesInfo.get(audienceType).excludedCriteriaField = null;
      this._audiencesInfo.get(audienceType).excludedCriterion = [];

      if (includeTargeting) {
        const audience = this.getAudienceByType(audienceType);
        audience.targeting.flex = [];
        audience.targeting.exclusions = new FlexSpec();
      }
    }
  }

  /**
   * Fill all fields by providing audience targeting and the audience type
   * @param targeting
   * @param audienceType
   */
  loadAudienceInfoFromTargeting(targeting: Targeting, audienceType: AudienceType) {
    this.resetAudienceFields(audienceType);

    const audienceInfo = this.audiencesInfo.get(audienceType);
    const defaultCriteriaField = audienceInfo.includedCriteriaFields[0];

    audienceInfo.ages = targeting.ageMin && targeting.ageMax ? [targeting.ageMin, targeting.ageMax] : [13, 65];
    audienceInfo.genders = targeting.genders ? targeting.genders : [1, 2];

    if (targeting.flex && targeting.flex.length > 0) {
      this.buildCriterionFromField(defaultCriteriaField, targeting.flex[0], audienceInfo);
      const otherFlex = targeting.flex.slice(1, targeting.flex.length);
      otherFlex.forEach(flex => {
        const field = new CriteriaField();
        audienceInfo.includedCriteriaFields.push(field);
        this.buildCriterionFromField(field, flex, audienceInfo);
      });
    }

    if (targeting.rigid) {
      this.buildCriterionFromField(defaultCriteriaField, targeting.rigid, audienceInfo);
    }

    if (targeting.locales) {
      this.currentInfo.includedLocales = targeting.locales.map(l => {
        return {
          key: l.toString(),
          name: TargetingUtils.getLabelForType('locales', l)
        };
      });
    }

    if (targeting.relationshipStatuses) {
      this.currentInfo.includedRelationshipStatuses = targeting.relationshipStatuses.map(l => {
        return {
          key: l.toString(),
          name: TargetingUtils.getLabelForType('relationship_statuses', l)
        };
      });
    }

    if (targeting.exclusions) {
      const exclusionField = new CriteriaField();
      audienceInfo.excludedCriteriaField = exclusionField;
      this.buildCriterionFromField(exclusionField, targeting.exclusions, audienceInfo, true);
    }

    this.buildGeolocFromTargeting(targeting, audienceType);

  }
  // . ---
  // Geolocation related
  /* Geolocation related */
  public buildGeolocFromTargeting(targeting: Targeting, audienceType: AudienceType) {
    const audienceInfo = this.audiencesInfo.get(audienceType);

    if (targeting.geolocations) {
      let geoCountryGroups: FbGeolocation[] = [];
      let geoCountries: FbGeolocation[] = [];
      let geoRegions: FbGeolocation[] = [];
      let geoCities: FbGeolocation[] = [];
      let geoZips: FbGeolocation[] = [];

      if (targeting.geolocations.country_groups) {
        geoCountryGroups = targeting.geolocations.country_groups.map(cg => {
          const geo = new FbGeolocation();
          geo.key = cg;
          geo.type = 'country_group';

          return geo;
        });
      }

      if (targeting.geolocations.countries) {
        geoCountries = targeting.geolocations.countries.map(c => {
          const geo = new FbGeolocation();
          geo.key = c;
          geo.name = c;
          geo.country_name = c;
          geo.country_code = c;
          geo.type = 'country';

          return geo;
        });
      }

      if (targeting.geolocations.regions) {
        geoRegions = targeting.geolocations.regions.map(r => {
          const geo = new FbGeolocation();
          geo.key = r.key;
          geo.name = r.name;
          geo.type = 'region';

          return geo;
        });
      }

      if (targeting.geolocations.cities) {
        geoCities = targeting.geolocations.cities.map(c => {
          const geo = new FbGeolocation();
          geo.key = c.key;
          geo.name = c.name;
          geo.type = 'city';

          return geo;
        });
      }

      if (targeting.geolocations.zips) {
        geoZips = targeting.geolocations.zips.map(z => {
          const geo = new FbGeolocation();
          geo.key = z.key;
          geo.name = z.name;
          geo.country_code = z.country;
          geo.type = 'zip';

          return geo;
        });
      }

      audienceInfo.includedGeolocations = geoCountryGroups.concat(geoCountries, geoRegions, geoCities, geoZips);
    }

    if (targeting.excludedGeolocations) {
      let geoCountryGroups: FbGeolocation[] = [];
      let geoCountries: FbGeolocation[] = [];
      let geoRegions: FbGeolocation[] = [];
      let geoCities: FbGeolocation[] = [];
      let geoZips: FbGeolocation[] = [];

      if (targeting.excludedGeolocations.country_groups) {
        geoCountryGroups = targeting.excludedGeolocations.country_groups.map(cg => {
          const geo = new FbGeolocation();
          geo.key = cg;
          geo.type = 'country_group';

          return geo;
        });
      }

      if (targeting.excludedGeolocations.countries) {
        geoCountries = targeting.excludedGeolocations.countries.map(c => {
          const geo = new FbGeolocation();
          geo.key = c;
          geo.name = c;
          geo.country_name = c;
          geo.type = 'country';
          geo.country_code = c;
          geo.country_name = c;

          return geo;
        });
      }

      if (targeting.excludedGeolocations.regions) {
        geoRegions = targeting.excludedGeolocations.regions.map(r => {
          const geo = new FbGeolocation();
          geo.key = r.key;
          geo.name = r.name;
          geo.type = 'region';

          return geo;
        });
      }

      if (targeting.excludedGeolocations.cities) {
        geoCities = targeting.excludedGeolocations.cities.map(c => {
          const geo = new FbGeolocation();
          geo.key = c.key;
          geo.name = c.name;

          return geo;
        });
      }

      if (targeting.excludedGeolocations.zips) {
        geoZips = targeting.excludedGeolocations.zips.map(z => {
          const geo = new FbGeolocation();
          geo.key = z.key;
          geo.name = z.name;

          return geo;
        });
      }

      audienceInfo.excludedGeolocations = geoCountryGroups.concat(geoCountries, geoRegions, geoCities, geoZips);
      audienceInfo.excludedGeolocationField = new GeoField();
    }
  }
  /**
   * Simple conversion from object geolocation to string (used by autocomplete)
   * @param geo The geolocation to convert
   */
  public geolocToString(geo: FbGeolocation) {
    if (!geo) { return undefined; }
    if (geo.type && geo.type == 'zip' && !geo.primary_city) {
      geo.primary_city = 'Getting city...';
      this.facebook.searchGeolocation(geo.name, 'en_US', {location_types: ['zip'], country_code: geo.country_code}).then(result => {
        if (result && result.length == 1) {
          const zip = result[0];
          geo.primary_city = zip.primary_city;
        } else {
          geo.primary_city = 'Unknown city';
        }
      });
    }
    let name = geo.type == 'zip' ? geo.name + (geo.primary_city ? ' - ' + geo.primary_city : '') : geo.name;
    if (!name) { geo.name = geo.key; }

    if (geo.region) { name += ', ' + geo.region; }
    if (geo.country_name && geo.type != 'country') { name += ', ' + geo.country_name; }

    if (geo.type) {
      name += ' (' + geo.type + ')';
    }

    return name;
  }
  public async deleteGeolocation(value: FbGeolocation, type: TargetingType) {
    if (type == TargetingType.INCLUSION) { this.includedGeolocations.splice(this.includedGeolocations.indexOf(value), 1); }
    else if (type == TargetingType.EXCLUSION) { this.excludedGeolocations.splice(this.excludedGeolocations.indexOf(value), 1); }
    else { this.logger.logWarning('Delete geolocation: unknown type ' + type); }

    this.socket.sendMessageType('project-creation-audience-update', {
      action: 'remove-geolocation',
      type: type.toString(),
      name: value.name
    });

    return this.updateCurrentAudience(true);
  }
  public async deleteGeolocationGroup(value: FbGeolocationGroup) {
    this.includedGeolocationGroup.splice(this.includedGeolocationGroup.indexOf(value), 1);
    return this.updateCurrentAudience(true);
  }
  public async addGeolocation(value: FbGeolocation, type: TargetingType, update: boolean = true) {
    if (!value) { return; }
    if (this.geolocationCompatibilityCheck(value, type)) {
      if (type == TargetingType.INCLUSION) {
        this.includedGeolocations.push(value);
        if (this.targetBenchmarkLink && this.audienceType == AudienceType.target) {
          this.audiencesInfo.get(AudienceType.benchmark).includedGeolocations = this.includedGeolocations.map(geo => geo);
        }
      } else if (type == TargetingType.EXCLUSION) {
        this.excludedGeolocations.push(value);
        if (this.targetBenchmarkLink && this.audienceType == AudienceType.target) {
          this.audiencesInfo.get(AudienceType.benchmark).excludedGeolocations = this.excludedGeolocations.map(geo => geo);
        }
      } else {
        this.logger.logWarning('Add geolocation : unknown type ' + type);
      }

      this.socket.sendMessageType('project-creation-audience-update', {
        action: 'add-geolocation',
        type: type.toString(),
        name: value.name
      });

      if (update) { return this.updateCurrentAudience(true); }
    }
  }
  public async addGeolocationGroup(values: FbGeolocation[], type: TargetingType) {
    if (!values || values.length == 0) { return; }
    let checkPass = true;
    for (const v of values) {
      if (!this.geolocationCompatibilityCheck(v, type)) {
        checkPass = false;
        break;
      }
    }
    if (checkPass) {
      if (type == TargetingType.INCLUSION) {
        this.includedGeolocationGroup.push({geolocations: values, name: 'GROUP - Universe Geo'});
        if (this.targetBenchmarkLink && this.audienceType == AudienceType.target) {
          this.audiencesInfo.get(AudienceType.benchmark).includedGeolocationGroups = this.includedGeolocationGroup.map(geo => geo);
        }
      } else if (type == TargetingType.EXCLUSION) {

      } else {
        this.logger.logWarning('Add geolocation : unknown type ' + type);
      }

      return this.updateCurrentAudience(true);
    }
  }
  public resetGeolocation(type: TargetingType, audienceType: AudienceType = this.audienceType) {
    if (type == TargetingType.INCLUSION) {
      this.audiences.get(audienceType).targeting.geolocations = new Geolocation();
      this._audiencesInfo.get(audienceType).includedGeolocations = [];
      this._audiencesInfo.get(audienceType).includedGeolocationGroups = [];
    } else if (type == TargetingType.EXCLUSION) {
      this.audiences.get(audienceType).targeting.excludedGeolocations = new Geolocation();
      this._audiencesInfo.get(audienceType).excludedGeolocations = [];
    }
  }
  /**
   * Check the compatibility between included and excluded geolocation
   * @param value The value to check
   * @param type Type of targeting (Inclusion or Exclusion)
   * @private If the geolocation should be added or not
   */
  public geolocationCompatibilityCheck(value: FbGeolocation, type: TargetingType): boolean {
    if (!value) { return false; }

    if (type == TargetingType.INCLUSION) {
      if (this.includedGeolocations.find(ig => ig.key == value.key)) { return false; }

      const excludedFound = this.excludedGeolocations.find(eg => eg.key == value.key);
      if (excludedFound) {
        this.excludedGeolocations.splice(this.excludedGeolocations.indexOf(excludedFound), 1);
      }

      // Handling overlaps
      this.includedGeolocations = this.includedGeolocations.filter(geoloc => {
        if (value.type == 'country') {
          return geoloc.country_code && value.country_code && geoloc.country_code != value.country_code;
        } else if (value.type == 'region') {
          const countryOverlap = geoloc.type == 'country' && geoloc.country_code == value.country_code;
          const regionOverlap = geoloc.type == 'region' && geoloc.key == value.key;
          const remainingOverlap = geoloc.type != 'country' && geoloc.type != 'region' && geoloc.region_id == parseInt(value.key);

          return !countryOverlap && !regionOverlap && !remainingOverlap;
        } else if (value.type == 'city' || value.type == 'subcity' || value.type == 'neighborhood') {
          const cityOverlap = geoloc.type == 'city' && geoloc.key == value.key;
          const regionOverlap = geoloc.type == 'region' && parseInt(geoloc.key) == value.region_id;
          const countryOverlap = geoloc.type == 'country' && geoloc.country_code == value.country_code;

          return !cityOverlap && !regionOverlap && !countryOverlap;
        } else if (value.type == 'zip') {
          const cityOverlap = parseInt(geoloc.key) == value.primary_city_id;

          return !cityOverlap;
        }
        return true;
      });
    } else if (type == TargetingType.EXCLUSION) {
      const includedFound = this.includedGeolocations.find(ig => ig.key == value.key);
      if (this.excludedGeolocations.find(eg => eg.key == value.key)) { return false; }

      switch (value.type) {
        case 'country':
          this.includedGeolocations = this.includedGeolocations.filter(ig => {
            return ig.country_code != value.country_code;
          });
          break;
        case 'region':
          this.includedGeolocations = this.includedGeolocations.filter(ig => {
            return ig.region_id != parseInt(value.key);
          });
      }
      if (includedFound) {
        this.includedGeolocations.splice(this.includedGeolocations.indexOf(includedFound), 1);
      }
    }

    return true;
  }
  public setUniversesGeolocation(audienceType: AudienceType, universes: Universe[]) {
    this.resetGeolocation(TargetingType.INCLUSION, audienceType);
    this.resetGeolocation(TargetingType.EXCLUSION, audienceType);

    universes.forEach(u => {
      this.setUniverseGeolocation(u);
    });
  }

  private setUniverseGeolocation(universe: Universe) {
    if (universe.custom_geo) {
      const geolocations: Array<FbGeolocation> = JSON.parse(universe.custom_geo);
      if (geolocations.length > 5) {
        this.addGeolocationGroup(geolocations, TargetingType.INCLUSION);
      } else {
        geolocations.forEach((geo, i, arr) => {
          this.addGeolocation(geo, TargetingType.INCLUSION, i == arr.length - 1);
        });
      }
    } else {
      if (universe.country_group) {
        this.addGeolocation(FbGeolocation.fromCountryGroupKey(universe.country_ref), TargetingType.INCLUSION);
      } else {
        this.addGeolocation(FbGeolocation.fromCountryKey(universe.country_ref), TargetingType.INCLUSION);
      }
    }
  }
  /*. --- .*/

  async addCriteria(element: FbCriteria, field: CriteriaField, type: TargetingType) {
    if (this.criteriaOverlapTest(element, field, type)) {
      if (type == TargetingType.INCLUSION) {
        const criteriaArray = this.currentInfo.includedCriterion.get(field);
        criteriaArray.push(element);
      } else if (type == TargetingType.EXCLUSION) {
        this.currentInfo.excludedCriterion.push(element);
      }

      this.socket.sendMessageType('project-creation-audience-update', {
        action: 'add-criteria',
        type: type.toString(),
        name: element.name
      });

      return this.updateCurrentAudience(type == TargetingType.INCLUSION);
    }
  }

  async deleteCriteria(field: CriteriaField, value: FbCriteria, type: TargetingType) {
    if (type == TargetingType.INCLUSION) {
      if (!field) {
        this.logger.logError('Criteria delete: the field must be specified');
        return;
      }

      this.socket.sendMessageType('project-creation-audience-update', {
        action: 'remove-criteria',
        type: type.toString(),
        name: value.name
      });

      const array = this.currentInfo.includedCriterion.get(field);
      array.splice(array.indexOf(value), 1);
    } else if (type == TargetingType.EXCLUSION) {
      this.currentInfo.excludedCriterion.splice(this.currentInfo.excludedCriterion.indexOf(value), 1);
    }

    return this.updateCurrentAudience(type == TargetingType.INCLUSION);
  }

  addExcludedCriterionField() {
    if (!this.currentInfo.excludedCriteriaField) { this.currentInfo.excludedCriteriaField = new CriteriaField(); }
  }

  /**
   * Checking if there is any overlapping criterion in the audience definition
   * @param element
   * @param field
   * @param type
   * @private
   */
  private criteriaOverlapTest(element: FbCriteria, field: CriteriaField, type: TargetingType): boolean {
    if (type == TargetingType.INCLUSION) {
      if (!this.currentInfo.includedCriterion.has(field)) { this.currentInfo.includedCriterion.set(field, []); }
      else {
        Array.from(this.currentInfo.includedCriterion.keys()).forEach(k => {
          const array = this.currentInfo.includedCriterion.get(k);
          const includedFound = array.find(c => c.id === element.id);
          if (includedFound) { array.splice(array.indexOf(includedFound), 1); }
        });
      }

      const exclusionFound = this.currentInfo.excludedCriterion.find(c => c.id == element.id);
      if (exclusionFound) { this.currentInfo.excludedCriterion.splice(this.currentInfo.excludedCriterion.indexOf(exclusionFound), 1); }
    } else if (type == TargetingType.EXCLUSION) {
      // Check if already in the array
      if (this.currentInfo.excludedCriterion.find(ec => ec.id == element.id)) { return false; }

      this.currentInfo.includedCriteriaFields.forEach(f => {
        const array = this.currentInfo.includedCriterion.get(f);
        // Fix a possible bug when a field is created but has no associated data
        if (array) {
          const includedFound = array.find(c => c.id == element.id);
          if (includedFound) {
            array.splice(array.indexOf(includedFound), 1);
            this.updateBenchmark();
          }
        }
      });
    }

    return true;
  }

  /**
   * Shortcut to update current selected audience type
   * @param updateBenchmark Update audience type "benchmark" in the process
   */
  public async updateCurrentAudience(updateBenchmark: boolean = false) {
    const geolocationOnly = false;
    await this.updateAudience(this.audienceType, geolocationOnly, true);
    if (this.audienceType != AudienceType.benchmark && updateBenchmark) {
      await this.updateBenchmark(geolocationOnly);
    }
  }

  /**
   * Update benchmark audience type based on current input info
   * @param geolocationOnly Only update geolocation
   */
  private async updateBenchmark(geolocationOnly: boolean = false) {
    if (this.audienceType == AudienceType.target && this.targetBenchmarkLink) {
      const benchAudienceInfo = this.audiencesInfo.get(AudienceType.benchmark);

      if (!geolocationOnly) {
        benchAudienceInfo.ages = Array.from(this.currentInfo.ages);
        benchAudienceInfo.genders = Array.from(this.currentInfo.genders);
        benchAudienceInfo.includedLocales = Array.from(this.currentInfo.includedLocales);

        /*benchAudienceInfo.excludedCriterion = [];
        const includedCriteriaFields = this.audiencesInfo.get(AudienceType.target).includedCriteriaFields;
        if (includedCriteriaFields.length > 0) {
          const field = this.audiencesInfo.get(AudienceType.target).includedCriteriaFields[0];
          const criterion = this.audiencesInfo.get(AudienceType.target).includedCriterion.get(field);
          criterion.forEach(c => benchAudienceInfo.excludedCriterion.push(c));
        }*/
      }
      benchAudienceInfo.platformsSelected = Array.from(this.audiencesInfo.get(AudienceType.target).platformsSelected);

      benchAudienceInfo.includedGeolocations = Array.from(this.includedGeolocations);
      benchAudienceInfo.includedGeolocationGroups = Array.from(this.includedGeolocationGroup);
      benchAudienceInfo.excludedGeolocations = Array.from(this.excludedGeolocations);

      return this.updateAudience(AudienceType.benchmark, geolocationOnly, true);
    }
  }

  /**
   * Update chosen audience type targeting based on audience info
   * @param audienceType
   * @param geolocationOnly
   * @param withPlatforms
   * @private
   */
  async updateAudience(audienceType: AudienceType, geolocationOnly: boolean = false, withPlatforms: boolean = true) {
    // Trigger for disabling target / benchmark linking
    if (this.audienceType == AudienceType.benchmark && this.targetBenchmarkLink) { this.targetBenchmarkLink = false; }

    const audience: Audience = this.getAudienceByType(audienceType);
    const wrapper = this.getWrapper(audienceType);

    const buildOptions: BuildOptions = {
      detailedGeolocation: true,
      buildPlatforms: withPlatforms,
      buildCriterion: !geolocationOnly,
      buildGeolocation: true,
      buildSociodemographic: !geolocationOnly
    };

    this.generateTargeting(audience, wrapper, buildOptions);
    await this.refreshAudienceSize(audience, audience.targeting);
  }

  private getWrapper(audienceType: AudienceType) {
    const audienceInfo = this.audiencesInfo.get(audienceType);
    const includedCriterion: FbCriteria[][] = Array.from(audienceInfo.includedCriterion.values());

    const wrapper = new TargetingWrapper();
    wrapper.includedCriterion = includedCriterion;
    wrapper.excludedCriterion = audienceInfo.excludedCriterion;
    wrapper.includedGeolocations = audienceInfo.includedGeolocations;
    wrapper.includedGeolocationGroups = audienceInfo.includedGeolocationGroups;
    wrapper.excludedGeolocations = audienceInfo.excludedGeolocations;

    wrapper.ageMin = audienceInfo.ages[0];
    wrapper.ageMax = audienceInfo.ages[1];
    wrapper.genders = audienceInfo.genders;
    wrapper.includedLocales = audienceInfo.includedLocales;
    wrapper.includedRelationshipStatuses = audienceInfo.includedRelationshipStatuses;

    let platforms: string[] = [];
    this.audiencesInfo.get(audienceType).platformsSelected.forEach(p => platforms = platforms.concat(p));
    wrapper.platforms = platforms;

    return wrapper;
  }

  /**
   * Creating criterion from flex to push them inside input fields for edition
   * @param field
   * @param flex
   * @param audienceInfo
   * @param exclude
   * @private
   */
  private buildCriterionFromField(field: CriteriaField, flex: FlexSpec, audienceInfo: AudienceInfo, exclude: boolean = false) {
    const criteriaBuffer: FbCriteria[] = [];

    flex.forEach((value, key) => {
      const type = key;

      value.forEach(entry => {
        let id: string, name: string;
        if (typeof entry == 'number') {
          id = entry.toString();
          name = TargetingUtils.getLabelForType(key, entry);
        } else {
          const mapEntry = entry as Map<string, string>;
          id = mapEntry.get('id');
          name = mapEntry.get('name');
        }

        const criteria = new FbCriteria();
        criteria.id = id;
        criteria.name = name;
        criteria.path = [];
        criteria.type = type;
        criteria.audience_size = 0;

        criteriaBuffer.push(criteria);
      });
    });

    if (exclude) {
      if (audienceInfo.excludedCriterion) {
        audienceInfo.excludedCriterion = audienceInfo.excludedCriterion.concat(criteriaBuffer);
      } else {
        audienceInfo.excludedCriterion = criteriaBuffer;
      }
    } else {
      if (audienceInfo.includedCriterion.has(field)) {
        audienceInfo.includedCriterion.set(field, audienceInfo.includedCriterion.get(field).concat(criteriaBuffer));
      } else {
        audienceInfo.includedCriterion.set(field, criteriaBuffer);
      }
    }
  }

  /**
   * Updating audience for a specific type
   * @param audience
   * @param type
   */
  public pushAudienceType(audience: Audience, type: AudienceType) {
    if (!audience.fb_size) { audience.fb_size = 0; }
    audience.targeting = Targeting.fromAudience(audience);
    this.audiences.set(type, audience);
  }

  public switchAudienceMode(mode: AudienceCreationMode) {
    if (this._audienceCreationMode !== mode) {
      this._audienceCreationMode = mode;
    }
  }

  public switchAudienceType(type: AudienceType) {
    this._audienceType = type;
    this._audienceTypeSwitchSubject.next(type);
  }
  public getAudienceByType(type: AudienceType) {
    return this.audiences.get(type);
  }
  public async refreshAudienceSize(audience: Audience, targeting: Targeting) {
    if (targeting.validate().geoloc) {
      const resp = await this.apiAudience.audienceSize(targeting, audience.type, audience.data_extra);
      audience.fb_size = resp.size;
      audience.fb_score = resp.score;
      audience.fb_score_v2 = resp.score_v2;
    }
  }
  public generateTargeting(audience: Audience, wrapper: TargetingWrapper, options: BuildOptions) {
    const builder = new TargetingBuilder(wrapper);
    audience.targeting = builder.generateTargeting(options, audience.targeting);
  }
  public changeProjectName(name: string, triggerEvent: boolean = true) {
    name = name.slice(0, 40);
    if (triggerEvent) { this._projectNameUpdateSubject.next(name); }
    this._projectName = name;
  }

  public enableTargetBenchmarkLink() { this._targetBenchmarkLink = true; }
  public disableTargetBenchmarkLink() { this._targetBenchmarkLink = false; }

  public enableProjectTitleLink() { this._projectTitleLink = true; }
  public disableProjectTitleLink() { this._projectTitleLink = false; }

  /*
   * Getters & Setters
   */
  get currentInfo(): AudienceInfo { return this.audiencesInfo.get(this.audienceType); }

  set includedGeolocations(geo: FbGeolocation[]) { this.currentInfo.includedGeolocations = geo; }
  get includedGeolocations() { return this.currentInfo.includedGeolocations; }
  get includedGeolocationGroup() { return this.currentInfo.includedGeolocationGroups; }

  get excludedGeolocations() { return this.currentInfo.excludedGeolocations; }

  get audienceCreationMode(): AudienceCreationMode { return this._audienceCreationMode; }

  get audienceType(): AudienceType { return this._audienceType; }

  get audienceTarget(): Audience { return this.audiences.get(AudienceType.target); }

  get audienceBenchmark(): Audience { return this.audiences.get(AudienceType.benchmark); }

  get currentAudience(): Audience { return this.audiences.get(this.audienceType); }

  get audiencesInfo(): Map<AudienceType, AudienceInfo> { return this._audiencesInfo; }

  set targetBenchmarkLink(link: boolean) { link ? this.enableTargetBenchmarkLink() : this.disableTargetBenchmarkLink(); }
  get targetBenchmarkLink(): boolean { return this._targetBenchmarkLink; }

  get projectTitleLink(): boolean { return this._projectTitleLink; }

  get audienceTypeSwitchSubject(): Subject<AudienceType> { return this._audienceTypeSwitchSubject; }
  get projectNameUpdateSubject(): Subject<string> { return this._projectNameUpdateSubject; }
  get wrongProjectNameSubject(): Subject<void> { return this._wrongProjectNameSubject; }

  get projectName(): string { return this._projectName; }
  set flag(value: string|undefined) {
    this._flag = value;
  }
  get flag(): string|undefined { return this._flag; }
  set topicUniverseSelected(value: Universe|undefined) {
    this._topicUniverseSelected = value;
  }
  get topicUniverseSelected(): Universe|undefined { return this._topicUniverseSelected; }
  set politicalUniverseSelected(value: Universe|undefined) {
    this._politicalUniverseSelected = value;
  }
  get politicalUniverseSelected(): Universe|undefined { return this._politicalUniverseSelected; }
  set cicCountriesSelected(value: CountryJson[]|undefined) {
    if (!value || value.length == 0) {
      this._cicCountriesSelected = undefined;
    } else {
      this._cicCountriesSelected = value;
    }
  }
  get cicCountriesSelected(): CountryJson[]|undefined { return this._cicCountriesSelected; }
}
