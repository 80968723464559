import Rollbar from 'rollbar';

import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';
import {environment} from '../environments/environment';

let env: string;
switch (window.location.hostname) {
  case "perf.soprism.com":
    env = 'perf';
    break;
  case "app.soprism.com":
    env = 'production';
    break;
  case "dev.soprism.com":
    env = 'dev';
    break;
  case "demo.soprism.com":
    env = 'demo';
    break;
  default:
    env = 'local';
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    console.error(err);
    if (environment.online && environment.config.production) {
      this.rollbar.error(err.originalError || err);
    }
  }
}

export function rollbarFactory() {
  const rollbarConfig: Rollbar.Configuration = {
    accessToken: '319072c83e31473599388ed70402a082',
    captureUncaught: environment.online,
    captureUnhandledRejections: environment.online,
    environment: env,
    enabled: true,
    ignoredMessages: ['Error: Highcharts error #13: www.highcharts.com/errors/13/']
  };

  return new Rollbar(rollbarConfig);
}
