import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SpThemingService} from '../../../services/sp-theming/sp-theming.service';
import {AuthenticationService} from '../../../services/sp-authentication/authentication.service';
import {Router} from '@angular/router';
import {ApiUserService} from '../../../services/sp-api/sp-api-user/api-user.service';
import {User} from '../../../models/user';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ValidationService} from '../../../services/sp-validation/validation.service';
import {ApiRoleService} from '../../../services/sp-api/sp-api-role/api-role.service';
import {ApiCompanyService} from '../../../services/sp-api/sp-api-company/api-company.service';
import {AdAccount} from '../../../models/ad-account';
import {FacebookLinkService} from '../../../services/sp-facebook/facebook-link.service';
import {environment} from '../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {SpModalFacebookAccountAddComponent} from './sp-modal-facebook-account-add/sp-modal-facebook-account-add.component';
import {NavigationService} from '../../../services/sp-navigation/navigation.service';
import {LoaderService} from '../../../services/sp-loading/loader.service';
import introJs from 'intro.js';
import {Plan} from '../../../models/plan';
import {Company} from '../../../models/company';
import {MatTableDataSource} from '@angular/material/table';
import {Role} from '../../../models/role';
import {RoleUtils} from '../../../utils/role/role-utils';
import {ApiPlanService} from '../../../services/sp-api/sp-api-plan/api-plan.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AlertService} from '../../../services/sp-alert/alert.service';
import {SpModalCompanyAddUsersComponent} from '../../sp-admin/sp-admin-company/sp-admin-company-create/sp-modal-company-add-users/sp-modal-company-add-users.component';
import {LoggerService} from '../../../services/sp-logger/logger.service';
import {SpAccountUserEditImageComponent} from './sp-account-user-edit-image/sp-account-user-edit-image.component';
import * as Highcharts from 'highcharts';
import {ChartService} from '../../../services/sp-chart/chart.service';
import {SocketService} from '../../../services/sp-ws/socket.service';
import {ProjectManagerService} from '../../../services/sp-project/project-manager.service';
import {SpModalUserDeleteComponent} from '../../sp-modal/sp-modal-user-delete/sp-modal-user-delete.component';

@Component({
  selector: 'app-sp-user-account-edit',
  templateUrl: './sp-account-user-edit.component.html',
  styleUrls: ['./sp-account-user-edit.component.scss']
})

export class SpAccountUserEditComponent implements OnInit, AfterViewInit {
  first_name: any;
  dataHasChanged = false;
  introJS = introJs();
  loadedUser: User = undefined;
  // Facebook connect related
  adAccounts: Array<AdAccount> = [];
  fbConnectionPending: boolean = false;
  // fbConnection & fbBusinessConnection => null = default, false = error, true = success
  fbConnection: boolean = null;
  validAdAccounts: boolean = null;
  fbAccountImage: string = null;
  fbAccountName: string = null;
  //. ---
  selectedImage: any = null;
  env = environment;
  customPlan: Plan = null;
  plans: Array<Plan> = [];
  subscriptionStartDate: UntypedFormControl;
  subDuration = 12;
  createdCompany: Company = null;
  userForm: UntypedFormGroup;
  switchRolePending = false;
  selectedPlan: Plan = null;
  monthlyCredits = 0;
  nbrUsers = 0;
  extraCredits = 0;
  searchText= '';
  roles: Array<Role> = [];
  dataSource = new MatTableDataSource<User>([]);
  isNotCreated = true;
  displayedColumns: string[] = [
    'image',
    'last_name',
    'email'
  ];
  editMode = false;

  updatingInfo = false;

  // Permissions
  isCompanySuperAdmin: boolean = false;
  canManageUsers: boolean = false;
  //. ----

  // User IDs of already resent invitation
  invitationSent: number[] = [];
  invitationPending: number[] = [];

  emailChanged = false;
  passwordChanged = false;

  freemium = false;
  trial = false;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  // Charts
  private chartCredits: Highcharts.Chart;
  private chartCreditsExtra: Highcharts.Chart;
  private chartUsers: Highcharts.Chart;

  // Seed
  seedSelection: number[] = [8247, 4712, 6569, 5153, 5165];
  seedSelected?: number;

  // Constructor.
  constructor(private _spTheming: SpThemingService,
              private router: Router,
              public auth: AuthenticationService,
              private apiUser: ApiUserService,
              private apiRole: ApiRoleService,
              private apiCompany: ApiCompanyService,
              private validation: ValidationService,
              private dialog: MatDialog,
              private formBuilder: UntypedFormBuilder,
              private navigation: NavigationService,
              private loader: LoaderService,
              private fb: FacebookLinkService,
              private chart: ChartService,
              private apiPlan: ApiPlanService,
              private alert: AlertService,
              private logger: LoggerService,
              private socket: SocketService,
              private pm: ProjectManagerService) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-desk');

    // Loading actual normalization seed if any
    this.seedSelected = this.pm.seed;
  }
  // On init.
  ngOnInit() {
    this.loader.load();
    this.navigation.adBlockDetectionLaunch().then();

    this.auth.permissionCheck("company.admin").then(authorization => {
      this.isCompanySuperAdmin = authorization;
    });
    if (this.auth.session.authenticated) {
      this.freemium = this.auth.session.user.role.level <= RoleUtils.freemiumLevel;
      this.trial = this.auth.session.user.role.level == RoleUtils.trialLevel
    }

    this.auth.permissionCheck("company.self.user.manage").then(authorization => {
      this.canManageUsers = authorization;
      if (authorization) this.displayedColumns.push("type", "actions");
      else this.displayedColumns.push("type-user");
    });

    this.userForm = this.formBuilder.group({
      first_name: ['', [
        Validators.required,
        Validators.maxLength(50)
      ]],
      last_name: ['', [
        Validators.required,
        Validators.maxLength(50)
      ]],
      email: ['', {
        validators: [
          Validators.required
        ],
        updateOn: "blur"
      }],
      passwordChange: ['', [
        Validators.minLength(8),
        Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)
      ]],
      passwordConfirm: [''],
      company: ['']
    }, {
      validators: this.validation.passwordMatchValidator('passwordChange', 'passwordConfirm')
    });

    this.userForm.controls['passwordConfirm'].disable();

    this.subscriptionStartDate = new UntypedFormControl(new Date(), [
      Validators.required
    ]);
  }

  ngAfterViewInit() {
    this.apiUser.getOne(this.auth.session.user.id).then(user => {
      this.loadedUser = user;

      this.apiCompany.getOne(this.loadedUser.company.id).then(company => {
        //Loading details of the company
        this.adAccounts = user.ad_accounts;
        this.refreshFbScopeChecks(false);
        this.loadedUser.company = company;

        let userLeft = this.loadedUser.company.subscription.users - this.loadedUser.company.users.length;
        this.chartCredits = Highcharts.chart('container',this.chart.createUserCreditsPieChart(this.loadedUser.company.subscription.credits,this.loadedUser.company.subscription.plan.credits,"Credits",this.loadedUser.company.subscription.credits + " credits left"))
        this.chartCreditsExtra = Highcharts.chart('container-2',this.chart.createUserCreditsPieChart(this.loadedUser.company.subscription.credits_extra,this.loadedUser.company.subscription.credits_extra_total,"Extra credits",this.loadedUser.company.subscription.credits_extra + " credits left"))
        this.chartUsers = Highcharts.chart('container-3',this.chart.createUserCreditsPieChart(userLeft,this.loadedUser.company.subscription.users,"Users",userLeft + " users left",true));

        this.initUserForm(this.loadedUser);

        this.loader.loaded();

        this.apiPlan.getAll().then((plans: Plan[]) => {
          this.plans = plans;
          if (this.canManageUsers) {
            this.apiRole.getAll().then((roles: Role[]) => {
              this.roles = roles;
              this.loadCompanyInfo(user.company);
            });
          } else {
            this.loadCompanyInfo(user.company);
          }
        });
      })
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.chartCredits?.reflow();
    this.chartCreditsExtra?.reflow();
    this.chartUsers?.reflow();
  }

  onSeedSelectionChange(seed: number) {
    this.seedSelected = seed;
    this.pm.seed = this.seedSelected;
  }

  navigateToNewProject(){
    if (this.freemium == true || this.trial) {
      this.router.navigateByUrl('/projects/create/select').then();
    } else if(this.fbConnection && this.validAdAccounts && this.auth.session.user.ad_accounts.length > 0){
      this.router.navigateByUrl('/projects/create/select').then();
    }
  }

  private refreshFbScopeChecks(refreshAdAccounts: boolean = true) {
    this.fb.isLogged().then(logged => {
      if (logged) {
        const refreshAdAccountsPromise = refreshAdAccounts ? this.refreshUserAdAccounts() : Promise.resolve();
        refreshAdAccountsPromise.then(_ => {
          this.fb.checkAllScopesAreValid().then(scopesOk => {
            const mustRefresh = this.auth.session.user.has_invalid_ad_accounts;
            this.validAdAccounts = this.adAccounts.length > 0;
            this.fbConnection = !mustRefresh && scopesOk;
          });
        });

        this.refreshFbUserPicture().then();
      } else {
        this.validAdAccounts = false;
        this.fbConnection = localStorage.getItem(FacebookLinkService.LOCAL_CHECK_KEY) === 'disconnected' ? false : null;
      }
    });
  }

  facebookGetStatus(): void {
    this.logger.debug("Local storage FB info: ", localStorage.getItem(FacebookLinkService.LOCAL_KEY));
    this.fb.getLoginStatus().then(resp => this.logger.debug(resp));
  }

  async facebookBusinessLogin() {
    if (this.fbConnectionPending) return;
    this.fbConnectionPending = true;
    try {
      const resp = await this.fb.login(true, this.fbConnection === false);
      this.refreshFbScopeChecks();
      this.logger.debug(resp);
      return resp;
    } catch (err) {
      throw err;
    } finally {
      this.fbConnectionPending = false;
    }
  }

  facebookLogout(): void {
    this.fb.logout().then(() => {
      this.fbAccountImage = null;
      this.fbAccountName = null;
      this.refreshFbScopeChecks();
    });
  }

  facebookUnlink(): void {
    this.fb.unlink().then(resp => {
      // Token must be refreshed when session is invalidated
      this.auth.session.user.has_invalid_ad_accounts = true;
      this.logger.debug(resp);
    });
  }

  facebookApiTestCall(): void {
    this.fb.getUserInfo().then(resp => this.logger.debug(resp)).catch(err => this.logger.logError(err, 1, err));
  }

  async refreshUserAdAccounts() {
    try {
      const accounts = await this.fb.getAdAccounts();
      const usedAccounts = this.loadedUser.ad_accounts.filter(a => a.selected).map(a => a.facebook_id);
      this.adAccounts = accounts.filter(a => usedAccounts.includes(a.facebook_id));
      await this.updateAdAccounts();
      this.auth.session.user.has_invalid_ad_accounts = false;
    }
    catch (error) {
      this.logger.logError('Unable to get ad accounts from FB', 3, error);
    }
  }

  async refreshFbUserPicture() {
    this.fbAccountImage = await this.fb.getUserPicture();
  }

  resetData() {
    this.initUserForm(this.loadedUser);
    this.dataHasChanged = false;
  }


  loadCompanyInfo(company: Company) {
    this.subscriptionStartDate.setValue(company.subscription.contract_start_at);

    this.monthlyCredits = company.subscription.credits;
    this.extraCredits = company.subscription.credits_extra;
    this.nbrUsers = company.subscription.users;

    let startAt = new Date(company.subscription.contract_start_at);
    let endAt = new Date(company.subscription.contract_expire_at);

    let months: number;
    months = (endAt.getFullYear() - startAt.getFullYear()) * 12;
    months -= startAt.getMonth();
    months += endAt.getMonth();
    months = months <= 0 ? 0 : months;
    this.subDuration = months;

    if(company.subscription.plan.custom) {
      this.customPlan = company.subscription.plan;
      this.selectedPlan = this.customPlan;
    } else {
      this.customPlan = null;
      this.selectedPlan = this.plans.find(p => p.id == company.subscription.plan.id);
    }

    //this.selectedImage = company.image === null ? null : this.env.values.api.storageUrl + company.image;

    this.createdCompany = company;
    this.dataSource.data = this.createdCompany.users;
    this.isNotCreated = false;
    this.editMode = true;
  }

  imageChanged = false;
  openUserImageDialog(): void {
    this.dialog.open(SpAccountUserEditImageComponent,{
      autoFocus: false,
      minHeight:'10rem',
      minWidth:'40rem',
      maxHeight:'60rem',
      maxWidth:'60rem',
    }).afterClosed().subscribe(result => {
      if (result) {
        if (typeof result === 'boolean') {
          if (!this.loadedUser.fb_picture || this.loadedUser.image !== this.fbAccountImage) {
            this.loadedUser.fb_picture = true;
            this.loadedUser.image = this.fbAccountImage;
            this.selectedImage = this.fbAccountImage;
            this.dataHasChanged = true;
          }
        } else {
          this.loadedUser.fb_picture = false;
          this.onImageChange(result);
          this.dataHasChanged = true;
        }
      }
    });
  }

  openUserAddDialog() {
      const dialogRef = this.dialog.open(SpModalCompanyAddUsersComponent,{
        maxHeight:'30rem',maxWidth:'40rem', minWidth:'40rem', data: {
          company: this.createdCompany
        }
      });

    dialogRef.afterClosed().subscribe(company => {
      if(company !== null && company !== undefined) {
        this.createdCompany = company;
        this.dataSource.data = this.createdCompany.users;
      }
    });
  }
  firstNameChanged = false;
  lastNameChanged = false;

  updateUserInfo() {
    if (!this.userForm.valid) {
      return;
    }
    this.updatingInfo = true;
    let user = this.buildUserFromForm();
    this.apiUser.updateOne(user)
      .then(user => {
        this.auth.sessionLoad().then();
        this.adAccounts = user.ad_accounts;

        const companyDetails = this.loadedUser.company;
        this.loadedUser = user;
        this.loadedUser.company = companyDetails;
        this.alert.notify('Success', 'Your profile has been updated', 'success');

      })
      .finally(() =>
        this.updatingInfo = false
      );
    this.dataHasChanged = false;
    this.userForm.get('passwordChange').setValue('');
    this.userForm.get('passwordConfirm').setValue('');
    this.userForm.get('passwordConfirm').disable();
    if (this.createdCompany) this.dataSource.data = this.createdCompany.users;
  }

  subscriptionUpdateDate(): Date {
    if (this.loadedUser) {
      const startSubDate = new Date(this.loadedUser.company.subscription.contract_start_at);
      return new Date(startSubDate.setMonth(startSubDate.getMonth()+1));
    } else {
      return undefined;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToUserUpdate(user: User) {
    this.router.navigate(["/admin/users/update/" + user.id], {
      queryParams: {
        'from': 'user-account'
      }
    });
  }
  switchUserCompanyRole(user: User, company: Company, roleId: number) {
    this.switchRolePending = true;

    const role = this.roles.find(r => r.id == roleId);

    this.apiUser.switchCompanyRole(user, company, role)
      .then(() => {
        user.role = role;
        this.dataSource.data = this.createdCompany.users;
      })
      .catch(error => {
        if(this.env.config.showErrorModal) this.alert.notify("Error", error.error.message, "error");
      })
      .finally(() => this.switchRolePending = false);
  }
  onRoleSelectionChange(user: User, roleId: number) {
    this.switchUserCompanyRole(user, this.createdCompany, roleId);
  }

  isRoleDisabled(user: User, role: Role): boolean {
    if(this.createdCompany) {
      if(user.role.level >= RoleUtils.teamAdminLevel && this.createdCompany.users.filter(u => u.role.level >= RoleUtils.teamAdminLevel).length <= 1) {
        return role.level < RoleUtils.teamAdminLevel || role.level > this.auth.session.user.role.level;
      }
      if (this.loadedUser.role.level < RoleUtils.superAdminLevel && role.level <= RoleUtils.trialLevel) {
        return true;
      }

      return role.level > this.auth.session.user.role.level;
    }
  }

  deleteUser(user: User) {
    this.apiCompany.deleteUser(this.createdCompany, user)
      .then(company => {
        if (company) {
          this.createdCompany = company;
          this.dataSource.data = this.createdCompany.users;
        }
      })
      .catch(error => {
        if(environment.config.showErrorModal) this.alert.notify("Error", error.error.message, "error");
      });
  }

  openDeleteDialog(user: User): void {
    const dialogRef = this.dialog.open(SpModalUserDeleteComponent,{
      maxHeight:'30rem',maxWidth:'40rem', data: {
        user,
        company: this.createdCompany
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.deleteUser(user);
      }
    });
  }

  openAdAccountDialog(): void {
    const dialogRef = this.dialog.open(SpModalFacebookAccountAddComponent,{
      maxHeight:'32rem',maxWidth:'40rem', minWidth:'40rem',
      data: this.adAccounts
    });

    dialogRef.afterClosed().subscribe(adAccounts => {
      if (adAccounts) {
        if (adAccounts.updated) {
          this.adAccounts = adAccounts.accounts === undefined ? this.adAccounts : adAccounts.accounts;
          this.auth.session.user.ad_accounts = this.adAccounts;
          if (this.adAccounts.length > 0) this.validAdAccounts = true;
          else this.validAdAccounts = null;
          this.updateAdAccounts().then(() => {
            this.alert.notify('Success', 'Your ad accounts have been updated successfully', 'success');
          }).catch(() => {
            this.alert.notify('Error', 'An error occurred while updating ad accounts, this has been reported', "error");
          });
        }
      }
    });
  }

  initUserForm(user: User) {
    let laravelEmailParams: Map<string, string> = new Map();
    laravelEmailParams.set('own_email', user.email);

    this.userForm.get('first_name').setValue(user.first_name);
    this.userForm.get('last_name').setValue(user.last_name);
    this.userForm.get('email').setValue(user.email);
    this.userForm.get('company').setValue(user.company.name);

    this.userForm.get('email')
      .setAsyncValidators(this.validation.laravelSideValidator("email", "validation/user/email", laravelEmailParams));

    this.userForm.get('company').disable();

    this.adAccounts.forEach(ad => ad.selected = true);

    if (this.loadedUser.image) {
      this.selectedImage = this.env.config.api.storageUrl + this.loadedUser.image;
    }
  }

  buildUserFromForm() {
    let email = this.userForm.get('email').value;
    let firstName = this.userForm.get('first_name').value;
    let lastName = this.userForm.get('last_name').value;
    let password = this.userForm.get('passwordChange').value;

    let user = new User(email, firstName, lastName);
    if (password !== "") {
      user.password = password;
    }
    user.id = this.loadedUser.id;
    user.ad_accounts = this.adAccounts;
    user.image = this.selectedImage;
    user.fb_picture = this.loadedUser.fb_picture;
    return user;
  }

  async updateAdAccounts() {
    try {
      const ads = await this.apiUser.updateAdAccounts(this.loadedUser, this.adAccounts);
      this.socket.sendMessageType('user_ad_account_update', {count: ads.length});
      this.adAccounts = ads;
    } catch (err) {
      this.logger.logError('Error while updating ad accounts', 3, err);
      throw err;
    }
  }

  onImageChange(imageInput: HTMLInputElement) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.selectedImage = event.target.result;
      this.imageChanged = true;
      this.dataChangeCheck();
    });

    reader.readAsDataURL(file);
  }

  dataChangeCheck():void{
    if (this.firstNameChanged || this.lastNameChanged || this.emailChanged || this.passwordChanged || this.imageChanged) {
      this.dataHasChanged = true;
    } else {
      this.dataHasChanged = false;
    }
  }
  onFirstNameChange(searchValue: string): void {
    if (this.auth.session.user.first_name === searchValue) {
      this.firstNameChanged = false;
    } else {
      this.firstNameChanged = true;
    }
    this.dataChangeCheck();
  }

  onLastNameChange(searchValue: string): void {
    if(this.auth.session.user.last_name === searchValue) {
      this.lastNameChanged = false;
    } else {
      this.lastNameChanged = true;
    }
    this.dataChangeCheck();
  }

  onEmailChange(searchValue: string): void {
    if(this.auth.session.user.email === searchValue) {
      this.emailChanged = false;
    } else {
      this.emailChanged = true;
    }
    this.dataChangeCheck();
  }

  onPasswordConfirmChange(password: any, confirmPassword: any): void {
    this.passwordChanged = password === confirmPassword;
    this.dataChangeCheck();
  }

  onPasswordChange(password: string) {
    if (password && password !== '') this.userForm.get('passwordConfirm').enable();
    else this.userForm.get('passwordConfirm').disable();
  }

  sendInvitation(user: User) {
    this.invitationPending.push(user.id);
    this.apiUser.sendInvitation(user).then(() => {
      this.alert.notify('OK!', 'The invitation has been sent successfully', 'success');
      this.invitationSent.push(user.id);
    }).catch(err => {
      this.alert.notify('Error', err.error.message, 'error');
    }).finally(() => {
      this.invitationPending.splice(this.invitationPending.indexOf(user.id), 1);
    })
  }


  get session() {
    return this.auth.session
  }

  protected readonly undefined = undefined;
}
