import {User} from "./user";

export class Session {
  private _user: User = null;
  private _token: string = null;
  private _socketToken: string = null;

  private readonly _authenticated: boolean = false;
  private _initialized: boolean = false;
  private readonly _failed: boolean = false;
  private _errorCode: number = null;
  private _error: any = null;

  constructor(user: User = null, token: string = null, socketToken: string = null) {
    this._user = user;
    this._token = token;
    this._socketToken = socketToken;

    this._authenticated = this._user !== null;
    this._failed = this.user === null;
  }

  initialize() {
    this._initialized = true;
  }

  get user(): User { return this._user; }
  get authenticated(): boolean { return this._authenticated; }
  get initialized(): boolean { return this._initialized; }
  get failed(): boolean { return this._failed; }
  get errorCode(): number { return this._errorCode; }
  get error(): any { return this._error; }

  set errorCode(errorCode: number) { this._errorCode = errorCode; }
  set error(error: any) { this._error = error; }
  set user(user: User) { this._user = user; }
}
