import {MapData} from '../../models/stats/map-data';
import {ChartService, MapLevel} from '../../services/sp-chart/chart.service';

export class MapDataHandler {
  private mapData: Map<string, MapData> = new Map();

  constructor(data: MapData[]) {
    data.forEach(d => {
      if (d.targetSize <= 50 || d.benchmarkSize <= 50) {
        d.targetSize = 0;
        d.benchmarkSize = 0;
        d.relativeDiff = 0;
        d.selectivity = 0;
        d.targetPer = 0;
        d.benchmarkPer = 0;
        d.signedPer1 = 0;
      } else {
        d.targetPer = ChartService.round(d.targetPer * 100);
        d.benchmarkPer = ChartService.round(d.benchmarkPer * 100);
        d.selectivity = ChartService.round(d.selectivity * 100);
      }
      this.mapData.set(d.ins, d)
    })
  }

  getData(id: string) {
    return this.mapData.get(id);
  }

  getLevel(level: MapLevel) {
    level = level.valueOf() - 1;

    return Array.from(this.mapData.values()).filter(m => m.level == level);
  }

  toJson() {
    const jsonData: any = {};
    this.mapData.forEach((val, key) => {
      jsonData[key] = val;
    });

    return jsonData;
  }

  static fromJson(js: any) {
    const handler = new MapDataHandler([]);
    Object.entries(js).forEach(([key, value]) => {
      handler.mapData.set(key, <any>value);
    });

    return handler;
  }
}
