<div class="pt-2 sp-dialog">
  <div class="p-3 text-center">
    <h3><strong>Hi {{this.auth.session.user.first_name}},</strong></h3>
    <h5 class="sp-text-secondary">Thank you for your subscription & welcome into the Digital Consumer Insights solution</h5>
  </div>
  <div class="sp-dialog-body">
    <div class="row">
      <div class="col-6">
        <h3> Get ready to discover what makes your audience and consumer segments unique.</h3><br>
        <h5 class="sp-text-secondary"> We’re happy to have you on board, and can’t wait to start awesome analysis together.
          Get started by using our simple step-by-step guide made to help you to discover all our features. </h5>
        <div class="mt-5">
          <button (click)="onNoClick()" class="btn btn-secondary mr-3 sp-btn sp-btn-secondary"  mat-button matTooltip="Skip the introduction" matTooltipHideDelay="10">Skip the tour</button>
          <button (click)="introJsActivate()" class="btn btn-primary sp-btn sp-btn-primary" mat-button matTooltip="Take a look at the introduction" matTooltipHideDelay="10">Take a tour & discover all capabilities</button>
        </div>
      </div>
      <div class="col-6">
        <img class="sp-img" src="assets/images/introJS/popupWelcome.png" width="700">
      </div>
    </div>
  </div>
</div>
