import { Injectable } from '@angular/core';
import {from} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  constructor(private cookie: CookieService) { }

  loadAppConfig() {
    return from(
      fetch('assets/config/app-config.json').then(function(response) {
        return response.json();
      })
    ).pipe(
      map((config) => {
        environment.config = config.global;
        if (environment.overrideEnv) {
          //environment.config = config[environment.overrideEnv];
          this.mergeConfig(environment.config, config[environment.overrideEnv]);
        } else {
          switch (window.location.hostname) {
            case "perf.soprism.com":
              //environment.config = config.perf;
              environment.config = this.mergeConfig(environment.config, config.perf);
              break;
            case "app.soprism.com":
              //environment.config = config.prod;
              environment.config = this.mergeConfig(environment.config, config.prod);
              break;
            case "dev.soprism.com":
              //environment.config = config.dev;
              environment.config = this.mergeConfig(environment.config, config.dev);
              break;
            case "demo.soprism.com":
              //environment.config = config.demo;
              environment.config = this.mergeConfig(environment.config, config.demo);
              break;
            default:
              //environment.config = config.local;
              environment.config = this.mergeConfig(environment.config, config.local);
          }
        }
        if (this.cookie.get("debug") == "on") {
          environment.config.enableDebugFeatures = true;
        }
        if (this.cookie.get("performance") == "over9000") {
          environment.config.performanceMode = true;
        }

        // Loading facebook SDK
        FB.init({
          appId: environment.config.fbAppId,
          cookie: true,
          xfbml: true,
          version: environment.config.fbVersion
        });

        return;
      }));
  }

  private mergeConfig(original: any, toMerge: any) {
    return {...original, ...toMerge};
  }
}
