<main role="main" class="w-100 container sp-project-details-tag-analysis">
  <div class="row no-gutters">
    <div class="col-7 pr-3">
      <div class="w-100 container">
        <div class="sp-table-div">
          <div class="row d-flex no-gutters">
            <div class="col-7 align-self-center">
              <mat-form-field appearance="outline" class="w-100 ml-3 mt-3 mb-1 sp-form-field">
                <mat-label class="sp-input-label">Search</mat-label>
                <mat-icon class="sp-input-search-icon"  matPrefix svgIcon="icon_sp_search"></mat-icon>
                <input #assetSearch (keyup)="onSearchFilter(assetSearch.value, $event)" class=" sp-input-search-content "  matInput placeholder="Search assets...">
                <button aria-label="Clear" mat-icon-button matSuffix>
                  <mat-icon (click)="assetSearch.value = ''; clearFilter()">close</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="col-2 ml-3 mt-4 mb-1 align-self-center">
              <p class="pl-3 sp-table-number">
              </p>
            </div>

            <div class="col-3 text-right">

            </div>
          </div>

          <table mat-table [dataSource]="assets" class="mat-elevation-z8 sp-table" matSort matSortActive="selectivity" matSortDirection="desc">

            <!-- Logo image column -->
            <ng-container matColumnDef="logo">
              <th mat-header-cell *matHeaderCellDef>Logo</th>
              <td class="sp-table-tags" mat-cell *matCellDef="let element">
                <img class="sp-avatar sp-avatar-md" [src]="element.img ? element.img : getDefaultImage()">
              </td>
            </ng-container>

            <!-- Name column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Interest Name</th>
              <td class="sp-table-target" mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <!-- Type column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Type</th>
              <td class="sp-table-bench" mat-cell *matCellDef="let element">{{ element.type }}</td>
            </ng-container>

            <!-- Expiration column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> Expiration</th>
              <td class="sp-table-selectivity" mat-cell *matCellDef="let element">{{ element.expirationDate ? element.expirationDate.toUTCString() : "No expiration" }}</td>
            </ng-container>
            <!-- Options column -->
            <ng-container matColumnDef="option">
              <th class="text-right pr-4" mat-header-cell *matHeaderCellDef> Options</th>
              <td class="sp-table-actions" mat-cell *matCellDef="let element" class="text-right pr-4">
                <i class="icon-sp-edit-white"> </i>
                <i (click)="$event.stopPropagation(); openDeleteDialog(element)" class="icon-sp-delete"> </i>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsAssets"></tr>
            <tr *matRowDef="let element; columns: displayedColumnsAssets;" style="cursor: pointer" (click)="openUpdateDialog(element)" class="sp-table-row" mat-row></tr>
          </table>
        </div>
        <mat-paginator class="sp-paginator" [ngStyle]="{'display': assets.data.length > 10  ? 'block' :  'none'}" #paginator [pageSize]="10" hidePageSize
                       showFirstLastButtons></mat-paginator>
      </div>
    </div>

    <div class="col-5 pl-3">
      <div class="w-100 container">
        <div class="sp-card">
          <div class="card">
            <div class="card-header ">
              <h5 class="pl-3">Default image</h5>
            </div>
            <div class="card-body p-3">

              <img class="sp-asset-img" [src]="getDefaultImage()"/>
              <div>
                <button (click)="fileInput.click()" type="button" class="btn btn-primary sp-btn sp-btn-primary" matTooltip="Change type" matTooltipHideDelay="10">
                  <span> Change image </span>
                </button>
              </div>

              <input #fileInput (change)="onDefaultImageChange(fileInput, $event)" hidden type="file" accept="image/x-png"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
