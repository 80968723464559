<div style="display: flex; flex-direction: column; gap:16px" class="sign-in-up-component">
  <div class="sp-header">
    <h2 class="h1 text-center sp-title">Everything starts from here!</h2>
    <h3 class="h5 text-center sp-text-secondary sp-baseline">Log in to access your account</h3>
  </div>
  <div class="card p-4" (keydown)="keyDownFunction($event)">
    <div *ngIf="!router.url.includes('user/login?state=registered')" style="display: flex; flex-direction: column; gap:8px" >
      <mat-form-field appearance="outline" class="sp-form-field" style="flex-grow: 1">
        <mat-label class="sp-input-label">Email Address</mat-label>
        <input [formControl]="emailControl" autofocus matInput placeholder="Type your email address..." required value=""/>
        <mat-error *ngIf="emailControl.hasError('required')">
          Required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="sp-form-field" style="flex-grow: 1">
        <mat-label class="sp-input-label">Password</mat-label>
        <input [formControl]="passwordControl" [type]="hide ? 'password' : 'text'" matInput placeholder="Type your password..." required value=""/>
        <mat-error  *ngIf="passwordControl.hasError('required')">
          Required
        </mat-error>
      </mat-form-field>
      <div style="display: flex; flex-direction: column; gap:4px">
        <div style="display:flex; justify-content: end">
          <a href="/user/password" matTooltip="Click here to recover your password" matTooltipHideDelay="10">Password forgotten?</a>
        </div>
        <button (click)="login()" [disabled]="!isSubmitEnabled()" class="btn btn-lg btn-primary sp-btn sp-btn-lg sp-btn-primary" matTooltip="Click here to sign in"  matTooltipHideDelay="10" type="button" >
          <span>Sign In</span>
        </button>
      </div>
    </div>
    <div *ngIf="router.url.includes('user/login?state=registered')">
      <div class="sp-text-secondary text-center m-3">
        <h4>Your account has been created successfully! <br></h4>
        <h4>Check your mails to finalize your inscription. </h4>
      </div>
      <img [src]="'assets/images/socio-demo/single.png'" class="sp-single-image">
      <img [src]="'assets/images/socio-demo/parents.png'" class="sp-parents-image">
      <img [src]="'assets/images/socio-demo/multiParents.png'" class="sp-relation-image">
    </div>
  </div>
  <div class="text-center" *ngIf="!router.url.includes('user/login?state=registered')">
    <span class="sp-text-secondary">No account yet?</span>
    <a class="" routerLink="/user/register" >
     Click here
    </a>
  </div>
  <p-messages [(value)]="msgs"></p-messages>
</div>
