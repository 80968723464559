import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../../../services/sp-authentication/authentication.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-introduction',
  templateUrl: './sp-introduction.component.html',
  styleUrls: ['./sp-introduction.component.scss']
})
export class SpIntroductionComponent implements OnInit {
  constructor( public auth: AuthenticationService,
               public dialogRef: MatDialogRef<SpIntroductionComponent>) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }


  introJsActivate() {
    this.dialogRef.close(true);
  }

}
