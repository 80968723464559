<main class="container" role="main" style="margin-top: -6.5rem">
  <!--<div [ngClass]="{'sp-project-top-fixed': pageScrolled == true}" class="row no-gutters pb-3 mb-4 sp-project-top-fixed" id="sp-vs">
    <div class="col-4 sp-wtf sp-hideOverlay" id="step2" >
      <div class="sp-card sp-card-audience" id="card-target">
        <div [style.borderColor]=" isSelected == false ? '#F36F31': 'white' " class="card sp-card-target">
          <div class="pb-3 card-header">
            <input #targetTitle (blur)="onAudienceTitleUpdate(targetTitle, audienceTarget); isInsideName = false" (keyup.enter)="targetTitle.blur()" [hidden]="!isTitleEditable(targetTitle)" [value]="audienceTarget.name" class="sp-target-audience" matTooltip="{{audienceTarget.name}}" matTooltipHideDelay="10" >
            <div (click)="editTitle(targetTitle)" [hidden]="isTitleEditable(targetTitle)" class="sp-target-audience" matTooltip="{{audienceTarget.name}}" matTooltipHideDelay="10">{{audienceTarget.name}}</div>
            <div class="pl-1" style="position: absolute; top: 22px; right: 10px">
              <i (click)="editTitle(targetTitle)" class="icon-sp-edit-white" style="font-size: 0.8rem;"> </i>
            </div>
          </div>
          <div *ngIf="audienceTarget.type == 'custom'" class="card-body" id="card-body-target">
            <span>Universes: <span class="sp-element">{{ selectedUniversesToString() }}</span> </span><br/>
            <span>Custom Audience: <span class="sp-element">{{ "WIP" }} </span></span><br />
            <span>Ad Account: <span class="sp-element">{{ "WIP" }}</span></span><br />
          </div>
          <div *ngIf="audienceTarget.type != 'custom'" class="card-body" id="card-body-target">
            <span>Universes: <span class="sp-element">{{ selectedUniversesToString() }}</span></span><br/>
            <span>Age:
              <span *ngIf="audienceTargetSpec.ageMax <65" class="sp-element">{{ audienceTargetSpec.ageMin }}-{{ audienceTargetSpec.ageMax }}</span>
              <span *ngIf="audienceTargetSpec.ageMax >=65" class="sp-element">{{ audienceTargetSpec.ageMin }}-{{ audienceTargetSpec.ageMax }}+</span>
            </span><br />
            <span>Gender: <span class="sp-element">{{ audienceTargetSpec.gendersString() }} </span></span><br/>
            <span *ngIf="audienceTargetSpec.geolocations">Geolocation:  <span class="sp-element">{{ audienceTargetSpec.geolocString() }}<br/></span></span>
            <span *ngIf="audienceTargetSpec.excludedGeolocations">Exclude:  <span class="sp-element">{{ audienceTargetSpec.geolocString(true) }}<br/></span></span>
            <div *ngFor="let flex of audienceTargetSpec.flex; let i = index"><span>{{ i == 0 ? 'Interests' : 'And' }}:  <span [innerHTML]="boldOr(audienceTargetSpec.flexOrString(flex))" class="sp-element"></span></span></div>
            <span *ngIf="audienceTargetSpec.exclusions">Exclude:  <span [innerHTML]="boldOr(audienceTargetSpec.flexOrString(audienceTargetSpec.exclusions))" class="sp-element"><br/></span></span>
          </div>
          <div class="card-footer">
            <div class="row no-gutters">
              <div [class]="audienceType == AudienceType.benchmark ? 'col-10 d-flex justify-content-start' : 'col-12'">
                <span class="sp-target-people"><span class="sp-target-number">{{ audienceTarget ? toNumberFormat(audienceTarget.fb_size) : 0 }}</span> people</span>
              </div>
              <div *ngIf="audienceType == AudienceType.benchmark" class="col-2 d-flex justify-content-end">
                <button (click)="switchAudienceType(AudienceType.target)" class="btn btn-primary sp-btn sp-btn-primary" matTooltip="Edit" matTooltipHideDelay="10" type="button">
                  <span> Edit </span>
                </button>
              </div>
            </div>
            <div *ngIf="audienceType == AudienceType.target" class="row d-flex no-gutters ">
              <div>
                <span>Too small</span>
              </div>
              <div [style.backgroundColor]=" audienceTarget.fb_size > 25001 ? '#F36F31': 'white' " class="m-1 sp-rectangle align-self-center" id="sp-rectangle-1"></div>
              <div [style.backgroundColor]=" audienceTarget.fb_size > 50001 ? '#F36F31': 'white' " class="m-1  sp-rectangle align-self-center" id="sp-rectangle-2"></div>
              <div [style.backgroundColor]=" audienceTarget.fb_size > 100001 ? '#F36F31': 'white' " class="m-1 sp-rectangle align-self-center" id="sp-rectangle-3"></div>
              <div [style.backgroundColor]=" audienceTarget.fb_size > 150001 ? '#F36F31': 'white' " class="m-1 sp-rectangle align-self-center" id="sp-rectangle-4"></div>
              <div [style.backgroundColor]=" audienceTarget.fb_size > 200001 ? '#F36F31': 'white' " class=" m-1 sp-rectangle align-self-center" id="sp-rectangle-5"></div>
              <div>
                <span>Too broad</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-1 d-flex align-self-center justify-content-center">
      <span class="sp-project-vs">VS</span>
    </div>
    <div class="col-4 mr-xxxl-4 mr-md-3" id="step3">
      <div class="sp-card sp-card-audience" id="card-benchmark">
        <div [style.borderColor]=" isSelected == true ? '#F36F31': 'white' " class="card sp-card-benchmark">
          <div class="pb-3 card-header">

            <input #benchmarkTitle (blur)="onAudienceTitleUpdate(benchmarkTitle, audienceBenchmark); isInsideName = false" (keyup.enter)="benchmarkTitle.blur()" [hidden]="!isTitleEditable(benchmarkTitle)" [value]="audienceBenchmark.name" class="sp-benchmark-audience" matTooltip="{{audienceBenchmark.name}}" matTooltipHideDelay="10" >
            <div (click)="editTitle(benchmarkTitle)" [hidden]="isTitleEditable(benchmarkTitle)" class="sp-benchmark-audience" matTooltip="{{audienceBenchmark.name}}" matTooltipHideDelay="10">
              {{ audienceBenchmark.name }}
            </div>
            <div class="pl-1" style="position: absolute; top: 22px; right: 10px">
              <i (click)="editTitle(benchmarkTitle)" class="icon-sp-edit-white" style="font-size: 0.8rem;"> </i>
            </div>
          </div>
          <div *ngIf="audienceBenchmark.type == 'custom'" class="card-body" id="card-body-benchmark">
            <span>Universes: <span class="sp-element">{{ selectedUniversesToString() }}</span></span><br/>
            <span>Custom Audience: <span class="sp-element">{{ "WIP" }}</span></span><br />
            <span>Ad Account: <span class="sp-element"> {{ "WIP" }}</span></span><br />
          </div>
          <div *ngIf="audienceBenchmark.type != 'custom'" class="card-body" id="card-body-benchmark">
            <span>Universes: <span class="sp-element">{{ selectedUniversesToString() }}</span></span><br/>
            <span>Age:
              <span *ngIf="audienceBenchmarkSpec.ageMax < 65" class="sp-element"> {{ audienceBenchmarkSpec.ageMin }}-{{ audienceBenchmarkSpec.ageMax }}</span>
              <span *ngIf="audienceBenchmarkSpec.ageMax >= 65"  class="sp-element"> {{ audienceBenchmarkSpec.ageMin }}-{{ audienceBenchmarkSpec.ageMax }}+</span>
            </span><br/>
            <span>Gender: <span class="sp-element">{{ audienceBenchmarkSpec.gendersString() }}</span></span><br/>
            <span *ngIf="audienceBenchmarkSpec.geolocations">Geolocation:  <span class="sp-element">{{ audienceBenchmarkSpec.geolocString() }}<br/></span></span>
            <span *ngIf="audienceBenchmarkSpec.excludedGeolocations">Exclude:  <span class="sp-element">{{ audienceBenchmarkSpec.geolocString(true) }}<br/></span></span>
            <div *ngFor="let flex of audienceBenchmarkSpec.flex; let i = index"><span>{{ i == 0 ? 'Interests' : 'And' }}:  <span [innerHTML]="boldOr(audienceBenchmarkSpec.flexOrString(flex))" class="sp-element"></span></span></div>
            <span *ngIf="audienceBenchmarkSpec.exclusions">Exclude:  <span [innerHTML]="boldOr(audienceBenchmarkSpec.flexOrString(audienceBenchmarkSpec.exclusions))" class="sp-element"><br/></span></span>
          </div>
          <div class="card-footer">
            <div class="row d-flex no-gutters">
              <div [class]="audienceType == AudienceType.target ? 'col-10 d-flex justify-content-start' : 'col-12'">
                <span class="sp-benchmark-people"><span class="sp-target-number">{{ audienceBenchmark ? toNumberFormat(audienceBenchmark.fb_size) : 0 }}</span> people</span>
              </div>
              <div *ngIf="audienceType == AudienceType.target" [matTooltip]="freemium ? 'The benchmark audience edition is not available in the free version. Contact us to upgrade your account.' : 'Edit'" class="col-2 d-flex justify-content-end" matTooltipHideDelay="10">
                <button (click)="switchAudienceType(AudienceType.benchmark)" [disabled]="freemium" class="btn btn-primary sp-btn sp-btn-primary" type="button">
                  <span> Edit </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 ml-xxxl-2 ml-xxxxl-4 ml-md-4">
      <button (click)="openCreateDialog()" class="w-100 mb-4 btn btn-primary sp-btn sp-btn-primary sp-btn-launch" id="step5" matTooltip="Launch your project" matTooltipHideDelay="10" type="button">
        <div class="row p-1 d-flex no-gutters ">
          <div class="col-10  align-self-center text-left">
            <span class="pl-2 text-uppercase"> Launch your project </span>
          </div>
          <div class="col-2">
            <i class="pr-2 icon-sp-arrow-circle-left"></i>
          </div>
        </div>
      </button>
        <div class="sp-card w-100 sp-card-1" id="step4">
          <div class="card">
            <div class="card-body text-center" id="card-img-1">
              <img alt="" class="sp-image-facebook-import" src="assets/images/backgrounds/facebook-criteria.png" />
            </div>
            <div class="pt-2 pb-3 pr-2 card-footer row no-gutters d-flex justify-content-end">
              <div class="col-md-auto align-self-center pr-2">
                <span *ngIf="isSelected == false">Facebook interest</span>
                <span *ngIf="isSelected == true">Facebook criteria</span>
              </div>
              <div class="col-md-auto ml-xxxl-3 ml-md-1">
                <button class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Change type" matTooltipHideDelay="10" routerLink="/projects/create/select" routerLinkActive="active" type="button">
                  <span> Change audience </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="sp-card w-100 sp-card-2">
          <div class="card">
            <div class="card-body text-center" id="card-img-2">
              <img class="sp-image-facebook-import" src="assets/images/backgrounds/facebook-import.svg" />
            </div>
            <div class="pt-2 card-footer row no-gutters d-flex justify-content-end">
              <div class="col-md-auto align-self-center pr-2">
                <span></span>
              </div>
              <div class="col-md-auto pb-3 pr-2">
                <button class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Change type" matTooltipHideDelay="10" type="button">
                  <span> Change type </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="sp-card w-100 sp-card-3">
          <div class="card">
            <div class="card-body text-center" id="card-img-3">
              <img class="sp-image-facebook-import" src="assets/images/backgrounds/facebook-import.svg" />
            </div>
            <div class="pt-2 card-footer row no-gutters d-flex justify-content-end">
              <div class="col-md-auto align-self-center pr-2">
                <span></span>
              </div>
              <div class="col-md-auto pb-3 pr-2">
                <button class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Change type" matTooltipHideDelay="10" type="button">
                  <span> Change type </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="sp-card w-100 sp-card-4">
        <div class="card">
          <div class="card-body text-center" id="card-img-4">
            <img class="sp-image-facebook-import" src="assets/images/backgrounds/facebook-import.svg" />
          </div>
          <div class="pt-2 card-footer row no-gutters d-flex justify-content-end">
            <div class="col-md-auto align-self-center pr-2">
              <span></span>
            </div>
            <div class="col-md-auto pb-3 pr-2">
              <button class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Change type" matTooltipHideDelay="10" type="button">
                <span> Change type </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <app-sp-project-create-header></app-sp-project-create-header>
  <div id="step1">
  <!--<h4>Project's setup</h4>
  <div class="row no-gutters pt-3 pb-5">
    <div class="w-100 sp-card">
      <div class="card ">
        <div class="card-body">
          <div class="row">
            <div class="col-6 pt-4 pb-4 pl-4 pr-3">
              <mat-form-field appearance="outline" class="w-100  sp-form-field">
                <mat-label class="sp-input-label">
                  <span>Project Name</span>
                </mat-label>
                <mat-icon class="sp-input-icon " matPrefix style="transform: translateY(-10px)" svgIcon="icon_sp_label"></mat-icon>
                <input #projectNameInput (change)="onProjectNameChange(projectNameInput.value)" [formControl]="projectNameControl" class="sp-input-content"  matInput maxlength="25" placeholder="Type project’s name..."/>
                <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="To create you project, please define the name of it." matTooltipHideDelay="10" style="transform: translateY(-3px)">
                    <span class="">i</span>
                 </span>
              </mat-form-field>
              <span class="float-right">{{projectNameInput.value.length}} / 25</span>
            </div>
            <div class="col-6 pt-4 pb-4 pl-3 pr-4 sp-project-setup-analysis">
              <mat-form-field (click)="reInit()" appearance="outline" class="w-100 sp-form-field">
                <mat-label class="sp-input-label">Project universe</mat-label>
                <mat-icon class="sp-input-icon " matPrefix svgIcon="icon_sp_world"></mat-icon>
                <mat-select (selectionChange)="onUniverseSelected($event.value)" [(ngModel)]="selectedUniverse" [formControl]="universeControl" [placeholder]="selectedUniverses.length == 0 ? 'Select at least one universe' : 'Not found'" class="sp-input-content" panelClass="sp-select-option-icon">
                  <input [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" aria-label="Number" class="pl-3 sp-input-search-content" matInput placeholder="Pick one" type="text">
                  <mat-option *ngFor="let universe of universes |  FilterPipe : searchText" [value]="universe">{{ universe.name }}</mat-option>
                </mat-select>
                <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="By selecting a geographical universe, you will get a detailed map and additional relevant criteria for that universe (ex: local brands, media,… )." matTooltipHideDelay="10">
                      <span class="">i</span>
                   </span>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->

    <app-sp-project-create-project-setup [options]="{universeNameFallback: 'Worldwide', showPresetSelection: showPresetSelection}"></app-sp-project-create-project-setup>

    <app-sp-project-create-audience-setup class="mt-3" [options]="{
      allowAudienceImport: false,
      allowGeolocationEditing: true,
      allowSociodemoOptions: true
    }"></app-sp-project-create-audience-setup>
    <!--<div class="row no-gutters pb-5">
      <div class="w-100 card">
        <div class="card-body p-3 sp-project-setup-localization">
          <h5 class="pl-3">Audience location</h5>
          <div class="row pb-3 no-gutters">
            <div class="col-12">
          <div class="pb-3 pl-3 pr-3 pt-4 mb-2 sp-project-localization">
            <mat-form-field appearance="outline" class="w-100 mb-3 sp-form-field">
              <mat-label class="sp-input-label">
                <span>Localization</span>
              </mat-label>
              <mat-icon class="sp-input-icon " matPrefix svgIcon="icon_sp_localization"></mat-icon>
              <input #geolocInput (keyup)="onGeolocationInputChange(geolocInput.value, $event)" [(ngModel)]="includedGeolocationField.geolocation" [matAutocomplete]="geolocAuto" class="sp-input-content" matInput placeholder="Type one or several locations" />
              <span class="sp-information-tooltip sp-transform-icon" matSuffix matTooltip="Here you can define the geographical scope of your project. Worldwide, continent, country, region, city can be selected.  You have also the possibility to select multiple area or exclude it." matTooltipHideDelay="10">
                        <span class="">i</span>
                     </span>
              <mat-autocomplete #geolocAuto="matAutocomplete" (optionSelected)="onGeolocationSelected($event.option.value, TargetingType.INCLUSION); geolocInput.value = ''" [displayWith]="projectCreationManager.geolocToString">
                <mat-option *ngFor="let geolocation of geolocations" [value]="geolocation">{{geolocation ? projectCreationManager.geolocToString(geolocation) : "Loading..."}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-chip-list aria-label="Geolocation selection" class="sp-chips sp-chips-input">
              <mat-chip (removed)="onGeolocationDeleted($event.chip.value, TargetingType.INCLUSION)" *ngFor="let geolocation of includedGeolocations" [removable]="true"
                        [value]="geolocation" class="ml-2">
                {{projectCreationManager.geolocToString(geolocation)}}
                <mat-icon *ngIf="removable" class="sp-icon-chips-delete" matChipRemove svgIcon="icon_sp_x">cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
            <div class="sp-project-criteria-button">
              <button (click)="toggleGeolocationExclusion()" *ngIf="!excludedGeolocationField" class="btn btn-primary rounded-circle sp-btn sp-btn-primary sp-btn-circle sp-btn-red" title="Exclude interest" type="button">
                <span class="sp-icon">
                  <i class="icon-sp-minus"></i>
                </span>
              </button>
            </div>
          </div>
          <div *ngIf="excludedGeolocationField" class="row mb-3 no-gutters">
            <div class="col-2">

            </div>
            <div class="col-1 sp-project-interest-minus">
              <div class="row line vl line-less">
                <i class="icon-sp-minus"></i>
              </div>
            </div>
            <div class="col-9">
              <div class="p-3 sp-project-localization" style="border: 1px solid #F51C1C">
                <button (click)="toggleGeolocationExclusion()" class="btn btn-primary rounded-circle sp-btn sp-btn-secondary sp-btn-circle sp-btn-remove" title="Delete action" type="button">
                  <span class="sp-icon" style="transform: translateX(0.05rem)">
                    <i class="icon-sp-escape"> </i>
                  </span>
                </button>
                <mat-form-field appearance="outline" class="sp-form-field" style="width: 95%">
                  <mat-label class="sp-input-label">
                    <span>Localization</span>
                  </mat-label>
                  <mat-icon class="sp-input-icon " matPrefix svgIcon="icon_sp_localization"></mat-icon>
                  <input #geoInput (keyup)="onGeolocationInputChange(geoInput.value, $event)" [(ngModel)]="excludedGeolocationField.geolocation" [matAutocomplete]="geoAuto" class="sp-input-content" matInput placeholder="Type one or several locations" />
                  <mat-autocomplete #geoAuto="matAutocomplete" (optionSelected)="projectCreationManager.addGeolocation($event.option.value, TargetingType.EXCLUSION); geoInput.value = ''" [displayWith]="projectCreationManager.geolocToString">
                    <mat-option *ngFor="let geolocation of geolocations" [value]="geolocation">{{ geolocation ? projectCreationManager.geolocToString(geolocation) : "Loading..." }}</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-chip-list aria-label="Geolocation selection" class="sp-chips sp-chips-input">
                  <mat-chip (removed)="onGeolocationDeleted(geolocation, TargetingType.EXCLUSION)" *ngFor="let geolocation of excludedGeolocations" [removable]="true"
                            [value]="geolocation" class="ml-2">
                    {{ projectCreationManager.geolocToString(geolocation) }}
                    <mat-icon *ngIf="removable" class="sp-icon-chips-delete" matChipRemove svgIcon="icon_sp_x">cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
                <div class="sp-project-interest-2-plus sp-project-criteria-button sp-project-criteria-button-plus">
                </div>
              </div>
            </div>
          </div>
          <div  class="sp-project-localization-option d-flex justify-content-center">
            <button (click)="showOptions()" [hidden]="!showMoreOptions" class="btn btn-primary sp-btn sp-btn-sm sp-btn-primary " matTooltip="More option" matTooltipHideDelay="10" style="transform: translateY(10px)" type="button">
              <span class="sp-icon">
                <i class="icon-sp-plus"></i>
              </span>
              <span class="pl-3">More option</span>
            </button>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
    <div [hidden]="showMoreOptions" class="row mb-5">
      <div class="card w-100">
        <div class="sp-project-localization-option-less d-flex justify-content-center">
          <button (click)="hideOptions()" class="btn btn-primary sp-btn sp-btn-sm sp-btn-primary sp-btn-red" matTooltip="Less option" matTooltipHideDelay="10" type="button">
           <span class="sp-icon">
              <i class="icon-sp-minus"></i>
            </span>
            <span class="pl-3">Less option</span>
          </button>
        </div>

        <div class="card-body p-4">
          <div class="row">
            <div class="col-6 pr-4" style="border-right: 1px dotted lightgray">
              <div class="mb-4">
                <h4 class="pb-3">Select a data source</h4>
                <mat-form-field appearance="outline" class="w-100 sp-form-field">
                  <mat-label class="sp-input-label">Data source</mat-label>
                  <mat-icon class="sp-input-icon" matPrefix svgIcon="icon_sp_data_source"></mat-icon>
                  <mat-select (selectionChange)="onPlatformUpdate()" [(ngModel)]="platformsSelected" [formControl]="toppings" class="sp-input-content" multiple>
                    <mat-option [value]="AudienceInfo.facebookPlatforms">Facebook</mat-option>
                    <mat-option [value]="AudienceInfo.instagramPlatforms">Instagram</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-6 pl-4">
              <div class="mb-4">
                <h4 class="pb-3">Select age</h4>
                <h6 *ngIf="ages[1] <65" class="mt-3">Range: {{ages[0] + ' - ' + ages[1]}}</h6>
                <h6 *ngIf="ages[1] >=65" class="mt-3">Range: {{ages[0] + ' - ' + ages[1]+'+'}}</h6>

                <p-slider (ngModelChange)="ageSliderChange.next(ages)" [(ngModel)]="ages" [max]="65" [min]="13" name="ranger" range="true"></p-slider>
              </div>
            </div>
          </div>
             <div class="row mb-4">
               <div class="col-6" style="border-right: 1px dotted lightgray">
                 <h4 class="pb-3">Select a gender</h4>
                 <div class="row">
                   <div class="col-4">
                     <div [style.borderColor]=" genders.length == 2 || genders.length == 0 ? '#F36F31': 'white' " class="card sp-project-gender" style="border: 1px solid">
                       <div (click)="updateGenders([1, 2])" class="card-body text-center">
                           <div (click)="updateGenders([1, 2])" class="card-body text-center">
                             <img alt="" class="sp-image-facebook-import" src="assets/images/socio-demo/genderMale.png" />
                             <img alt="" class="sp-image-facebook-import" src="assets/images/socio-demo/genderFemale.png" />
                           </div>
                       </div>
                     </div>
                   </div>
                   <div class="col-4">
                     <div [style.borderColor]=" genders.length == 1 && genders[0] == 1 ? '#F36F31': 'white' " class="card sp-project-gender" style="border: 1px solid">
                         <div (click)="updateGenders([1])" class="card-body text-center">
                           <div (click)="updateGenders([1])" class="card-body text-center">
                             <img alt="" class="sp-image-facebook-import" src="assets/images/socio-demo/genderMale.png" />
                           </div>
                        </div>
                     </div>
                   </div>
                   <div class="col-4">
                     <div [style.borderColor]=" genders.length == 1 && genders[0] == 2 ? '#F36F31': 'white' " class="card sp-project-gender" style="border: 1px solid">
                       <div (click)="updateGenders([2])" class="card-body text-center">
                         <div (click)="updateGenders([2])" class="card-body text-center">
                           <img alt="" class="sp-image-facebook-import" src="assets/images/socio-demo/genderFemale.png" />
                         </div>
                       </div>
                      </div>
                   </div>
                 </div>
               </div>
               <div class="col-6">
                 <h4 class="pb-3">Language of your audience</h4>
                 <mat-form-field appearance="outline" class="w-100  sp-form-field">
                   <mat-label class="sp-input-label">
                     <span>Language</span>
                   </mat-label>
                   <mat-icon class="sp-input-icon " matPrefix svgIcon="icon_sp_language"></mat-icon>
                   <input #localeInput (keyup)="onLocaleInputChange(localeInput.value, $event)" [matAutocomplete]="localeAuto" class="sp-input-content" matInput placeholder="Type languages" />
                   <mat-autocomplete #localeAuto="matAutocomplete" (optionSelected)="onLocaleSelected($event.option.value); localeInput.value = ''" [displayWith]="localeToString">
                     <mat-option *ngFor="let locale of locales" [value]="locale">{{locale ? locale.toString() : "Loading..."}}</mat-option>
                   </mat-autocomplete>
                 </mat-form-field>
                 <mat-chip-list aria-label="Geolocation selection" class="sp-chips sp-chips-input">
                   <mat-chip (removed)="onLocaleDeleted(locale)" *ngFor="let locale of includedLocales" [removable]="true"
                             [value]="locale" class="ml-2">
                     {{ locale.toString() }}
                     <mat-icon *ngIf="removable" class="sp-icon-chips-delete" matChipRemove svgIcon="icon_sp_x">cancel</mat-icon>
                   </mat-chip>
                 </mat-chip-list>
               </div>
            </div>
          </div>
      </div>
    </div>-->
  </div>
  <div class="w-100 row d-flex justify-content-center sp-notification-div">
    <p-messages [(value)]="msgs" class="w-50 sp-notification"></p-messages>
  </div>
</main>
