import {SpThemingService} from '../../services/sp-theming/sp-theming.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {debounceTime, EMPTY, firstValueFrom, Subject, Subscription, switchMap} from 'rxjs';
import {Targeting} from '../../classes/targeting/targeting';
import {AdAccount} from '../../models/ad-account';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/sp-authentication/authentication.service';
import {FacebookLinkService} from '../../services/sp-facebook/facebook-link.service';
import {AlertService} from '../../services/sp-alert/alert.service';
import {
  AudienceInfo,
  AudienceType,
  CriteriaField,
  ProjectCreationManagerService,
  TargetingType
} from '../../services/sp-project/project-creation-manager.service';
import {FACEBOOK_GE0_SUPPORT, FbCriteria, FbGeolocation, FbGeolocationGroup, FbLocale} from '../../types/facebook-types';
import {ApiAudienceService} from '../../services/sp-api/sp-api-audience/api-audience.service';
import {KeyboardUtils} from '../../utils/keyboard/keyboard-utils';
import {Audience} from '../../models/audience';
import {first} from 'rxjs/operators';
import {ActiveToast} from 'ngx-toastr';
import {ApiService} from '../../services/sp-api/api.service';
import {LoaderService} from '../../services/sp-loading/loader.service';
import {LoggerService} from '../../services/sp-logger/logger.service';
import {SocketService} from '../../services/sp-ws/socket.service';
import {environment} from '../../../environments/environment';

interface MetaTargetMatching {
  objective: string;
  optimization: string;
  billing_events: string;
}

@Component({
  selector: 'app-sp-meta-targeting',
  templateUrl: './sp-meta-targeting.component.html',
  styleUrls: ['./sp-meta-targeting.component.scss']
})
export class SpMetaTargetingComponent implements OnInit,AfterViewInit,OnDestroy {
  private readonly appName: string = 'SOPRISM';

  /// Pour la card campaign name
  //Campaign name
  campaignName: string;
  stepNumber = 1 ;

  exportName?: string;
  exportType?: string;
  exportAccountId?: string;
  exportAccountName?: string;
  //AdAccount
  adAccounts: AdAccount[] = [];
  selectedAdAccount?: AdAccount;

  // AdSet required info
  dsaPayer: string = '';
  dsaBeneficiary: string = '';

  /// Pour la card attributes
  //Gender
  femaleSelected = false;
  maleSelected = false;
  //Age
  rangeValues: number[] = [18, 65];
  ageSliderChange: Subject<number[]> = new Subject<number[]>();
  private ageSliderSubscription: Subscription;
  //Geoloc
  localizationInput = [];
  geolocations: FbGeolocation[] = [];
  fbCriteriaList: Array<FbCriteria|null> = [];
  locales: FbLocale[] = [];
  ///Des trucs divers
  separatorKeysCodes: number[] = [ENTER, COMMA];

  ///Pour la card social media
  //Meta Platform
  facebookSelected = false;
  instagramSelected = false;

  ///Pour la card Interest
  exportTargeting?: Targeting;
  private geolocationInputSubscription: Subscription;
  private geolocationInputChange: Subject<string> = new Subject<string>();
  //Interest
  private criteriaInputChange: Subject<{query: string, locales: boolean}> = new Subject();
  private localeInputChange: Subject<string> = new Subject<string>();
  private localeInputSubscription: Subscription;
  private campaignId?: number;
  private subs: Subscription[] = [];
  private newTargeting;

  // Notifications
  private pendingNotification?: ActiveToast<any>;

  // Objective & Matching table
  objectives: {label: string, value: string}[] = [
    //{value: 'OUTCOME_APP_PROMOTION', label: 'App Promotion'},
    {value: 'OUTCOME_AWARENESS', label: 'Awareness'},
    {value: 'OUTCOME_ENGAGEMENT', label: 'Engagement'},
    {value: 'OUTCOME_LEADS', label: 'Leads'},
    {value: 'OUTCOME_SALES', label: 'Sales'},
    {value: 'OUTCOME_TRAFFIC', label: 'Traffic'},
  ];

  campaignObjectiveDefault = this.objectives[0];
  adAccountDefault: AdAccount;
  // Put this in ngModel of objectives mat select, the matching stuff is done when creating the campaign/ad set
  selectedObjective?: {label: string, value: string};
  metaTargetMatching: MetaTargetMatching[] = [];

  constructor(private _spTheming: SpThemingService,
              private route: ActivatedRoute,
              private router: Router,
              private auth: AuthenticationService,
              private fb: FacebookLinkService,
              private alert: AlertService,
              private api: ApiService,
              private apiAudience: ApiAudienceService,
              private projectCreationManager: ProjectCreationManagerService,
              private loader: LoaderService,
              private logger: LoggerService,
              private socket: SocketService) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-desk');
  }

  async ngOnInit() {
    this.loader.load('Please wait');
    this.adAccounts = this.auth.session.user.ad_accounts;

    if (this.adAccounts.length == 0) {
      this.pendingNotification = this.alert.notify('Warning',
        'You need to have at least 1 ad account to use the export tool. You can click here to navigate to \'My profile\' and update your info',
        'warning',
        {disableTimeOut: true});
      this.pendingNotification.onTap.pipe(first()).subscribe(() => {
        this.router.navigate(['account', 'edit']).then();
      });
    }

    this.route.queryParamMap.subscribe(params => {
      if (params.has('targeting') && params.has('name') && params.has('type')) {
        this.exportName = params.get('name');
        this.exportType = params.get('type');
        if (params.has('adAccountId') && params.has('adAccountName')) {
          this.exportAccountId = params.get('adAccountId');
          this.exportAccountName = params.get('adAccountName');
          this.adAccounts = this.adAccounts.filter(a => a.facebook_id == this.exportAccountId);
        }
        const audienceType: string = params.has('audienceType') ? params.get('audienceType') : 'sociodemo'
        this.exportTargeting = Targeting.fromJson(params.get('targeting'), audienceType);
        if (this.exportAccountId) {
          if (this.adAccounts.length > 0) {
            const account = this.adAccounts[0];
            this.exportTargeting.extra = {
              accountId: this.exportAccountId,
              accountName: account.name,
              accountToken: account.token,
              subtype: 'unknown',
              id: '-1'
            };
          } else {
            if (this.pendingNotification) this.pendingNotification.toastRef.close();
            this.pendingNotification = this.alert.notify('Warning',
              'We cannot find the ad account \"' + this.exportAccountName + '\" used for this custom audience. You can click here to navigate to \'My profile\' and update your info',
              'warning',
              {disableTimeOut: true});
            this.pendingNotification.onTap.pipe(first()).subscribe(() => {
              this.router.navigate(['account', 'edit']).then();
            });
            this.exportTargeting.customAudiences = undefined;
            this.exportTargeting.extra = undefined;
            this.exportTargeting.type = 'sociodemo';
          }
        }
        if (this.exportTargeting.ageMin < 18) this.exportTargeting.ageMin = 18;

        this.projectCreationManager.pushAudienceType(this.exportTargeting.toAudience(this.exportName, '', 'en_US', this.exportTargeting.type), AudienceType.target);
        // Loading matching table between objective and other values
        firstValueFrom(this.api.get<MetaTargetMatching[]>('meta_target_matching')).then(matching => {
          this.metaTargetMatching = matching;
        }).finally(() => this.loader.loaded());
      } else {
        this.alert.notify("Error", "You must specify the targeting and the name to continue", "error");
      }
    });
    const sub = this.criteriaInputChange.pipe(
      debounceTime(500),
      switchMap(query => {
        if (query.query !== "") this.fb.searchFbInterest(query.query).then(criterion => {
          this.fbCriteriaList = criterion;
          if (query.locales) {
            this.fb.searchLocale(query.query).then(locales => {
              this.locales = locales;
            });
          }
        });
        else this.fbCriteriaList = [];
        return EMPTY;
      })
    ).subscribe();
    this.subs.push(sub);

    this.geolocationInputSubscription = this.geolocationInputChange.pipe(
      debounceTime(500),
      switchMap(query => {
        if (query !== "") this.fb.searchGeolocation(query).then(geolocations => {
          this.geolocations = geolocations.filter(g => FACEBOOK_GE0_SUPPORT.includes(g.type));
        });
        else this.geolocations = [];
        return EMPTY;
      })
    ).subscribe();

    this.ageSliderSubscription = this.ageSliderChange.pipe(
      debounceTime(500),
      switchMap(() => {
        this.updateAges(this.rangeValues);
        return EMPTY;
      })
    ).subscribe();

    this.localeInputSubscription = this.localeInputChange.pipe(
      debounceTime(500),
      switchMap(query => {
        if (query !== "") this.fb.searchLocale(query).then(locales => {
          this.locales = locales;
        });
        else this.locales = [];
        return EMPTY;
      })
    ).subscribe();
    this.selectedObjective = this.campaignObjectiveDefault;

    this.adAccountDefault = this.adAccounts[0];
    await this.adAccountSelect();
  }

  hideExclusion = false;

  ngOnDestroy() {
    if (this.pendingNotification) {
      this.pendingNotification.toastRef.close();
    }

    this.subs.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    if (this.exportTargeting) {
      this.currentAudience.targeting = this.exportTargeting;
      this.projectCreationManager.loadAudienceInfoFromTargeting(this.exportTargeting, AudienceType.target);
      this.projectCreationManager.refreshAudienceSize(this.currentAudience, this.exportTargeting).catch(error => {
        const err = error.error;
        if (err && err.error && err.error.code && (err.error.message || err.error.error_user_msg) && err.error.code == 100) { // Most likely to be facebook error
          //const msg: string = err.error.error_user_msg ? err.error.error_user_msg : err.error.message;
          if (this.exportTargeting.type == 'custom' || this.exportTargeting.type == 'fanpage') {
            let id: string;
            let customType: string;
            if (this.exportTargeting.customAudiences && this.exportTargeting.customAudiences.length > 0) {
              id = this.exportTargeting.customAudiences[0].id;
              customType = 'custom audience';
            } else if (this.exportTargeting.connections && this.exportTargeting.connections.length > 0) {
              id = this.exportTargeting.connections[0].id;
              customType = 'fan page';
            }

            const title: string = err.error.error_user_title ? err.error.error_user_title : ('Invalid ' + customType);

            if (id && customType) {

              this.projectCreationManager.audienceTarget.data_extra = undefined;
              if (this.exportTargeting.customAudiences) this.exportTargeting.customAudiences = undefined;
              if (this.exportTargeting.connections) this.exportTargeting.connections = undefined;
              this.projectCreationManager.pushAudienceType(
                this.exportTargeting.toAudience(this.exportName, '', 'en_US', 'sociodemo'),
                AudienceType.target
              );
              this.updateInfo();

              let notificationMessage: string;
              // Invalid custom audience notification
              if (err.error.error_subcode === 1487366) {
                // Custom audience has been deleted
                notificationMessage = 'The custom audience you are referring to has been deleted and is no longer available';
              } else if (err.error.error_subcode === 1487124) {
                // Invalid connection (fan page)
                notificationMessage = 'You do not have the necessary rights for this connection (fan page)';
              } else {
                notificationMessage = 'It seems like we cannot process your ' + customType + ', it may have been deleted or you could not have the right permissions';
              }

              this.alert.notify(title, notificationMessage + '. As a result, it has been removed from your targeting.', 'error');
            }
          }
        }
      });
    }

    if(this.exportTargeting.genders.includes(1)) {
      this.genderSelect(null,true, false)
    }
    if(this.exportTargeting.genders.includes(2)) {
      this.genderSelect(true,null, false)
    }

    if(this.exportTargeting.platforms[1]) {
      this.socialMediaSelect(true,null, false)
    }
    if(this.exportTargeting.platforms[3]) {
      this.socialMediaSelect(null,true, false)
    }
    this.rangeValues[0] = this.exportTargeting.ageMin;
    this.rangeValues[1] = this.exportTargeting.ageMax;
    this.campaignName = this.exportName;

    this.newTargeting = this.exportTargeting;
    this.blurInput();
  }

  campaignObjectiveSelect(){
    this.selectedObjective = this.campaignObjectiveDefault;
  }
  async adAccountSelect(){
    // Settings these params empty so the validation button is disabled while the info is being retrieved
    this.dsaBeneficiary = '';
    this.dsaPayer = '';

    try {
      const accountDetails = await this.fb.getAdAccountInfo(this.adAccountDefault.facebook_id);
      if (accountDetails.default_dsa_payor) {
        this.dsaPayer = accountDetails.default_dsa_payor;
      } else {
        this.dsaPayer = this.auth.session.user.companyName;
      }
      if (accountDetails.default_dsa_beneficiary) {
        this.dsaBeneficiary = accountDetails.default_dsa_beneficiary;
      } else {
        this.dsaBeneficiary = this.auth.session.user.companyName;
      }
    } catch (err) {
      this.dsaBeneficiary = this.auth.session.user.companyName;
      this.dsaPayer = this.auth.session.user.companyName;
    }

    this.selectedAdAccount = this.adAccountDefault;
  }

  blurInput(){
    document.getElementById("sp-test-input").classList.remove('mat-focused' );
   const elems = document.getElementsByClassName('sp-form-field') as HTMLCollectionOf<HTMLElement>;
    for(let i = 0; i < elems.length; i++) {
      elems[i].blur();
    }
  }

  addExcludedCriterionField() {
    this.hideExclusion = true;
    this.projectCreationManager.addExcludedCriterionField();
  }

// Des fonctions (enfin askip)
  genderSelect(female: boolean, male:boolean, update: boolean = true){
    if (female) {
      this.femaleSelected = !this.femaleSelected;
      if(this.femaleSelected) {
        document.getElementById("sp-attributes-female").style.border = "3px solid #B0D8D4"
        document.getElementById("sp-attributes-female").style.boxShadow = "box-shadow: 0px 4px 24px rgba(242, 158, 119, 0.15);"
      } else {
        document.getElementById("sp-attributes-female").style.border = "0px"
      }
    }
    if (male) {
      this.maleSelected = !this.maleSelected;
      if(this.maleSelected) {
        document.getElementById("sp-attributes-male").style.border = "3px solid #B0D8D4"
        document.getElementById("sp-attributes-male").style.boxShadow = "box-shadow: 0px 4px 24px rgba(242, 158, 119, 0.15);"
      } else {
        document.getElementById("sp-attributes-male").style.border = "0px"
      }
    }

    if (update) {
      if (this.maleSelected || this.femaleSelected) {
        const genders: number[] = [];
        if (this.maleSelected) genders.push(1);
        if (this.femaleSelected) genders.push(2);
        this.updateGenders(genders);
      } else {
        this.updateGenders([]);
      }
    }
  }

  updateAges(ages: number[]) {
    this.projectCreationManager.currentInfo.ages = ages;
    this.updateInfo();
  }
  updateGenders(genders: number[]) {
    this.projectCreationManager.currentInfo.genders = genders;
    this.updateInfo();
  }

  onPlatformUpdate() {
    const platforms: string[][] = [];

    if (this.facebookSelected) platforms.push(AudienceInfo.facebookPlatforms);
    if (this.instagramSelected) platforms.push(AudienceInfo.instagramPlatforms);

    this.projectCreationManager.currentInfo.platformsSelected = platforms;
    this.updateInfo();
  }

  socialMediaSelect(facebook: boolean, instagram:boolean, update: boolean = true){
    if(facebook) {
      this.facebookSelected = !this.facebookSelected;
      if(this.facebookSelected) {
        document.getElementById("sp-facebook").style.border = "3px solid #B0D8D4"
        document.getElementById("sp-facebook").style.boxShadow = "box-shadow: 0px 4px 24px rgba(242, 158, 119, 0.15);"
      } else {
        document.getElementById("sp-facebook").style.border = "0px"
      }

    }
    if(instagram){
      this.instagramSelected = !this.instagramSelected;
      if(this.instagramSelected) {
        document.getElementById("sp-instagram").style.border = "3px solid #B0D8D4"
        document.getElementById("sp-instagram").style.boxShadow = "box-shadow: 0px 4px 24px rgba(242, 158, 119, 0.15);"
      } else {
        document.getElementById("sp-instagram").style.border = "0px"
      }
    }

    if (update) this.onPlatformUpdate();
  }

// Ici c'est toutes les fonctions pour les interest
  onCriteriaInputChange(query: string, locales: boolean = false) {
    this.fbCriteriaList = [null];
    this.criteriaInputChange.next({query, locales});
  }

  onCriteriaSelected(element: any, field: CriteriaField, type: TargetingType, locale: boolean = false) {
    this.fbCriteriaList = [];

    if (locale) {
      if (!this.projectCreationManager.currentInfo.includedLocales.find(l => l.key === element.key)) {
        this.projectCreationManager.currentInfo.includedLocales.push(element);
        this.updateInfo();
      }
    } else {
      this.projectCreationManager.addCriteria(element, field, type);
    }
  }

  /**
   * Criteria deletion event trigger
   * Used to delete a previously selected criteria
   */
  onCriteriaDeleted(field: CriteriaField, value: FbCriteria, type: TargetingType) {
    this.projectCreationManager.deleteCriteria(field, value, type);
  }

  onLocaleDeleted(value: FbLocale) {
    this.includedLocales.splice(this.includedLocales.indexOf(value), 1);

    this.updateInfo();
  }

  addIncludedCriterionField() {
    const field = new CriteriaField();
    this.includedCriteriaFields.push(field);
  }


  toggleCriteriaExclusion() {
    if (this.excludedCriteriaField) {
      this.hideExclusion = false;
      this.excludedCriterion = [];
    } else {

      this.excludedCriteriaField = new CriteriaField();
    }
  }

  removeIncludeCriteriaField(field: CriteriaField) {
    this.includedCriterion.delete(field);
    this.includedCriteriaFields.splice(this.includedCriteriaFields.indexOf(field), 1);
    this.updateInfo();
  }

// Ici y'a les géoloc mon reuf
  onGeolocationSelected(value: FbGeolocation, type: TargetingType) {
    this.localizationInput.push(value.name);
    this.projectCreationManager.addGeolocation(value, type);
  }
  geolocToString(geolocation: FbGeolocation) {
    return this.projectCreationManager.geolocToString(geolocation);
  }
  onGeolocationInputChange(query: string, keyboardEvent: KeyboardEvent) {
    const key = keyboardEvent.key;

    // If the key is a char or the backspace key
    if (KeyboardUtils.isValidCharacter(key) || key == "Backspace") {
      this.geolocations = [null];
      this.geolocationInputChange.next(query);
    }
  }
  onGeolocationDeleted(geolocation: FbGeolocation) {
    this.projectCreationManager.deleteGeolocation(geolocation, TargetingType.INCLUSION);
  }

  onGeolocationGroupDeleted(geolocationGroup: FbGeolocationGroup) {
    this.projectCreationManager.deleteGeolocationGroup(geolocationGroup);
  }

  private updateInfo(updateBenchmark: boolean = false) {
    this.projectCreationManager.updateCurrentAudience(updateBenchmark).then();
  }

// Ici c'est la finalité , pouvoir créer une campagne
  async createCampaign() {
    if (!this.campaignName || !this.exportTargeting) return;
    const res = await this.fb.createAdCampaign(
      this.selectedAdAccount,
      this.campaignName,
      this.selectedObjective?.value);
    this.campaignId = res.id;
  }

  async createAdSet() {
    const handleFbError = (err: any) => {
      if (err.error_user_msg) {
        this.alert.notify('Facebook Error', err.error_user_msg, 'error');
      } else if (err.code === 190) {
        this.alert.notify('Facebook Error', 'There was an issue with Facebook, you should try updating your ad accounts', 'error');
      } else if (err == 'Login status : unknown' || err == 'Login status : not_authorized' || err == 'Login status : not_logged_in') {
        const toast = this.alert.notify('Facebook Error', 'We could not connect to your Facebook account, please check if your account is still linked with ' + this.appName, 'error');
        const sub = toast.onTap.subscribe(() => {
          this.router.navigate(['account', 'edit']);
        });
        firstValueFrom(toast.onHidden).then(() => sub.unsubscribe());
      } else {
        this.alert.notify('Error', 'An unknown error has occurred, please try again later.', 'error');
        this.logger.logError(err.message ? err.message : 'No error message information', 3, err);
      }
    }

    const dsaBeneficiaryOk = this.dsaBeneficiary && this.dsaBeneficiary.length > 0;
    const dsaPayerOk = this.dsaPayer && this.dsaPayer.length > 0;
    if (!this.exportName || !this.exportTargeting || !dsaBeneficiaryOk) return;
    if (!this.campaignId) {
      try {
        await this.createCampaign();
      } catch (err) {
        handleFbError(err);
        return;
      }
    }
    if (!dsaPayerOk) this.dsaPayer = this.dsaBeneficiary;
    const targeting = this.currentAudience.targeting;
    // Adding location_types to match europe rules for adsets
    if (targeting && targeting.geolocations) {
      targeting.geolocations.location_types = ['home', 'recent'];
    }

    try {
      const matching = this.metaTargetMatching.find(m => m.objective == this.selectedObjective.value);
      if (!matching) {
        const errMsg =
          'Misconfigured campaign objective matching, please report this behavior. As a result, the campaign objective is set to Awareness by default';
        this.logger.logError(errMsg, 3);
        this.alert.notify('Error', errMsg, 'error');
      }
      const adSet = await this.fb.createAdSet(
        this.selectedAdAccount,
        this.campaignId,
        this.campaignName,
        targeting.toFbJsonString(),
        this.dsaPayer,
        this.dsaBeneficiary,
        undefined,
        undefined,
        matching?.billing_events,
        matching?.optimization
      );
      this.alert.notify("AdSet created", "AdSet created successfully with ID " + adSet.id, "success");
      if (this.exportType == 'project') this.socket.sendMessageType('meta-adset-create-audience', {name: this.exportName});
      else this.socket.sendMessageType('meta-adset-create-segment', {name: this.exportName});
      this.campaignId = undefined; // Removing campaign ID so the new one is not using old campaign if done with the same data
    } catch (err) {
      handleFbError(err);
    }
  }

  exportIsValid(): boolean {
    const dsaBeneficiaryOk = this.dsaBeneficiary && this.dsaBeneficiary.length > 0;
    //const dsaPayorOk = this.dsaPayer && this.dsaPayer.length > 0;

    return this.selectedAdAccount && this.metaTargetMatching.length > 0 && dsaBeneficiaryOk; //&& dsaPayorOk
  }

  get currentAudience(): Audience {
    return this.projectCreationManager.currentAudience;
  }

  get includedLocales() {
    return this.projectCreationManager.currentInfo.includedLocales
  }

  get TargetingType(): typeof TargetingType {
    return TargetingType;
  }

  get includedGeolocationGroups() {
    return this.projectCreationManager.currentInfo.includedGeolocationGroups;
  }

  get includedGeolocations() {
    return this.projectCreationManager.currentInfo.includedGeolocations;
  }

  get excludedCriteriaField() {
    return this.projectCreationManager.currentInfo.excludedCriteriaField;
  }

  set excludedCriteriaField(field: CriteriaField) {
    this.projectCreationManager.audiencesInfo.get(AudienceType.target).excludedCriteriaField = field;
  }

  get includedCriterion() {
    return this.projectCreationManager.currentInfo.includedCriterion;
  }

  get excludedCriterion() {
    return this.projectCreationManager.currentInfo.excludedCriterion;
  }

  set excludedCriterion(criterion: FbCriteria[]) {
    this.projectCreationManager.currentInfo.excludedCriterion = criterion;
  }

  get includedCriteriaFields() {
    return this.projectCreationManager.currentInfo.includedCriteriaFields;
  }

  protected readonly environment = environment;
}
