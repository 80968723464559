import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {SpModalFacebookAccountAddComponent} from '../sp-modal-facebook-account-add/sp-modal-facebook-account-add.component';
import {AuthenticationService} from '../../../../services/sp-authentication/authentication.service';
import {FacebookLinkService} from '../../../../services/sp-facebook/facebook-link.service';

@Component({
  selector: 'app-sp-account-user-edit-image',
  templateUrl: './sp-account-user-edit-image.component.html',
  styleUrls: ['./sp-account-user-edit-image.component.scss']
})
export class SpAccountUserEditImageComponent implements OnInit {

  selectedImage;
  fbConnected = false;
  constructor( public dialogRef: MatDialogRef<SpModalFacebookAccountAddComponent>,
               public auth: AuthenticationService,
               private fb: FacebookLinkService,) { }

  get session() {
    return this.auth.session
  }

  ngOnInit() {
    this.fb.isLogged().then(logged => {
      this.fbConnected = logged;
    });
  }

  onImageChange(imageInput: HTMLInputElement) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.dialogRef.close(imageInput);
    });

    reader.readAsDataURL(file);
  }

  save() {
    this.dialogRef.close(true);
  }

}
