<div style="display: flex; flex-direction: column; gap:16px" class="sign-in-up-component">
  <div class="sp-header">
    <h2 class="h1 text-center sp-title">Forgot your password?</h2>
    <h3 class="h5 text-center sp-text-secondary sp-baseline">Enter your email address below and we'll get you back on track</h3>
  </div>
  <div class="card p-4" style="display: flex; flex-direction: column; gap:8px" (keydown)="keyDownFunction($event)">
    <mat-form-field class="sp-form-field" appearance="outline">
      <mat-label class="sp-input-label">Email Address</mat-label>
      <input [formControl]="emailFormControl" matInput placeholder="Type your email address..." value="" required/>
      <mat-error *ngIf="emailFormControl.hasError('required')">
        Required
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('email')">
        Invalid email
      </mat-error>
    </mat-form-field>
    <re-captcha (resolved)="onCaptchaResponse($event)" class="text-center sp-captcha" id="recaptcha" name="recaptcha" required></re-captcha>
    <button [disabled]="buttonLocked() || waiting || alreadyRequested" type="button" (click)="requestPasswordReset()" class="btn btn-lg btn-primary sp-btn sp-btn-lg sp-btn-primary" matTooltip="Click here to recover your password" matTooltipHideDelay="10" >
      <span>Request Reset Link</span>
    </button>
  </div>
  <div class="text-center sp-text-secondary">
    Back to <a routerLink="/user/login" routerLinkActive="active-link" matTooltip="Back to sign in" matTooltipHideDelay="10">Sign In</a>
  </div>
</div>
