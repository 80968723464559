<div class="container text-center mt-5 mb-5">
  <div>
    <h4>Do you want to add a new profile picture ?</h4>
  </div>
  <div class="mt-3">
    <input #imagePicker  (change)="onImageChange(imagePicker)" [hidden]="true" accept="image/jpeg,image/gif,image/png" type="file"/>
    <button (click)="imagePicker.click()" class="btn btn-secondary sp-btn sp-btn-secondary" matTooltip="Back to projects" matTooltipHideDelay="10"  routerLinkActive="active" type="button">
      <span>Add custom profile picture</span>
    </button>
    <button (click)="save()" [disabled]="auth.session.user.fb_picture || !fbConnected " class="ml-3 btn btn-primary sp-btn sp-btn-primary" matTooltip="Update your account" matTooltipHideDelay="10" type="button">
      <span>Add facebook profile picture</span>
    </button>
  </div>
</div>
