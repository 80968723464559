import {Component, OnInit} from '@angular/core';
import {SpThemingService} from '../../../../services/sp-theming/sp-theming.service';
import { MatDialog } from '@angular/material/dialog';
import {Message} from 'primeng/api';
import {NavigationService} from '../../../../services/sp-navigation/navigation.service';
import {
  AudienceCreationMode,
  AudienceType,
  ProjectCreationManagerService,
} from '../../../../services/sp-project/project-creation-manager.service';
import introJs from 'intro.js';
import {SpIntroductionComponent} from '../../sp-project-list/sp-introduction/sp-introduction.component';
import {IntroService} from '../../../../services/sp-intro/intro.service';
import {AudienceImportType} from '../../../../services/sp-facebook/facebook-link.service';

@Component({
  selector: 'app-sp-project-create-facebook-import',
  templateUrl: './sp-project-create-facebook-import.component.html',
  styleUrls: ['./sp-project-create-facebook-import.component.scss']
})
export class SpProjectCreateFacebookImportComponent implements OnInit {
  // Searching and filtering
  hideScroll = false;
  introJS = introJs();
  subscription : any;
  // Notifications
  msgs: Message[] = [];

  // Constructor.
  constructor(private _spTheming: SpThemingService,
              public projectCreationManager: ProjectCreationManagerService,
              private navigation: NavigationService,
              private dialog: MatDialog,
              private introService: IntroService) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-desk');
    //this.introService.setCurrentArea(IntroArea.PROJECT_CREATE_IMPORT);

    this.subscription = this.introService.introStarted$.subscribe((introStarted) => {
      if (introStarted) {
        this.hideScroll = true;
        document.documentElement.scrollTop = 0;
        if(document.documentElement.scrollTop >= 0) {
          document.getElementById("card-body-target").style.display = "block";
          document.getElementById("card-target").style.minHeight = "3rem";
          document.getElementById("card-body-benchmark").style.display = "block";
          document.getElementById("card-benchmark").style.minHeight = "3rem";
          document.getElementById("card-img-1").style.display = "block";
          document.getElementById("card-img-2").style.display = "block";
          document.getElementById("card-img-3").style.display = "block";
          document.getElementById("card-img-4").style.display = "block";
        }
        this.introJS.start().onexit(()=> this.hideScroll = false);
        this.introJS.oncomplete(function() {
          document.documentElement.scrollTop = 0;
        });
      }
    });
    this.introJS.setOptions({
      steps: [
        {
          element: '#step1',
          intro: 'To \<strong> launch </strong> your new project, fill out the \<strong> form fields </strong> below by \<strong> assigning a name </strong> to your project, \<strong> assigning a country \</strong>, \<strong>choosing an audience \</strong> from the audiences you have already \<strong> created on facebook \</strong> and finally \<strong> choosing the data source \</strong> you want to use for your analysis (facebook, Instagram or both).',
          position: 'left',
        },
        {
          element: '#step2',
          intro: 'These boxes allow you to \<strong> get an overview \</strong> of the audience you want to analyze. You can see the \<strong> interests/behaviors \</strong> and the \<strong> size \</strong> of your audience. An indicator helps you to check if the size of your audience is good for the relevancy of your analyze.',
          position: 'right',
        },
        {
          element: '#step3',
          intro: ' To have the best analyze coming from your audience.\n' + ' \<strong> SOPRISM automatically generated an adapted benchmark \</strong> audience. But if you want to \<strong> adapt \</strong> it, click on the \<strong> Edit \</strong> button and create the one with fit with your needs.',
          position: 'bottom',
        },
        {
          element: '#step4',
          intro: ' Thanks to this box, you can \<strong> change the type \</strong> of your audiences by clicking on the « \<strong> Change Type \</strong>» \<strong> button \</strong> and then select the \<strong> audience type \</strong> you want to \<strong> use \</strong>.',
          position: 'bottom',
        },
        {
          element: '#step5',
          intro: 'To <strong>finish </strong> the creation of your project,<strong>click</strong> to this button, Check the <strong>summary</strong> to ensure that your project is perfectly configured and <strong>validate</strong> the use of your <strong>credits</strong>.',
          position: 'bottom',
        },
      ],
      showProgress: false,
      disableInteraction: true,
      hideNext: true,
      scrollToElement: true,
      highlightClass: 'sp-helperLayer-insight'
    });
    const mwa = this;
    this.introJS.onexit(function() {
      const currentStep = this._currentStep + 1;
      if (currentStep < this._options.steps.length) {
        mwa.introService.autoStart = false;
      }
    });
    projectCreationManager.switchAudienceType(AudienceType.target);
    projectCreationManager.switchAudienceMode(AudienceCreationMode.import);
  }
  // On init.
  ngOnInit() {
    this.navigation.adBlockDetectionLaunch().then();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showError(errorName) {
    this.msgs = [];
    this.msgs.push({severity:'error', summary:'Error Message', detail: errorName});
  }

  openIntroDialog(): void {
    this.dialog.open(SpIntroductionComponent,{
      autoFocus: false,
      minHeight:'30rem',
      minWidth:'40rem',
      maxHeight:'60rem',
      maxWidth:'60rem',
    }).afterClosed().subscribe(result => {
      if(result) {
        this.hideScroll = true;
        this.introJS.start().onexit(()=> this.hideScroll = false);
        this.introJS.oncomplete(function() {
          document.documentElement.scrollTop = 0;
        });
      }
    });
  }

  get AudienceImportType(): typeof AudienceImportType { return AudienceImportType; }
}
