import { Injectable } from '@angular/core';
import {ApiService} from "../api.service";
import {HttpClient} from "@angular/common/http";
import {Asset} from "../../../models/asset";
import {LoggerService} from '../../sp-logger/logger.service';
import {firstValueFrom} from "rxjs";

export interface AssetSuggestion {
  id?: string;
  name: string;
  domain?: string;
  logo: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiAssetService extends ApiService {
  constructor(http: HttpClient, logger: LoggerService) {
    super(http, logger);
  }

  getAll(): Promise<Asset[]> {
    return new Promise<Asset[]>((resolve, reject) => {
      const sub = this.get<Asset[]>("assets").subscribe(assets => {
        assets.forEach(a => {
          // Conversion from PHP timestamp format to Date format
          if (a.expiration) a.expirationDate = new Date(a.expiration * 1000)
        })
        resolve(assets);
        sub.unsubscribe();
      }, error => {
        reject(error);
        sub.unsubscribe();
      })
    });
  }

  getSome(assets: string[], type: string, useB64: boolean = false) : Promise<Asset[]> {
    return new Promise<Asset[]>((resolve, reject) => {
      const sub = this.get<Asset[]>("assets", {assets: assets.join(","), type, useB64: useB64 ? '1' : '0'}).subscribe(assets => {
        // Conversion from PHP timestamp format to Date format
        assets.forEach(asset => {
          if (asset.expiration) asset.expirationDate = new Date(asset.expiration * 1000);
        });
        resolve(assets);
        sub.unsubscribe();
      }, error => {
        reject(error);
        sub.unsubscribe();
      })
    })
  }

  deleteOne(id: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.delete("assets/" + id).subscribe(() => {
        resolve(true);
      }, error => reject(error));
    })
  }

  updateDefault(imageBase64: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      firstValueFrom(this.put("assets/default", {image: imageBase64})).then(() => {
        resolve(true);
      }).catch(reject)
    })
  }

  getDefault(): Promise<Asset> {
    return firstValueFrom(this.get<Asset>("assets/default"));
  }

  getSuggestions(query: string, type: string): Promise<AssetSuggestion[]> {
    return new Promise<AssetSuggestion[]>((resolve, reject) => {
      const sub = this.get<AssetSuggestion[]>("assets/suggestions", {query, type})
        .subscribe(response => {
          resolve(response);
          sub.unsubscribe();
        }, error => {
          reject(error);
          sub.unsubscribe();
        });
    })
  }

  useSuggestion(asset: Asset, suggestion: string, type: string): Promise<Asset> {
    return new Promise<Asset>((resolve, reject) => {
      const sub = this.post<Asset>("assets/suggestions", {asset: asset.name, suggestion, type})
        .subscribe(asset => {
          // Conversion from PHP timestamp format to Date format
          if (asset.expiration) asset.expirationDate = new Date(asset.expiration * 1000);
          resolve(asset);
          sub.unsubscribe();
        }, error => {
          reject(error);
          sub.unsubscribe();
        });
    });
  }

  useCustomImage(asset: Asset, imageBase64: string): Promise<Asset> {
    return new Promise<Asset>((resolve, reject) => {
      const sub = this.post<Asset>("assets/custom", {asset: asset.name, image: imageBase64})
        .subscribe(asset => {
          // Conversion from PHP timestamp format to Date format
          if (asset.expiration) asset.expirationDate = new Date(asset.expiration * 1000);
          resolve(asset);
          sub.unsubscribe();
        }, error => {
          reject(error);
          sub.unsubscribe();
        });
    });
  }
}
