import {Component, OnDestroy, OnInit} from '@angular/core';
import {SpThemingService} from '../../../../services/sp-theming/sp-theming.service';
import introJs from 'intro.js';
import {IntroService} from '../../../../services/sp-intro/intro.service';
import {SpIntroductionComponent} from '../../sp-project-list/sp-introduction/sp-introduction.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthenticationService} from '../../../../services/sp-authentication/authentication.service';
import {RoleUtils} from "../../../../utils/role/role-utils";
import {Router} from "@angular/router";

@Component({
  selector: 'app-sp-project-create-type-select',
  templateUrl: './sp-project-create-type-select.component.html',
  styleUrls: ['./sp-project-create-type-select.component.scss']
})
export class SpProjectCreateTypeSelectComponent implements OnInit, OnDestroy {
  introJS = introJs();
  neverOpen = true;
  // Constructor.
  subscription: any;
  introIsStarted = false;
  freemium = false;
  constructor(private _spTheming: SpThemingService,
              private dialog: MatDialog,
              private introService: IntroService,
              private auth: AuthenticationService,
              private router: Router) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-desk');
    //this.introService.setCurrentArea(IntroArea.PROJECT_CREATE_SELECT);

    this.subscription = this.introService.introStarted$.subscribe((introStarted) => {
      if (introStarted) {
        this.introJS.start();
      }
    });
    this.introJS.setOptions({
      steps: [
        {
          element: '#step1',
          intro: 'To start creating your project, you need to \<strong> select the type \</strong> of \<strong> audience \</strong> you want to analyze',
        },
      ],
      showProgress: false,
      disableInteraction: true,
      hideNext: true,
      highlightClass: "sp-helperLayer",
      tooltipPosition: 'bottom-middle-aligned'
    });
    const mwa = this;
    this.introJS.onexit(function() {
      const currentStep = this._currentStep + 1;
      if (currentStep < this._options.steps.length) {
        mwa.introService.autoStart = false;
      }
    });
  }

  // On init.
  ngOnInit() {
    if (this.auth.session.authenticated) {
      this.freemium = this.auth.session.user.role.level <= RoleUtils.freemiumLevel;
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openIntroDialog(): void {
    this.dialog.open(SpIntroductionComponent,{
      autoFocus: false,
      minHeight:'30rem',
      minWidth:'40rem',
      maxHeight:'60rem',
      maxWidth:'60rem',
    }).afterClosed().subscribe(result => {
      if(result) {
        this.introJS.start();
        this.neverOpen = false;
        this.introIsStarted = true;
      } else {
        this.introIsStarted = false;
      }
    });
  }
}
