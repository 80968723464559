import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from "../../services/sp-authentication/authentication.service";
import {Session} from "../../models/session";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthenticationService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Returning promise in case if session has not been initialized yet
    return new Promise<boolean | UrlTree>((resolve => {
      const authCheck = (session: Session): boolean | UrlTree => {
        return session && session.authenticated ? true : this.router.parseUrl("/user/login");
      };

      if(!environment.config.apiCheck) resolve(true);

      // Session check
      if (this.auth.session.initialized) {
        resolve(authCheck(this.auth.session));
      } else {
        this.auth.sessionListener.subscribe(session => {
          resolve(authCheck(session));
        });
      }
    }));
  }
}
