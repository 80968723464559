import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Asset} from "../../../../models/asset";
import {ApiAssetService} from '../../../../services/sp-api/sp-api-asset/api-asset.service';
import {environment} from '../../../../../environments/environment';
import {AlertService} from '../../../../services/sp-alert/alert.service';
import {LoggerService} from '../../../../services/sp-logger/logger.service';

@Component({
  selector: 'app-sp-modal-asset-manager-delete',
  templateUrl: './sp-modal-asset-manager-delete.component.html',
  styleUrls: ['./sp-modal-asset-manager-delete.component.scss']
})
export class SpModalAssetManagerDeleteComponent implements OnInit {

  pending: boolean = false;

  constructor(public dialogRef: MatDialogRef<SpModalAssetManagerDeleteComponent>,
              @Inject(MAT_DIALOG_DATA) private data: Asset,
              private apiAsset: ApiAssetService,
              private alert: AlertService,
              private logger: LoggerService) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  delete() {
    this.pending = true;
    this.apiAsset.deleteOne(this.data.id).then(() => {
      this.dialogRef.close(true);
    }).catch(error => {
      if (environment.config.showErrorModal) this.alert.notify("Error", error, "error");
      this.logger.logError("Could not delete asset : " + error.message, 3);
      this.dialogRef.close(false);
    })
  }
}
