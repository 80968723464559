import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {SpThemingService} from '../../../services/sp-theming/sp-theming.service';
import {MatDialog} from '@angular/material/dialog';
import {SpModalAssetManagerEditComponent} from './sp-modal-asset-manager-edit/sp-modal-asset-manager-edit.component';
import {SpModalAssetManagerDeleteComponent} from './sp-modal-asset-manager-delete/sp-modal-asset-manager-delete.component';
import {Asset} from "../../../models/asset";
import {ApiAssetService} from "../../../services/sp-api/sp-api-asset/api-asset.service";
import {KeyboardUtils} from "../../../utils/keyboard/keyboard-utils";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-sp-asset-manager',
  templateUrl: './sp-asset-manager.component.html',
  styleUrls: ['./sp-asset-manager.component.scss']
})
export class SpAssetManagerComponent implements OnInit {
  private defaultImageUrl = environment.config.api.storageUrl + 'files/default.png';
  imageLoadTime: number = new Date().getTime();

  displayedColumnsAssets: string[] = ['logo', 'name', 'type', 'date', 'option'];

  assets: MatTableDataSource<Asset> = new MatTableDataSource<Asset>();

  @ViewChild('paginator', {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  // Constructor.
  constructor(private _spTheming: SpThemingService,
              private dialog: MatDialog,
              private apiAsset: ApiAssetService) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-desk');
  }

  // On init.
  ngOnInit() {
    this.apiAsset.getAll().then(assets => {
      this.assets.data = assets;
      this.assets.sort = this.sort;
      this.assets.paginator = this.paginator;
    });
  }

  openDeleteDialog(asset: Asset): void {
    const sub = this.dialog.open(SpModalAssetManagerDeleteComponent,{
      minHeight:'10rem',
      minWidth:'40rem',
      maxHeight:'20rem',
      maxWidth:'40rem',
      data: asset
    }).afterClosed().subscribe(result => {
      if (result) {
        this.assets.data = this.assets.data.filter(d => d.id !== asset.id);
      }

      sub.unsubscribe();
    });
  }

  openUpdateDialog(asset: Asset) {
    const sub = this.dialog.open(SpModalAssetManagerEditComponent,{
      minHeight:'10rem',
      minWidth:'40rem',
      maxHeight:'20rem',
      maxWidth:'40rem',
      data: asset
    }).afterClosed().subscribe(newAsset => {
      if (newAsset) {
        this.assets.data = this.assets.data.map(a => a.id == asset.id ? newAsset : a);
      }

      sub.unsubscribe();
    });
  }

  onSearchFilter(search: string, event: KeyboardEvent) {
    if (event) {
      if (KeyboardUtils.isValidCharacter(event.key) || event.key == "Backspace") {
        this.assets.filter = search;
      }
    }
  }

  onDefaultImageChange(input: HTMLInputElement, event: Event) {
    const file: File = input.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      const defaultImage = event.target.result;
      this.apiAsset.updateDefault(defaultImage).then(() => {
        // Simple hack to refresh default image
        this.setDefaultImage(this.defaultImageUrl);
      });
    });

    reader.readAsDataURL(file);
  }

  clearFilter() {
    this.assets.filter = undefined;
  }

  getDefaultImage() {
    if(this.imageLoadTime) {
      return this.defaultImageUrl + '?' + this.imageLoadTime;
    }
    return this.defaultImageUrl;
  }

  setDefaultImage(url: string) {
    this.imageLoadTime = new Date().getTime();
    this.defaultImageUrl = url;
  }
}
