<div class="sp-dialog">
  <div class="sp-dialog-header">
    Delete folder
  </div>
  <div class="sp-dialog-body">
    <h3>Are you sure you want to delete this folder ?</h3>
    <p class="text-center">This action cannot be undone.</p>
    <div class="pb-3 row justify-content-center">
      <button mat-button class="btn btn-secondary m-1 text-uppercase sp-btn sp-btn-secondary" (click)="onNoClick()">Cancel</button>
      <button mat-button class="btn btn-primary m-1 text-uppercase sp-btn sp-btn-primary" (click)="folderDelete()">Delete</button>
    </div>
  </div>
</div>
