<div class="card">
  <div class="card-header">
    <span>{{ project.name }}</span>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-5">
        <div class="sp-card">
          <div class="card">
            <div class="card-header">
              <span class="sp-target-audience">{{ project.audience_target.name }}</span>
            </div>
            <div class="card-body">
              <span>Project Universe : <span class="sp-element">{{ selectedUniversesToString() }}</span></span><br>
              <span>Age: <span class="sp-element">{{ audienceTargetSpec.ageMin }}-{{ audienceTargetSpec.ageMax }}</span></span><br>
              <span>Gender: <span class="sp-element">{{ audienceTargetSpec.gendersString() }}</span></span><br>
              <span *ngIf="audienceTargetSpec.geolocations">Geolocation: <span class="sp-element">{{ audienceTargetSpec.geolocString() }}</span></span><br>
              <div *ngIf="audienceTargetSpec.relationshipStatuses">Relationship Statuses: <span class="sp-element">{{relationshipStatusLabels().join(', ')}}</span></div>
              <div *ngFor="let flex of audienceTargetSpec.flex | OrderByPipe:'flex' ; let i = index"><span>{{ i == 0 ? 'Interests' : 'And' }}: <span [innerHTML]="boldOr(audienceTargetSpec.flexOrString(flex))" class="sp-element"></span></span></div>
              <span *ngIf="audienceTargetSpec.exclusions">Exclude: <span [innerHTML]="boldOr(audienceTargetSpec.flexOrString(audienceTargetSpec.exclusions))" class="sp-element"></span></span><br>
            </div>
            <div class="card-footer" *ngIf="project.audience_target.latest_crawl">
              <div class="row no-gutters pt-4">
                <div class="col-12">
                  <span class="sp-target-people"><span class="sp-target-number">{{ toNumberFormat(project.audience_target.latest_crawl.audience_size) }}</span> people</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2 d-flex align-self-center justify-content-center">
        <div class="wrapper">
          <div class="line"></div>
          <div class="wordwrapper">
            <span class="word sp-project-vs">VS</span>
          </div>
        </div>
      </div>
      <div class="col-5">
        <div class="sp-card">
          <div class="card">
            <div class="card-header">
              <span class="sp-benchmark-audience">{{ project.audience_bench.name }}</span>
            </div>
            <div class="card-body">
              <span>Project Universe : <span class="sp-element">{{ selectedUniversesToString() }}</span></span><br>
              <span>Age: <span class="sp-element">{{ audienceBenchmarkSpec.ageMin }}-{{ audienceBenchmarkSpec.ageMax }}</span></span><br>
              <span>Gender: <span class="sp-element">{{ audienceBenchmarkSpec.gendersString() }}</span></span><br>
              <span *ngIf="audienceBenchmarkSpec.geolocations">Geolocation: <span class="sp-element">{{ audienceBenchmarkSpec.geolocString() }}</span></span><br>
              <div *ngIf="audienceBenchmarkSpec.relationshipStatuses">Relationship Statuses: <span class="sp-element">{{relationshipStatusLabels(true).join(', ')}}</span></div>
              <div *ngFor="let flex of audienceBenchmarkSpec.flex; let i = index"><span>{{ i == 0 ? 'Interests' : ' And ' }}: <span [innerHTML]="boldOr(audienceBenchmarkSpec.flexOrString(flex))" class="sp-element"></span></span></div>
              <span *ngIf="audienceBenchmarkSpec.exclusions">Exclude: <span [innerHTML]="boldOr(audienceBenchmarkSpec.flexOrString(audienceBenchmarkSpec.exclusions))" class="sp-element"></span></span><br>
            </div>
            <div class="card-footer" *ngIf="project.audience_bench.latest_crawl">
              <div class="row no-gutters pt-4">
                <div class="col-10 d-flex justify-content-start">
                  <span class="sp-benchmark-people"><span class="sp-target-number">{{ toNumberFormat(project.audience_bench.latest_crawl.audience_size) }}</span> people</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row no-gutters d-flex pb-4">
    <div class="col-12 text-center">
      <button (click)="onNoClick()" class="pl-5 pr-5 btn btn-primary sp-btn sp-btn-primary" matTooltip="Close" matTooltipHideDelay="10">
        <span>Close</span>
      </button>
    </div>
  </div>
</div>
