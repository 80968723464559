<main class="container" role="main" style="margin-top: -6.5rem">
  <app-sp-project-create-header></app-sp-project-create-header>
  <div id="step1">
    <app-sp-project-create-project-setup [options]="{universeNameFallback: 'Worldwide'}"></app-sp-project-create-project-setup>
    <app-sp-project-create-audience-setup [options]="{
      allowAudienceImport: true,
      importAudienceTypes: [AudienceImportType.FanpageAudience],
      allowGeolocationEditing: true,
      allowSociodemoOptions: true,
      importFieldName: 'Facebook Page'
    }"></app-sp-project-create-audience-setup>
  </div>
  <div class="w-100 row d-flex justify-content-center sp-notification-div">
    <p-messages class="w-50 sp-notification" [(value)]="msgs"></p-messages>
  </div>
</main>
