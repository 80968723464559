import {Component, OnDestroy, OnInit} from '@angular/core';
import {SpThemingService} from '../../../../services/sp-theming/sp-theming.service';
import {AuthenticationService} from '../../../../services/sp-authentication/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import {Message} from 'primeng/api';
import {
  AudienceCreationMode,
  AudienceType,
  ProjectCreationManagerService,
} from '../../../../services/sp-project/project-creation-manager.service';
import introJs from 'intro.js';
import {IntroService} from '../../../../services/sp-intro/intro.service';
import {SpIntroductionComponent} from '../../sp-project-list/sp-introduction/sp-introduction.component';
import {LoggerService} from '../../../../services/sp-logger/logger.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-sp-project-create-facebook-interest',
  templateUrl: './sp-project-create-facebook-interest.component.html',
  styleUrls: ['./sp-project-create-facebook-interest.component.scss']
})
export class SpProjectCreateFacebookInterestComponent implements OnInit, OnDestroy {
  // IntroJS Related
  introJS = introJs();
  hideScroll = false;
  //. ---

  // Notifications
  msgs: Message[] = [];
  showPresetSelection = false;

  subscription : any;
  // Constructor.
  constructor(private _spTheming: SpThemingService,
              private auth: AuthenticationService,
              public creationManager: ProjectCreationManagerService,
              private dialog: MatDialog,
              private introService: IntroService,
              private logger: LoggerService) {
    // Set the theme to use.
    this._spTheming.setSpThemeName('dashboard-desk');
    //this.introService.setCurrentArea(IntroArea.PROJECT_CREATE_INTERESTS);

    this.subscription = this.introService.introStarted$.subscribe((introStarted) => {
      if (introStarted) {
        this.hideScroll = true;
        document.documentElement.scrollTop = 0;
        if(document.documentElement.scrollTop >= 0) {
          document.getElementById("card-body-target").style.display = "block";
          document.getElementById("card-target").style.minHeight = "3rem";
          document.getElementById("card-body-benchmark").style.display = "block";
          document.getElementById("card-benchmark").style.minHeight = "3rem";
          document.getElementById("card-img-1").style.display = "block";
          document.getElementById("card-img-2").style.display = "block";
          document.getElementById("card-img-3").style.display = "block";
          document.getElementById("card-img-4").style.display = "block";
        }
        this.introJS.start().onexit(()=> this.hideScroll = false);
        this.introJS.oncomplete(function() {
          document.documentElement.scrollTop = 0;
        });
      }
    });
    this.introJS.setOptions({
      steps: [
        {
          element: '#step1',
          intro: 'To <strong> launch </strong> your new project, fill out the <strong> form fields </strong> below by assigning a <strong>name</strong> to your project, assigning a <strong>country</strong>, a <strong>localization</strong> choosing an audience \</strong> and finally choosing the data source you want to use for your analysis (facebook, Instagram or both).',
          position: 'bottom',
        },
        {
          element: '#step2',
          intro: 'These boxes allow you to \<strong> get an overview \</strong> of the audience you want to analyze. You can see the \<strong> interests/behaviors \</strong> and the \<strong> size \</strong> of your audience. An indicator helps you to check if the size of your audience is good for the relevancy of your analyze.',
          position: 'right',
        },
        {
          element: '#step3',
          intro: ' To have the best analyze coming from your audience.\n' + ' \<strong> SOPRISM automatically generated an adapted benchmark \</strong> audience. But if you want to \<strong> adapt \</strong> it, click on the \<strong> Edit \</strong> button and create the one with fit with your needs.',
          position: 'bottom',
        },
        {
          element: '#step4',
          intro: ' Thanks to this box, you can \<strong> change the type \</strong> of your audiences by clicking on the « \<strong> Change Type \</strong>» \<strong> button \</strong> and then select the \<strong> audience type \</strong> you want to \<strong> use \</strong>.',
          position: 'bottom',
        },
        {
          element: '#step5',
          intro: 'To <strong>finish </strong> the creation of your project,<strong>click</strong> to this button, Check the <strong>summary</strong> to ensure that your project is perfectly configured and <strong>validate</strong> the use of your <strong>credits</strong>.',
          position: 'bottom',
        },
      ],
      showProgress: false,
      disableInteraction: true,
      hideNext: true,
      scrollToElement: false,
      highlightClass: 'sp-helperLayer-insight'
    });
    const mwa = this;
    this.introJS.onexit(function() {
      const currentStep = this._currentStep + 1;
      if (currentStep < this._options.steps.length) {
        mwa.introService.autoStart = false;
      }
    });
    creationManager.switchAudienceType(AudienceType.target);
    creationManager.switchAudienceMode(AudienceCreationMode.interest);

    const audienceTargetSpec = this.creationManager.audienceTarget.targeting;
    const audienceBenchmarkSpec = this.creationManager.audienceBenchmark.targeting;
    creationManager.loadAudienceInfoFromTargeting(audienceTargetSpec, AudienceType.target);
    creationManager.loadAudienceInfoFromTargeting(audienceBenchmarkSpec, AudienceType.benchmark);
  }
  // On init.
  async ngOnInit() {
    this.scrollToTop();
    this.showPresetSelection = await this.auth.permissionCheck('audience.library.access');
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  scrollToTop() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }
  openIntroDialog(): void {
    this.dialog.open(SpIntroductionComponent,{
      autoFocus: false,
      minHeight:'30rem',
      minWidth:'40rem',
      maxHeight:'60rem',
      maxWidth:'60rem',
    }).afterClosed().subscribe(result => {

      if(result) {
        this.hideScroll = true;
        this.introJS.start().onexit(()=> this.hideScroll = false);
        this.introJS.oncomplete(function() {
          document.documentElement.scrollTop = 0;
        });
      }
    });
  }
  showError(errorName: string) {
    this.logger.logError(errorName);
    this.msgs = [];
    this.msgs.push({severity:'error', summary:'Error Message', detail: errorName});
  }

  protected readonly environment = environment;
}
