import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {RouterLinkActive} from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-sp-modal-user-delete',
  templateUrl: './sp-modal-user-delete.component.html',
  imports: [
    RouterLinkActive
  ]
})
export class SpModalUserDeleteComponent {
  constructor(public dialogRef: MatDialogRef<SpModalUserDeleteComponent>) {}
}
