import {Injectable} from '@angular/core';
import {ApiModelService} from "../sp-api-model/api-model.service";
import {Project} from "../../../models/project";
import {HttpClient} from "@angular/common/http";
import {User} from "../../../models/user";
import {LoggerService} from '../../sp-logger/logger.service';
import {firstValueFrom} from "rxjs";
import {Universe} from '../../../models/universe';
import {Folder} from '../../../models/folder';

export interface ProjectBatch {
  name: string;
  universes: Universe[],
  projects: Project[]
}

@Injectable({
  providedIn: 'root'
})
export class ApiProjectService extends ApiModelService<Project> {
  protected readonly basePath = "projects";

  constructor(httpClient: HttpClient, logger: LoggerService) {
    super(httpClient, logger);
  }

  /* Other methods with relations go here */

  projectBatch(projectBatch: ProjectBatch) {
    return firstValueFrom(this.post<Folder>(this.basePath + '/launch/batch', projectBatch));
  }

  projectCrawl(project: Project, sourceType?: string, universeId?: number): Promise<void> {
    if (!sourceType) sourceType = 'original';
    const path = universeId ? `${this.basePath}/${project.id}/crawl/${universeId}` : `${this.basePath}/${project.id}/crawl`;
    return firstValueFrom(this.post<void>(path, {'source_type': sourceType}));
  }

  projectCrawlBatch(folder: Folder) {
    return firstValueFrom(this.post<void>(this.basePath + '/crawl/batch/' + folder.id));
  }

  async projectCrawlCancel(project: Project) {
    await firstValueFrom(this.delete(`${this.basePath}/${project.id}/crawl`));
  }

  projectDuplicate(project: Project, sourceType: string = "original"): Promise<Project> {
    return new Promise<Project>((resolve, reject) => {
      this.post<Project>(`${this.basePath}/${project.id}/duplicate`, {'source_type': sourceType})
        .subscribe(project => resolve(project), error => reject(error));
    });
  }

  async projectShare(project: Project, user: Array<User>) {
    for (const listUser of user) {
      await firstValueFrom(this.post<void>(`${this.basePath}/${project.id}/users/${listUser.id}/share`));
    }

    return true;
  }

  async projectShareRevoke(project: Project, user: Array<User>) {
    for (const listUser of user) {
      await firstValueFrom(this.delete<void>(`${this.basePath}/${project.id}/users/${listUser.id}/share`));
    }

    return true;
  }

  /*projectSunburst(project: Project): Promise<TreeNode<PathEntry>> {
    return new Promise<TreeNode<PathEntry>>((resolve, reject) => {
      const sub = this.get<TreeNode<PathEntry>>(`${this.basePath}/${project.id}/sunburst`)
        .subscribe(result => resolve(result), err => reject(err), () => sub.unsubscribe());
    });
  }*/

  projectMapVersionUpdate(project: Project, version: number): Promise<Project> {
    return new Promise<Project>((resolve, reject) => {
      const sub1 = this.put(`${this.basePath}/${project.id}/map/v/${version}`).subscribe(() => {
        sub1.unsubscribe();
        this.getOne(project.id).then(project => {
          resolve(project);
        }).catch(err => reject(err));
      });
    });
  }

  projectCacheClear(project: Project, clearDbCache: boolean = true): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const sub = this.delete<any>(`${this.basePath}/${project.id}/cache`, {"clear_db_cache": clearDbCache ? 1 : 0}).subscribe(resp => {
        sub.unsubscribe();
        resolve(resp.success == 1);
      }, reject)
    })
  }

  projectSetReady(project: Project, targetReady: boolean = true, benchReady: boolean = true) {
    return firstValueFrom(this.post<any>(`${this.basePath}/${project.id}/ready`, {
      'target_ready': targetReady,
      'bench_ready': benchReady
    }));
  }

  /* ------------------------------------ */
}
