export enum DashboardArea {
  ALL_AREA = "All area",
  PROJECT_LIST = "Project list",
  PROJECT_CREATE_SELECTION = "Project create selection",
  PROJECT_CREATE_INTEREST = "Project create type interest",
  PROJECT_CREATE_IMPORT = "Project create type import",
  PROJECT_CREATE_FANPAGE = "Project create type fanpage",
  INSIGHT_OVERVIEW = "Insight overview",
  INSIGHT_TOPICS = "Insight topics",
  INSIGHT_SOCIODEMO = "Insight sociodemo",
  INSIGHT_MAP = "Insight map",
  PERSONA_OVERVIEW = "Persona overview",
  PERSONA_SEGMENTATION = "Persona segmentation",
  TAG_MANAGER = "Tag manager",
  ADMIN_COMPANY = "Admin company",
  ADMIN_COMPANY_FORM = "Admin company edit/create form",
  ADMIN_USER = "Admin user",
  ADMIN_USER_FORM = "Admin user edit/create form",
  ADMIN_ASSETS = "Admin assets",
  ADMIN_NOTIFICATION = "Admin notification",
  ADMIN_TESTING_SANDBOX = "Admin testing sandbox",
  USER_LOGIN = "User login",
  USER_REGISTER = "User register",
  USER_ACCOUNT = "User account edit page"
}

export class Dashboard {
  public static readonly area: Map<string, DashboardArea> = new Map([
    ['/projects/list', DashboardArea.PROJECT_LIST],
    ['/projects/create/select', DashboardArea.PROJECT_CREATE_SELECTION],
    ['/projects/create/facebook-interest', DashboardArea.PROJECT_CREATE_INTEREST],
    ['/projects/create/facebook-import', DashboardArea.PROJECT_CREATE_IMPORT],
    ['/projects/create/facebook-page', DashboardArea.PROJECT_CREATE_FANPAGE],
    ['/projects/details/x/insights/socio-demo', DashboardArea.INSIGHT_SOCIODEMO],
    ['/projects/details/x/insights/topics', DashboardArea.INSIGHT_TOPICS],
    ['/projects/details/x/insights/overview', DashboardArea.INSIGHT_OVERVIEW],
    ['/projects/details/x/insights/maps', DashboardArea.INSIGHT_MAP],
    ['/projects/details/x/persona/overview', DashboardArea.PERSONA_OVERVIEW],
    ['/projects/details/x/persona/manager', DashboardArea.PERSONA_SEGMENTATION],
    ['/projects/details/x/tags/manager', DashboardArea.TAG_MANAGER],
    ['/admin/companies/list', DashboardArea.ADMIN_COMPANY],
    ['/admin/companies/create', DashboardArea.ADMIN_COMPANY_FORM],
    ['/admin/companies/update/x', DashboardArea.ADMIN_COMPANY_FORM],
    ['/admin/users/list', DashboardArea.ADMIN_USER],
    ['/admin/users/create', DashboardArea.ADMIN_USER_FORM],
    ['/admin/users/update/x', DashboardArea.ADMIN_USER_FORM],
    ['/admin/assets', DashboardArea.ADMIN_ASSETS],
    ['/admin/notification', DashboardArea.ADMIN_NOTIFICATION],
    ['/admin/testing/sandbox', DashboardArea.ADMIN_TESTING_SANDBOX],
    ['/user/login', DashboardArea.USER_LOGIN],
    ['/user/register', DashboardArea.USER_REGISTER],
    ['/account/edit', DashboardArea.USER_ACCOUNT]
  ]);
}
