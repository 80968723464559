import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Notification} from '../../../models/notification';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiNotificationService {
  private readonly apiUrl = environment.config.api.baseUrl;

  constructor(private http: HttpClient) { }

  createNotification(notification: Notification) {
    return new Promise<Notification>((resolve, reject) => {
      const sub = this.http.post<Notification>(this.apiUrl + 'notification', notification).subscribe(n => {
        resolve(n);
      }, error => reject(error), () => sub.unsubscribe());
    });
  }

  getLastNotification() {
    return new Promise<Notification>((resolve, reject) => {
      const sub = this.http.get<Notification>(this.apiUrl + 'notification').subscribe(n => {
        resolve(n);
      }, error => reject(error), () => sub.unsubscribe());
    });
  }

  getById(id: number) {
    return new Promise<Notification>((resolve, reject) => {
      const sub = this.http.get<Notification>(this.apiUrl + 'notification/' + id).subscribe(n => {
        resolve(n);
      }, error => reject(error), () => sub.unsubscribe());
    });
  }

  getActiveNotifications() {
    return new Promise<Notification[]>((resolve, reject) => {
      const sub = this.http.get<Notification[]>(this.apiUrl + 'notification/active').subscribe(ns => resolve(ns),
        error => reject(error), () => sub.unsubscribe());
    });
  }

  activateNotification(notification: Notification) {
    return new Promise<boolean>((resolve, reject) => {
      const sub = this.http.post(this.apiUrl + 'notification/' + notification.id + '/activate', {}).subscribe(() => resolve(true),
        () => resolve(false), () => sub.unsubscribe())
    });
  }

  deactivateNotification(notification: Notification) {
    return new Promise<boolean>((resolve, reject) => {
      const sub = this.http.post(this.apiUrl + 'notification/' + notification.id + '/deactivate', {}).subscribe(() => resolve(true),
        error => reject(error), () => sub.unsubscribe())
    });
  }

  updateNotification(notification: Notification) {
    return new Promise<Notification>((resolve, reject) => {
      const sub = this.http.put<Notification>(this.apiUrl + 'notification/' + notification.id, notification).subscribe(n => resolve(n),
        error => reject(error), () => sub.unsubscribe());
    });
  }

  deleteNotification(notification: Notification) {
    return new Promise<boolean>((resolve, reject) => {
      const sub = this.http.delete(this.apiUrl + 'notification/' + notification.id).subscribe(() => resolve(true),
        error => reject(error), () => sub.unsubscribe())
    });
  }
}
