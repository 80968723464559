import {ClientInfo} from '../../classes/client/client-info';

export abstract class ClientUtils {
  static getClientInfo(): ClientInfo {
    return {
      screenSize: screen.width + "x" + screen.height,
      screenAvailableSize: screen.availWidth + "x" + screen.availHeight,
      browserName: navigator.appName + " (" + navigator.appCodeName + ")",
      browserVersion: navigator.appVersion,
      browserUserAgent: navigator.userAgent,
      browserPlatform: navigator.platform,
      browserEngine: navigator.product,
      javascriptEnabled: navigator.javaEnabled(),
      cookiesEnabled: navigator.cookieEnabled
    }
  }
}
