import {Model} from "./model";
import {Permission} from "./permission";
import {HasPermission} from './has-permission';
import {NamedItem} from '../pipes/array-name-join.pipe';

export class Plan implements HasPermission, NamedItem {
  id: number;
  name: string;
  credits: number;
  users: number;
  custom: boolean;
  permissions: Permission[];
  created_at: Date;
  updated_at: Date;

  constructor(name: string, credits: number, users: number, permissions: Permission[]) {
    this.name = name;
    this.credits = credits;
    this.users = users;
    this.permissions = permissions;
  }
}
