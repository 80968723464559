
<div *ngIf="personaProgressBar() == 0 || !router.url.includes('persona/manager')" class="sp-spinner basic-container">
  <mat-spinner [mode]="loader.loadProgress !== undefined ? 'determinate' : 'indeterminate'" [value]="loader.loadProgress" strokeWidth="3" style="margin:0 auto;"></mat-spinner>
  <img [src]="logo" id="sp-logo-loader">
</div>

<div *ngIf="personaProgressBar() > 0 && router.url.includes('persona/manager')"  class="sp-persona-loader">
  <img id="home" src="assets/images/gif/animation.gif" style="width: 70%; height: auto">
  <h1>{{ personaProgress() }}</h1>
  <span>
    ({{ this.loader.personaLoadingData.audience_target.status.actualStep }}/{{ this.loader.personaLoadingData.audience_target.status.steps }})
  </span>
  <mat-progress-bar [value]="crawlTracker.progress(this.loader.personaLoadingData)" class="sp-progress-bar" mode="determinate" ></mat-progress-bar>
  <h2>Don't worry.</h2>
    <span class="sp-text-secondary">We are building all your segments.</span>
    <span class="sp-text-secondary">We will notify you as soon as they are ready!</span>
</div>

<div *ngIf="loader.loadText && personaProgressBar() == 0 || !router.url.includes('persona/manager')" class="sp-loader-text">
  <span style="color: darkgray">{{ loader.loadText }}</span>
</div>
