import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {ApiFolderService} from '../../../../services/sp-api/sp-api-folder/api-folder.service';
import {Folder} from '../../../../models/folder';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-sp-modal-project-list-folder-create',
  templateUrl: './sp-modal-project-list-folder-create.component.html',
  styleUrls: ['./sp-modal-project-list-folder-create.component.scss']
})
export class SpModalProjectListFolderCreateComponent implements OnInit {

  folderNameControl: UntypedFormControl = new UntypedFormControl('', Validators.required);

  constructor(
    public dialogRef: MatDialogRef<SpModalProjectListFolderCreateComponent>,
    private apiFolder: ApiFolderService) { }

  ngOnInit() {
  }

  createFolder() {
    if (this.folderNameControl.valid) {
      let folder = new Folder(this.folderNameControl.value)

      this.apiFolder.postOne(folder).then(folder => {
        this.dialogRef.close(folder);
      })
    }
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

}
