import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SpUserLoginComponent } from './sp-user-login/sp-user-login.component';
import { SpUserPasswordComponent } from './sp-user-password/sp-user-password.component';
import { SpUserRegisterComponent } from './sp-user-register/sp-user-register.component';

const spUserRoutes: Routes = [
  {
    path: 'user',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: SpUserLoginComponent
      },
      {
        path: 'register',
        component: SpUserRegisterComponent
      },
      {
        path: 'password',
        component: SpUserPasswordComponent
      }
]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(spUserRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SpUserRoutingModule {}
