import {Model} from "./model";
import {Tag} from "./tag";
import {Universe} from "./universe";
import {NamedItem} from '../pipes/array-name-join.pipe';

export class Criteria implements Model, NamedItem {
  id: number;
  name: string;
  type: string;
  target_spec: string;
  tags: Array<Tag>;
  universes: Array<Universe>;
  fb_size: number;
  path: string;
  universe_ids?: Array<number>;
  created_at: Date;
  updated_at: Date;

  public static readonly BRAND_PATH = "Brands relationship";
  public static readonly EVENT_PATH = "Influence -- Events";
  public static readonly ORGANIZATION_PATH = "Politics -- Organization & Association";
  public static readonly PERSONALITY_PATH = "Influence -- People";
  public static readonly MEDIA_PATH = "Media";

  constructor(name: string, type: string, target_spec: any) {
    this.name = name;
    this.type = type;
    this.target_spec = target_spec;
  }

  public static nationalMediaPath(country: string) {
    return "Media -- National (" + country + ")";
  }
}
