<!-- Segment Integration -->
<script *ngIf="env.config.dev || !env.online">
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="Zo3GcLkIibc4MV9Lh0adzozDKKGHBxkz";;analytics.SNIPPET_VERSION="4.15.3";
    analytics.load("Zo3GcLkIibc4MV9Lh0adzozDKKGHBxkz");
    analytics.page();
  }}();
</script>

<ng-container [ngSwitch]="spThemeName">
  <ng-container *ngSwitchCase="'dashboard-desk'">
    <div class="sp-layout-{{spThemeName}} sp-h-100">
      <app-sp-layout-dashboard-desk></app-sp-layout-dashboard-desk>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'dashboard-simple'">
    <div class="sp-layout-{{spThemeName}} sp-h-100">
      <app-sp-layout-dashboard-simple></app-sp-layout-dashboard-simple>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'dashboard-welcome'">
    <div class="sp-layout-{{spThemeName}} sp-h-100">
      <app-sp-layout-dashboard-welcome></app-sp-layout-dashboard-welcome>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="sp-layout-dashboard-default sp-h-100">
      <app-sp-layout-dashboard-default></app-sp-layout-dashboard-default>
      <app-sp-tools *ngIf="loader.loadingGlobal"></app-sp-tools>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="env.config.enableDebugFeatures && isDebugBoxVisible()">
  <div id="sp-debug-box">
    <h5 id="sp-debug-title">Debugging</h5>
    <div id="sp-debug-content" [innerHTML]="debugOutput"></div>
    <div style="margin-left: 1em; margin-right: 1em" *ngFor="let featureFlag of debugFeatureFlags">{{featureFlag.flag}} <mat-slide-toggle (change)="updateFeatureFlag(featureFlag.flag, $event.checked)" [checked]="featureFlag.active"></mat-slide-toggle></div>
  </div>
</ng-container>
<button *ngIf="env.config.enableDebugFeatures" (click)="toggleDebugBox()" id="sp-debug-button" [ngClass]="{'sp-debug-button-enabled': this.isDebugBoxVisible()}">
  <mat-icon style="vertical-align: middle">bug_report</mat-icon>
</button>

