<div style="display: flex;flex-direction: column;gap: 40px;padding: 24px 32px;">
  <div style="display: flex;flex-direction: column;gap: 32px">
    <span class="sp-audience-fb-score-title">Audience Size Indicator Scale</span>

    <div style="display: flex;flex-direction: column;gap: 8px;align-items: center">
      <span class="sp-audience-fb-score-subtitle">Very bad</span>
      <div class="sp-audience-fb-score-very-bad">
        <div>🥶 Audience size is less than 10,000</div>
        <div>🥵 Audience size is greater than 60% of the benchmark</div>
      </div>
      <span class="sp-audience-fb-score-subtitle">Bad</span>
      <div class="sp-audience-fb-score-bad">
        <div>😨 Audience size is between 10,000 and 150,000</div>
        <div>😥 Audience size is between 40% and 60% of the benchmark</div>
      </div>
      <span class="sp-audience-fb-score-subtitle">Good</span>
      <div class="sp-audience-fb-score-good">
        <div>🙂 Audience size is between 50,000 and 100,000</div>
        <div>🙂 Audience size is less than 40% of the benchmark</div>
      </div>
      <span class="sp-audience-fb-score-subtitle">Perfect</span>
      <div class="sp-audience-fb-score-perfect">
        <div>😎 Audience size is greater than 100,000</div>
        <div>😎 Audience size is less than 40% of the benchmark</div>
      </div>
    </div>
    <span class="text-center" style="font-size: 12px">
        If your audience size can fit into two categories, the percentage rule will decide which color of the indicator you see.
        For example, if you have an audience size of 75,000 and that’s also 75% of your baseline audience, it’ll show up in the “Red” category.<br>
        <a target="_blank" href="https://intercom.help/soprism/en/articles/9717004-how-does-the-audience-size-indicator-work-when-creating-a-project">Read more</a>
      </span>
  </div>
  <div *ngIf="!showCloseButton" class="text-center">
    <button (click)="dialogRef.close(true)" class="btn btn-secondary m-1 text-uppercase sp-btn sp-btn-secondary" mat-button>Close</button>
  </div>
</div>
