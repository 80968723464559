import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'FilterPipe'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter( it => {
      if (typeof it === 'string') {
        return it.toLowerCase().includes(searchText);
      } else if (it.hasOwnProperty('name')) {
        return it.name.toLowerCase().includes(searchText);
      } else {
        return true;
      }
    });
  }
}
