export enum PersonaLocation {
  ALL, URBAN, NON_URBAN, UNDEFINED
}

export interface PersonaFilter {
  targetMin: number;
  targetMax: number;
  ageMin: number;
  ageMax: number;
  gender: number;
  location: PersonaLocation;
  segment?: string;
  mindsets: boolean;
}
