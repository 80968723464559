<div class="card" style="color: #555555; padding: 24px 36px 32px;gap: 16px">
  <div class="d-flex flex-column align-items-center" style="gap: 16px">
    <h4>This action will permanently delete a user</h4>
    <div style="text-align: center">
      By deleting this user, you will lose all associated data.
      That includes user insights, projects, <b>projects shared with other users</b>, folders and custom dashboards.
    </div>
  </div>
  <div class="d-flex flex-column align-items-center" style="gap:16px">
    <div>This action cannot be undone. Are you sure you want to continue?</div>
    <div class="d-flex justify-content-center">
      <button type="button" class="btn btn-secondary sp-btn sp-btn-secondary" (click)="dialogRef.close(false)" routerLinkActive="active">
        <span>Cancel</span>
      </button>
      <button (click)="dialogRef.close(true)" class="ml-3 btn btn-primary sp-btn sp-btn-primary">
        <span>Delete</span>
      </button>
    </div>
  </div>
</div>
