import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiModelService} from "../sp-api-model/api-model.service";
import {Audience} from "../../../models/audience";
import {Targeting} from "../../../classes/targeting/targeting";
import {Project} from '../../../models/project';
import {LoggerService} from '../../sp-logger/logger.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiAudienceService extends ApiModelService<Audience> {
  protected readonly basePath = "audiences";

  constructor(httpClient: HttpClient, logger: LoggerService) {
    super(httpClient, logger);
  }

  audienceSize(targeting: Targeting, type: string, extra: string): Promise<{ size: number, score: number, score_v2: string }> {
    return firstValueFrom(this.post<{size: number, score: number, score_v2: string}>(`${this.basePath}/size`, {target: targeting.toFbJsonString(), type, extra}));
  }

  /* Other methods with relations go here */

  personaCreate(project: Project, personaId: number) {
    return new Promise<Audience>((resolve, reject) => {
      const sub = this.post<Audience>(`${this.basePath}/${project.audience_target.id}/${project.audience_bench.id}/persona/${personaId}`, {'projectId': project.id}).subscribe(audience => {
        resolve(audience);
      }, error => {
        reject(error);
      }, () => sub.unsubscribe());
    });
  }

  personaTargeting(project: Project, personaId: number) {
    // Abs value for personaId
    personaId = Math.abs(personaId);
    return new Promise<{target: any, benchmark: any}>((resolve, reject) => {
      const sub = this.get<{target: any, benchmark: any}>(`${this.basePath}/${project.audience_target.id}/${project.audience_bench.id}/persona/${personaId}/targeting`).subscribe(result => {
        resolve(result);
      }, error => {
        reject(error);
      }, () => sub.unsubscribe());
    })
  }

  /* ------------------------------------ */
}
