<div class="sp-dialog">
  <div class="sp-dialog-header">
    <h5> Edit {{ asset.name }} image </h5>
  </div>
  <div class="sp-dialog-body">

    <input #fileInput (change)="onCustomImageChange(fileInput)" hidden type="file" accept="image/x-png,image/gif,image/jpeg"/>

    <mat-form-field class="w-100 sp-form-field" appearance="outline">
      <mat-label class="sp-input-label">Dashboard suggestions</mat-label>
      <mat-icon class="sp-select-icon sp-dialog-icon" matPrefix svgIcon="icon_sp_search"></mat-icon>
      <input #assetSearchInput (keyup)="onAssetSearchInputChange(assetSearchInput.value, $event)" [(ngModel)]="selectedSuggestion" [matAutocomplete]="assetsAuto" class="sp-input-content" matInput placeholder="Search suggestions..." />
      <mat-autocomplete #assetsAuto="matAutocomplete" [displayWith]="assetToString">
        <mat-option *ngFor="let suggestion of suggestions" [value]="suggestion">
          <div *ngIf="!suggestion" class="row d-flex no-gutters">
            <div class="col-3 text-center align-self-center ">
              <img class="sp-avatar" [src]="defaultImageUrl">
            </div>
            <div class="col-9 align-self-center">
              <span>Loading...</span><br/>
              <span></span>
            </div>
          </div>
          <div *ngIf="suggestion" class="row d-flex no-gutters">
            <div class="col-2 text-center align-self-center ">
              <img class="sp-avatar" *ngIf="suggestion && suggestion.logo" [src]="suggestion.logo">
            </div>
            <div class="col-5 align-self-center">
              <span>{{ suggestion.name }}</span><br/>
              <span></span>
            </div>
            <div class="col-5 align-self-center text-right">
              <span>{{ suggestion.domain }}</span>
            </div>
          </div>
        </mat-option>
        <mat-option (click)="fileInput.click()">
          <div class="row  d-flex no-gutters">
            <div class="col-1 text-center align-self-center ">
              <i class="icon-sp-import"> </i>
            </div>
            <div class="col-8 align-self-center">
              <span>Upload your own</span>
            </div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="pb-3 sp-dialog-footer">
      <button mat-button class="btn btn-secondary m-1 text-uppercase sp-btn sp-btn-secondary" (click)="onNoClick()">Cancel</button>
      <button [disabled]="pending || !selectedSuggestion" mat-button class="btn btn-primary m-1 text-uppercase sp-btn sp-btn-primary" (click)="confirm()">Save image</button>
    </div>
  </div>
</div>
