export abstract class KeyboardUtils {
  private static readonly nonCharKeyList: string[] = [
    "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight",
    "Enter", "Backspace", "Meta", "Alt", "Control", "Shift", "CapsLock",
    "F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10",
    "F11", "F12", "Escape"
  ];

  private static readonly scrollKeyList: string[] = [
    "Home", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight",
    "PageUp", "PageDown", "End", "Space"
  ];

  public static isValidCharacter(key: string): boolean {
    return !this.nonCharKeyList.includes(key);
  }

  public static isScrollKey(key: string): boolean {
    return this.scrollKeyList.includes(key);
  }
}
