import {Injectable} from '@angular/core';
import {ApiModelService, DataPagination} from "../sp-api-model/api-model.service";
import {Folder} from "../../../models/folder";
import {HttpClient} from "@angular/common/http";
import {Project} from '../../../models/project';
import {LoggerService} from '../../sp-logger/logger.service';
import {firstValueFrom} from "rxjs";
import {ProjectSortType} from "../../sp-project/project-manager.service";

@Injectable({
  providedIn: 'root'
})
export class ApiFolderService extends ApiModelService<Folder> {
  protected readonly basePath = "folders";

  constructor(httpClient: HttpClient, logger: LoggerService) {
    super(httpClient, logger);
  }

  /* Other methods with relations go here */

  projectAdd(folder: Folder, project: Project): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.post(`${this.basePath}/${folder.id}/projects/${project.id}`)
        .subscribe(() => resolve(true), error => reject(error))
    })
  }

  projectRemove(folder: Folder, project: Project): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const sub = this.delete(`${this.basePath}/${folder.id}/projects/${project.id}`)
        .subscribe(() => resolve(true), error => reject(error), () => sub.unsubscribe());
    })
  }

  /* ------------------------------------ */
}
