import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";
import {Model} from "../../../models/model";
import {firstValueFrom} from "rxjs";

export interface DataPagination<T> {
  data: T[];
  links;
  meta: {
    current_page: number,
    total: number,
    from: number,
    last_page: number,
    per_page: number,
    to: number
  };
}

@Injectable({
  providedIn: 'root'
})
export abstract class ApiModelService<T extends Model> extends ApiService {
  protected abstract readonly basePath;

  getAll(params?: any): Promise<T[]|DataPagination<T>> {
    return firstValueFrom(this.get<T[]|DataPagination<T>>(`${this.basePath}`, params));
  }

  getOne(id: number): Promise<T> {
    return firstValueFrom(this.get<T>(`${this.basePath}/${id}`));
  }

  postRaw(url: string, body: any) {
    return firstValueFrom(this.httpClient.post(url, body, {responseType: 'blob'}));
  }

  postOne(model: T): Promise<T> {
    return firstValueFrom(this.post<T>(`${this.basePath}`, model));
  }

  updateOne(model: T): Promise<T> {
    return firstValueFrom(this.put<T>(`${this.basePath}/${model.id}`, model));
  }

  deleteOne(model: T): Promise<boolean> {
    return firstValueFrom(this.delete(`${this.basePath}/${model.id}`));
  }
}
