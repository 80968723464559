import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {image} from 'html2canvas/dist/types/css/types/image';


@Component({
  selector: 'app-sp-modal-info',
  standalone: true,
  imports: [
    MatIconModule,
    NgOptimizedImage,
    NgIf
  ],
  templateUrl: './sp-modal-info.component.html'
})

export class SpModalInfoComponent implements OnInit {
  title = '';
  textContent = '';
  icon = '';
  image = '';
  buttonText = 'Close';

  constructor(
    public dialogRef: MatDialogRef<SpModalInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, content: string, icon?: string, image?: string, buttonText?: string},
  ) {

  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.textContent = this.data.content;
    this.icon = this.data.icon;
    this.image = this.data.image;
    this.buttonText = this.data.buttonText;
  }

}
