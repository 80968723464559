import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpThemingService {

  // Theme name.
  themeSubject = new BehaviorSubject<any>('undefined');
  spThemeName = this.themeSubject.asObservable();

  // Constructor.
  constructor() {
  }

  // Theme name | Get.
  public getSpThemeName() {
    return this.spThemeName;
  }

  // Theme name | Set.
  public setSpThemeName(value: string) {
    this.themeSubject.next(value);
  }
}
