import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FilterPipeUser'
})
export class FilterPipe implements PipeTransform {

  private allUsers = [];
  private allUsersSelected = [];
  private totalUsers = [];

  transform(items: any[], searchText: string): any[] {

    if(!items) return [];
    if(!searchText) return items;
    this.allUsers = (items.filter(x => x.isTemporarySelected == true))


    searchText = searchText.toLowerCase();

    this.allUsersSelected = ( (items.filter( it => {
      return it.first_name.toLowerCase().startsWith(searchText) || it.last_name.toLowerCase().startsWith(searchText);
    })))


    /*
    return items.filter( it => {
      return it.first_name.toLowerCase().startsWith(searchText) || it.last_name.toLowerCase().startsWith(searchText);
    });

     */
    this.totalUsers = this.allUsers.concat(this.allUsersSelected)
    return this.totalUsers;
  }
}
