import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Folder} from '../../../../models/folder';
import {ApiFolderService} from '../../../../services/sp-api/sp-api-folder/api-folder.service';

@Component({
  selector: 'app-sp-modal-project-list-folder-delete',
  templateUrl: './sp-modal-project-list-folder-delete.component.html',
  styleUrls: ['./sp-modal-project-list-folder-delete.component.scss']
})
export class SpModalProjectListFolderDeleteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SpModalProjectListFolderDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Folder,
    private apiFolder: ApiFolderService) { }

  ngOnInit() {
  }

  folderDelete() {
    this.apiFolder.deleteOne(this.data).then(() => {
      this.dialogRef.close(this.data.projects);
    });
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

}
